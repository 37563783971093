import React, { useState, useEffect } from 'react';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import DownArrowIcon from '../../../../assets/downArrow.jsx';
import SearchIcon from '../../../../assets/search';
import {
  MultiselectSearch,
  Label,
  List,
  ListItem,
  Content,
  Box,
  Options,
  Footer,
  MultiselectWrapper,
  Title,
  FinishButton,
  SearchInput,
} from './styles';
import { truncateString } from '../../../../_core/services/formaters';

interface Props {
  options: IOption[];
  className?: string;
  label?: string;
  onPick?: any;
  selectedFromParent: IOption[];
  title?: string;
  singleSelect?: boolean;
  search?: boolean;
  fixedLabel?: string;
  todos?: boolean;
  allSelectedPadrao?: boolean;
  updateFromParent?: boolean;
  disabled?: boolean;
  active?: boolean;
  placeholder?: string;
  smallMinWidth?: boolean;
}

export interface IOption {
  id: any;
  label: string;
  Icon?: string;
  value?: unknown;
}

export const Multiselect: React.FC<Props> = ({
  options,
  className,
  label,
  onPick,
  selectedFromParent,
  title,
  singleSelect,
  search,
  fixedLabel,
  todos,
  allSelectedPadrao,
  updateFromParent,
  disabled,
  active,
  placeholder = 'Selecione',
  smallMinWidth,
}: Props) => {
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState<IOption[]>([]);
  const [searchText, setSearchText] = useState('');
  const [allSelected, setAllSelect] = useState(false);
  const objetoTODOS: IOption = {
    id: options.length + 1,
    label: 'TODOS',
  };

  useEffect(() => {
    setSelected(selectedFromParent);

    if (todos) {
      if (options.filter((o: IOption) => o.label === objetoTODOS.label).length <= 0) {
        options.unshift(objetoTODOS);
      }
    }
    selecionarTudo();
  }, [options]);

  useEffect(() => {
    if (updateFromParent) setSelected(selectedFromParent);
  }, [selectedFromParent]);

  const isSelected = (o: IOption) => {
    const status = selected.filter((s: IOption) => {
      if (s.label === o.label) return true;
      return false;
    });

    return status.length > 0;
  };

  function selecionarTudo() {
    if (allSelectedPadrao) {
      setAllSelect(true);
      setSelected(options);
      const newOptions = options.filter((o: IOption) => o.label !== objetoTODOS.label);
      onPick(newOptions);
    }
  }

  const allToggleSelection = () => {
    if (allSelected) {
      setAllSelect(false);
      setSelected([]);
      onPick([]);
    } else {
      setAllSelect(true);
      setSelected(options);
      const newOptions = options.filter((o: IOption) => o.label !== objetoTODOS.label);
      onPick(newOptions);
    }
  };

  function testarSeTODOSEstaSelecionadoEDevolverOLenght() {
    return selected.filter((o: IOption) => o?.label !== objetoTODOS?.label).length;
  }

  function comparar(item1: IOption, item2: IOption) {
    if (item1.label > item2.label) return 1;
    if (item1.label < item2.label) return -1;
    return 0;
  }

  const onOpen = () => {
    setOpen(true);
    setSearchText('');
  };

  const handleSelect = (option: IOption) => {
    if (!active) {
      if (singleSelect) {
        setSelected([option]);
        onPick([option]);
        setOpen(false);
      } else if (isSelected(option)) {
        const index = selected.findIndex(e => {
          return e.label === option.label;
        });

        let tempOps = [...selected];
        tempOps.splice(index, 1);

        tempOps = tempOps.filter((o: IOption) => o.label !== objetoTODOS.label);
        setSelected(tempOps);
        onPick(tempOps);
      } else {
        setSelected([...selected, option]);
        const tempSelected = [...selected, option];
        if (
          tempSelected.filter((o: IOption) => o.label !== objetoTODOS.label).length ===
          options.filter((o: IOption) => o.label !== objetoTODOS.label).length
        ) {
          setSelected([...tempSelected, objetoTODOS]);
        }
        tempSelected.sort((item1: IOption, item2: IOption) => comparar(item1, item2));
        onPick(tempSelected.filter((o: IOption) => o.label !== objetoTODOS.label));
      }
      if (option.label === objetoTODOS.label && !singleSelect) {
        allToggleSelection();
      }
    }
  };

  const optionsRender = () => {
    let arr: IOption[] = [];
    if (searchText && search) {
      arr = options?.filter(a => a.label.toUpperCase().includes(searchText.toUpperCase()));
    } else {
      arr = options;
    }

    return arr.map((o, i) => {
      return (
        <ListItem onClick={() => handleSelect(o)} key={i} isSelected={!!isSelected(o)}>
          <span>{o.label}</span>
          {o.Icon && (
            <span>
              <o.Icon />
            </span>
          )}
        </ListItem>
      );
    });
  };

  const handleLabel = () => {
    if (fixedLabel) {
      return <p>{fixedLabel}</p>;
    }
    if (singleSelect) {
      return <p>{truncateString(selected[0]?.label, 15) || placeholder}</p>;
    }
    return <p>{testarSeTODOSEstaSelecionadoEDevolverOLenght()} Item(ns)</p>;
  };

  return (
    <MultiselectWrapper
      smallMinWidth={smallMinWidth}
      style={{
        cursor: disabled ? 'not-allowed' : 'default',
      }}
    >
      {label && <Label>{label}</Label>}
      <Content>
        <Box
          className={className}
          onClick={() => onOpen()}
          completed={selected.length > 0}
          style={{ pointerEvents: disabled ? 'none' : 'visible', background: disabled ? '#ebebeb' : '' }}
        >
          {handleLabel()}
          <DownArrowIcon click={null} />
        </Box>
        {isOpen && (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <Options>
              {search && (
                <MultiselectSearch>
                  <SearchInput type="text" placeholder="Pesquisar..." onChange={e => setSearchText(e.target.value)} />
                  <SearchIcon />
                </MultiselectSearch>
              )}
              {title && <Title>{title}</Title>}
              <List search={search === true}>{optionsRender()}</List>
              {!singleSelect && (
                <Footer>
                  <FinishButton onClick={() => setOpen(false)}>OK</FinishButton>
                </Footer>
              )}
            </Options>
          </ClickAwayListener>
        )}
      </Content>
    </MultiselectWrapper>
  );
};
