import React, { useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { TipoReceitaEncargo } from '../interfaces';
import { Button, Form, Input, SingleSelect } from '../../../../../_core/_components';

type ModalProps = {
  onClose: () => void;
  onSubmit: (values: any) => void;
  values?: TipoReceitaEncargo;
  options: Array<{
    label: string;
    value: number;
  }>;
};

const Modal: React.FC<ModalProps> = ({ onClose, onSubmit, values, options }) => {
  const [id] = useState(values?.id ?? '');
  const [revenueType, setRevenueType] = useState(values?.revenueType ?? '');
  const [chargeTypeId, setChargeTypeId] = useState<string | number>(values?.chargeTypeId ?? options[0].value);

  const handleSubmit = () => onSubmit({ id, revenueType, chargeTypeId });

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {values ? 'Editar' : 'Criar'} Tipo de Receita Encargo
        </span>
        <Grid>
          <Form
            items={[
              <Input label="Tipo de receita" placeholder="Tipo de receita" state={[revenueType, setRevenueType]} />,
              <SingleSelect
                placeholder="Encargo"
                options={options}
                state={[
                  options.find(e => e.value === chargeTypeId),
                  option => setChargeTypeId(option?.value as string | number),
                ]}
              />,
            ]}
            submitButton={<Button text="Salvar" onClick={handleSubmit} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
