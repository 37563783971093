import React, { useMemo, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import Table from '../../../_core/_components/table';
import { Button, Checkbox } from '../../../_core/_components';

type Props = {
  values: any[];
  onClose: () => void;
  onClickCancel: (selectedData: number[]) => void;
};

export default function Modal({ values, onClickCancel, onClose }: Props): JSX.Element {
  const [selectedData, setSelectedData] = useState<number[]>([]);

  const renderedData = useMemo(
    () =>
      values.map(item => ({
        toggle: <Checkbox checked={selectedData.includes(item.numBoleto)} onClick={() => clickRow(item.numBoleto)} />,
        numBoleto: item.numBoleto,
        nomFantasia: item.nomFantasia,
        luc: item.luc,
      })),
    [values, selectedData],
  );

  const clickRow = (numBoleto: number) => {
    const arr = [...selectedData];
    const indexOf = arr.indexOf(numBoleto);
    if (indexOf !== -1) {
      setSelectedData(selectedData.filter(e => e !== numBoleto));
    } else {
      setSelectedData([...selectedData, numBoleto]);
    }
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Detalhes
        </span>
      </DialogTitle>
      <DialogContent>
        <Table
          columns={[
            { label: 'Selecionar', key: 'toggle' },
            { label: 'Número do Boleto', key: 'numBoleto', orderable: true },
            { label: 'Razão Social', key: 'nomFantasia', orderable: true },
            { label: 'luc', key: 'luc', orderable: true },
          ]}
          data={renderedData}
        />
      </DialogContent>
      <DialogActions>
        <Button text="Fechar" onClick={onClose} />
        <Button text="Cancelar boletos" onClick={() => onClickCancel(selectedData)} />
      </DialogActions>
    </Dialog>
  );
}
