import React, { createContext, useState } from 'react';

type EncargoType = {
  chargeTypeId: number;
  chargeType: string;
};

export type Shopping = {
  value: number;
  label: string;
};

type PropsInadimplenciaContext = {
  portfolioShoppings: Shopping[];
  setPortfolioShoppings: React.Dispatch<React.SetStateAction<Shopping[]>>;
  shoppings: Shopping[];
  setShoppings: React.Dispatch<React.SetStateAction<Shopping[]>>;
  brands: string[];
  setBrands: React.Dispatch<React.SetStateAction<string[]>>;
  clientes: string[];
  setClientes: React.Dispatch<React.SetStateAction<string[]>>;
  lucs: string[];
  setLucs: React.Dispatch<React.SetStateAction<string[]>>;
  encargos: EncargoType[];
  setEncargos: React.Dispatch<React.SetStateAction<EncargoType[]>>;
};

const DEFAULT_VALUE = {
  portfolioShoppings: [],
  setPortfolioShoppings: () => null,
  shoppings: [],
  setShoppings: () => null,
  brands: [],
  setBrands: () => null,
  clientes: [],
  setClientes: () => null,
  lucs: [],
  setLucs: () => null,
  encargos: [],
  setEncargos: () => null,
};

const InadimplenciaContext = createContext<PropsInadimplenciaContext>(DEFAULT_VALUE);

const InadimplenciaProvider: React.FC = ({ children }) => {
  const [portfolioShoppings, setPortfolioShoppings] = useState<Shopping[]>(DEFAULT_VALUE.portfolioShoppings);
  const [shoppings, setShoppings] = useState<Shopping[]>(DEFAULT_VALUE.shoppings);
  const [brands, setBrands] = useState<string[]>(DEFAULT_VALUE.brands);
  const [clientes, setClientes] = useState<string[]>(DEFAULT_VALUE.clientes);
  const [lucs, setLucs] = useState<string[]>(DEFAULT_VALUE.lucs);
  const [encargos, setEncargos] = useState<EncargoType[]>(DEFAULT_VALUE.encargos);

  return (
    <InadimplenciaContext.Provider
      value={{
        portfolioShoppings,
        setPortfolioShoppings,
        shoppings,
        setShoppings,
        brands,
        setBrands,
        clientes,
        setClientes,
        lucs,
        setLucs,
        encargos,
        setEncargos,
      }}
    >
      {children}
    </InadimplenciaContext.Provider>
  );
};

export { InadimplenciaProvider };
export default InadimplenciaContext;
