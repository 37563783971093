import styled from 'styled-components';
import Pages from '@material-ui/lab/Pagination';

interface IMenuActive {
  active?: boolean;
}

export const PageHeader = styled.h2`
  color: #7e9395;
  margin-bottom: 1rem;
  font-weight: 600;
`;

export const FiltroWrapper = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 0.8rem;
  padding: 2rem 3rem;
  margin-bottom: 1rem;
`;

export const FieldGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 2rem;
  flex: 1;
`;

export const Section = styled.div`
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
`;

export const ColumnStart = styled.div`
  margin: 1.5rem;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.9rem 2.2rem;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3rem;
  font-size: 1.7rem;
`;

export const FormLine = styled.div`
  display: flex;
`;

export const Column = styled.div`
  margin: 1.5rem;
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SideMenu = styled.div`
  width: 20%;
  padding: 0 1rem;
`;

export const ItemMenu = styled.div<IMenuActive>`
  background: ${({ active }) => (active ? '#7E9395' : '#ffffff')};
  border-radius: 1rem;
  color: ${({ active }) => (active ? '#ffffff' : '#7E9395')};
  font-weight: 600;
  font-size: 2rem;
  padding: 0.8rem 3rem;
  margin-bottom: 0.8rem;
  height: 8.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;

  :hover {
    background: #7e939529;
    color: #798c91;
  }
`;

export const ContentContainer = styled.div`
  padding: 2rem;
  background-color: #fff;
  border-radius: 15px;
  position: relative;
  left: 1rem;
  top: -2rem;
  width: 75vw;
  overflow-x: auto;
`;

export const TopBar = styled.div`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

export const SearchBar = styled.div`
  display: flex;
`;
export const SearchField = styled.div`
  margin-right: 5rem;
  position: relative;
  svg {
    fill: #208cb9;
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }
`;

export const SimpleSelect = styled.select`
  border: none;
  background-color: white;
  font-size: 2rem;
  padding: 1rem 5rem;
  border-radius: 4rem;
  color: #7e9395;
`;
export const SimpleSelectOption = styled.option``;

export const SearchInput = styled.input`
  border: none;
  font-size: 2rem;
  padding: 1rem 6rem 1rem 3rem;
  border-radius: 4rem;
  color: #7e9395;
`;

export const AddButton = styled.button`
  border: 0;
  background-color: transparent;
  color: #7e9395;
  font-size: 2rem;
  line-height: 3.2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;

  :hover {
    background-color: white;
    color: #31c6d3;
    padding: 0 2rem;
    font-size: 2.3rem;
    border-radius: 1.3rem;
  }

  img {
    margin-left: 1rem;
    width: 3rem;
  }
`;

export const DataContainer = styled.div`
  width: 80%;
  padding: 0 3rem;
`;

export const Table = styled.table`
  border-collapse: collapse;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 2rem;
  tr {
    &:nth-child(odd) {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &:nth-child(even) {
      background: #e5e9ea 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &:first-child {
      background-color: #31c6d3;
      padding: 1.6rem;
      border-radius: 0.8rem 0.8rem 0rem 0rem;
      color: #fff;
      font-size: 2rem;
      font-weight: 600;
      line-height: 4.3rem;
    }
    td {
      padding: 2.5rem 1.2rem;
      text-align: left;
      color: #3f3f3f;
      font-size: 2rem;
      text-transform: uppercase;
      text-align: left;
    }
    th {
      text-align: left;
      padding: 0 1.8rem;
    }
  }
`;

export const ListActions = styled.div`
  font-size: 1.8rem;
`;

export const ListActionItem = styled.div`
  padding: 1.5rem 1.5rem;
  cursor: pointer;
  font-size: 2rem;
  transition: all 0.5s ease;

  :hover {
    background-color: #7e9395;
    color: white;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50rem;
`;

export const CheckArea = styled.div`
  padding-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

/* Table styles */
export const TableContainer = styled.div`
  margin-top: 3rem;
  overflow-x: auto;
`;

export const CustomTable = styled.table`
  width: 100%;
  font-size: 1.5rem;
  text-align: left;
  border-spacing: 0px;
`;

export const TableRow = styled.tr`
  color: #3f3f3f;

  &:nth-child(even) {
    background-color: #fff;
  }

  &:nth-child(odd) {
    background-color: #e5e9ea;
  }
  &:hover {
    background-color: #e2f4f5;
  }
`;

export const RowItem = styled.td`
  padding: 2rem 1rem;
  .row-select > div {
    width: 15rem;
  }
`;

export const HeaderItem = styled.td`
  color: #7e9395;
  padding: 0rem 1rem;
  font-weight: 700;
`;

export const Pagination = styled(Pages)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    flex-wrap: nowrap;
    padding-left: 20px;
  }
  li {
    width: 28px;
    height: 28px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;

    button {
      border-radius: 0 !important;
      color: #7e9395 !important;
      font-size: 9px !important;
      font-weight: 600 !important;
      span {
        border-radius: 0 !important;
      }
    }

    .MuiPaginationItem-page.Mui-selected {
      background-color: #00959c27;
    }

    .MuiPaginationItem-page:hover {
      background-color: #00959c27;
    }
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  .MuiPaginationItem-root {
    font-size: 1.8rem !important;
    margin: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #edeeee !important;
    color: #7e9395 !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', sans-serif !important ;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: #edeeee !important;
  }

  .MuiPaginationItem-ellipsis {
    border: none !important;
  }
`;

export const AcompanharButton = styled.button`
  background-color: #7e9395;
  color: #fff;
  border: none;
  padding: 0.7rem 1rem;
  border-radius: 50px;
  font-size: 1.6rem;
`;

export const Tick = styled.div`
  margin: auto;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #7e9395;
  border-radius: 2px;

  &.selected {
    background-color: #31c6d3;
    border: none;
  }
`;

export const BodyModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  input {
    width: 100% !important;
  }
`;

export const UploadLabel = styled.label`
  color: #2ac0ce;
  font-weight: 600;
  border: 1px solid #2ac0ce;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const UploadFileRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  #remove-span {
    cursor: pointer;
    margin-left: 1rem;
    margin-bottom: 1rem;
    color: gray;
  }
`;

export const UploadIconImg = styled.img`
  width: 2rem;
`;

export const Button = styled.button`
  background: ${props => (props.disabled ? 'gray' : '#31c6d3')};
  border-radius: 0.7rem;
  color: #fff !important;
  font-size: 1.7rem;
  border: none;
  padding: 1rem 8rem;
`;

export const SecondaryButton = styled.button`
  background: #fff;
  /* border: 1px solid #31c6d3 !important; */
  border-radius: 3.5rem;
  color: #31c6d3 !important;
  font-size: 1.7rem;
  border: none;
  padding: 1rem 3rem;
  font-weight: bold;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

export const TitleAnexo = styled.div`
  color: blue;
  font-weight: 600;
  margin-left: 1rem;
  cursor: pointer;
`;

export const Mandatory = styled.p`
  color: red;
  font-size: 2rem;
`;

export const ModalTitle = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
