import styled from 'styled-components';

export const Container = styled.div`
  display: ${({ hidden }) => (hidden ? 'none' : '')};
  color: #7e9395;
`;

export const SelectArea = styled.div`
  width: 100%;
  font-weight: 600;
  background-color: #ffffff;
  border: 1px solid #7e93954d;
  padding: 1rem;
  border-radius: 32px;
  display: flex;
  justify-content: center;
`;

export const SelectInput = styled.select`
  width: 95%;
  background-color: transparent;
  border: 0;
  color: #7e9395;
`;
