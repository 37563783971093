export const getStatusWorkflow = (pathname: string): string => {
  switch (pathname) {
    case '/analisar-ajuizamento-shopping':
      return 'ANALISE_SHOPPING';
    case '/aprovar-ajuizamento-shopping':
      return 'APROVACAO_SHOPPING';
    case '/analisar-ajuizamento-corporativa':
      return 'ANALISE_CORPORATIVA';
    case '/aprovar-ajuizamento-corporativa':
      return 'APROVACAO_CORPORATIVA';
    case '/validar-ajuizamento':
      return 'APROVACAO_FINAL';
    default:
      return '';
  }
};

export const getTitle = (pathname: string): string => {
  switch (pathname) {
    case '/analisar-ajuizamento-shopping':
      return '';
    case '/aprovar-ajuizamento-shopping':
      return '';
    case '/analisar-ajuizamento-corporativa':
      return '';
    case '/aprovar-ajuizamento-corporativa':
      return '';
    case '/validar-ajuizamento':
      return '';
    default:
      return 'Validar Ajuizamento';
  }
};
