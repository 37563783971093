import React, { useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { Button, Checkbox, Form, Input, Select, SingleSelect, Option } from '../../../../_core/_components';
import { EditUserProperty, INITIAL_STATE } from './reducer';

type Props = {
  open: boolean;
  shoppingsList: Option[];
  perfisList: Option[];
  form: any;
  dispatch: React.Dispatch<{
    type: EditUserProperty;
    payload: any;
  }>;
  onBlurUsername: () => void;
  onClickSave: () => void;
  onClose: () => void;
};

const Modal: React.FC<Props> = ({
  open,
  shoppingsList,
  perfisList,
  form,
  dispatch,
  onBlurUsername,
  onClickSave,
  onClose,
}: Props) => {
  const onEditShoppings = (payload: any) => {
    dispatch({ type: 'SHOPPINGS', payload });
  };

  const disabled = useMemo(() => {
    return [form.userName, form.name, form.profile].some(e => ['', undefined].includes(e));
  }, [form]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar Usuário
        </span>
        <Grid>
          <Form
            items={[
              <Input
                state={[
                  form.userName ?? '',
                  (value: React.SetStateAction<string>) => dispatch({ type: 'USERNAME', payload: value }),
                ]}
                onBlur={onBlurUsername}
                label="Email (Obrigatório)"
              />,
              <Input
                state={[
                  form.name ?? '',
                  (value: React.SetStateAction<string>) => dispatch({ type: 'NAME', payload: value }),
                ]}
                label="Nome (Obrigatório)"
              />,
              <Input
                state={[
                  form.lotacao ?? '',
                  (value: React.SetStateAction<string>) => dispatch({ type: 'LOTACAO', payload: value }),
                ]}
                label="Shopping lotação"
              />,
              <SingleSelect
                options={perfisList}
                state={[form.profile, option => dispatch({ type: 'PROFILE', payload: option })]}
                placeholder="Perfil (Obrigatório)"
              />,
              <Select options={shoppingsList} state={[form.shoppings, onEditShoppings]} placeholder="Shoppings" />,
              <Checkbox
                checked={form.ativo ?? false}
                onClick={() => dispatch({ type: 'ACTIVE', payload: !form.ativo })}
                label="Ativo"
              />,
            ]}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch({ type: 'SET_USER', payload: INITIAL_STATE })} text="Limpar" />
        <Button onClick={onClickSave} text="Salvar" disabled={disabled} />
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
