import { useQuery } from '@tanstack/react-query';

type Params = {
  key: string;
  fn: any;
};

type Response = {
  isError: boolean;
  isLoading: boolean;
  response: any;
};

export function useCache(params: Params): Response {
  const { key, fn } = params;
  const { data: response, isError, isLoading } = useQuery([key], () => fn());
  return { response, isError, isLoading };
}
