export const ROWS_PER_PAGE = 20;

export type IShopping = {
  shoppingId: number;
  shoppingName: string;
};

export type Response = {
  datVencimento: string;
  fiadores: any[];
  flagNegativar: boolean;
  idBoleto: number;
  idShopping: number;
  luc: string;
  nmShopping: string;
  nomFantasia: string;
  numBoleto: number;
  numChamado: string;
  nomeAnexo: string;
  qtdNegativacao: 0;
  quantidadeExcecao: 0;
  serasaJustificatica?: { idSerasaJustificatica: number; txtJustificativa: string };
  statusWorkflowSerasa: { ativo: boolean; codigo: string; id: number; nome: string };
  nomGrupoCobranca: string;
  nomPortifolio: string;
  serasaLoja: {
    idSerasaLoja: number;
    grupoCobranca: string;
    portfolio: string;
    nomRazaoSocial: string;
  };
  txtObservacao: string;
  valTotalVencido: number;
};

export const DATA_MULTISELECT = [
  { value: 0, label: 'NÃO' },
  { value: 1, label: 'SIM' },
];

export type Values = {
  id: number;
  negativado: boolean;
  fiadores: any[];
  justificativa: number;
  observacao: string;
  nrChamado: string;
  anexo: string;
};
