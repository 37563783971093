import styled, { css } from 'styled-components/macro';
import ReactInputMask from 'react-input-mask';

type OrderableProps = {
  arrowDirection?: 'desc' | 'asc';
};

type TableRowProps = {
  mod?: boolean;
};

export const Container = styled.div`
  > h2 {
    color: #7e9395;

    margin-bottom: 1rem;
    font-weight: 600;
  }

  .approveNegative__multiselect {
    padding: 0.9rem 2.2rem;
    p {
    }
  }
`;

export const Mandatory = styled.p`
  color: red;
`;

export const TextInput = styled.input`
  background-color: #fff;
  padding: 0.4rem;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  border-radius: 5rem;
  border: 1px solid #7e93954d;
  width: 100%;

  ::placeholder {
    color: #7e9395;
  }
`;

export const FiltroWrapper = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 0.8rem;
  padding: 2rem 3rem;
`;

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Button = styled.button`
  background: ${props => (props.disabled ? 'gray' : '#31c6d3')};
  border-radius: 0.7rem;
  color: #fff !important;

  border: none;
  padding: 1rem 8rem;
`;

export const SecondaryButton = styled.button`
  background: #fff;
  /* border: 1px solid #31c6d3 !important; */
  border-radius: 3.5rem;
  color: #31c6d3 !important;

  border: none;
  padding: 1rem 3rem;
  font-weight: bold;
`;

export const FieldArea = styled.div`
  margin: 0 3rem;
`;

export const FieldGroup = styled.div`
  /* display: flex; */
  padding-right: 2rem;
  flex: 7;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.9rem 2.2rem;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3rem;
`;

export const CheckboxGroup = styled.div`
  flex: 3;
`;

export const TickGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 4rem 0;
`;

export const Label = styled.div`
  color: #7e9395;

  font-weight: 600;
  margin-left: 1rem;
`;

export const Column = styled.div`
  margin: 1.5rem;
  flex: 1;
`;

export const ColumnStart = styled.div`
  margin: 1.5rem;
`;

export const ColumnReverse = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const Title = styled.div`
  color: #7e9395;

  font-weight: 600;
  margin-left: 1rem;
  display: flex;
  align-items: flex-end;

  svg {
    width: 3rem;
    margin-left: 1rem;
    cursor: pointer;
    top: 1.2rem;
    position: relative;
  }
`;

export const TitleAnexo = styled.div`
  color: blue;

  font-weight: 600;
  margin-left: 1rem;
  cursor: pointer;
`;

export const Section = styled.div`
  margin-top: 2rem;
  gap: 1rem;
`;

/* Table styles */
export const TableContainer = styled.div`
  margin-top: 3rem;
  overflow: visible;
`;

export const CustTable = styled.table`
  width: 100%;

  text-align: left;
  border-spacing: 0px;
  overflow: visible;
`;

export const TableRow = styled.tr<TableRowProps>`
  color: #3f3f3f;

  &:nth-child(4n + 3),
  &:nth-child(4n + 4) {
    background-color: #fff;
  }

  &:nth-child(4n + 1),
  &:nth-child(4n + 2) {
    background-color: #e5e9ea;
  }
  /* &:hover {
    background-color: #e2f4f5;
  } */

  ${props => {
    return (
      props.mod &&
      css`
        b {
          color: #7e9395;
        }
      `
    );
  }}
`;

export const RowItem = styled.td`
  padding: 2rem 1rem;
  .row-select > div {
    width: 15rem;
  }
`;

export const HeaderItem = styled.td`
  color: #fff;
  background-color: #31c6d3;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  padding: 0 10px;
`;

export const Orderable = styled.div<OrderableProps>`
  cursor: pointer;
  width: fit-content;
  svg {
    display: ${props => (props.arrowDirection ? 'initial' : 'none')};
    margin-left: 5px;
    transform: scaleY(${props => (props.arrowDirection === 'desc' ? 1 : -1)});
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  .MuiPaginationItem-root {
    margin: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #edeeee !important;
    color: #7e9395 !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', sans-serif !important ;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: #edeeee !important;
  }

  .MuiPaginationItem-ellipsis {
    border: none !important;
  }
`;

export const Tick = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #7e9395;
  border-radius: 0.4rem;

  &.selected {
    background-color: #31c6d3;
    border: none;
  }
`;

export const ActionArea = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    background-color: #31c6d3;
    color: #fff;
  }
`;

export const ModalTitle = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const BodyModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  input {
    width: 100% !important;
  }
`;

export const InputMask = styled(ReactInputMask)`
  height: 6.5rem;
  width: 100%;
  padding: 1rem 2rem;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 3rem;
`;

export const UploadLabel = styled.label`
  color: #2ac0ce;

  font-weight: 600;
  margin-left: 1rem;
  border: 1px solid #2ac0ce;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UploadFileRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  #remove-span {
    cursor: pointer;
  }
`;

export const FormLine = styled.div`
  display: flex;
`;

export const UploadIconImg = styled.img`
  width: 3rem;
  margin-left: 2rem;
`;
