import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import MoreIcon from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import * as S from './styles';
import * as T from './types';
import api from '../../../_core/api';
import Modal from './modal';
import { AuthContext } from '../../../_main/contexts/auth';
import { Confirmation } from './confimation';
import { failure, handleErrors, success, warning } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { requests } from '../../../requests';
import { useCache } from '../../../_core/cache';
import { Button, Form, Input, Option, Select, SingleSelect, Table } from '../../../_core/_components';
import { getCodigosSerasa } from '../../../requests/endpoints';

const CODIGOS_SERASA = new Map<string, string[]>([
  [
    '/analisar-negativacao',
    ['PESQUISAR', 'PENDENTE_RECOMENDACAO_EQUIPE_CORPORATIVA', 'PENDENTE_RECOMENDACAO_EQUIPE_SHOPPING'],
  ],
  [
    '/aprovar-negativacao',
    ['EXPORTAR', 'PENDENTE_APROVACAO_SUPERINTENDENTE_HOLDING', 'PENDENTE_APROVACAO_SUPERINTENDENTE_SHOPPING'],
  ],
]);

export const AprovarNegativacao: React.FC = () => {
  const { response: responseGruposCobranca, isLoading: isLoadingGruposCobranca } = useCache({
    key: 'getGruposCobranca',
    fn: requests.get('getGruposCobranca'),
  });
  const { response: responsePortfolios, isLoading: isLoadingPortfolios } = useCache({
    key: 'getPortfolios',
    fn: requests.get('getPortfolios'),
  });
  const { response: responseJustificativas, isLoading: isLoadingJustificativas } = useCache({
    key: 'getJustificativas',
    fn: requests.get('getJustificativas'),
  });
  const location = useLocation();
  const { id, profile, shoppings: profileShoppings } = useContext(AuthContext);
  const [justificativas, setJustificativas] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [idBoleto, setIdBoleto] = useState(-1);
  const [marca, setMarca] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [luc, setLuc] = useState('');
  const [nrBoleto, setNrBoleto] = useState('');
  const [shoppingsList, setShoppingsList] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [portfolio, setPortfolio] = useState<Option[]>([]);
  const [portifolioList, setPortifolioList] = useState<Option[]>([]);
  const [grupoCobranca, setGrupoCobranca] = useState<Option[]>([]);
  const [grupoCobrancaList, setGrupoCobrancaList] = useState<Option[]>([]);
  const [customerInSerasa] = useState(false);
  const [customerInOutSerasa] = useState(false);
  const [customerNeverSerasa] = useState(false);
  const [data, setData] = useState<T.Response[]>([]);
  const [values, setValues] = useState<T.Values[]>([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [codigos, setCodigos] = useState<string[]>([]);

  const title = location.pathname.includes('analisar') ? 'Analisar' : 'Aprovar';

  useEffect(() => {
    const getCodigos = async () => {
      try {
        const url = location.pathname;
        const { data } = await getCodigosSerasa({
          idUsuario: id,
          url,
          codigos: CODIGOS_SERASA.get(url),
        });
        if (data.content) {
          setCodigos(data.content.map((e: any) => e.codigo));
        }
      } catch (error) {
        handleErrors(error);
      }
    };
    getCodigos();
  }, []);

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppingsList(listShopping);
      setSelectedShoppings(listShopping);
    }
  }, [profileShoppings]);

  useEffect(() => {
    if (responseGruposCobranca !== undefined) {
      const listGrupoCobranca: Option[] = [];
      responseGruposCobranca.data.content.forEach((item: any) => {
        listGrupoCobranca.push({
          value: item.idGrupoCobranca,
          label: item.nome,
        });
      });
      setGrupoCobrancaList(listGrupoCobranca);
      setGrupoCobranca(listGrupoCobranca);
    }
  }, [responseGruposCobranca]);

  useEffect(() => {
    if (responsePortfolios !== undefined) {
      const listPortfolios: Option[] = [];
      responsePortfolios.data.content.map((item: string) => {
        listPortfolios.push({
          value: item,
          label: item,
        });
        return null;
      });
      setPortfolio(listPortfolios);
      setPortifolioList(listPortfolios);
    }
  }, [responsePortfolios]);

  useEffect(() => {
    if (responseJustificativas !== undefined) {
      const listJustificativas: any[] = [];
      responseJustificativas.data.content.map((item: any) => {
        listJustificativas.push({
          value: item.idSerasaJustificatica,
          label: item.txtJustificativa,
          campoObrigatorio: item.campoObrigatorio,
        });
        return null;
      });
      listJustificativas.unshift({
        value: 999,
        label: 'Selecione',
        campoObrigatorio: '',
      });

      setJustificativas(listJustificativas);
    }
  }, [responseJustificativas]);

  const getFiadores = (vals: any) => {
    if (vals.fiadores) {
      const fiadores = vals.fiadores.map((f: any, i: any) => ({
        value: i,
        label: f.nomeFiador,
      }));
      return fiadores;
    }
    return [];
  };

  const open = useMemo(() => idBoleto !== -1, [idBoleto]);

  const getGrupoCobranca = () => {
    if (grupoCobranca && grupoCobranca.length > 0) {
      return grupoCobranca.map(e => e.value);
    }

    return undefined;
  };

  const getFilters = async () => {
    setLoading(true);
    setData([]);

    const paramAdicionais = ['COBRANÇA CORPORATIVA', 'APROVADOR HOLDING', 'HOLDING'].includes(profile);
    try {
      const response = await api.post(`/workflow/serasa/serasa-negativacao/buscar/boletos-shopping`, {
        idShopping: paramAdicionais
          ? [2, 5, 6, 7, 8, 9, 10, 11, 12, 13, 16, 17, 19, 20, 21, 22, 23, 27, 28, 29, 30, 31, 32, 15]
          : selectedShoppings.map(item => item.value),
        idUsuario: id,
        clientesNegativado: customerInSerasa,
        clientesForamNegativado: customerInOutSerasa,
        clientesNuncaNegativado: customerNeverSerasa,
        marca: marca.length > 0 ? marca : undefined,
        luc: luc.length > 0 ? luc : undefined,
        numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
        numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
        portifolio: paramAdicionais ? portfolio.map(e => e.label) : undefined,
        grupoCobranca: paramAdicionais ? getGrupoCobranca() : undefined,
        status: codigos,
        url: location.pathname,
      });
      if (_.isEmpty(response.data.content)) {
        warning('Não obteve resultados.');
      } else {
        setValues(
          response.data.content.map((item: T.Response) => ({
            id: item.idBoleto,
            negativado: item.flagNegativar,
            fiadores: item.fiadores.map(fiador => ({
              id: fiador.usuarioCadastro.id,
              label: fiador.usuarioCadastro.nome,
            })),
            valTotalVencido: item.valTotalVencido,
            justificativa: item.serasaJustificatica?.idSerasaJustificatica ?? 999,
            observacao: item.txtObservacao ?? '',
            nrChamado: item.numChamado ?? '',
            anexo: item.nomeAnexo ?? '',
          })),
        );
        setData(response.data.content);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onModalSubmit = async (params: any) => {
    setLoading(true);
    try {
      const arr = [...values];
      const index = arr.findIndex(e => e.id === idBoleto);
      arr[index] = { ...arr[index], ...params };
      if (params.file) {
        const formData = new FormData();
        formData.append('file', params.file);
        formData.append('idBoleto', arr[index].id.toString());
        formData.append('idJustificativa', arr[index].justificativa.toString());
        formData.append('idUsuario', id.toString());
        formData.append('numChamado', arr[index].nrChamado);
        formData.append('txtObservacao', arr[index].observacao);
        await api.post('/workflow/serasa/serasa-upload/historico/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        const payload = {
          idBoleto: arr[index].id,
          idJustificativa: arr[index].justificativa,
          idUsuario: id,
          numChamado: arr[index].nrChamado,
          txtObservacao: arr[index].observacao,
        };
        await api.post('/workflow/serasa/serasa-workflow/salvar-historico', payload);
      }
      setIdBoleto(-1);
      getFilters();
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onChangeNegativados = async (boletoId: number) => {
    try {
      const i = values.findIndex(e => e.id === boletoId);
      const arr = [...values];
      arr[i].negativado = !arr[i].negativado;
      const item = data[i];
      const boleto = {
        idBoleto: item.idBoleto,
        loja: {
          idSerasaLoja: item.serasaLoja.idSerasaLoja,
          negativar: arr[i].negativado,
        },
        fiadores: item.fiadores.map(e => ({
          idSerasaBoletoFiador: e.idSerasaBoletoFiador,
          negativar: arr[i].negativado,
        })),
      };
      await api.post('/workflow/serasa/serasa-negativacao/atualizar-negativacao', {
        idUsuario: id,
        boleto,
      });
      setValues(arr);
    } catch (error) {
      handleErrors(error);
    }
  };

  const submitNegative = async () => {
    let disabled = false;
    let boletoId = 0;
    values.forEach(e => {
      if (!e.negativado && (!e.justificativa || e.justificativa === 999)) {
        disabled = true;
        boletoId = e.id;
      }
    });
    if (disabled) {
      const boleto = data.find(e => e.idBoleto === boletoId);
      return failure(`O boleto ${boleto?.numBoleto} precisa de uma justificativa.`);
    }
    setLoading(true);
    try {
      const boletos = data.map((item, i) => ({
        idBoleto: item.idBoleto,
        loja: {
          idSerasaLoja: item.serasaLoja.idSerasaLoja,
          negativar: values[i].negativado,
        },
        fiadores: item.fiadores.map(e => ({
          idSerasaBoletoFiador: e.idSerasaBoletoFiador,
          negativar: values[i].negativado,
        })),
      }));
      await api.post('/workflow/serasa/serasa-negativacao/submeter-recomendacao', {
        idUsuario: id,
        boletos,
      });
      await getFilters();
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
    setConfirmOpen(false);
    return null;
  };

  const flatNegativar = (boletoId: number) => {
    const item = values.find(e => e.id === boletoId);
    if (item?.negativado) {
      return T.DATA_MULTISELECT[1];
    }
    return T.DATA_MULTISELECT[0];
  };

  const limparObservacoes = async () => {
    setLoading(true);
    setIdBoleto(-1);
    try {
      await api.post('/workflow/serasa/serasa-workflow/historico/limpar', {
        idBoleto,
        idUsuario: id,
      });
      getFilters();
      success('Submetido com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const download = async () => {
    setLoading(true);
    const paramAdicionais = ['COBRANÇA CORPORATIVA', 'APROVADOR HOLDING', 'HOLDING'].includes(profile);
    try {
      const response = await api.post(
        `/workflow/serasa/serasa-negativacao/download/boletos`,
        {
          idShopping: paramAdicionais ? profileShoppings?.map(e => e.id) : selectedShoppings.map(item => item.value),
          idUsuario: id,
          clientesNegativado: customerInSerasa,
          clientesForamNegativado: customerInOutSerasa,
          clientesNuncaNegativado: customerNeverSerasa,
          marca: marca.length > 0 ? marca : undefined,
          luc: luc.length > 0 ? luc : undefined,
          numBoleto: nrBoleto.length > 0 ? nrBoleto : undefined,
          numCpfcnpj: cnpj.length > 0 ? cnpj : undefined,
          portifolio: paramAdicionais ? portfolio.map(e => e.label) : undefined,
          grupoCobranca: paramAdicionais ? grupoCobranca.map(e => e.value) : undefined,
          status: codigos,
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const formItems = () => {
    const items = [
      <Input label="Marca" state={[marca, setMarca]} />,
      <Input label="CNPJ/CPF" state={[cnpj, setCnpj]} />,
      <Input label="LUC" state={[luc, setLuc]} />,
      <Input label="Número do boleto" state={[nrBoleto, setNrBoleto]} />,
    ];
    if (['APROVADOR HOLDING', 'COBRANÇA CORPORATIVA'].includes(profile)) {
      items.push(<Select state={[portfolio, setPortfolio]} options={portifolioList} placeholder="Portfólios" />);
      items.push(
        <Select
          state={[grupoCobranca, setGrupoCobranca]}
          options={grupoCobrancaList}
          placeholder="Grupos de cobrança"
        />,
      );
    } else {
      items.push(
        <Select
          placeholder="Shoppings"
          state={[selectedShoppings, setSelectedShoppings]}
          options={shoppingsList}
          disabled={profile.includes('SHOPPING')}
        />,
      );
    }
    return items;
  };

  const columns = useMemo(() => {
    const columns1 = [
      { label: 'Nome do Shopping', key: 'nmShopping', orderable: true },
      { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
      { label: 'Razão Social', key: 'nomRazaoSocial', orderable: true },
    ];
    const columns2 = [
      { label: 'LUC', key: 'luc', orderable: true },
      { label: 'Vencimento do boleto', key: 'datVencimento', orderable: true },
      { label: 'Número do boleto', key: 'numBoleto', orderable: true },
      { label: 'Valor do Boleto', key: 'valTotalVencido', orderable: true },
      { label: 'Qtd de negativações', key: 'qtdNegativacao', orderable: true },
      { label: 'Qtd de exceções', key: 'quantidadeExcecao', orderable: true },
      { label: 'Fiadores', key: 'fiadores', orderable: false },
      { label: 'Negativar', key: 'negativar', orderable: false },
      { label: 'Add', key: 'add', orderable: false },
    ];
    if (profile === 'COBRANÇA CORPORATIVA') {
      return [
        ...columns1,
        { label: 'Grupo de cobrança', key: 'grupoCobranca', orderable: true },
        { label: 'Portfolio', key: 'portfolio', orderable: true },
        ...columns2,
      ];
    }
    return [...columns1, ...columns2];
  }, [profile]);

  const renderSubItems = (item: T.Response) => {
    const arr = [];
    if (item.serasaJustificatica?.txtJustificativa) {
      arr.push(<b>Justificativa: </b>);
      arr.push(item.serasaJustificatica.txtJustificativa);
    }
    if (item.numChamado) {
      arr.push(<b>Nr. do chamado: </b>);
      arr.push(item.numChamado);
    }
    if (item.txtObservacao) {
      arr.push(<b>Observação: </b>);
      arr.push(item.txtObservacao);
    }
    return arr.length > 0 ? arr : undefined;
  };

  const renderedData = () => {
    return data.map(item => ({
      nmShopping: item.nmShopping,
      nomFantasia: item.nomFantasia,
      nomRazaoSocial: item.serasaLoja.nomRazaoSocial,
      luc: item.luc,
      datVencimento: item.datVencimento,
      numBoleto: item.numBoleto,
      valTotalVencido: item.valTotalVencido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      qtdNegativacao: item.qtdNegativacao,
      quantidadeExcecao: item.quantidadeExcecao,
      grupoCobranca: item.nomGrupoCobranca,
      portfolio: item.nomPortifolio,
      fiadores: <Select state={[getFiadores(item), () => null]} options={getFiadores(item)} />,
      negativar: (
        <SingleSelect
          state={[flatNegativar(item.idBoleto), () => onChangeNegativados(item.idBoleto)]}
          options={T.DATA_MULTISELECT}
        />
      ),
      add: (
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          color="primary"
          component="span"
          onClick={() => setIdBoleto(item.idBoleto)}
        >
          <MoreIcon />
        </IconButton>
      ),
      subItems: renderSubItems(item),
    }));
  };

  return (
    <>
      <FullScreenLoading
        isEnabled={loading || isLoadingGruposCobranca || isLoadingJustificativas || isLoadingPortfolios}
      />
      <S.Container>
        <Form items={formItems()} submitButton={<Button onClick={getFilters} text="Pesquisar" />} />
        {renderedData().length > 0 && (
          <>
            <Table columns={columns} data={renderedData()} />
            <S.Section>
              <Button text="Exportar" onClick={download} />
              <Button
                text={title === 'Aprovar' ? 'Aprovar Negativação' : 'Submeter Recomendação'}
                onClick={() => setConfirmOpen(true)}
              />
            </S.Section>
          </>
        )}
      </S.Container>
      {open && (
        <Modal
          onClose={() => setIdBoleto(-1)}
          onSubmit={onModalSubmit}
          limparObservacoes={limparObservacoes}
          values={values.find(e => e.id === idBoleto)!}
          justificativas={justificativas}
        />
      )}
      <Confirmation onClose={() => setConfirmOpen(false)} open={confirmOpen} onConfirm={submitNegative} />
    </>
  );
};
