import React, { useEffect, useState } from 'react';
import moment from 'moment';
import api from '../../../_core/api';
import LoadingIcon from '../../../assets/loader.jsx';
import icon from '../../../assets/cloud-computing.svg';
import { Container, Content, ImportArea, LoadingBox, Sucess, InputGroupColumn } from './styles';
import { handleErrors } from '../../../_core/services/toast';
import { SingleSelect, Option } from '../../../_core/_components';

export const ImportarDadosOracle: React.FC = () => {
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [uploadingOracle, setUploadingOracle] = useState<boolean>(false);
  const [sucessOracle, setSucessOracle] = useState<boolean>(false);
  const [oracleShopping, setOracleShopping] = useState<Option>();

  useEffect(() => {
    const getShoppings = async () => {
      const payload = await api.get('/charge-legal-information/shoppings');
      setShoppings(
        payload.data.map((item: any) => ({
          value: item.shoppingId,
          label: item.shoppingName,
        })),
      );
    };
    getShoppings();
  }, []);

  const handleSendButton = (postType: string) => {
    if (postType === 'inputOracle') {
      setUploadingOracle(true);
      sendFormOracle();
    }
  };

  const sendFormOracle = async () => {
    const fileInput = document.getElementById('inputOracleFile') as HTMLInputElement;
    if (fileInput.files![0] == null || oracleShopping === undefined) return setUploadingOracle(false);
    try {
      const dateForm = moment().format('MM/YYYY');
      const formData = new FormData();
      formData.append('file', fileInput.files![0]);
      formData.append('dateReference', dateForm);
      formData.append('spreadSheetType', 'ORACLE');
      formData.append('shopping', oracleShopping.label);
      await api.post('/producer/updload/spreadsheet', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSucessOracle(true);
    } catch (error) {
      handleErrors(error);
    }
    setUploadingOracle(false);
  };

  return (
    <Container>
      <Content>
        <ImportArea>
          <h2>Buscar arquivos</h2>
          <img src={icon} alt="" />
          <InputGroupColumn>
            <SingleSelect placeholder="Shoppings" state={[oracleShopping, setOracleShopping]} options={shoppings} />
            <input id="inputOracleFile" type="file" />
          </InputGroupColumn>
          <LoadingBox active={uploadingOracle}>
            <LoadingIcon />
          </LoadingBox>
        </ImportArea>
        {sucessOracle && <Sucess>Importado com sucesso!</Sucess>}
        <button type="button" onClick={() => handleSendButton('inputOracle')}>
          Importar
        </button>
      </Content>
    </Container>
  );
};
