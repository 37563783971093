import styled from 'styled-components';

import Pages from '@material-ui/lab/Pagination';

import DatePicker from 'react-datepicker';

interface checkBoxProps {
  active?: boolean;
  all?: boolean;
  onClick?: any;
}

interface Itab {
  active?: boolean;
  onClick?: any;
  disabled?: any;
}
interface ITabConent {
  active?: boolean;
  disabled?: any;
}
interface IActionMore {
  active?: boolean;
}
interface ISelect {
  active?: boolean;
  onClick?: any;
  disabled?: any;
  hidden?: boolean;
}

export const ClearButtonModal = styled.div`
  width: 10%;
  background-color: #00959c;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
  padding: 7px 15px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;

  h2 {
    color: #7e9395;
    font-size: 20px;
    margin-bottom: 10px;
    font-weight: 600;
  }
`;

export const ActionArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0 3rem;
  button {
    background-color: #31c6d3;
    color: #fff;
    display: flex;
    justify-content: center;
  }
`;

export const DivVoltar = styled.div`
  /* margin-top: -40px; */
  padding: 15px 0;
  cursor: pointer;
  p {
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 16px/22px
      var(--unnamed-font-family-open-sans);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-7e9395);
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #7e9395;
    opacity: 0.5;
  }
`;

export const Resume = styled.div`
  // width: 48%;
`;

export const Dpicker = styled(DatePicker)`
  margin: 2px;
  border: 1px solid #7e93954d;
  border-radius: 10px;
  display: block;
  width: 100%;
  height: 39px;
  padding: 8px 10px;
  text-align: left;
  color: #7e9395;
  margin: 2px;
`;

export const NegotiationArea = styled.div<IActionMore>`
  display: ${({ active }) => (active ? 'flex' : 'flex')};
  opacity: ${({ active }) => (active ? '1' : '0')};
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 50px;
  h2 {
    width: 100%;
    color: #7e9395;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
  }
`;
export const BoxNegotiation = styled.div`
  width: 21%;
  margin-right: 30px;
  margin-bottom: 20px;
  div {
  }
  textarea {
    width: 99%;
    height: 100px;
    background-color: #fff;
    border: 1px solid #e5e9ea;
    border-radius: 10px;
    margin-top: 2px;
  }
`;
export const ButtonNegotiation = styled.div`
  width: 100%;
  background-color: #00959c;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
  padding: 7px 15px;
  text-transform: uppercase;
  cursor: pointer;
`;
export const SelectActive = styled.div`
  // width: 48%;
`;
export const Option = styled.div`
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #3f3f3f;
  opacity: 1;
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    border: 0;
    color: #7e9395;
    font-size: 16px;
    padding: 10px;
    background-color: transparent;
  }
`;

export const FilterBar = styled.div`
  background-color: #fff;
  padding: 25px;
  border-radius: 12px;
  margin-right: 20px;
  min-height: 134px;
  min-width: 620px;
`;

export const Box = styled.div<Itab>`
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  justify-content: flex-start;
  align-items: center;
  div {
    margin-right: 0px;
    padding: 0 10px;
  }
  &:first-child {
    margin-bottom: 30px;
    justify-content: space-between;
    div {
      margin: 0;
      padding: 0 10px;
      padding-right: 40px;
      white-space: nowrap;
    }
  }
  &:nth-child(2) {
    margin-bottom: 30px;
    justify-content: space-between;
    div {
      margin: 0;
      padding: 0 10px;
      padding-right: 40px;
      white-space: nowrap;
    }
  }
`;

export const Item = styled.div<Itab>`
  display: ${({ disabled }) => (disabled ? 'none' : '')};
  color: #7e9395;
  font-size: 16px;
  span {
  }
  p {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
  }
`;

export const ItemSelect = styled.div<Itab>`
  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  color: #7e9395;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  p {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
  }
`;

export const FilterCheck = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  width: 50%;
`;

export const Check = styled.div`
  width: 100%;
  margin: 20px 0;
`;
export const CheckBox = styled.div<checkBoxProps>`
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background: ${({ active }) => (active ? '#31C6D3' : 'transparent')};
  border: ${({ active }) => (active ? '1px solid #7E93954D' : '1px solid #7e9395')};
`;

export const Checks = styled.div`
  border-left: 1px solid #7e9395;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  label {
    min-width: 190px;
    position: relative;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    input {
      position: 0;
      width: 0;
      height: 0;
      left: 0;

      &:checked ~ span {
        background-color: #31c6d3;
        color: #fff;
      }
    }
    span {
      display: block;
      min-width: 190px;
      text-align: center;
      white-space: nowrap;
      background-color: #ffffff;
      color: #7e9395;
      font-size: 12px;
      font-weight: 600;
      padding: 5px 10px;
      border-radius: 50px;
      box-shadow: 3px 3px 6px #7e939526;
    }
  }
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  button {
    border-radius: 20px;
    border: 0;
    background-color: #fff;
    color: #00959c;
    font-weight: 600;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    img {
      margin-left: 10px;
    }
  }
  span {
    color: #00959c;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const PaginationT = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  li {
    width: 32px;
    height: 32px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;
    color: #7e9395;
    font-size: 12px;
    font-weight: 600;
  }
`;

export const Tabela = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
    max-width: 1480px;
    white-space: nowrap;
    thead {
      tr {
        background-color: #f2f4f4;
        opacity: 1;
        cursor: pointer;
        th {
          padding: 15px 8px;
          text-align: left;
          color: #7e9395;
          font-weight: 600;
          font-size: 12px;
          img {
            width: 13px !important;
            height: 7px !important;
            margin: auto !important;
            margin-left: 4px !important;
          }
          .semOrdenacao {
            display: none;
          }
          .crescente {
            transform: rotate(-180deg);
          }
          .decrescente {
          }
        }
      }
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background: #ffffff 0% 0% no-repeat padding-box;
          opacity: 1;
        }
        &:nth-child(even) {
          background: #e5e9ea 0% 0% no-repeat padding-box;
          opacity: 1;
        }
        td {
          padding: 15px 8px;
          text-align: left;
          color: #3f3f3f;
          font-size: 12px;
          text-transform: uppercase;
          img {
            margin-left: 10px;
          }
          button {
            border: 0;
            background: transparent;
            color: #3f3f3f;
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          text-align: left;
          font: normal normal 600 14px Open Sans;
          letter-spacing: 0.7px;
          color: #00959c;
          text-transform: uppercase;
          opacity: 1;
          padding: 15px 8px;
          p {
            text-align: right;
            font: normal normal 600 14px Open Sans;
            letter-spacing: 0.7px;
            color: #7e9395;
            text-transform: uppercase;
            opacity: 1;
          }

          button {
            border-radius: 20px;
            border: 0;
            background-color: #fff;
            color: #00959c;
            font-weight: 600;
            padding: 8px 15px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 14px;
            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
`;

export const HeadTable = styled.div`
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  button {
    border-radius: 20px;
    border: 0;
    background-color: #fff;
    color: #00959c;
    font-weight: 600;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 30px;
    img {
      margin-left: 10px;
      width: 15px;
      margin-top: 0 !important;
    }
  }
`;

export const FilterSearch = styled.div`
  width: 100%;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
  button {
    border-radius: 20px;
    border: 0;
    background-color: #fff;
    color: #00959c;
    font-weight: 600;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 30px;
    img {
      margin-left: 10px;
      width: 15px;
      margin-top: 0 !important;
    }
  }
`;

export const Search = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #7e939526;
  border-radius: 50px;
  opacity: 1;
  padding: 0 15px;
  /* margin-right: 30px; */

  input {
    width: 100%;
    background: transparent;
    border: 0;
    padding: 8px 10px;
    margin-left: 10px;
  }
  img {
    width: 18px !important;
    margin-top: 0 !important;
  }
`;

// contatos dos clientes

export const Title = styled.h1`
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #7e9395;
  opacity: 1;
  margin-bottom: 10px;
`;

export const Records = styled.div<Itab>`
  /* display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  // width: 48%;
  flex-wrap: wrap; */
`;

export const Div = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  h2 {
    width: 60%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #7e9395;
    padding: 30px 0;
    border-bottom: 2px solid #707070;
    margin-bottom: 20px;
  }
`;

export const RecordsBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  div {
    margin-right: 40px;
    padding: 0 10px;
  }
  &:first-child {
    margin-bottom: 15px;
    justify-content: space-between;
    div {
      margin: 0;
      padding: 0 10px;
      padding-right: 40px;
      white-space: nowrap;
    }
  }
  :nth-child(2) {
    div:nth-child(2) {
      padding: 0;
    }
  }
`;

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const TabItem = styled.div<Itab>`
  background-color: ${({ active }) => (active ? '#ffffff' : '#e5e9ea')};
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  padding: 10px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #7e9395;
  opacity: ${({ active }) => (active ? '1' : '0.5')};
  margin-right: 1px;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : '')};
`;

export const AddTab = styled.div<Itab>`
  background-color: #31c6d3;
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  margin-right: 1px;
  color: #fff;
  cursor: pointer;
`;

export const Content = styled.div<ITabConent>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  width: 100%;
  background: #fff;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  flex-wrap: nowrap;
  min-height: 152px;
  min-width: 481px;
  max-width: 1000px;
  h2 {
    width: 100%;
  }
`;

export const Fields = styled.div`
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const FieldBox = styled.div``;

export const CloseModal = styled.button`
  position: absolute;
  right: 0;
  border: 0;
  background: transparent;
  color: #707070;
  padding: 20px;
  cursor: pointer;
`;

export const FieldModal = styled.div`
  li {
    margin: 25px 0;
  }
  p {
    margin-bottom: 30px;
  }
  span {
    display: block;
  }
`;

export const Label = styled.span`
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #7e9395;
  opacity: 1;
  margin-bottom: 10px;
  display: block;
  white-space: nowrap;
`;

export const Field = styled.p`
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #7e9395;
  opacity: 1;
  min-width: 200px;
`;

export const MoreContacts = styled.button`
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #31c6d3;
  background-color: transparent;
  border: 0;
  margin-left: 10px;
`;

export const ActionBoxContato = styled.div`
  display: flex;
  flex-wrap: wrap;
  opacity: 1;
`;

export const ActionBox = styled.div<Itab>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-wrap: wrap;
  opacity: 1;
  text-align: center;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0.6px;
  color: #ffffff;
  position: absolute;
  top: -6px;
  left: -3px;
`;

export const ActionEdit = styled.div`
  background: #7e9395 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  width: 94px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionDelete = styled.div`
  background: #7e9395 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  width: 94px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteButton = styled.button`
  border: 2px solid #7e9395;
  border-radius: 50px;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0.7px;
  color: #7e9395;
  background-color: transparent;
  padding: 10px 20px;
  font-weight: 600;
  white-space: nowrap;
  height: 42px;
`;

export const EditButton = styled.button`
  background: #7e9395;
  box-shadow: 3px 3px 6px #7e939526;
  border: 2px solid #7e9395;
  border-radius: 50px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #ffffff;
  padding: 10px 20px;
  white-space: nowrap;
  height: 42px;
  margin: 8px 0;
`;

// end contatos dos clientes

export const TabMenuTable = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TabMenuItem = styled.button<IActionMore>`
  margin-right: 40px;
  background-color: transparent;
  border: 0;
  border-bottom: ${({ active }) => (active ? ' 3px solid #31c6d3' : 'none')};
  padding: 5px 0;
`;

export const ContentTable = styled.div<IActionMore>`
  display: ${({ active }) => (active ? 'block' : 'none')};
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
`;

export const Thead = styled.thead``;

export const Tr = styled.tr`
  cursor: pointer;
`;

export const Th = styled.th`
  text-align: left;
  color: #7e9395;
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
`;

export const Tbody = styled.tbody`
  tr {
    height: 62px;
    cursor: pointer;
    &:nth-child(odd) {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &:nth-child(even) {
      background: #e5e9ea 0% 0% no-repeat padding-box;
      opacity: 1;
    }
  }
`;

export const Td = styled.td`
  padding: 15px 10px;
  text-align: left;
  color: #3f3f3f;
  font-size: 11px;
  text-transform: uppercase;
  position: relative;
  button {
    border: 0;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Pagination = styled(Pages)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    flex-wrap: nowrap;
    padding-left: 20px;
  }
  li {
    width: 28px;
    height: 28px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;

    button {
      border-radius: 0 !important;
      color: #7e9395 !important;
      font-size: 9px !important;
      font-weight: 600 !important;
      span {
        border-radius: 0 !important;
      }
    }

    .MuiPaginationItem-page.Mui-selected {
      background-color: #00959c27;
    }

    .MuiPaginationItem-page:hover {
      background-color: #00959c27;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

export const SelectModal = styled.div<ISelect>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ModalContainer = styled.div<ISelect>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  position: fixed;
  margin: 0 auto;
  width: 85vw;
  height: 85vh;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  flex-direction: column;
  z-index: 11;
  overflow: auto;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  h3 {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: #00959c;

    margin-bottom: 2rem;
  }
  input {
    width: 64rem;
    height: 7.7rem;
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    background-color: #f2f4f4;
    padding-left: 4rem;
    padding-right: 4rem;

    text-transform: uppercase;
    color: #00959c;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    &::placeholder {
      color: #ccc;
      font-size: 20px;
    }
  }
  ul {
  }
`;

export const CloseSelectModal = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #00959c;
  background-color: transparent;
  border: 0;
  font-weight: bold;
`;

export const SelectedCard = styled.div`
  margin-top: 30px;
  padding: 30px 0;
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  h5 {
    color: #484848;

    font-weight: 700;
    margin-right: 20px;
  }
  span {
    background-color: #00959c;
    padding: 10px 15px;
    color: #fff;
    border-radius: 40px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    button {
      background-color: transparent;
      border: 0;
      margin-left: 5px;
      color: #fff;
    }
  }
`;

export const ListCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  ul {
    margin: 15px 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    align-items: flex-start;
    li {
      max-width: 30%;
      min-width: 18%;
      width: 100%;
      height: 35px;
      cursor: pointer;
      list-style: none;
      display: flex;
      color: #484848;

      transition: all 0.3s;
      margin: 1rem 1rem 0 0;
      align-items: center;
      /* flex: 0 0 3.2rem; */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 10px 10px;
      animation: opaqueIn 0.4s ease-out;
      &:nth-child(even) {
        background: #e2f4f5;
      }
      &:nth-child(odd) {
        background: #edeeee;
      }
      &:hover {
        background-color: #00959c;
        color: #fff;
      }
    }
  }
`;
