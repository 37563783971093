import styled from 'styled-components';

interface IMenuActive {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SideMenu = styled.div`
  width: 20%;
  padding: 0 1rem;
`;

export const ItemMenu = styled.div<IMenuActive>`
  background: ${({ active }) => (active ? '#7E9395' : '#ffffff')};
  border-radius: 1rem;
  color: ${({ active }) => (active ? '#ffffff' : '#7E9395')};
  font-weight: 600;

  padding: 0.8rem;
  margin-bottom: 0.8rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;

  :hover {
    background: #7e939529;
    color: #798c91;
  }
`;

export const Content = styled.div`
  width: 80%;
  padding: 0 1rem;
`;

export const Filter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const RightSideMenu = styled.div`
  width: 20%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ActionsSideMenu = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: 30px;
`;
