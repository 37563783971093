import React, { useMemo, useState } from 'react';
import { InadimplenciaProvider } from '../../../_main/contexts/inadimplencia';
import { Container, SideMenu, ItemMenu } from './styles';
import Portfolio from './portfolio';
import KeyAccounts from './ka';
import Intercompany from './intercompany';
import TipoUnidade from './tipo-unidade';
import TipoAjuste from './tipo-ajuste';
import TipoReceitaEncargo from './tipo-receita-encargo';
import ParametrosCalculo from './parametros-calculo';
import DataCorte from './data-corte';

const MENU_LIST = [
  'Portfólio',
  'Listas dos KA',
  'Cobrança Corporativa',
  'Intercompany',
  'Tipo Unidade de Negócio Inadimplência',
  'Tipo de Ajuste',
  'Tipo de Receita Encargo',
  'Parâmetros Cálculo',
  'Data Corte e Prévia',
];

export const CadastroInadimplencia: React.FC = () => {
  const [menu, setMenu] = useState(0);

  const menuItem = useMemo(() => {
    switch (menu) {
      case 0:
        return <Portfolio />;
      case 1:
        return <KeyAccounts />;
      case 3:
        return <Intercompany />;
      case 4:
        return <TipoUnidade />;
      case 5:
        return <TipoAjuste />;
      case 6:
        return <TipoReceitaEncargo />;
      case 7:
        return <ParametrosCalculo />;
      case 8:
        return <DataCorte />;
      default:
        return <></>;
    }
  }, [menu]);

  return (
    <InadimplenciaProvider>
      <Container>
        <SideMenu>
          {MENU_LIST.map((item: any, i: number) => (
            <ItemMenu key={i} onClick={() => setMenu(i)} active={i === menu}>
              {item}
            </ItemMenu>
          ))}
        </SideMenu>
        {menuItem}
      </Container>
    </InadimplenciaProvider>
  );
};
