import styled, { keyframes } from 'styled-components';
import Pages from '@material-ui/lab/Pagination';

interface Itab {
  active?: boolean;
  onClick?: any;
  disabled?: any;
  hidden?: boolean;
}
interface ISelect {
  active?: boolean;
  onClick?: any;
  disabled?: any;
  hidden?: boolean;
}

export const Container = styled.div`
  h2 {
    color: #7e9395;

    margin-bottom: 1rem;
    font-weight: 600;
  }

  > p {
    color: #00959c;
  }
`;

export const FiltroWrapper = styled.div`
  border-radius: 10px;
  min-height: 30rem;
  display: flex;
  flex-wrap: wrap;
`;

export const ListCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  ul {
    margin: 15px 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    align-items: flex-start;
    li {
      max-width: 30%;
      min-width: 18%;
      width: 100%;
      height: 35px;
      cursor: pointer;
      list-style: none;
      display: flex;
      color: #484848;

      transition: all 0.3s;
      margin: 1rem 1rem 0 0;
      align-items: center;
      /* flex: 0 0 3.2rem; */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 10px 10px;
      animation: opaqueIn 0.4s ease-out;
      &:nth-child(even) {
        background: #e2f4f5;
      }
      &:nth-child(odd) {
        background: #edeeee;
      }
      &:hover {
        background-color: #00959c;
        color: #fff;
      }
    }
  }
`;

export const TickSelect = styled.div<ISelect>`
  flex: 0 0 25%;
  min-height: 12.5rem;
  box-shadow: 0.5rem 1rem 2rem var(--grey-light-4);
  border-radius: 5px;
  background-color: #fff;
  margin: 2rem 2.5rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 15px;
  cursor: pointer;
`;

export const TickTitle = styled.div`
  font-weight: 600;
  color: #00959c;
  text-transform: capitalize;
`;

export const TickSubtitle = styled.div`
  color: #00959c;
  text-transform: uppercase;
  width: 100%;
`;

export const SelectedCard = styled.div`
  margin-top: 30px;
  padding: 30px 0;
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;

  h5 {
    color: #484848;

    font-weight: 700;
    margin-right: 20px;
  }
  span {
    background-color: #00959c;
    padding: 10px 15px;
    color: #fff;
    border-radius: 40px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    button {
      background-color: transparent;
      border: 0;
      margin-left: 5px;
      color: #fff;
    }
  }
`;

export const SelectModal = styled.div<ISelect>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ModalContainer = styled.div<ISelect>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  position: fixed;
  margin: 0 auto;
  width: 85vw;
  height: 85vh;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  flex-direction: column;
  z-index: 11;
  overflow: auto;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  h3 {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: #00959c;

    margin-bottom: 2rem;
  }
  input {
    width: 64rem;
    height: 7.7rem;
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    background-color: #f2f4f4;
    padding-left: 4rem;
    padding-right: 4rem;

    text-transform: uppercase;
    color: #00959c;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    &::placeholder {
      color: #ccc;
      font-size: 20px;
    }
  }
  ul {
  }
`;

export const CloseSelectModal = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #00959c;
  background-color: transparent;
  border: 0;
  font-weight: bold;
`;

export const ButtonTable = styled.button`
  background: #ffffff;
  box-shadow: 3px 3px 6px #7e939526;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #00959c;
  text-transform: uppercase;
  border: 0;
  padding: 8px 15px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Section = styled.div`
  h2 {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    padding-right: 200px;
  }
`;

export const FieldGroup = styled.div`
  display: flex;
  margin: 2rem 0;
  align-items: flex-end;
`;

export const FieldArea = styled.div<Itab>`
  display: ${({ hidden }) => (hidden ? 'none' : '')};
  margin: 0 3rem;
  flex: 1;

  &.streched {
    align-self: center;
    justify-content: center;
    flex: 2;
  }
`;

export const Label = styled.div`
  color: #7e9395;

  font-weight: 600;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

export const ActionArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0 3rem;
  gap: 5px;
  button {
    background-color: #31c6d3;
    color: #fff;
    display: flex;
    justify-content: center;
  }
`;

/* Table styles */
export const TableContainer = styled.div`
  margin-top: 3rem;

  > p {
  }
`;

export const CustTable = styled.table`
  width: 100%;

  text-align: left;
  border-spacing: 0px;
`;

export const TableRow = styled.tr`
  color: #3f3f3f;

  &:nth-child(even) {
    background-color: #fff;
  }

  &:nth-child(odd) {
    background-color: #e5e9ea;
  }
  &:hover {
    background-color: #e2f4f5;
  }
`;

export const Pagination = styled(Pages)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    flex-wrap: nowrap;
    padding-left: 20px;
  }
  li {
    width: 28px;
    height: 28px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;

    button {
      border-radius: 0 !important;
      color: #7e9395 !important;
      font-size: 9px !important;
      font-weight: 600 !important;
      span {
        border-radius: 0 !important;
      }
    }

    .MuiPaginationItem-page.Mui-selected {
      background-color: #00959c27;
    }

    .MuiPaginationItem-page:hover {
      background-color: #00959c27;
    }
  }
`;

export const RowItem = styled.td`
  padding: 2rem 1rem;
  .row-select > div {
    width: 15rem;
  }

  svg {
    cursor: pointer;
  }

  &:last-child {
    position: relative;
  }
`;

export const HeaderItem = styled.td`
  color: #7e9395;
  padding: 2rem 1rem;
  font-weight: 700;
`;

export const PaginationWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  .MuiPaginationItem-root {
    margin: 0 !important;
    border-radius: 0 !important;
    border: 1px solid #edeeee !important;
    color: #7e9395 !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', sans-serif !important ;
  }

  .MuiPaginationItem-page.Mui-selected {
    background-color: #edeeee !important;
  }

  .MuiPaginationItem-ellipsis {
    border: none !important;
  }
`;

const fadeObjLeft = keyframes`
    from {
      left: 0;
      opacity: (0);
    }
    to {
      left: -5rem;
      opacity: (1);
    }
  `;

export const MenuActionsWrapper = styled.ul`
  cursor: pointer;
  list-style: none;
  position: absolute;
  left: -4rem;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: #495e84;
  z-index: 20;
  -webkit-animation: ${fadeObjLeft} 0.5s ease;
  animation: ${fadeObjLeft} 0.5s ease;
`;

export const MenuAction = styled.li`
  padding: 1rem 2rem;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-radius: 10px;
    background-color: #31c6d3;
  }
`;

// table

export const ContentTable = styled.div`
  display: block;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: 20px 0;
`;

export const Thead = styled.thead``;

export const Tr = styled.tr`
  cursor: pointer;
`;

export const Th = styled.th<Itab>`
  display: ${({ hidden }) => (hidden ? 'none' : '')};
  text-align: left;
  color: #7e9395;
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  white-space: nowrap;
`;

export const Tbody = styled.tbody`
  tr {
    height: 62px;
    cursor: pointer;
    &:nth-child(odd) {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &:nth-child(even) {
      background: #e5e9ea 0% 0% no-repeat padding-box;
      opacity: 1;
    }
  }
`;

export const Td = styled.td<Itab>`
  display: ${({ hidden }) => (hidden ? 'none' : '')};
  padding: 15px 10px;
  text-align: left;
  color: #3f3f3f;
  font-size: 11px;
  text-transform: uppercase;
  position: relative;
  button {
    border: 0;
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const ActionBox = styled.div<Itab>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  flex-wrap: wrap;
  opacity: 1;
  text-align: center;
  font: normal normal normal 12px Open Sans;
  letter-spacing: 0.6px;
  color: #ffffff;
  position: absolute;
  top: -6px;
  left: -3px;
`;

export const ActionEdit = styled.div`
  background: #7e9395 0% 0% no-repeat padding-box;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  width: 94px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionDelete = styled.div`
  background: #7e9395 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
  width: 94px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ClearButtonModal = styled.div`
  width: 10%;
  background-color: #00959c;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
  padding: 7px 15px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;
`;

export const Option = styled.div`
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #3f3f3f;
  opacity: 1;
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    border: 0;
    color: #7e9395;
    font-size: 16px;
    padding: 10px;
    background-color: transparent;
  }
`;
