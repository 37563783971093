import styled from 'styled-components';
import { Dialog, DialogContent } from '@material-ui/core';

export const Title = styled.span`
  color: #00959c;
  font-size: 24px;
  font-weight: bold;
  padding: 50px 0px 0px 50px;
`;

export const Form = styled.form`
  flex-direction: column;
  display: flex;
  margin-top: 13px;
  align-items: flex-start;
  padding: 0px 50px 50px 50px;

  div {
    width: 60%;
    display: flex;
    flex-wrap: wrap;

    label {
      width: 100%;
      color: #7e9395;
      font-size: 16px;
      padding: 10px 10px;
      margin-top: 10px;
    }
    input {
      width: 100%;
      padding: 9px 15px;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 20px;
    }
  }
  button {
    width: 152px;
    height: 40px;
    background: #00959c;
    box-shadow: 0px 8px 8px rgba(196, 196, 196, 0.3);
    border-radius: 25px;
    color: #fff !important;
    margin-top: 35px;
    font-size: 16px;
  }
`;

export const ModalDialog = styled(Dialog)``;

export const ModalDialogContent = styled(DialogContent)`
  /* padding: 3% 8% !important; */
  border-radius: 20px !important;
`;
