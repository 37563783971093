import styled from 'styled-components';

export const Wrapper = styled.div`
  > h2 {
    color: #7e9395;

    margin-bottom: 1rem;
    font-weight: 600;
  }
`;
export const FilterWrapper = styled.div`
  background-color: #fff;
  border-radius: 0.8rem;
  padding: 3rem 4rem;
  margin-bottom: 1rem;
`;

export const FilterContentGrid = styled.div``;

export const FilterItem = styled.div``;

export const InputLabel = styled.span`
  color: #7e9395;

  font-weight: 600;
  margin-left: 1rem;
`;

export const FilterActions = styled.div`
  display: flex;
  margin-top: 3rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50rem;
`;
