import React, { useState, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import FileSaver from 'file-saver';
import * as S from './styles';
import Modal from './modal';
import api from '../../../_core/api';
import { Recomendation } from './types';
import { failure, handleErrors, success } from '../../../_core/services/toast';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { getStatusWorkflow } from './ultils';
import { Form } from './form';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import Table from '../../../_core/_components/table';
import { Button, Checkbox, SingleSelect } from '../../../_core/_components';
import { downloadXls } from '../../../_core/services/download';

const OPTIONS = [
  {
    value: true,
    label: 'AJUIZAR',
  },
  {
    value: false,
    label: 'ISENTAR',
  },
];

export const WorkflowAjuizamento: React.FC = () => {
  const location = useLocation();
  const { response: responseJustificativas, isLoading: isLoadingJustificativas } = useCache({
    key: 'getAjuizamentoJustificativas',
    fn: requests.get('getAjuizamentoJustificativas'),
  });
  const [queryString, setQueryString] = useState('');
  const [stepWorkflow] = useState(() => getStatusWorkflow(location.pathname));
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState<Recomendation[]>([]);
  const [selectedRegister, setSelectedRegister] = useState<Recomendation>();
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const allToggle = useMemo(() => {
    if (values && selectedIds.length > 0) {
      const ids = values.map(e => e.id);
      return _.isEqual(ids, selectedIds);
    }
    return false;
  }, [values, selectedIds]);

  const searchRecomendations = async (formQueryString: string) => {
    setLoading(true);
    try {
      setQueryString(formQueryString);
      const { data } = await api.get<Recomendation[]>(`/workflow/ajuizamento/unpaged?${formQueryString}`);
      if (data.length === 0) {
        failure('Essa busca não retornou resultado.');
      }
      setValues(data);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const handleFinishedSaving = async (params: any) => {
    setLoading(true);
    try {
      const { justificativa, observacao, file, nrChamado } = params;
      const { requerAnexo, requerChamado } = responseJustificativas.data.find((e: any) => e.id === justificativa);
      if (requerAnexo && !file) throw Error('Anexo é obrigatório.');
      if (requerChamado && !nrChamado) throw Error('Número do chamado é obrigatório.');
      const formData = new FormData();
      formData.append('file', file);
      formData.append('idJustificativa', justificativa);
      formData.append('numChamado', nrChamado);
      formData.append('observacao', observacao);
      formData.append('recomendacao', 'ISENTAR');
      await api.put(`/workflow/ajuizamento/recomendar/${selectedRegister!.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSelectedRegister(undefined);
      setSelectedIds([]);
      await searchRecomendations(queryString);
      success('Enviado com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onToggleAll = () => {
    const ids = values ? values.map(e => e.id) : [];
    if (allToggle) {
      setSelectedIds(selectedIds.filter(e => !ids.includes(e)));
      return;
    }
    setSelectedIds([...selectedIds, ...ids]);
  };

  const onSingleToggle = (id: number) => {
    const arr = [...selectedIds];
    if (arr.includes(id)) {
      setSelectedIds(arr.filter(e => e !== id));
      return;
    }
    arr.push(id);
    setSelectedIds(arr);
  };

  const getJustificativasSubItems = (item: Recomendation) => {
    if (item.justificativa) {
      return [
        <b>Justificativa: </b>,
        item.justificativa,
        <b>Nr. do chamado: </b>,
        item.numChamado,
        <b>Observação: </b>,
        item.observacao,
      ];
    }
    return null;
  };

  const renderedData = useMemo(() => {
    if (values.length > 0) {
      return values.map(item => ({
        toggle: <Checkbox checked={selectedIds.includes(item.id)} onClick={() => onSingleToggle(item.id)} />,
        id: item.id,
        contratoShoppingNome: item.contratoShoppingNome,
        grupo: item.grupo,
        contratoNomeFantasia: item.contratoNomeFantasia,
        contratoLuc: item.contratoLuc,
        contratoRazaoSocial: item.contratoRazaoSocial,
        contratoCodObjeto: item.contratoCodObjeto,
        tipoDividaNome: item.tipoDividaNome,
        saldoAberto: item.saldoAberto,
        tipoAcaoNome: item.tipoAcaoNome,
        quantidadeExcecao: item.quantidadeExcecao,
        subItems: getJustificativasSubItems(item),
        ajuizar: (
          <SingleSelect
            state={[OPTIONS.find(e => e.label === item.recomendacao)!, () => null]}
            options={OPTIONS}
            disabled
            controlStyle={{ minWidth: 135 }}
          />
        ),
        modal: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.id.toString()}
            onClick={() => setSelectedRegister(values.find(e => e.id === item.id))}
          >
            <MoreIcon />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [values, selectedIds]);

  const submit = async () => {
    setLoading(true);
    try {
      await api.put('/workflow/ajuizamento/avancar', {
        ids: selectedIds,
      });
      setSelectedIds([]);
      await searchRecomendations(queryString);
      success('Enviado com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const limparJustificativa = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('idJustificativa', '');
      formData.append('numChamado', '');
      formData.append('observacao', '');
      formData.append('recomendacao', 'AJUIZAR');
      await api.put(`/workflow/ajuizamento/recomendar/${selectedRegister!.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setSelectedRegister(undefined);
      setSelectedIds([]);
      await searchRecomendations(queryString);
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onDownloadAnexo = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/workflow/ajuizamento/anexo/${selectedRegister!.anexoId}`, {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data]);
      FileSaver.saveAs(blob, selectedRegister!.nomeAnexo!);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onClickExportar = async () => {
    try {
      const response = await api.get(`/workflow/ajuizamento/xls?${queryString}`);
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
  };

  return (
    <S.ContentContainer>
      <FullScreenLoading isEnabled={loading || isLoadingJustificativas} />
      <Form status={stepWorkflow} submit={searchRecomendations} />
      {selectedRegister && (
        <Modal
          onSubmit={handleFinishedSaving}
          onClear={limparJustificativa}
          onClose={() => setSelectedRegister(undefined)}
          values={selectedRegister!}
          justificativas={responseJustificativas.data}
          onDownloadAnexo={onDownloadAnexo}
        />
      )}
      {renderedData.length > 0 && (
        <>
          <Table
            columns={[
              { label: <Checkbox type="secondary" checked={allToggle} onClick={onToggleAll} />, key: 'toggle' },
              { label: 'Nome do Shopping', key: 'contratoShoppingNome', orderable: true },
              { label: 'Grupo de cobranca', key: 'grupo', orderable: true },
              { label: 'Nome Fantasia', key: 'contratoNomeFantasia', orderable: true },
              { label: 'LUC', key: 'contratoLuc', orderable: true },
              { label: 'Razão Social', key: 'contratoRazaoSocial', orderable: true },
              { label: 'Objeto do Contrato', key: 'contratoCodObjeto', orderable: true },
              { label: 'Tipo de Dívida', key: 'tipoDividaNome', orderable: true },
              { label: 'Ação cabível', key: 'tipoAcaoNome', orderable: true },
              { label: 'Quantidade Exceção', key: 'quantidadeExcecao', orderable: true },
              { label: 'Ajuizar', key: 'ajuizar', orderable: true },
              { label: 'Add', key: 'modal' },
            ]}
            data={renderedData}
          />
          <S.Section>
            <Button text="Submeter Recomendação" onClick={submit} />
            <Button text="Exportar" onClick={onClickExportar} />
          </S.Section>
        </>
      )}
    </S.ContentContainer>
  );
};
