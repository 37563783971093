import React, { useMemo, useState } from 'react';
import { Dialog } from '@material-ui/core';
import * as S from './styles';
import { Button, Input, Select, Option, SingleSelect, Checkbox } from '../../../../_core/_components';
import CloseIcon from '../../../../assets/close.jsx';
import { FASE, FASES_MAP, STATUS_OPTIONS } from './constants';
import { mascaraDDMMAAAA, mascaraNumeracaoPtBr, converterParaFloat } from '../../../../_core/masks';
import { AjuizamentoDetalhes } from '../types';
import { GuiaPagamentoMask, convertGuiasPagamentoMask, convertToDatabaseDate } from './services';

type Props = {
  ajuizamento: AjuizamentoDetalhes;
  tipoCreditoOptions: Option[];
  statusAcaoOptions: Option[];
  onClose: () => void;
  onSave: (payload: any) => Promise<void>;
};

export function Update({ ajuizamento, tipoCreditoOptions, statusAcaoOptions, onClose, onSave }: Props): JSX.Element {
  const [documentacaoRecebida, setDocumentacaoRecebida] = useState(ajuizamento.documentacaoRecebida);
  const [guiasPagamento, setGuiasPagamento] = useState<GuiaPagamentoMask[]>(
    convertGuiasPagamentoMask(ajuizamento.guias),
  );
  const [numeroChamadoDoc, setNumeroChamadoDoc] = useState(ajuizamento.numeroChamado ?? '');
  const [statusAcao, setStatusAcao] = useState<Option | undefined>(
    statusAcaoOptions.find(e => e.value === ajuizamento.idStatusAcao),
  );
  const [tipoDeCredito, setTipoDeCredito] = useState<Option | undefined>(
    tipoCreditoOptions.find(e => e.value === ajuizamento.idEspecieCredito),
  );

  const verificarFaseConcluida = (nomeFase: FASE) => {
    const idFase = FASES_MAP.get(nomeFase)!;
    const fase = ajuizamento.historicoFases.find(e => e.fase.id === idFase);
    if (fase) {
      return fase.conclusao.length > 0;
    }
    return false;
  };

  const updateGuiasPagamento = (i: number, field: string, value: any) => {
    setGuiasPagamento(prevGuiasPagamento => {
      const updatedGuiasPagamento = [...prevGuiasPagamento];
      updatedGuiasPagamento[i] = {
        ...updatedGuiasPagamento[i],
        [field]: value,
      };
      return updatedGuiasPagamento;
    });
  };

  const verificarGuiaCompleto = (guia: GuiaPagamentoMask) => {
    const values = Object.values(guia);
    values.forEach(value => {
      if (value === '' || value === false) {
        throw Error('Campo não pode ser vazio.');
      }
    });
  };

  const desabilitarStatusAcaoTipoCredit = useMemo(() => {
    try {
      if (guiasPagamento.length === 0) throw Error('');
      guiasPagamento.forEach(verificarGuiaCompleto);
    } catch (error) {
      return true;
    }
    return false;
  }, [guiasPagamento]);

  const setSavePayload = () => {
    const payload = {
      documentacaoRecebida,
      numeroChamado: numeroChamadoDoc,
      saldoDevedorAtualizado: ajuizamento.saldoDevedorAtualizado,
      guias: guiasPagamento.map(e => ({
        id: e.id,
        valor: converterParaFloat(e.valor),
        numeroPO: e.numeroPO,
        numeroChamadoPagamento: e.numeroChamadoPagamento,
        comprovantePagamento: e.comprovantePagamento,
        numeroChamadoAjuizamento: e.numeroChamadoAjuizamento,
        statusChamadoAjuizamento: e.statusChamadoAjuizamento,
        numeroProtocolo: e.numeroProtocolo,
        dataAjuizamento: convertToDatabaseDate(e.dataAjuizamento),
      })),
      idStatusAcao: statusAcao?.value,
      idEspecieCredito: tipoDeCredito?.value,
      idFase: ajuizamento.idFaseAtual,
    };
    onSave(payload);
  };

  const disableSubmitButton = () => {
    // if (!documentacaoRecebida) return true;
    // if ([statusAcao, tipoDeCredito].some(e => e.length === 0)) return true;
    return false;
  };

  return (
    <Dialog open fullWidth maxWidth="lg" onClose={onClose} aria-labelledby="max-width-dialog-title">
      <S.DialogContent>
        <S.Header>
          <S.Title>Controle de Ações Ajuizadas - Por Contrato</S.Title>
          <CloseIcon click={onClose} color="#fff" />
        </S.Header>
        <S.MainContent>
          <S.DocumentacaoSection>
            <S.Section>
              <S.SectionTitle>Documentação</S.SectionTitle>
              <S.DocRow>
                <Checkbox
                  label="Documentação Recebida"
                  checked={documentacaoRecebida}
                  onClick={() => setDocumentacaoRecebida(!documentacaoRecebida)}
                />
                <S.FieldAreaDoc>
                  <label>Número do chamado</label>
                  <Input
                    state={[numeroChamadoDoc, setNumeroChamadoDoc]}
                    disabled={!documentacaoRecebida || verificarFaseConcluida('Documentação')}
                  />
                </S.FieldAreaDoc>
              </S.DocRow>
            </S.Section>
          </S.DocumentacaoSection>
          <S.StatusSection>
            <S.Section>
              <S.SectionTitle>Status/Tipo</S.SectionTitle>
              <S.SelectContent>
                <S.SelectArea>
                  <S.FieldSelect>
                    <S.FieldHeader>Status da Ação</S.FieldHeader>
                    <SingleSelect
                      state={[statusAcao, setStatusAcao]}
                      options={statusAcaoOptions}
                      disabled={desabilitarStatusAcaoTipoCredit}
                    />
                  </S.FieldSelect>
                </S.SelectArea>
                <S.SelectArea>
                  <S.FieldSelect>
                    <S.FieldHeader>Tipo de Crédito</S.FieldHeader>
                    <SingleSelect
                      state={[tipoDeCredito, setTipoDeCredito]}
                      options={tipoCreditoOptions}
                      disabled={desabilitarStatusAcaoTipoCredit}
                    />
                  </S.FieldSelect>
                </S.SelectArea>
              </S.SelectContent>
            </S.Section>
          </S.StatusSection>
          <S.FasesSection>
            <S.Section>
              <S.SectionTitle>Fases</S.SectionTitle>
              <S.FasesTable>
                <S.TableHead>
                  <tr>
                    <td>Guia pgto. (R$)</td>
                    <td>Num. PO</td>
                    <td>Num. Chamado pgto.</td>
                    <td>Comp PG</td>
                    <td>Nº. Chamado ajuizamento</td>
                    <td>Status chamado ajuizamento</td>
                    <td>Número protocolo</td>
                    <td>Data ajuizamento</td>
                  </tr>
                </S.TableHead>
                <S.TableBody>
                  {guiasPagamento.map((dado, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <Input
                            pattern={mascaraNumeracaoPtBr}
                            state={[dado.valor, value => updateGuiasPagamento(idx, 'valor', value.toString())]}
                            disabled={!numeroChamadoDoc || verificarFaseConcluida('Guia de Pagamento')}
                          />
                        </td>
                        <td>
                          <Input
                            disabled={!dado.valor || verificarFaseConcluida('PO')}
                            state={[dado.numeroPO, value => updateGuiasPagamento(idx, 'numeroPO', value.toString())]}
                          />
                        </td>
                        <td>
                          <Input
                            disabled={!dado.numeroPO || verificarFaseConcluida('Chamado para Pagamento')}
                            state={[
                              dado.numeroChamadoPagamento,
                              value => updateGuiasPagamento(idx, 'numeroChamadoPagamento', value.toString()),
                            ]}
                          />
                        </td>
                        <td>
                          <Checkbox
                            disabled={
                              !dado.numeroChamadoPagamento || verificarFaseConcluida('Comprovantes de Pagamento')
                            }
                            checked={dado.comprovantePagamento}
                            onClick={() =>
                              updateGuiasPagamento(
                                idx,
                                'comprovantePagamento',
                                !guiasPagamento[idx].comprovantePagamento,
                              )
                            }
                          />
                        </td>
                        <td>
                          <Input
                            disabled={!dado.comprovantePagamento || verificarFaseConcluida('Chamado para Ajuizamento')}
                            state={[
                              dado.numeroChamadoAjuizamento,
                              value => updateGuiasPagamento(idx, 'numeroChamadoAjuizamento', value.toString()),
                            ]}
                          />
                        </td>
                        <td>
                          <SingleSelect
                            containerStyles={S.selectContainerStyles}
                            controlStyle={S.selectControlStyles}
                            disabled={
                              !dado.numeroChamadoAjuizamento || verificarFaseConcluida('Chamado para Ajuizamento')
                            }
                            state={[
                              STATUS_OPTIONS.find(status => status.label === dado.statusChamadoAjuizamento),
                              option => updateGuiasPagamento(idx, 'statusChamadoAjuizamento', option?.label),
                            ]}
                            options={STATUS_OPTIONS}
                          />
                        </td>
                        <td>
                          <Input
                            disabled={
                              !dado.statusChamadoAjuizamento || verificarFaseConcluida('Chamado para Ajuizamento')
                            }
                            state={[dado.numeroProtocolo, value => updateGuiasPagamento(idx, 'numeroProtocolo', value)]}
                          />
                        </td>
                        <td>
                          <Input
                            pattern={mascaraDDMMAAAA}
                            placeholder="DD/MM/YYYY"
                            state={[
                              dado.dataAjuizamento,
                              value => updateGuiasPagamento(idx, 'dataAjuizamento', value.toString()),
                            ]}
                            disabled={!dado.numeroProtocolo || verificarFaseConcluida('Chamado para Ajuizamento')}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </S.TableBody>
              </S.FasesTable>
            </S.Section>
          </S.FasesSection>
          <S.ActionsSection>
            <Button disabled={disableSubmitButton()} onClick={setSavePayload} text="SALVAR INFORMAÇÕES" />
          </S.ActionsSection>
        </S.MainContent>
      </S.DialogContent>
    </Dialog>
  );
}
