import React, { useState, useContext } from 'react';
import api from '../../../_core/api';
import LoadingIcon from '../../../assets/loader.jsx';
import icon from '../../../assets/cloud-computing.svg';
import { AuthContext } from '../../../_main/contexts/auth';
import { Container, Content, ImportArea, InputGroup, LoadingBox, Sucess, Error } from './styles';
import { handleErrors } from '../../../_core/services/toast';
import { Button } from '../../../_core/_components';

export const BoletosNegativados: React.FC = () => {
  const [uploadingXls, setUploadingXls] = useState<boolean>(false);
  const [sucessXls, setSucessXls] = useState<boolean>(false);
  const [errorXls, setErrorXls] = useState<string>('');
  const { id } = useContext(AuthContext);

  const handleSendButton = (postType: string) => {
    if (postType === 'serasaImport') {
      setErrorXls('');
      setUploadingXls(true);
      sendFormSerasa();
    }
  };

  const fileToBase64 = (file: any) =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });

  const sendFormSerasa = async () => {
    const fileInput = document.getElementById('inputFileSerasa') as HTMLInputElement;
    const fileBase64 = `${await fileToBase64(fileInput.files![0])}`;

    try {
      const response = await api.post('/workflow/serasa/serasa-negativacao/negativar-boletos/importar', {
        file: fileBase64.substr(fileBase64.indexOf('base64,') + 7),
        idUsuario: id,
      });

      if (response.status === 200) {
        setSucessXls(true);
        setUploadingXls(false);
      }
    } catch (err) {
      handleErrors(err);
      setUploadingXls(false);
    }
  };

  return (
    <Container>
      <Content>
        <ImportArea>
          <h2>Buscar arquivos</h2>
          <img src={icon} alt="" />
          <InputGroup>
            <input id="inputFileSerasa" type="file" accept=".csv,.txt" />
          </InputGroup>
          <LoadingBox active={uploadingXls}>
            <LoadingIcon />
          </LoadingBox>
          <p>Apenas arquivos com extensão .csv ou .txt.</p>
        </ImportArea>
        {errorXls && <Error>{errorXls}</Error>}
        {sucessXls && <Sucess>Importado com sucesso!</Sucess>}
        <Button text="Importar" onClick={() => handleSendButton('serasaImport')} />
      </Content>
    </Container>
  );
};
