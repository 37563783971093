import React, { useContext, useState } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { Form, Title, InputDate, TextField, TextFieldTitle, SubmitButton } from './styles';
import ParametrosCalculoInadimplenciaContext from '../../../_main/contexts/parametros-calculo-inadimplencia';

type ModalProps = {
  onClose: () => void;
  onSubmit: (values: any) => void;
};

const Modal: React.FC<ModalProps> = ({ onClose, onSubmit }) => {
  const { state, dispatch } = useContext(ParametrosCalculoInadimplenciaContext);
  const [date, setDate] = useState(state.date ?? '');
  const [processingDate, setProcessingDate] = useState(state.processingDate ?? '');
  const [adjustmentReceiptStartDate, setAdjustmentReceiptStartDate] = useState(state.adjustmentReceiptStartDate ?? '');
  const [adjustmentReceiptEndDate, setAdjustmentReceiptEndDate] = useState(state.adjustmentReceiptEndDate ?? '');
  const [netReceiptDate, setNetReceiptDate] = useState(state.netReceiptDate ?? '');
  const [previousMonthNetReceiptDate, setPreviousMonthNetReceiptDate] = useState(
    state.previousMonthNetReceiptDate ?? '',
  );
  const [minimumNumberOfDays, setMinimumNumberOfDays] = useState(state.minimumNumberOfDays ?? '');

  const handleSubmit = () => {
    const [month, year] = date.split('/');
    const payload = {
      month,
      year,
      processingDate: convertDate(processingDate),
      adjustmentReceiptStartDate: convertDate(adjustmentReceiptStartDate),
      adjustmentReceiptEndDate: convertDate(adjustmentReceiptEndDate),
      netReceiptDate: convertDate(netReceiptDate),
      previousMonthNetReceiptDate: convertDate(previousMonthNetReceiptDate),
      minimumNumberOfDays,
    };
    onSubmit(payload);
  };

  const convertDate = (value: string) => {
    const arr = value.split('/');
    return `${arr[2]}-${arr[1]}-${arr[0]}`;
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Title>PROCESSAR</Title>
        <Form>
          <TextFieldTitle>Data de referência</TextFieldTitle>
          <InputDate mask="99/9999" value={date} onChange={(e: any) => setDate(e.target.value)} />
          <TextFieldTitle>Data de processamento</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            value={processingDate}
            onChange={(e: any) => setProcessingDate(e.target.value)}
          />
          <TextFieldTitle>Data Inicio Faturado</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            onChange={event => setAdjustmentReceiptStartDate(event.target.value)}
            value={adjustmentReceiptStartDate}
          />
          <TextFieldTitle>Data Fim Faturado</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            onChange={event => setAdjustmentReceiptEndDate(event.target.value)}
            value={adjustmentReceiptEndDate}
          />
          <TextFieldTitle>Data de recebimento líquido do mês</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            onChange={event => setNetReceiptDate(event.target.value)}
            value={netReceiptDate}
          />
          <TextFieldTitle>Data de recebimento líquido do mês anterior</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            onChange={event => setPreviousMonthNetReceiptDate(event.target.value)}
            value={previousMonthNetReceiptDate}
          />
          <TextFieldTitle>Quantidade mínima de dias</TextFieldTitle>
          <TextField onChange={event => setMinimumNumberOfDays(event.target.value)} value={minimumNumberOfDays} />
          <div>
            <SubmitButton onClick={handleSubmit}>Salvar</SubmitButton>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
