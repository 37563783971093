import React, { useContext, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Avatar, Collapse, Drawer, Hidden, Popover } from '@material-ui/core';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { AuthContext } from '../../../_main/contexts/auth';
import { AvatarContainer, AvatarTextInfoColumn, Head, HeadList, HeadListItem, PerfilOpen } from './styles';
import logoSiscob from '../../../assets/logo_siscob.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
      marginRight: theme.spacing(2),
      color: '#00959c',
    },
    title: {
      flexGrow: 1,
    },
    list: {
      width: 250,
    },
    fullList: {
      width: 'auto',
    },
  }),
);

export function Header(): JSX.Element {
  const history = useHistory();
  const { signOut, routes, profile, userName, name, email } = useContext(AuthContext);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<number[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickMenuItem = (index: number) => {
    const arr = [...openSubMenu];
    if (arr.includes(index)) {
      const i = arr.indexOf(index);
      arr.splice(i, 1);
    } else {
      arr.push(index);
    }
    setOpenSubMenu(arr);
  };

  const onClickRoute = (url: string) => {
    setIsOpenDrawer(false);
    setOpenSubMenu([]);
    history.push(url);
  };

  const list = () => (
    <div role="presentation">
      <div
        className={classes.toolbar}
        style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center' }}
      >
        <img src={`${logoSiscob}`} alt="" />
      </div>
      <Divider />
      <List>
        {routes.map((item, index) => (
          <>
            <ListItem button key={index} onClick={() => onClickMenuItem(index)}>
              <ListItemText primary={item.nome} />
            </ListItem>
            <Collapse in={openSubMenu.includes(index)}>
              <List>
                {item.menus.map((route, i) => (
                  <ListItem button key={i} onClick={() => onClickRoute(route.url)}>
                    <ListItemIcon />
                    <ListItemText primary={route.label} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
            <Divider />
          </>
        ))}
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <Drawer anchor="left" open={isOpenDrawer} onClose={() => setIsOpenDrawer(!isOpenDrawer)}>
        {list()}
      </Drawer>
      <AppBar position="static" style={{ backgroundColor: 'white' }} elevation={0}>
        <Toolbar>
          <Hidden>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => setIsOpenDrawer(!isOpenDrawer)}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <Hidden xsDown>
            <Head>
              <HeadList>
                {routes.map((item, index) => (
                  <HeadListItem key={index}>
                    <a href="#">{item.nome}</a>
                  </HeadListItem>
                ))}
              </HeadList>
            </Head>
          </Hidden>
          <AvatarContainer>
            <AvatarTextInfoColumn>
              <p>{name}</p> <p>{profile}</p>
            </AvatarTextInfoColumn>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <Avatar src="/static/media/user.ab68a7bc.svg" />
            </IconButton>
            <Popover onClose={handleClose} open={open} anchorEl={anchorEl}>
              <PerfilOpen>
                <Avatar src="/static/media/user.ab68a7bc.svg" style={{ height: 60, width: 60 }} />
                <span>{userName}</span>
                <p>{profile}</p>
                <p>{email}</p>
                <button type="button" onClick={signOut}>
                  Sair
                </button>
              </PerfilOpen>
            </Popover>
          </AvatarContainer>
        </Toolbar>
      </AppBar>
    </div>
  );
}
