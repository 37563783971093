export const homeRoute = {
  nome: '',
  menus: [
    {
      titulo: '',
      url: '/home',
      regras: [],
    },
  ],
};
