import React, { useState, useEffect, useMemo, useContext } from 'react';
import GridOnIcon from '@material-ui/icons/GridOn';
import TableChartIcon from '@material-ui/icons/TableChart';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import Detalhamento from './detalhamento';
import CancelamentoModal, { OnSaveParams } from './cancelamento';
import DocumentationIcon from '../../../assets/documentation.png';
import VentoIcon from '../../../assets/vento.png';
import ListIcon from '../../../assets/list.png';
import JudgeIcon from '../../../assets/judge.png';
import GuiaIcon from '../../../assets/guia.png';
import BillIcon from '../../../assets/bill.png';
import {
  Container,
  LineTotalizador,
  AcoesPorFase,
  Acoes,
  TableWrapper,
  ActionsRow,
  ViewChanger,
  getGridIconStyle,
  getTableIconStyle,
  CardWrapper,
  Card,
  CardCol,
  CardLine,
  CardLabel,
  CardInfo,
  CardSeparator,
  CardValueInfo,
  Acao,
  FaseIcon,
  AcaoText,
  AcaoPercent,
  ContentWrapper,
} from './styles';
import { AjuizamentoResponse, AcompanhamentoAjuizamentoItem, AjuizamentoDetalhes, TipoCancelamento } from './types';
import { TableContainer, CustTable, TableRow, RowItem, HeaderItem, AcompanharButton, Pagination } from './table-styles';
import { toReal, truncateString } from '../../../_core/services/formaters';
import api from '../../../_core/api';
import { handleErrors, success } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Button, Checkbox, Form, Input, Option, Select, SingleSelect } from '../../../_core/_components';
import { Update } from './update';
import { AuthContext } from '../../../_main/contexts/auth';

const FASE_ATUAL: Option[] = [
  { value: 1, label: 'DOCUMENTAÇÃO' },
  { value: 2, label: 'GUIA DE PAGAMENTO' },
  { value: 3, label: 'PO' },
  { value: 4, label: 'CHAMADO PARA PAGAMENTO' },
  { value: 5, label: 'COMPROVANTES DE PAGAMENTO' },
  { value: 6, label: 'CHAMADO PARA AJUIZAMENTO' },
  { value: 7, label: 'AÇÃO AJUIZADA' },
];

export const AcompanharAjuizamento: React.FC = () => {
  const { shoppings: profileShoppings } = useContext(AuthContext);
  const { response: responseOrigem, isLoading: isLoadingOrigem } = useCache({
    key: 'getOrigem',
    fn: requests.get('getOrigem'),
  });
  const { response: responseGruposCobranca, isLoading: isLoadingGruposCobranca } = useCache({
    key: 'getAjuizamentoGruposCobranca',
    fn: requests.get('getAjuizamentoGruposCobranca'),
  });
  const [tipoAcaoOptions, setTipoAcaoOptions] = useState<Option[]>([]);
  const [acoesPendentes, setAcoesPendentes] = useState(true);
  const [acoesAjuizadas, setAcoesAjuizadas] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [tipoCancelamento, setTipoCancelamento] = useState<TipoCancelamento>();
  const [ajuizamentos, setAjuizamentos] = useState<AjuizamentoResponse | null>(null);
  const [ajuizamentoDetalhes, setAjuizamentoDetalhes] = useState<AjuizamentoDetalhes | undefined>(undefined);
  const [ajuizamentoDetalhesUpdate, setAjuizamentoDetalhesUpdate] = useState<AjuizamentoDetalhes | undefined>(
    undefined,
  );
  const [page, setPage] = React.useState(1);

  /* Filtros Selecionados */
  const [selectedShopping, setselectedShopping] = useState<Option[]>([]);
  const [origem, setOrigem] = useState<Option>();
  const [billingGroups, setBillingGroups] = useState<Option[]>([]);
  const [sourceList, setSourceList] = useState<Option[]>([]);
  const [billingGroupsList, setBillingGroupsList] = useState<Option[]>([]);
  const [tipoAcao, setTipoAcao] = useState<Option[]>([]);
  const [contractNumber, setContractNumber] = useState<string>('');
  const [idsFases, setIdsFases] = useState<Option[]>([]);
  const [numeroChamado, setNumeroChamado] = useState<string>('');
  const [cpfCnpj, setCpfCnpj] = useState<string>('');
  const [luc, setLuc] = useState<string>('');
  const [mesAnoCicloAjuizamento, setMesAnoCicloAjuizamento] = useState<Option>();
  const [currentView, setCurrentView] = useState<'table' | 'card'>('card');

  const [statusAcaoOptions, setStatusAcaoOptions] = useState<Option[]>([]);
  const [tipoCreditoOptions, setTipoCreditoOptions] = useState<Option[]>([]);

  useEffect(() => {
    const getTipoAcaoData = async () => {
      const { data } = await api.get(`/parametros/ajuizamento/tipo-acao?page=${page}`);
      setTipoAcaoOptions(
        data.data.map((e: any) => ({
          value: e.id,
          label: e.nome,
        })),
      );
    };
    getTipoAcaoData();
  }, []);

  const shoppingsOptions = useMemo(() => {
    if (profileShoppings) {
      return profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
    }
    return [];
  }, [profileShoppings]);

  useEffect(() => {
    if (responseOrigem !== undefined) {
      const tmpSources: Option[] = [];
      responseOrigem.data.forEach((item: any) => {
        tmpSources.push({ label: item.sourceName, value: item.code });
      });

      setSourceList(tmpSources);
    }
  }, [responseOrigem]);

  useEffect(() => {
    if (responseGruposCobranca !== undefined) {
      const tmpBillingGroups: Option[] = [];
      responseGruposCobranca.data.forEach((item: any) => {
        if (item.federationGroupName != null) {
          tmpBillingGroups.push({ label: item.federationGroupName, value: item.federationGroupId });
        }
      });

      setBillingGroupsList(tmpBillingGroups);
    }
  }, [responseGruposCobranca]);

  const last12Months = () => {
    const datesArr = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 14; i++) {
      const value = moment().subtract(i, 'month').format('MM/YYYY');
      datesArr.push({
        value,
        label: value,
      });
    }

    return datesArr;
  };

  const handleChangePagination = (event: any, value: any) => {
    setPage(value);
    setLoading(true);
    pesquisar(value);
  };

  function montarBuscaShoppings() {
    if (selectedShopping.length === 0) {
      return shoppingsOptions.map(e => e.value).toString();
    }
    return selectedShopping.map(e => e.value).toString();
  }

  const pesquisar = async (pgnb: number) => {
    setLoading(true);
    try {
      const newPage = pgnb || page;
      setPage(newPage);
      const params = {
        shoppingIds: montarBuscaShoppings(),
        source: origem?.value,
        idsGruposCobranca: billingGroups.length > 0 ? billingGroups.map(e => e.value).toString() : undefined,
        contractNumber: contractNumber.length > 0 ? contractNumber : undefined,
        numeroChamado: numeroChamado.length > 0 ? numeroChamado : undefined,
        idsFases: idsFases.length > 0 ? idsFases.map(e => e.value).toString() : undefined,
        cpfCnpj: cpfCnpj.length > 0 ? cpfCnpj : undefined,
        luc: luc.length > 0 ? luc : undefined,
        mesAnoCicloAjuizamento: mesAnoCicloAjuizamento ? mesAnoCicloAjuizamento.value : undefined,
        tiposAcao: tipoAcao.length > 0 ? tipoAcao.map(e => e.value).join(',') : undefined,
        acoesAjuizadas,
        size: 10,
        acoesPendentes,
        page: newPage,
      };
      const response = await api.get('/workflow/ajuizamento/acompanhamento', {
        params,
      });
      setAjuizamentos(response.data);
    } catch (error) {
      handleErrors(error);
    }

    setLoading(false);
  };

  const handleAjuizamentoExport = async () => {
    const params = {
      shoppingIds: montarBuscaShoppings(),
      source: origem?.value,
      idsGruposCobranca: billingGroups.length > 0 ? billingGroups.map(e => e.value).toString() : undefined,
      contractNumber: contractNumber.length > 0 ? contractNumber : undefined,
      numeroChamado: numeroChamado.length > 0 ? numeroChamado : undefined,
      idsFases: idsFases.length > 0 ? idsFases.map(e => e.value).toString() : undefined,
      cpfCnpj: cpfCnpj.length > 0 ? cpfCnpj : undefined,
      luc: luc.length > 0 ? luc : undefined,
      mesAnoCicloAjuizamento: mesAnoCicloAjuizamento ? mesAnoCicloAjuizamento.value : undefined,
      tiposAcao: tipoAcao.length > 0 ? tipoAcao.map(e => e.value).join(',') : undefined,
      acoesAjuizadas,
      acoesPendentes,
    };

    const response = await api.get(`/judgment/track-judgment-actions/xls`, { responseType: 'arraybuffer', params });
    downloadXls(response);
  };

  const detalhesAjuizamento = async (acao: AcompanhamentoAjuizamentoItem) => {
    setLoading(true);
    try {
      const { data } = await api.get(`/workflow/ajuizamento/acompanhamento/${acao.id}`);
      setAjuizamentoDetalhes(data);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  async function getTipoCredito() {
    const res = await api.get(`/filters/type-of-credit`);
    const tempTypeOfCredity: Option[] = [];
    res.data.forEach((creditType: any) => {
      tempTypeOfCredity.push({
        value: creditType.id,
        label: creditType.nome,
      });
    });
    setTipoCreditoOptions(tempTypeOfCredity);
  }

  async function getTipoAcao() {
    const res = await api.get(`/filters/action-status`);
    const tempActionStatus: Option[] = [];
    res.data.forEach((actStats: any) => {
      tempActionStatus.push({
        value: actStats.id,
        label: actStats.nome,
      });
    });
    setStatusAcaoOptions(tempActionStatus);
  }

  const openUpdateFase = async () => {
    setLoading(true);
    try {
      if ([tipoCreditoOptions, statusAcaoOptions].some(e => e.length === 0)) {
        await getTipoCredito();
        await getTipoAcao();
      }
      setAjuizamentoDetalhes(undefined);
      setAjuizamentoDetalhesUpdate(ajuizamentoDetalhes);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const salvarAjuizamentoDetalhes = async (payload: any) => {
    setLoading(true);
    try {
      const idAcao = ajuizamentoDetalhesUpdate?.idAcaoAjuizamento ?? ajuizamentoDetalhes?.idAcaoAjuizamento;
      await api.put(`/workflow/ajuizamento/acompanhamento/${idAcao}`, payload);
      setAjuizamentoDetalhes(undefined);
      setAjuizamentoDetalhesUpdate(undefined);
      success('Salvo com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const salvarCancelamento = async (params: OnSaveParams) => {
    setLoading(true);
    try {
      if (tipoCancelamento === 'DESISTENCIA') {
        api.put(`/workflow/ajuizamento/acompanhamento/${ajuizamentoDetalhes?.idAcaoAjuizamento}/desistencia`, {
          motivo: params.descricao,
        });
      } else {
        api.put(`/workflow/ajuizamento/acompanhamento/${ajuizamentoDetalhes?.idAcaoAjuizamento}/excecao`, {
          idJustificativa: params.motivo!.value,
          numeroChamado: params.numeroChamado,
          observacao: params.descricao,
        });
      }
      setTipoCancelamento(undefined);
      setAjuizamentoDetalhes(undefined);
      success('Salvo com sucesso');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const handleCheck = (typeAction: string) => {
    if (typeAction === 'acoesPendentes') {
      if (acoesAjuizadas === false && acoesPendentes === true) {
        setAcoesAjuizadas(!acoesAjuizadas);
      }
      setAcoesPendentes(!acoesPendentes);
    }
    if (typeAction === 'acoesAjuizadas') {
      if (acoesAjuizadas === true && acoesPendentes === false) {
        setAcoesPendentes(!acoesPendentes);
      }
      setAcoesAjuizadas(!acoesAjuizadas);
    }
  };

  const getAcaoIcon = (fase: string) => {
    switch (fase) {
      case 'Documentação':
        return DocumentationIcon;
      case 'Guia de Pagamento':
        return GuiaIcon;
      case 'PO':
        return VentoIcon;
      case 'Comprovantes de Pagamento':
        return BillIcon;
      case 'Chamado para Ajuizamento':
        return ListIcon;
      case 'Ação Ajuizada':
        return JudgeIcon;

      default:
        return JudgeIcon;
    }
  };

  const ajuizamentoDetails = useMemo(() => {
    if (ajuizamentos?.pageInfo === null) {
      return null;
    }

    return (
      <>
        <LineTotalizador>
          <span>Total de Contratos: {ajuizamentos?.pageInfo.totalContratos}</span>
          <span>Total de Clientes: {ajuizamentos?.pageInfo.totalClientes}</span>
          <span>Valor Total de Contratos: {toReal(ajuizamentos?.pageInfo.saldoAbertoTotal)}</span>
        </LineTotalizador>

        <AcoesPorFase>
          <h2>Ações por Fase</h2>
          <Acoes>
            {ajuizamentos?.pageInfo.acoesPorFase.map((item, i) => {
              return (
                <Acao key={i}>
                  <FaseIcon src={getAcaoIcon(item.fase)} />
                  <AcaoText>{item.fase}</AcaoText>
                  <AcaoPercent>{item.percentual}%</AcaoPercent>
                </Acao>
              );
            })}
          </Acoes>
        </AcoesPorFase>
      </>
    );
  }, [ajuizamentos]);

  return (
    <>
      {ajuizamentoDetalhes && tipoCancelamento === undefined && (
        <Detalhamento
          onCloseDetalhamento={() => setAjuizamentoDetalhes(undefined)}
          ajuizamento={ajuizamentoDetalhes}
          onClickUpdateFase={openUpdateFase}
          onSave={salvarAjuizamentoDetalhes}
          onCancelamento={setTipoCancelamento}
        />
      )}

      {ajuizamentoDetalhesUpdate && (
        <Update
          ajuizamento={ajuizamentoDetalhesUpdate}
          onClose={() => setAjuizamentoDetalhesUpdate(undefined)}
          onSave={salvarAjuizamentoDetalhes}
          tipoCreditoOptions={tipoCreditoOptions}
          statusAcaoOptions={statusAcaoOptions}
        />
      )}

      {tipoCancelamento && (
        <CancelamentoModal
          onCloseDesistencia={() => setTipoCancelamento(undefined)}
          tipoCancelamento={tipoCancelamento}
          onSave={salvarCancelamento}
        />
      )}

      <Container>
        <FullScreenLoading isEnabled={isLoading || isLoadingOrigem || isLoadingGruposCobranca} />

        <Form
          items={[
            <Select
              placeholder="Shoppings"
              options={shoppingsOptions}
              state={[selectedShopping, setselectedShopping]}
            />,
            <SingleSelect placeholder="Origem" options={sourceList} state={[origem, setOrigem]} />,
            <Select placeholder="Fase atual" options={FASE_ATUAL} state={[idsFases, setIdsFases]} />,
            <Input
              placeholder="Número do Contrato"
              label="Número do Contrato"
              state={[contractNumber, setContractNumber]}
            />,
            <Input
              placeholder="Número do Chamado"
              label="Número do Chamado"
              state={[numeroChamado, setNumeroChamado]}
            />,
            <Input placeholder="CPF/CNPJ" label="CPF/CNPJ" state={[cpfCnpj, setCpfCnpj]} />,
            <SingleSelect
              options={last12Months()}
              state={[mesAnoCicloAjuizamento, setMesAnoCicloAjuizamento]}
              placeholder="Mês/Ano"
            />,
            <Input placeholder="LUC" label="LUC" state={[luc, setLuc]} />,
            <Select
              options={billingGroupsList}
              state={[billingGroups, setBillingGroups]}
              placeholder="Grupo de Cobrança"
            />,
            <Select placeholder="Tipo de ação" state={[tipoAcao, setTipoAcao]} options={tipoAcaoOptions} />,
            <Checkbox label="Ações Pendentes" onClick={() => handleCheck('acoesPendentes')} checked={acoesPendentes} />,
            <Checkbox label="Ações Ajuizadas" onClick={() => handleCheck('acoesAjuizadas')} checked={acoesAjuizadas} />,
          ]}
          submitButton={<Button text="Pesquisar" onClick={() => pesquisar(1)} />}
        />
      </Container>

      {ajuizamentos && ajuizamentos.data.length > 0 && (
        <ContentWrapper>
          <ViewChanger>
            <GridOnIcon onClick={() => setCurrentView('card')} style={getGridIconStyle(currentView)} />
            <TableChartIcon onClick={() => setCurrentView('table')} style={getTableIconStyle(currentView)} />
          </ViewChanger>
          {currentView === 'table' && (
            <TableWrapper>
              <TableContainer>
                <CustTable>
                  <thead>
                    <tr>
                      <HeaderItem>Origem</HeaderItem>
                      <HeaderItem>Shopping</HeaderItem>
                      <HeaderItem>Nome Fantasia</HeaderItem>
                      <HeaderItem>LUC</HeaderItem>
                      <HeaderItem>Razão Social</HeaderItem>
                      <HeaderItem>CPF/CNPJ</HeaderItem>
                      <HeaderItem>Contrato</HeaderItem>
                      <HeaderItem>Saldo Devedor Corrigido</HeaderItem>
                      <HeaderItem>Tipo Ação</HeaderItem>
                      <HeaderItem>Tipo Dívida</HeaderItem>
                      <HeaderItem>Status do Processo</HeaderItem>
                      <HeaderItem>Fase Atual</HeaderItem>
                      <HeaderItem />
                      <HeaderItem>Prazo de Conclusao</HeaderItem>
                    </tr>
                  </thead>
                  <tbody>
                    {ajuizamentos?.data.map((acao, i) => {
                      return (
                        <TableRow key={i}>
                          <RowItem>{acao.origem}</RowItem>
                          <RowItem>{acao.shopping}</RowItem>
                          <RowItem>{acao.nomeFantasia}</RowItem>
                          <RowItem>{acao.luc}</RowItem>
                          <RowItem>
                            <Tooltip title={acao.razaoSocial}>
                              <span>{truncateString(acao.razaoSocial, 14)}</span>
                            </Tooltip>
                          </RowItem>
                          <RowItem>{acao.cpfCnpj}</RowItem>
                          <RowItem>{acao.contrato}</RowItem>
                          <RowItem>{toReal(acao.saldoDevedorCorrigido)}</RowItem>
                          <RowItem>{acao.tipoAcao}</RowItem>
                          <RowItem>{acao.tipoDivida}</RowItem>
                          <RowItem>{acao.statusProcesso}</RowItem>
                          <RowItem>{acao.faseAtual}</RowItem>
                          <RowItem>
                            <AcompanharButton onClick={() => detalhesAjuizamento(acao)}>ACOMPANHAR</AcompanharButton>
                          </RowItem>
                          <RowItem>{acao.prazoConclusao}</RowItem>
                        </TableRow>
                      );
                    })}
                  </tbody>
                </CustTable>
              </TableContainer>
            </TableWrapper>
          )}
          {currentView === 'card' && (
            <CardWrapper>
              {ajuizamentos?.data.map((acao, i) => {
                return (
                  <Card key={i}>
                    <CardLine>
                      <CardCol>
                        <CardLabel>Tipo de Dívida</CardLabel>
                        <CardInfo tipo={acao.tipoDivida}>{acao.tipoDivida}</CardInfo>
                      </CardCol>
                    </CardLine>
                    <CardSeparator />
                    <CardLine>
                      <CardCol>
                        <CardLabel>Origem</CardLabel>
                        <CardInfo>{acao.origem}</CardInfo>
                      </CardCol>
                      <CardCol>
                        <CardLabel>Shopping</CardLabel>
                        <CardInfo>{acao.shopping}</CardInfo>
                      </CardCol>
                    </CardLine>
                    <CardLine>
                      <CardCol>
                        <CardLabel>Nome Fantasia</CardLabel>
                        <CardInfo>{acao.nomeFantasia}</CardInfo>
                      </CardCol>
                      <CardCol>
                        <CardLabel>LUC</CardLabel>
                        <CardInfo>{acao.luc}</CardInfo>
                      </CardCol>
                    </CardLine>
                    <CardLine>
                      <CardCol>
                        <CardLabel>Razão Social</CardLabel>
                        <CardInfo>{truncateString(acao.razaoSocial, 14)}</CardInfo>
                      </CardCol>
                      <CardCol>
                        <CardLabel>CPF/CNPJ</CardLabel>
                        <CardInfo>{acao.cpfCnpj}</CardInfo>
                      </CardCol>
                    </CardLine>
                    <CardSeparator />
                    <AcompanharButton full onClick={() => detalhesAjuizamento(acao)}>
                      ACOMPANHAR
                    </AcompanharButton>
                  </Card>
                );
              })}
            </CardWrapper>
          )}
          {ajuizamentos.pageInfo && (
            <>
              <ActionsRow>
                <Button text="Exportar" onClick={handleAjuizamentoExport} styles={{ backgroundColor: '#00959c' }} />
                <Pagination count={ajuizamentos?.pageInfo.totalPages} page={page} onChange={handleChangePagination} />
              </ActionsRow>
            </>
          )}
          {ajuizamentoDetails}
        </ContentWrapper>
      )}
    </>
  );
};
