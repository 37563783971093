import React, { useMemo, useState, useEffect } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { Portfolio, PortfolioPostRequest } from './interfaces';
import { Shopping } from '../../../../_main/contexts/inadimplencia';
import { Button, Form, Input, Option, Select } from '../../../../_core/_components';

type ModalProps = {
  options: Shopping[];
  onClose: () => void;
  onSubmit: (values: PortfolioPostRequest) => void;
  values?: Portfolio;
};

const Modal: React.FC<ModalProps> = ({ onClose, onSubmit, values, options }: ModalProps) => {
  const [id] = useState(values?.id ?? '');
  const [name, setName] = useState(values?.name ?? '');
  const [shopping, setShopping] = useState<Option[]>([]);

  useEffect(() => {
    const parsed =
      values?.shoppings.map(s => ({
        value: s.id,
        label: s.name,
      })) || [];

    setShopping([...shopping, ...parsed]);
    setName(values?.name || '');
  }, [values]);

  const handleSubmit = () => {
    onSubmit({ id, name, shoppingIds: shopping.map(s => s.value as number), active: true });
  };

  const shoppingSelect = useMemo(() => {
    // let selectedValue = null;
    // shoppings disponiveis pelo endpoint
    const arrOptions = options.map(o => ({
      value: o.value,
      label: o.label,
    }));

    // + shoppings caso edit
    const shoppingsEditedOpt =
      values?.shoppings.map(s => ({
        value: s.id,
        label: s.name,
      })) || [];

    return (
      <Select
        placeholder="Shoppings"
        state={[shopping, setShopping]}
        options={[...arrOptions, ...shoppingsEditedOpt]}
      />
    );
  }, [shopping]);

  const isValid = useMemo(() => name && shopping.length > 0, [name, shopping]);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {values ? 'Editar' : 'Criar'} Portfolio
        </span>
        <Grid>
          <Form
            items={[shoppingSelect, <Input state={[name, setName]} placeholder="Portfolio" label="Portfolio" />]}
            submitButton={<Button disabled={!isValid} onClick={handleSubmit} text="Salvar" />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
