import styled from 'styled-components';
import DatePicker from 'react-datepicker';

export const Container = styled.div``;

export const Required = styled.div`
  font-size: 12px;
  color: red;
  margin-left: 10px;
`;

export const FlexForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
`;

export const LucDpicker = styled.div`
  display: flex;
  &:nth-child(odd) {
    width: 60%;
  }
  &:nth-child(even) {
    width: 40%;
  }
`;

export const FormField = styled.div`
  padding: 10px;
  &:nth-child(odd) {
    width: 60%;
  }
  &:nth-child(even) {
    width: 40%;
  }
  &:nth-child(7) {
    width: 100%;
  }
  .luc {
    font-size: 16px;
    width: 206px;
    div {
      padding: 10px 15px;
      margin-top: 4px;
    }
  }
  .select {
    font-size: 16px;
    div {
      padding: 10px 15px;
      margin-top: 4px;
    }
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 3rem;
  }
  .react-datepicker__current-month {
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7e93954d;
  border-radius: 20px;
  opacity: 1;
  padding: 10px 15px;
  min-height: 90px;
`;

/** map to isolated component */
export const Label = styled.label`
  box-sizing: border-box;
  color: #7e9395;
  font-size: 16px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const InputBox = styled.div`
  width: 100%;
  height: 100%;
`;

export const InputElement = styled.input`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7e93954d;
  border-radius: 20px;
  opacity: 1;
  padding: 10px 15px;
  width: 100%;
`;

export const Dpicker = styled(DatePicker)`
  border: 1px solid #7e93954d;
  border-radius: 20px;
  width: 129px;
  padding: 8px 10px;
  text-align: center;
  color: #7e9395;
`;
