import styled from 'styled-components';
import InputMask from 'react-input-mask';

type OrderableProps = {
  arrowDirection?: 'desc' | 'asc';
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  h1 {
    font-size: 20px;
    font-weight: bold;
    color: #7e9395;
    padding: 20px 0;
  }
`;

export const MainRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SearchColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 7;
`;

export const CalcColumn = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 2;
`;

export const SearchBox = styled.div`
  background-color: white;
  height: 100px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  input {
    height: 40px;
    width: 100%;
    padding: 7px 15px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 20px;
  }
  button {
    min-width: 138px;
    height: 40px;
    background: #31c6d3;
    border-radius: 25px;
    color: #fff !important;
    font-size: 16px;
    border: none;
  }
`;

export const InputDate = styled(InputMask)`
  height: 40px;
  width: 100%;
  padding: 7px 15px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
`;

export const CalcBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100px;
  align-items: center;
  justify-content: center;

  & #calc-inadimplencia-label {
    background: #ffffff;
    box-shadow: 0px 8px 8px rgba(196, 196, 196, 0.3);
    border-radius: 25px;
    cursor: pointer;
    padding: 8px 16px;
    color: #00959c;
    font-size: 14px;
  }
`;

export const SizedBox = styled.div`
  min-width: 32px;
`;

export const SideMenu = styled.div`
  width: 20%;
`;

export const TableContainer = styled.div`
  background: #f2f4f4;
  border-radius: 10px;
  table {
    border-collapse: collapse;
    width: 100%;
    white-space: nowrap;
    tr {
      &:nth-child(odd) {
        background: #ffffff 0% 0% no-repeat padding-box;
        opacity: 1;
      }
      &:nth-child(even) {
        background: #e5e9ea 0% 0% no-repeat padding-box;
        opacity: 1;
      }
      &:first-child {
        background-color: #31c6d3;
        padding: 10px;
        border-radius: 5px 5px 0px 0px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
      }
      td {
        padding: 15px 8px;
        text-align: left;
        color: #3f3f3f;
        font-size: 12px;
        text-transform: uppercase;
        text-align: left;
      }
      th {
        text-align: left;
        padding: 0 10px;
      }
      .file-download {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

export const Orderable = styled.div<OrderableProps>`
  cursor: pointer;
  width: fit-content;
  svg {
    display: ${props => (props.arrowDirection ? 'initial' : 'none')};
    margin-left: 5px;
    transform: scaleY(${props => (props.arrowDirection === 'desc' ? 1 : -1)});
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.span`
  color: #7e9395;
  font-size: 20px;
`;

export const Form = styled.form`
  padding: 10px;
  flex-direction: column;
  display: flex;
  input {
    margin-top: 10px;
  }
  span {
    margin-top: 10px;
  }
  button {
    margin-top: 10px;
  }
`;

export const TextFieldTitle = styled.span`
  color: #7e9395;
  font-size: 16px;
`;

export const SizedBoxHeight = styled.div`
  min-height: 32px;
`;

export const TextField = styled.input`
  height: 40px;
  min-width: 100px;
  width: 100%;
  padding: 7px 15px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
`;

export const SubmitButton = styled.button`
  min-width: 138px;
  height: 40px;
  background: #31c6d3;
  border-radius: 25px;
  color: #fff !important;
  font-size: 16px;
  border: none;
`;

export const Tick = styled.div`
  margin: auto;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #7e9395;
  border-radius: 2px;

  &.selected {
    background-color: #31c6d3;
    border: none;
  }
`;

export const TickAll = styled.div`
  margin: auto;
  height: 1.5rem;
  width: 1.5rem;
  border: 1px solid #7e9395;
  border-radius: 2px;

  &.selected {
    background-color: #fff;
    border: none;
  }
`;

export const Button = styled.button`
  max-width: 215px;
  background: #ffffff;
  box-shadow: 0px 8px 8px rgba(196, 196, 196, 0.3);
  border-radius: 25px;
  padding: 8px 16px;
  color: #00959c;
  font-size: 14px;
  border: 0px;
`;

export const FileDownload = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;
