import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
  Table,
  MenuTable,
  FilterContainer,
  SizedBoxHeight,
  PaginationWrapper,
  Pagination,
  TableActions,
} from '../styles';
import api from '../../../../_core/api';
import { TipoUnidade as TipoUnidadeType, PageInfo } from './interfaces';
import Modal from './modal';
import { useInadimplencia } from '../hooks/useInadimplencia';
import { handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { downloadXls } from '../../../../_core/services/download';
import { Button } from '../../../../_core/_components';

const TipoUnidade: React.FC = () => {
  const { brands } = useInadimplencia();
  const [loadingExport, setLoadingExport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState<TipoUnidadeType[]>([]);
  const [editValues, setEditValues] = useState<TipoUnidadeType>();
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  useEffect(() => {
    const getFields = async () => {
      setLoading(true);
      await getTipoUnidade();
      setLoading(false);
    };
    getFields();
  }, [page]);

  const getTipoUnidade = async () => {
    try {
      const { data } = await api.get(`business-unit-types?active=true&page=${page}`);
      setValues(data.data);
      setPageInfo(data.pageInfo);
    } catch (error) {
      handleErrors(error);
    }
  };

  const onCreate = async (values: TipoUnidadeType) => {
    setOpen(false);
    setLoading(true);
    try {
      await api.post('business-unit-types', values);
      await getTipoUnidade();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onUpdate = async (values: TipoUnidadeType) => {
    setEditValues(undefined);
    setLoading(true);
    try {
      await api.put(`business-unit-types/${values.id}`, values);
      await getTipoUnidade();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    setAnchorEl(null);
    try {
      await api.delete(`business-unit-types/${anchorEl?.id}`);
      await getTipoUnidade();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onClickEdit = () => {
    const obj = values.find(e => e.id == anchorEl?.id);
    setEditValues(obj);
    setAnchorEl(null);
  };

  const createModal = useMemo(() => {
    if (open) {
      return <Modal onClose={() => setOpen(false)} onSubmit={onCreate} options={brands} />;
    }
    return null;
  }, [open, brands]);

  const editModal = useMemo(() => {
    if (editValues) {
      return (
        <Modal onClose={() => setEditValues(undefined)} onSubmit={onUpdate} options={brands} values={editValues} />
      );
    }
    return null;
  }, [editValues, brands]);

  const exportFile = async () => {
    setLoadingExport(true);
    try {
      const response = await api.get(`/business-unit-types/download?active=true&page=${page}`, {
        responseType: 'arraybuffer',
      });
      downloadXls(response);
      success('Arquivo baixado! Confira no gerenciador de arquivos de seu navegador.');
    } catch (error) {
      handleErrors(error);
    }
    setLoadingExport(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Table>
        <MenuTable>
          <FilterContainer />
          <Button text="Criar novo" onClick={() => setOpen(true)} />
        </MenuTable>
        <table>
          <tr>
            <th>Tipo de unidade negócio</th>
            <th>Tipo de transação</th>
            <th>Tipo de receita</th>
            <th>Tipo de unidade de negócio Inadimplência</th>
            <th />
          </tr>
          {values.map((item, i) => (
            <tr key={i}>
              <td>{item.businessUnitType}</td>
              <td>{item.transactionType}</td>
              <td>{item.revenueType}</td>
              <td>{item.debtBusinessUnitType}</td>
              <td>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  component="span"
                  id={item.id.toString()}
                  onClick={(e: any) => setAnchorEl(e.currentTarget)}
                >
                  <MoreVert />
                </IconButton>
              </td>
            </tr>
          ))}
        </table>
        <SizedBoxHeight />
        <TableActions>
          <Button disabled={loadingExport} text="exportar" onClick={exportFile} />
          <PaginationWrapper>
            <Pagination count={pageInfo?.totalPages} page={page} onChange={(e, i) => setPage(i)} />
          </PaginationWrapper>
        </TableActions>
      </Table>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onClickEdit}>Editar</MenuItem>
        <MenuItem onClick={onDelete}>Excluir</MenuItem>
      </Menu>
      {createModal}
      {editModal}
    </>
  );
};

export default TipoUnidade;
