/* eslint-disable prefer-const */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import ReactSelect, { ValueContainerProps, components } from 'react-select';
import { InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Option } from './types';

interface Props {
  state: [Option[], React.Dispatch<React.SetStateAction<Option[]>>];
  placeholder?: string;
  options: Option[];
  disabled?: boolean;
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    position: 'relative',
  },
  label: {
    backgroundColor: 'white',
    padding: '0 4px',
    transform: 'translate(14px, -9px) scale(0.75)',
    transformOrigin: 'top left',
    zIndex: 1,
    position: 'absolute',
    transition: 'color 0.2s',
  },
}));

const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
  let [values, input] = children as any;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? '' : 's';
    values = `${values.length} item${plural} selecionado${plural}`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export function Select({ state: [value, setValue], placeholder, options, disabled = false }: Props): JSX.Element {
  const classes = useStyles();
  const [isFocused, setIsFocused] = useState(false);

  const onChange = (option: readonly Option[]) => {
    setValue(option.map(e => e));
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel shrink className={classes.label} style={{ color: isFocused ? '#31c6d3' : 'rgba(0, 0, 0, 0.54)' }}>
        {placeholder}
      </InputLabel>
      <ReactSelect
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        hideSelectedOptions={false}
        placeholder=""
        isMulti
        isSearchable
        isClearable
        options={options}
        menuPortalTarget={document.body}
        closeMenuOnSelect={false}
        classNamePrefix="react-select"
        styles={{
          control: (base, state) => ({
            ...base,
            height: 40,
            minHeight: 40,
            minWidth: '10rem',
            borderRadius: 30,
            boxShadow: state.isFocused ? '0 0 0 1px #31c6d3' : 'none',
            borderColor: state.isFocused ? '#31c6d3' : 'hsl(0, 0%, 80%)',
            '&:hover': {
              borderColor: state.isFocused ? '#31c6d3' : 'hsl(0, 0%, 80%)',
            },
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          valueContainer: base => ({
            ...base,
            flexWrap: 'nowrap',
            padding: '0 12px',
          }),
          multiValueRemove: base => ({ ...base, display: 'none' }),
          multiValue: base => ({ ...base, paddingRight: 3 }),
          placeholder: base => ({ ...base, marginLeft: '8px', marginRight: '8px' }),
          singleValue: base => ({ ...base, marginLeft: '8px', marginRight: '8px' }),
        }}
        noOptionsMessage={() => ''}
        components={{ ValueContainer }}
        isDisabled={disabled}
      />
      <FormHelperText />
    </FormControl>
  );
}
