import styled from 'styled-components/macro';

export const Container = styled.div`
  > h2 {
    color: #7e9395;
    margin-bottom: 1rem;
    font-weight: 600;
  }
`;

export const Label = styled.p`
  color: #7e9395;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-right: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  gap: 1rem;
`;
