import React, { useContext, useState } from 'react';
import { Container, Form, InputDate, Expanded, SubmitButton, TextField, TextFieldTitle } from './styles';
import api from '../../../../_core/api';
import ParametrosCalculoInadimplenciaContext from '../../../../_main/contexts/parametros-calculo-inadimplencia';
import { handleErrors } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';

const ParametrosCalculo: React.FC = () => {
  const { state, dispatch } = useContext(ParametrosCalculoInadimplenciaContext);
  const [date, setDate] = useState(state.date ?? '');
  const [processingDate, setProcessingDate] = useState(state.processingDate ?? '');
  const [adjustmentReceiptStartDate, setAdjustmentReceiptStartDate] = useState(state.adjustmentReceiptStartDate ?? '');
  const [adjustmentReceiptEndDate, setAdjustmentReceiptEndDate] = useState(state.adjustmentReceiptEndDate ?? '');
  const [netReceiptDate, setNetReceiptDate] = useState(state.netReceiptDate ?? '');
  const [previousMonthNetReceiptDate, setPreviousMonthNetReceiptDate] = useState(
    state.previousMonthNetReceiptDate ?? '',
  );
  const [minimumNumberOfDays, setMinimumNumberOfDays] = useState(state.minimumNumberOfDays ?? '');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    setLoading(true);
    try {
      e.preventDefault();
      const payload = [
        {
          name: 'DATA_REFERENCIA',
          value: date,
        },
        {
          name: 'DATA_PROCESSAMENTO',
          value: processingDate,
        },
        {
          name: 'DATA_INICIO_FATURAMENTO',
          value: adjustmentReceiptStartDate,
        },
        {
          name: 'DATA_FIM_FATURAMENTO',
          value: adjustmentReceiptEndDate,
        },
        {
          name: 'DATA_RECEB_LIQ_MES',
          value: netReceiptDate,
        },
        {
          name: 'DATA_RECEB_LIQ_MES_ANT',
          value: previousMonthNetReceiptDate,
        },
        {
          name: 'QTD_MIN_DIAS',
          value: minimumNumberOfDays,
        },
      ];
      await api.put('spredsheet/parameter/feature-name/CALCULO_INAD', payload);
      dispatch({
        type: 'setStateDefault',
        payload: {
          date,
          processingDate,
          adjustmentReceiptStartDate,
          adjustmentReceiptEndDate,
          netReceiptDate,
          previousMonthNetReceiptDate,
          minimumNumberOfDays,
        },
      });
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const enabled = [
    date,
    processingDate,
    adjustmentReceiptStartDate,
    adjustmentReceiptEndDate,
    netReceiptDate,
    previousMonthNetReceiptDate,
    minimumNumberOfDays,
  ].every(e => e);

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Container>
        <Form>
          <TextFieldTitle>Data de referência</TextFieldTitle>
          <InputDate mask="99/9999" value={date} onChange={(e: any) => setDate(e.target.value)} />
          <TextFieldTitle>Data de processamento</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            value={processingDate}
            onChange={(e: any) => setProcessingDate(e.target.value)}
          />
          <TextFieldTitle>Data Inicio Faturado</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            onChange={event => setAdjustmentReceiptStartDate(event.target.value)}
            value={adjustmentReceiptStartDate}
          />
          <TextFieldTitle>Data Fim Faturado</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            onChange={event => setAdjustmentReceiptEndDate(event.target.value)}
            value={adjustmentReceiptEndDate}
          />
          <TextFieldTitle>Data de recebimento líquido do mês</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            onChange={event => setNetReceiptDate(event.target.value)}
            value={netReceiptDate}
          />
          <TextFieldTitle>Data de recebimento líquido do mês anterior</TextFieldTitle>
          <InputDate
            mask="99/99/9999"
            onChange={event => setPreviousMonthNetReceiptDate(event.target.value)}
            value={previousMonthNetReceiptDate}
          />
          <TextFieldTitle>Quantidade mínima de dias</TextFieldTitle>
          <TextField onChange={event => setMinimumNumberOfDays(event.target.value)} value={minimumNumberOfDays} />
          <SubmitButton onClick={handleSubmit} disabled={!enabled}>
            Salvar
          </SubmitButton>
        </Form>
        <Expanded />
      </Container>
    </>
  );
};

export default ParametrosCalculo;
