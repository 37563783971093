import styled, { keyframes, createGlobalStyle } from 'styled-components';
import Pages from '@material-ui/lab/Pagination';
import DatePicker from 'react-datepicker';

interface Itab {
  active?: boolean;
  onClick?: any;
  disabled?: any;
  hidden?: boolean;
}
interface ISelect {
  active?: boolean;
  onClick?: any;
  disabled?: any;
  hidden?: boolean;
  isSelect?: boolean;
}

export const NegotiationArea = styled.div<ISelect>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 50px;
  h2 {
    width: 100%;
    color: #7e9395;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
  }
`;
export const BoxNegotiation = styled.div`
  width: 21%;
  margin-right: 30px;
  margin-bottom: 20px;
  div {
  }
  textarea {
    width: 99%;
    height: 100px;
    background-color: #fff;
    border: 1px solid #e5e9ea;
    border-radius: 10px;
    margin-top: 2px;
  }
`;
export const ButtonNegotiation = styled.div<ISelect>`
  position: relative;
  width: 100%;
  background-color: ${({ active }) => (active ? '#00959c' : '#828787')};
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
  padding: 7px 15px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: ${({ isSelect }) => (isSelect ? '10px 10px 5px 0px rgba(110,110,110,0.75)' : '')};
`;

export const SelectActive = styled.div`
  // width: 48%;
`;

export const Dpicker = styled(DatePicker)`
  border: 1px solid #7e93954d;
  border-radius: 10px;
  display: block;
  width: 100%;
  height: 39px;
  padding: 8px 10px;
  text-align: left;
  color: #7e9395;
  margin: 2px;
`;

export const ActionArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin: 0 3rem;
  button {
    background-color: #31c6d3;
    color: #fff;
    display: flex;
    justify-content: center;
  }
`;

export const SelectModal = styled.div<ISelect>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ModalContainer = styled.div<ISelect>`
  display: ${({ active }) => (active ? 'flex' : 'none')};
  position: fixed;
  margin: 0 auto;
  width: 85vw;
  height: 85vh;
  background-color: #fff;
  border-radius: 5px;
  padding: 30px;
  flex-direction: column;
  z-index: 11;
  overflow: auto;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  h3 {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 700;
    color: #00959c;

    margin-bottom: 2rem;
  }
  input {
    width: 64rem;
    height: 7.7rem;
    border: 1px solid #e0e0e0;
    border-radius: 50px;
    background-color: #f2f4f4;
    padding-left: 4rem;
    padding-right: 4rem;

    text-transform: uppercase;
    color: #00959c;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    &::placeholder {
      color: #ccc;
      font-size: 20px;
    }
  }
  ul {
  }
`;

export const CloseSelectModal = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  color: #00959c;
  background-color: transparent;
  border: 0;
  font-weight: bold;
`;

export const ListCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;

  ul {
    margin: 15px 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
    align-items: flex-start;
    li {
      max-width: 30%;
      min-width: 18%;
      width: 100%;
      height: 35px;
      cursor: pointer;
      list-style: none;
      display: flex;
      color: #484848;

      transition: all 0.3s;
      margin: 1rem 1rem 0 0;
      align-items: center;
      /* flex: 0 0 3.2rem; */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 10px 10px;
      animation: opaqueIn 0.4s ease-out;
      &:nth-child(even) {
        background: #e2f4f5;
      }
      &:nth-child(odd) {
        background: #edeeee;
      }
      &:hover {
        background-color: #00959c;
        color: #fff;
      }
    }
  }
`;

export const SelectedCard = styled.div`
  margin-top: 30px;
  padding: 30px 0;
  width: 100%;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;

  h5 {
    color: #484848;

    font-weight: 700;
    margin-right: 20px;
  }
  span {
    background-color: #00959c;
    padding: 10px 15px;
    color: #fff;
    border-radius: 40px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    button {
      background-color: transparent;
      border: 0;
      margin-left: 5px;
      color: #fff;
    }
  }
`;

export const ClearButtonModal = styled.div`
  width: 10%;
  background-color: #00959c;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  border-radius: 10px;
  padding: 7px 15px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;
`;

export const Option = styled.div`
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #3f3f3f;
  opacity: 1;
  padding: 0 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    border: 0;
    color: #7e9395;
    font-size: 16px;
    padding: 10px;
    background-color: transparent;
  }
`;
