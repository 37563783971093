import styled from 'styled-components';

interface ILoadingBox {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  margin: 0 1.8rem;
  padding: 2rem;
  top: -2.6rem;
  position: relative;
  border-radius: 21px;

  h1 {
    font-size: 20px;
    font-weight: bold;
    color: #7e9395;
  }
  button {
    background: #00959c 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #00959c22;
    border-radius: 50px;
    color: #fff;
    font-size: 14px;
    padding: 5px 25px;
    margin-top: 30px;
  }
`;

export const Content = styled.div`
  margin-bottom: 50px;
  position: relative;
`;
export const Sucess = styled.div`
  width: 95%;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  background: #15a126;
  margin-top: 15px;
  padding: 5px 10px;
`;
export const Error = styled.div`
  width: 95%;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  background: #ff3333;
  margin-top: 15px;
  padding: 5px 10px;
`;

export const ImportArea = styled.div`
  position: relative;
  margin-top: 40px;
  width: 640px;
  height: 370px;
  background: transparent linear-gradient(180deg, #31c6d30d 0%, #00959c27 100%) 0% 0% no-repeat padding-box;
  border: 1px dashed #7e939580;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #7e9395;
    width: 100%;
    text-align: center;
  }
  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #7e9395;
  }
  span {
    width: 100%;
    text-align: center;
    font-size: 14px;
    color: #7e9395;
  }
  input[type='file'] {
    // &::-webkit-file-upload-button {
    //   visibility: hidden;
    // }
    // &::before {
    //   content: 'Select some files';
    //   display: inline-block;
    //   background: linear-gradient(top, #f9f9f9, #e3e3e3);
    //   border: 1px solid #999;
    //   border-radius: 3px;
    //   padding: 5px 8px;
    //   outline: none;
    //   white-space: nowrap;
    //   -webkit-user-select: none;
    //   cursor: pointer;
    //   text-shadow: 1px 1px #fff;
    //   font-weight: 700;
    //   font-size: 10pt;
  }
  input {
    background: #7e9395 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 10px #7e93954d;
    border-radius: 50px;
    color: #ffffff;
    padding: 10px;
    border: 0;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const LoadingBox = styled.div<ILoadingBox>`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background: #7e9395aa;
  border-radius: 15px;
  display: ${props => (props.active ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;
