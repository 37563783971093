import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import siscobLogo from '../../../../assets/logo_siscob_modal.svg';
import CloseIcon from '../../../../assets/close';
import illustration from '../../../../assets/illustration.svg';
import './styles.scss';

type Props = {
  closeWelcome: Function;
  isOpen: boolean;
};

const Welcome: React.FC<Props> = ({ closeWelcome, isOpen }) => {
  return (
    <Dialog open={isOpen} onClose={() => closeWelcome()} aria-labelledby="max-width-dialog-title" className="welcome">
      <div className="welcome__content">
        <CloseIcon click={() => closeWelcome()} />
        <img className="welcome__logo" src={siscobLogo} alt="LogoWhiteSiscob" />
        <div className="welcome__textSection">
          <div className="welcome__head">
            <span>Olá,</span>
          </div>
          <div className="welcome__message">Esperamos que</div>
          <div className="welcome__message">você curta o nosso novo sistema de cobrança</div>
          <div className="welcome__subMsg">
            Trabalhamos para conquistar rapidez e assertividade na tomada de decisões, além de facilitar o seu dia a
            dia.
          </div>
        </div>
        <div className="welcome__action">
          <div onClick={() => closeWelcome()} className="welcome__button">
            Vamos Lá!
          </div>
        </div>
        <div></div>
        <img src={illustration} alt="festaIcon" className="welcome__illustration" />
      </div>
    </Dialog>
  );
};

export default Welcome;
