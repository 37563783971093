import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { toast } from 'react-toastify';
import Table from '../../../../_core/_components/table';
import * as S from '../styles';
import Modal from './modal';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';
import api from '../../../../_core/api';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Input, PaginatedTable } from '../../../../_core/_components';
import { handleErrors } from '../../../../_core/services/toast';

export type CpfCnpj = {
  id: number;
  cpfCnpj: string;
  luc: string;
  observacao: string;
  idModulo: number;
  nomeModulo: string;
  codigoModulo: string;
  ativo: boolean;
};

const ExcecaoCpf: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [registerList, setRegisterList] = useState<CpfCnpj[]>([]);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [modules, setModules] = useState<any[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRegister, setSelectedRegister] = useState<CpfCnpj | null>(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getModulos();
  }, []);

  useEffect(() => {
    getData();
  }, [page]);

  const getModulos = async () => {
    const responseModules = await api.get('/modulos');
    setModules(responseModules.data);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/excecoes?page=${page}`);
      setPageInfo(data.pageInfo);
      setRegisterList(data.data);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const save = async (payload: any) => {
    setLoading(true);
    try {
      if (selectedRegister?.id) {
        await api.put(`/excecoes/${selectedRegister.id}`, payload);
      } else {
        await api.post('/excecoes', payload);
      }
      const { data } = await api.get(`/excecoes?page=${page}`);
      setRegisterList(data.data);
      setModalOpen(false);
      setSelectedRegister(null);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const handleEditItem = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList.filter(reg => reg.cpfCnpj.toUpperCase().includes(searchText.toUpperCase()));
      return filtered;
    }
    return registerList;
  }, [searchText, registerList]);

  const excludeItem = async () => {
    try {
      await api.delete(`/judgment/exception-cpf-cnpj/rmv/${selectedRegister?.id}`);
      toast.success('O registro foi removido');
      const { data } = await api.get(`/excecoes?page=${page}`);
      setPageInfo(data.pageInfo);
      setRegisterList(data.data);
      setConfirmationOpen(false);
      setAnchorEl(null);
    } catch (e) {
      toast.error('Houve um erro ao tentar remover!');
    }
    setSelectedRegister(null);
  };

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        id: item.id,
        cpfCnpj: item.cpfCnpj,
        luc: item.luc,
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.id.toString()}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
              setSelectedRegister(item);
            }}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  return (
    <S.ContentContainer>
      <FullScreenLoading isEnabled={loading} />
      <ConfirmationModal
        open={isConfirmationOpen}
        text="Deseja excluir o registro?"
        handleCancel={() => setConfirmationOpen(false)}
        handleOk={excludeItem}
      />
      {isModalOpen && (
        <Modal save={save} onClose={() => handleCloseModal()} selectedRegister={selectedRegister} modules={modules} />
      )}

      <S.TopBar>
        <S.SearchBar>
          <Input label="Buscar CPF" state={[searchText, setSearchText]} />
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setModalOpen(true)} />
      </S.TopBar>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleEditItem}>Editar</MenuItem>
        {/* <MenuItem onClick={() => setConfirmationOpen(true)}>Excluir</MenuItem> */}
      </Menu>
      {renderedData.length > 0 && (
        <PaginatedTable
          columns={[
            { label: 'Id', key: 'id' },
            { label: 'CPF/CNPJ', key: 'cpfCnpj' },
            { label: 'Luc', key: 'luc' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
          pagination={pageInfo}
          onChangePage={setPage}
        />
      )}
    </S.ContentContainer>
  );
};

export default ExcecaoCpf;
