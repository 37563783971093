import React, { useEffect, useState, useContext, useMemo, useCallback } from 'react';
import ReactTooltip from 'react-tooltip';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FileSaver from 'file-saver';
import Dialog from '@material-ui/core/Dialog';
import { NovaNegociacao } from '../components/nova-negociacao';
import NewNegotiationBillingGroup from '../components/new-negotiation-billing-group/edit';
import { AuthContext } from '../../../_main/contexts/auth';
import iconArrow from '../../../assets/apple-keyboard-control.svg';
import more from '../../../assets/more.svg';
import {
  Container,
  FiltroWrapper,
  SelectedCard,
  ListCard,
  TickSelect,
  TickTitle,
  TickSubtitle,
  SelectModal,
  ModalContainer,
  CloseSelectModal,
  ActionArea,
  Section,
  LoaderWrapper,
  ContentTable,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ActionBox,
  ActionEdit,
  ActionDelete,
  PaginationWrapper,
  Pagination,
  ButtonTable,
} from './styles';
import Loader from '../../../assets/loader.jsx';
import api from '../../../_core/api';
import Negotiation from './negociacao';
import ShoppingUseCase from './negociacao/use-cases/shopping-use-case';
import CobrancaCorporativaUseCase from './negociacao/use-cases/cobranca-corporativa-use-case';
import { handleErrors } from '../../../_core/services/toast';
import { Button } from '../../../_core/_components';

interface ISelectOption {
  value: string;
  label: string;
}

interface IFilter {
  billingGroupIds: string[];
  shoppingIds: string[];
  cpfsCnpjs: string[];
  nomesFantasia: string[];
  lucs: string[];
  negotiator: string[];
}

interface INegotiation {
  identifier: number;
  cpfCnpj?: number;
  customerName?: string;
  brand?: string;
  billingGroupId?: number;
  billingGroupName?: string;
  luc?: string;
  shoppingId: number;
  shoppingName?: string;
  contactDate: string;
  negotiationReturnId?: number;
  negotiationReturnName?: string;
  contactResponsible?: string;
  active?: boolean;
  additionalInformation?: string;
}

interface ModelGroup {
  index: number;
  loading: boolean;
  onRemove: number;
  input: string;
  response: any[];
  values: any[];
}

export const HistoricoNegociacao: React.FC = () => {
  const { profile, shoppingProfileId, shoppingProfileName, userName } = useContext(AuthContext);
  const [loaderTableActive, setloaderTableActive] = useState(false);
  const [nbPage, setNbPage] = useState(1);
  const [page, setPage] = React.useState(1);
  const [showPagination, setShowPagination] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [itemDelete, setItemDelete] = React.useState(0);
  const [pageNbAtual, setPageNbAtual] = useState(1);
  const [campoOrdenacao1, setCampoOrdenacao1] = useState('');
  const [campoOrdenacao2, setCampoOrdenacao2] = useState('');
  const [modalGroup, setModalGroup] = useState<ModelGroup>({
    index: -1,
    loading: false,
    onRemove: -1,
    input: '',
    response: [],
    values: [undefined, undefined, undefined, undefined, new Date(), undefined, undefined, undefined],
  });
  const [screenLoading, setScreenLoading] = useState(false);

  const initialFilter: IFilter = {
    billingGroupIds: [''],
    shoppingIds: profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING' ? [shoppingProfileId || ''] : [''],
    cpfsCnpjs: [''],
    nomesFantasia: [''],
    lucs: [''],
    negotiator: [''],
  };
  const [filter, setFilter] = useState<IFilter>(initialFilter as IFilter);
  const [negotiationData, setNegotiationData] = useState([]);

  const [filterQuery, setFilterQuery] = useState<string | null>(null);

  const [modalNewNegotiationActive, setModalNewNegotiationActive] = useState(false);
  const [modalNewNegotiationBgActive, setModalNewNegotiationBgActive] = useState(false);
  const [negotiationSelected, setNegotiationSelected] = useState<INegotiation | null>(null);
  const [tableActionSelected, setTableActionSelected] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const [selectedShoppings, setSelectedShoppings] = useState<string[]>([]);
  const [inputShopping, setInputShopping] = useState('');
  const [isSelect, setIsSelect] = useState(false);
  const [shoppings, setShoppings] = useState<ISelectOption[]>([] as ISelectOption[]);

  const [selectedBgroup, setSelectedBgroup] = useState<string[]>([]);
  const [inputBgroup, setInputBgroup] = useState('');
  const [isSelectBg, setIsSelectBg] = useState(false);
  const [billingGroups, setBillingGroups] = useState<ISelectOption[]>([] as ISelectOption[]);

  const [selectedCustomer, setSelectedCustomer] = useState<string[]>([]);
  const [inputCustomer, setInputCustomer] = useState('');
  const [isSelectCustomer, setIsSelectCustomer] = useState(false);
  const [customers, setCustomers] = useState<ISelectOption[]>([] as ISelectOption[]);

  const [selectedBrand, setSelectedBrand] = useState<string[]>([]);
  const [inputBrand, setInputBrand] = useState('');
  const [isSelectBrand, setIsSelectBrand] = useState(false);
  const [brand, setBrand] = useState<ISelectOption[]>([] as ISelectOption[]);

  const [selectedNegotiator, setSelectedNegotiator] = useState<string[]>([]);
  const [inputNegotiator, setInputNegotiator] = useState('');
  const [isSelectNegotiator, setIsSelectNegotiator] = useState(false);
  const [negotiator, setNegotiator] = useState<ISelectOption[]>([]);

  const [isNegotiation, setIsNegotiation] = useState(false);

  useEffect(() => {
    // make parsed query
    const filterTemp = filter;

    if (filterTemp.billingGroupIds[0] === '' && filterTemp.billingGroupIds.length > 1) {
      filterTemp.billingGroupIds.shift();
    }
    if (filterTemp.shoppingIds[0] === '' && filterTemp.shoppingIds.length > 1) {
      filterTemp.shoppingIds.shift();
    }
    if (filterTemp.lucs[0] === '' && filterTemp.lucs.length > 1) {
      filterTemp.lucs.shift();
    }
    if (filterTemp.cpfsCnpjs[0] === '' && filterTemp.cpfsCnpjs.length > 1) {
      filterTemp.cpfsCnpjs.shift();
    }
    if (filterTemp.nomesFantasia[0] === '' && filterTemp.nomesFantasia.length > 1) {
      filterTemp.nomesFantasia.shift();
    }
    let parsed_query = JSON.stringify(filterTemp);

    parsed_query = parsed_query.replace('{', '');
    parsed_query = parsed_query.replace('}', '');
    parsed_query = parsed_query.replace(/],/g, ']&');
    parsed_query = parsed_query.replace(/[[\]']+/g, '');
    parsed_query = parsed_query.replace(/"/g, '');
    parsed_query = parsed_query.replace(/:/g, '=');
    parsed_query = parsed_query.replace('cpfsCnpjs', 'cpfsCnpjs');
    parsed_query = parsed_query.replace('nomesFantasia', 'brands');

    if (filterTemp.billingGroupIds[0] === '' || !filterTemp.billingGroupIds.length) {
      parsed_query = parsed_query.replace('&billingGroupIds=', '');
      parsed_query = parsed_query.replace('billingGroupIds=&', '');
      parsed_query = parsed_query.replace('billingGroupIds=', '');
    }
    if (filterTemp.shoppingIds[0] === '') {
      parsed_query = parsed_query.replace('&shoppingIds=', '');
      parsed_query = parsed_query.replace('shoppingIds=&', '');
      parsed_query = parsed_query.replace('shoppingIds=', '');
    }
    if (filterTemp.nomesFantasia[0] === '') {
      parsed_query = parsed_query.replace('&brands=', '');
      parsed_query = parsed_query.replace('brands=&', '');
      parsed_query = parsed_query.replace('brands=', '');
    }
    if (filterTemp.cpfsCnpjs[0] === '') {
      parsed_query = parsed_query.replace('&cpfsCnpjs=', '');
      parsed_query = parsed_query.replace('cpfsCnpjs=&', '');
      parsed_query = parsed_query.replace('cpfsCnpjs=', '');
    }
    if (filterTemp.negotiator[0] === '') {
      parsed_query = parsed_query.replace('&negotiator=', '');
      parsed_query = parsed_query.replace('negotiator=&', '');
      parsed_query = parsed_query.replace('negotiator=', '');
    }
    if (filterTemp.lucs[0] === '') {
      parsed_query = parsed_query.replace('&lucs=', '');
      parsed_query = parsed_query.replace('lucs=&', '');
      parsed_query = parsed_query.replace('lucs=', '');
    }

    setFilterQuery(parsed_query);
  }, [filter]);

  /** methods */

  const getNegotiator = async () => {
    setLoading(true);
    try {
      const negotiator_list: ISelectOption[] = [] as ISelectOption[];
      const response = await api.get(`/negotiation-history?active=1`);
      const response1 = await api.get(`/filters/negotiator`);
      setNbPage(Math.ceil(response.data.pageInfo.totalItems / response.data.pageInfo.pageSize));
      response1.data.map((item: any) => {
        if (
          negotiator_list.filter((nL: any) => nL.value === item.negotiatorName).length === 0 &&
          item.negotiatorName != null
        ) {
          negotiator_list.push({
            value: item.negotiatorName.toString(),
            label: item.negotiatorName.toString(),
          });
        }
        return null;
      });
      setNegotiator(negotiator_list);
      setLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const handleChangePagination = (event: any, value: any) => {
    setPage(value);
    getData(value);
  };

  const getShoppings = async () => {
    setLoading(true);
    try {
      const shopping_list: ISelectOption[] = [] as ISelectOption[];
      const response = await api.get(`/charge-legal-information/shoppings`);

      response.data.map((item: any) => {
        shopping_list.push({
          value: item.shoppingId.toString(),
          label: item.shoppingName,
        });
        return null;
      });
      // shopping_list.sort((item1, item2) => ordemCrescente(item1, item2, 'label'));
      // shopping_list.unshift({
      //   value: '',
      //   label: 'Todos',
      // });
      setShoppings(shopping_list);
      setLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const getBillingGroups = async () => {
    setLoading(true);
    try {
      const billingGroup_list: ISelectOption[] = [] as ISelectOption[];
      const response = await api.get(`/filters/billing-groups`);
      response.data.map((item: any) => {
        billingGroup_list.push({
          value: item.federationGroupId.toString(),
          label: item.federationGroupName,
        });
        return null;
      });
      // billingGroup_list.sort((item1, item2) => ordemCrescente(item1, item2, 'label'));
      // billingGroup_list.unshift({
      //   value: '',
      //   label: 'Todos',
      // });
      setBillingGroups(billingGroup_list);
      setLoading(false);
    } catch (err) {
      console.log({ err });
    }
  };

  const getCustomers = async () => {
    setLoading(true);
    try {
      const customer_list: ISelectOption[] = [] as ISelectOption[];
      const response = await api.get(`/filters/siscob-filters-information/clientes`);
      response.data.map((item: any) => {
        customer_list.push({
          value: item.cpfCnpj.toString(),
          label: item.customerName,
        });
        return null;
      });
      // customer_list.sort((item1, item2) => ordemCrescente(item1, item2, 'label'));
      // customer_list.unshift({
      //   value: '',
      //   label: 'Todos',
      // });
      setLoading(false);
      setCustomers(customer_list);
    } catch (err) {
      console.log({ err });
    }
  };

  const getCustomersDinamic = async () => {
    setLoading(true);
    try {
      const customer_list: ISelectOption[] = [] as ISelectOption[];
      const response = await api.get(`/filters/siscob-filters-information/clientes?${filterQuery}`);
      response.data.map((item: any) => {
        customer_list.push({
          value: item.cpfCnpj.toString(),
          label: item.customerName,
        });
        return null;
      });
      // customer_list.sort((item1, item2) => ordemCrescente(item1, item2, 'label'));
      // customer_list.unshift({
      //   value: '',
      //   label: 'Todos',
      // });
      setLoading(false);
      setCustomers(customer_list);
    } catch (err) {
      console.log({ err });
    }
  };

  const getBrandsDinamic = async () => {
    setLoading(true);
    try {
      const brand_list: ISelectOption[] = [] as ISelectOption[];
      const response = await api.get(`/filters/siscob-filters-information/marcas?${filterQuery}`);
      response.data.map((item: any) => {
        brand_list.push({
          value: item.toString(),
          label: item,
        });
        return null;
      });

      setLoading(false);
      setBrand(brand_list);
    } catch (err) {
      console.log({ err });
    }
  };

  const getBrands = async () => {
    setLoading(true);
    try {
      const brand_list: ISelectOption[] = [] as ISelectOption[];
      const response = await api.get(`/filters/siscob-filters-information/marcas`);
      response.data.map((item: any) => {
        brand_list.push({
          value: item.toString(),
          label: item,
        });
        return null;
      });
      // customer_list.sort((item1, item2) => ordemCrescente(item1, item2, 'label'));
      // customer_list.unshift({
      //   value: '',
      //   label: 'Todos',
      // });
      setLoading(false);
      setBrand(brand_list);
    } catch (err) {
      console.log({ err });
    }
  };

  const handleClickOpenBg = (item: INegotiation) => {
    setOpen(true);
    setItemDelete(item.identifier);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = async (pager: number) => {
    let params = { active: '1', pager };

    if (filter?.billingGroupIds[0] && filter?.billingGroupIds[0] !== '') {
      params = {
        ...params,
        ...{ billingGroupIds: filter?.billingGroupIds.map(item => item).join(',') },
      };
    }

    if (filter?.shoppingIds[0] && filter?.shoppingIds[0] !== '') {
      params = {
        ...params,
        ...{ shoppingId: filter?.shoppingIds[0] },
      };
    }

    if (filter?.nomesFantasia[0] && filter?.nomesFantasia[0] !== '') {
      params = {
        ...params,
        ...{ brand: filter?.nomesFantasia[0] },
      };
    }

    if (filter?.cpfsCnpjs[0] && filter?.cpfsCnpjs[0] !== '') {
      params = {
        ...params,
        ...{ cpfCnpj: filter?.cpfsCnpjs[0] },
      };
    }

    if (filter?.negotiator[0] && filter?.negotiator[0] !== '') {
      params = {
        ...params,
        ...{ negotiator: filter?.negotiator[0] },
      };
    }

    try {
      setloaderTableActive(true);
      setPageNbAtual(pager);
      const response = await api.get(`/negotiation-history`, { params });

      setNbPage(Math.ceil(response.data.pageInfo.totalItems / response.data.pageInfo.pageSize));
      // &sort=${ascDesc  +  campoOrdenacao}

      setNegotiationData(response.data.data);
    } catch (err) {
      handleErrors(err);
    } finally {
      setloaderTableActive(false);
    }
  };

  const ordernar = async (ordernacao: any) => {
    let teste = '';
    if (campoOrdenacao1 === ordernacao && campoOrdenacao2 === ordernacao) {
      teste = '';
    } else {
      teste = `${ordernacao}`;
    }
    setCampoOrdenacao1(ordernacao);
    setCampoOrdenacao2(teste);
    try {
      setloaderTableActive(true);
      const response = await api.get(`/negotiation-history?${filterQuery}&active=1&page=${pageNbAtual}&sort=${teste}`);

      setNegotiationData(response.data.data);
    } catch (err) {
      console.log({ err });
    } finally {
      setloaderTableActive(false);
    }
  };

  const exportData = async () => {
    api.get(`/negotiation-history/xls/?${filterQuery}&active=1`, { responseType: 'arraybuffer' }).then(response => {
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, 'relatorio.xlsx');
    });
  };

  const handleSearch = () => {
    getData(page);
    setShowPagination(true);
    setPage(1);
  };

  const clearFilter = () => {
    setFilter(initialFilter);
    setShoppings([] as ISelectOption[]);
    setBillingGroups([] as ISelectOption[]);
    setCustomers([] as ISelectOption[]);
    setBrand([] as ISelectOption[]);
    setSelectedBgroup([]);
    setSelectedBrand([]);
    setSelectedCustomer([]);
    setSelectedShoppings([]);
    setSelectedNegotiator([]);
    setInputShopping('');
    setInputBgroup('');
    setInputNegotiator('');
    setInputCustomer('');
    setInputBrand('');
    // getFilters(initialFilter, 'clear');
  };

  function formatarData(data: any) {
    try {
      return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0, 4)}`;
    } catch {
      return '';
    }
  }

  const handleExitAddNewNegotiation = (inseriu = false) => {
    setModalNewNegotiationActive(false);
    setModalNewNegotiationBgActive(false);
    setNegotiationSelected(null);
    if (inseriu === true) {
      getData(page);
    }
  };

  const handleTableActions = (identifier: number) => {
    if (tableActionSelected !== identifier) {
      setTableActionSelected(identifier);
    } else {
      setTableActionSelected(null);
    }
  };

  const handleEditBillingGroup = (item: INegotiation) => {
    setNegotiationSelected(item);
    setModalNewNegotiationBgActive(true);
    setTableActionSelected(null);
    window.scrollTo(0, 0);
  };

  const handleDelete = (identifier: number) => {
    deleteNegotiationtRequest(identifier);
    setTableActionSelected(null);
    handleClose();
  };

  const deleteNegotiationtRequest = async (identifier: number) => {
    api.delete(`negotiation-history/${identifier}?userIdentifier=${userName}`).then(response => {
      getData(page);
    });
  };

  function formatarRazaoSocial(nome: any) {
    try {
      if (nome.length <= 15) {
        return nome;
      }
      if (nome.charAt(14) === ' ' || nome.charAt(14) === ',') {
        return `${nome.substring(0, 14)}...`;
      }
      return `${nome.substring(0, 15)}...`;
    } catch {
      return '';
    }
  }

  const handleModalShopping = () => {
    if (profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING') {
      alert('Shopping já selecionado');
    } else {
      setIsSelect(!isSelect);
      if (shoppings.length <= 0) {
        getShoppings();
      }
    }
  };

  const handleModalBillingGroup = () => {
    setIsSelectBg(!isSelectBg);
    if (billingGroups.length <= 0) {
      getBillingGroups();
    }
  };

  const handleModalCustomer = () => {
    setIsSelectCustomer(!isSelectCustomer);

    if (selectedShoppings.length > 0 || selectedBgroup.length > 0 || shoppingProfileName) {
      getCustomersDinamic();
    } else {
      getCustomers();
    }
  };

  const handleModalBrand = () => {
    setIsSelectBrand(!isSelectBrand);

    if (brand.length <= 0) {
      if (
        selectedShoppings.length > 0 ||
        selectedBgroup.length > 0 ||
        selectedCustomer.length > 0 ||
        shoppingProfileName ||
        selectedNegotiator.length <= 0
      ) {
        getBrandsDinamic();
      } else {
        getBrands();
      }
    }
  };

  const handleModalNegotiator = () => {
    setIsSelectNegotiator(!isSelectNegotiator);
    if (negotiator.length <= 0) {
      getNegotiator();
    }
  };

  const handleSelectShoppings = (label: any, value: any) => {
    const newFilter = { ...filter };
    const newShopping = [...selectedShoppings];

    if (newShopping.length <= 0) {
      newFilter.shoppingIds = [value.toString()];
      if (!newShopping.includes(label)) {
        newShopping.push(label);
      }

      setSelectedShoppings(newShopping);
      setFilter(newFilter);
    } else {
      alert('Shopping já selecionado');
    }
  };

  const handleRemoveShopping = (index: number) => {
    const newFilter = { ...filter };
    const deleteShopping = [...selectedShoppings];
    if (index > -1) {
      deleteShopping.splice(index, 1);
      newFilter.shoppingIds.splice(index, 1);
    }
    setSelectedShoppings(deleteShopping);
    setFilter(newFilter);
  };

  const handleSelectbgroup = (label: any, value: any) => {
    const newFilter = { ...filter };
    const newBgroup = [...selectedBgroup];

    if (!newBgroup.includes(label)) {
      newBgroup.push(label);
      newFilter.billingGroupIds.push(value.toString());
    }
    console.log('new BG', newBgroup);
    setSelectedBgroup(newBgroup);
    setFilter(newFilter);
  };

  const handleRemoveBgroup = (index: number) => {
    const newFilter = { ...filter };
    const deleteBgroup = [...selectedBgroup];
    if (index > -1) {
      deleteBgroup.splice(index, 1);
      newFilter.billingGroupIds.splice(index, 1);
    }
    setSelectedBgroup(deleteBgroup);
    setFilter(newFilter);
  };

  const handleSelectCustomer = (label: any, value: any) => {
    const newFilter = { ...filter };
    const newCustomer = [...selectedCustomer];
    if (newCustomer.length <= 0) {
      newFilter.cpfsCnpjs = [value.toString()];
      if (!newCustomer.includes(label)) {
        newCustomer.push(label);
      }
      setSelectedCustomer(newCustomer);
      setFilter(newFilter);
    } else {
      alert('Cliente já selecionado');
    }
  };

  const handleRemoveCustomer = (index: number) => {
    const newFilter = { ...filter };
    const deleteCustomer = [...selectedCustomer];
    if (index > -1) {
      deleteCustomer.splice(index, 1);
      newFilter.cpfsCnpjs.splice(index, 1);
    }
    setSelectedCustomer(deleteCustomer);
    setFilter(newFilter);
  };

  const handleSelectBrand = (label: any, value: any) => {
    const newFilter = { ...filter };
    const newBrand = [...selectedBrand];
    if (newBrand.length <= 0) {
      newFilter.nomesFantasia = [value.toString()];
      if (!newBrand.includes(label)) {
        newBrand.push(label);
      }
      setSelectedBrand(newBrand);
      setFilter(newFilter);
    } else {
      alert('Nome fantasia já selecionado');
    }
  };

  const handleRemoveBrand = (index: number) => {
    const newFilter = { ...filter };
    const deleteBrand = [...selectedBrand];
    if (index > -1) {
      deleteBrand.splice(index, 1);
      newFilter.nomesFantasia.splice(index, 1);
    }
    setSelectedBrand(deleteBrand);
    setFilter(newFilter);
  };

  const handleSelectNegotiator = (label: any, value: any) => {
    const newFilter = { ...filter };
    const newNegotiator = [...selectedNegotiator];

    if (newNegotiator.length <= 0) {
      newFilter.negotiator = [value.toString()];
      if (!newNegotiator.includes(label)) {
        newNegotiator.push(label);
      }

      setSelectedNegotiator(newNegotiator);
      setFilter(newFilter);
    } else {
      alert('Negociador já selecionado');
    }
  };

  const handleRemoveNegotiator = (index: number) => {
    const newFilter = { ...filter };
    const deleteNegotiator = [...selectedNegotiator];
    if (index > -1) {
      deleteNegotiator.splice(index, 1);
      newFilter.negotiator.splice(index, 1);
    }
    setSelectedNegotiator(deleteNegotiator);
    setFilter(newFilter);
  };

  function converterData(data: any): string {
    let dia = `${data.getDate()}`;
    let mes = `${data.getMonth() + 1}`;
    const ano = `${data.getFullYear()}`;

    const pad = '00';
    dia = pad.substring(0, pad.length - dia.length) + dia;
    mes = pad.substring(0, pad.length - mes.length) + mes;

    return `${ano}-${mes}-${dia}`;
  }

  const handleSelectItem = (label: string, value: string) => {
    const { index, values } = modalGroup;
    const arr = [...values];
    if (index === 1 && arr[1]?.length > 0) return;
    if (index === 6 && arr[6]?.length > 0) return;
    if (arr[index]?.find((item: any) => item.value === value)) return;
    if (arr[index]?.length > 0) {
      arr[index].push({ label, value });
    } else {
      arr[index] = [{ label, value }];
    }

    setModalGroup({ ...modalGroup, values: arr });
  };

  const getDinamicFilter = (index: number) => {
    const shops =
      modalGroup.values[0]?.length > 0 ? `shoppingIds=${modalGroup.values[0].map((item: any) => item.value)}` : '';
    const billingGroup = modalGroup.values[1]?.length > 0 ? `billingGroupIds=${modalGroup.values[1][0].value}` : '';
    const cpfsCnpjs =
      modalGroup.values[2]?.length > 0
        ? `cpfsCnpjs=${modalGroup.values[2].map((item: any) => item.value).join(',')}`
        : '';
    const lucs = modalGroup.values[3]?.length > 0 ? `lucs=${modalGroup.values[3].map((item: any) => item.label)}` : '';
    const brands =
      modalGroup.values[5]?.length > 0 ? `brands=${modalGroup.values[5].map((item: any) => item.value)}` : '';
    switch (index) {
      case 0:
        return [billingGroup, cpfsCnpjs, lucs, brands].filter(e => e).join('&');
      case 1:
        return [shops, cpfsCnpjs, lucs, brands].filter(e => e).join('&');
      case 2:
        return [shops, billingGroup, lucs, brands].filter(e => e).join('&');
      case 3:
        return [shops, billingGroup, cpfsCnpjs, brands].filter(e => e).join('&');
      case 5:
        return [shops, billingGroup, cpfsCnpjs, lucs].filter(e => e).join('&');
      default:
        return null;
    }
  };

  const getShoppingsModal = async () => {
    const list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/shoppings?${getDinamicFilter(0)}`);
    response.data.map((item: any) => {
      list.push({
        value: item.shoppingId.toString(),
        label: item.shopping,
      });
      return null;
    });
    setModalGroup({ ...modalGroup, response: list, loading: false });
  };

  const getBillingGroupsModal = async () => {
    const list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/billing-groups?${getDinamicFilter(1)}`);
    response.data.map((item: any) => {
      list.push({
        value: item.federationGroupId.toString(),
        label: item.federationGroupName,
      });
      return null;
    });
    setModalGroup({ ...modalGroup, response: list, loading: false });
  };

  const getCustomersModal = async () => {
    const customer_list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`);
    response.data.map((item: any) => {
      customer_list.push({
        value: item.cpfCnpj.toString(),
        label: item.customerName,
      });
      return null;
    });
    setModalGroup({ ...modalGroup, response: customer_list, loading: false });
  };

  const getLucModal = async () => {
    const lucs: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`);
    response.data.map((item: any, i: number) => {
      lucs.push({
        value: i.toString(),
        label: item.luc.toUpperCase(),
      });
      return null;
    });
    setModalGroup({ ...modalGroup, response: lucs, loading: false });
  };

  const getBrandsModal = async () => {
    const brand_list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`);
    response.data.map((item: any) => {
      brand_list.push({
        value: item.toString(),
        label: item,
      });
      return null;
    });
    setModalGroup({ ...modalGroup, response: brand_list, loading: false });
  };

  const getNegotiationsReturnModal = async () => {
    const { data } = await api.get(`/filters/negotiations-return`);
    const negotiations: any[] = [];
    data.map((item: any) => {
      negotiations.push({
        value: item.returnId.toString(),
        label: item.returnName.toUpperCase(),
      });
      return null;
    });
    negotiations.sort((item1, item2) => {
      if (item1.label > item2.label) return 1;
      if (item1.label < item2.label) return -1;
      return 0;
    });
    setModalGroup({ ...modalGroup, response: negotiations, loading: false });
  };

  useEffect(() => {
    if (disableShoppingButton) {
      const { values } = modalGroup;
      const arr = [...values];
      arr[0] = [{ value: shoppingProfileId, label: shoppingProfileName }];
      setModalGroup({ ...modalGroup, values: arr });
    }
  }, []);

  useEffect(() => {
    const { index, loading: load, onRemove } = modalGroup;
    if (load) {
      switch (index) {
        case 0:
          getShoppingsModal();
          break;
        case 1:
          getBillingGroupsModal();
          break;
        case 2:
          getCustomersModal();
          break;
        case 3:
          getLucModal();
          break;
        case 5:
          getBrandsModal();
          break;
        case 6:
          getNegotiationsReturnModal();
          break;
        default:
          console.log('default');
      }
    } else if (onRemove > -1) {
      if (onRemove !== 1 && profile === 'COBRANÇA CORPORATIVA') {
        onRefreshByIndex();
      } else if (onRemove !== 0 && profile === 'COBRANÇA SHOPPING') {
        NoRefreshShopping();
      }
    }
  }, [modalGroup]);

  const refreshAllItems = async () => {
    if (modalGroup.values[1]?.length > 0) {
      const { values } = modalGroup;
      const arr = [...values];
      setScreenLoading(true);
      const response = await Promise.all([
        api.get(`/filters/siscob-filters-information/shoppings?${getDinamicFilter(0)}`),
        api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
        api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`),
        api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
      ]);
      response.forEach((payload: any, i: number) => {
        switch (i) {
          case 0: // Shoppings
            arr[0] = payload.data.map((item: any) => ({
              value: item.shoppingId.toString(),
              label: item.shopping,
            }));
            break;
          case 1: // Clientes
            arr[2] = payload.data.map((item: any) => ({
              value: item.cpfCnpj.toString(),
              label: item.customerName,
            }));
            break;
          case 2: // Lucs
            arr[3] = payload.data.map((item: any, idx: number) => ({
              value: idx.toString(),
              label: item.luc.toUpperCase(),
            }));
            break;
          case 3: // Nome fantasias
            arr[5] = payload.data.map((item: any) => ({
              value: item.toString(),
              label: item,
            }));
            break;
          default:
            console.log('default');
        }
      });
      setModalGroup({ ...modalGroup, index: -1, input: '', values: arr });
      setScreenLoading(false);
    }
  };

  const onRefreshByIndex = async () => {
    setScreenLoading(true);
    const { values, onRemove } = modalGroup;
    const arr = [...values];
    const allPromises = [
      api.get(`/filters/siscob-filters-information/shoppings?${getDinamicFilter(0)}`),
      undefined,
      api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
      api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`),
      undefined,
      api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
    ];
    const response = await Promise.all(allPromises);
    response.forEach((payload: any, i: number) => {
      if (i === onRemove) return;
      switch (i) {
        case 0: // Shoppings
          arr[0] = payload.data.map((item: any) => ({
            value: item.shoppingId.toString(),
            label: item.shopping,
          }));
          break;
        case 2: // Clientes
          arr[2] = payload.data.map((item: any) => ({
            value: item.cpfCnpj.toString(),
            label: item.customerName,
          }));
          break;
        case 3: // Lucs
          arr[3] = payload.data.map((item: any, idx: number) => ({
            value: idx.toString(),
            label: item.luc.toUpperCase(),
          }));
          break;
        case 5: // Nome fantasias
          arr[5] = payload.data.map((item: any) => ({
            value: item.toString(),
            label: item,
          }));
          break;
        default:
          break;
      }
    });
    setModalGroup({ ...modalGroup, index: -1, input: '', values: arr, onRemove: -1 });
    setScreenLoading(false);
  };

  const refreshOnChangeLucs = async () => {
    const { values } = modalGroup;
    const arr = [...values];
    if (modalGroup.values[3]?.length > 0) {
      setScreenLoading(true);
      const response = await Promise.all([
        api.get(`/filters/siscob-filters-information/shoppings?${getDinamicFilter(0)}`),
        api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
        api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
      ]);
      response.forEach((payload: any, i: number) => {
        switch (i) {
          case 0: // Shoppings
            arr[0] = payload.data.map((item: any) => ({
              value: item.shoppingId.toString(),
              label: item.shopping,
            }));
            break;
          case 1: // Clientes
            arr[2] = payload.data.map((item: any) => ({
              value: item.cpfCnpj.toString(),
              label: item.customerName,
            }));
            break;
          case 3: // Nome fantasias
            arr[5] = payload.data.map((item: any) => ({
              value: item.toString(),
              label: item,
            }));
            break;
          default:
            console.log('default');
        }
      });
      setScreenLoading(false);
    } else {
      arr.forEach((element, index) => {
        arr[index] = [0, 1, 2, 5].includes(index) ? [] : arr[index];
      });
    }
    setModalGroup({ ...modalGroup, index: -1, input: '', values: arr });
  };

  const NoRefreshShopping = async () => {
    setScreenLoading(true);
    const { values, onRemove } = modalGroup;
    const arr = [...values];
    const allPromises = [
      undefined,
      undefined,
      api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
      api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`),
      undefined,
      api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
    ];
    const response = await Promise.all(allPromises);
    response.forEach((payload: any, i: number) => {
      if (i === onRemove) return;
      switch (i) {
        case 2: // Clientes
          arr[2] = payload.data.map((item: any) => ({
            value: item.cpfCnpj.toString(),
            label: item.customerName,
          }));
          break;
        case 3: // Lucs
          arr[3] = payload.data.map((item: any, idx: number) => ({
            value: idx.toString(),
            label: item.luc.toUpperCase(),
          }));
          break;
        case 5: // Nome fantasias
          arr[5] = payload.data.map((item: any) => ({
            value: item.toString(),
            label: item,
          }));
          break;
        default:
          console.log('default');
      }
    });
    setModalGroup({ ...modalGroup, index: -1, input: '', values: arr, onRemove: -1 });
    setScreenLoading(false);
  };

  const NoRefreshClientes = async () => {
    setScreenLoading(true);
    const { values, onRemove } = modalGroup;
    const arr = [...values];
    const allPromises = [
      undefined,
      undefined,
      undefined,
      api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`),
      undefined,
      api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
    ];
    const response = await Promise.all(allPromises);
    response.forEach((payload: any, i: number) => {
      if (i === onRemove) return;
      switch (i) {
        case 3: // Lucs
          arr[3] = payload.data.map((item: any, idx: number) => ({
            value: idx.toString(),
            label: item.luc.toUpperCase(),
          }));
          break;
        case 5: // Nome fantasias
          arr[5] = payload.data.map((item: any) => ({
            value: item.toString(),
            label: item,
          }));
          break;
        default:
          console.log('default');
      }
    });
    setModalGroup({ ...modalGroup, index: -1, input: '', values: arr, onRemove: -1 });
    setScreenLoading(false);
  };

  const NoRefreshLucs = async () => {
    setScreenLoading(true);
    const { values, onRemove } = modalGroup;
    const arr = [...values];
    const allPromises = [
      undefined,
      undefined,
      api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
      undefined,
      undefined,
      api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
    ];
    const response = await Promise.all(allPromises);
    response.forEach((payload: any, i: number) => {
      if (i === onRemove) return;
      switch (i) {
        case 2: // Clientes
          arr[2] = payload.data.map((item: any) => ({
            value: item.cpfCnpj.toString(),
            label: item.customerName,
          }));
          break;
        case 5: // Nome fantasias
          arr[5] = payload.data.map((item: any) => ({
            value: item.toString(),
            label: item,
          }));
          break;
        default:
          console.log('default');
      }
    });
    setModalGroup({ ...modalGroup, index: -1, input: '', values: arr, onRemove: -1 });
    setScreenLoading(false);
  };

  const NoRefreshMarcas = async () => {
    setScreenLoading(true);
    const { values, onRemove } = modalGroup;
    const arr = [...values];
    const allPromises = [
      undefined,
      undefined,
      api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
      api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`),
      undefined,
      undefined,
    ];
    const response = await Promise.all(allPromises);
    response.forEach((payload: any, i: number) => {
      if (i === onRemove) return;
      switch (i) {
        case 2: // Clientes
          arr[2] = payload.data.map((item: any) => ({
            value: item.cpfCnpj.toString(),
            label: item.customerName,
          }));
          break;
        case 3: // Lucs
          arr[3] = payload.data.map((item: any, idx: number) => ({
            value: idx.toString(),
            label: item.luc.toUpperCase(),
          }));
          break;
        default:
          console.log('default');
      }
    });
    setModalGroup({ ...modalGroup, index: -1, input: '', values: arr, onRemove: -1 });
    setScreenLoading(false);
  };

  const handleRemoveSelectedModalItems = (i: number, modelGroupIndex?: number) => {
    const { index, values } = modalGroup;
    const currentIndex = modelGroupIndex ?? index;
    const arr = [...values];
    arr[currentIndex] = values[currentIndex].filter((e: any, idx: number) => idx !== i);
    if (currentIndex === 1) {
      arr.forEach((item, idx) => {
        if (idx === 1) return;
        arr[idx] = [];
      });
    }
    setModalGroup({ ...modalGroup, values: arr, onRemove: modelGroupIndex ?? -1 });
  };

  const clearAll = useCallback(
    (index: number) => {
      const { values } = modalGroup;
      const arr = [...values];
      arr[index] = undefined;
      setModalGroup({ ...modalGroup, values: arr });
    },
    [modalGroup],
  );

  const onCloseModal = async () => {
    const { index } = modalGroup;
    if (profile === 'COBRANÇA CORPORATIVA') {
      setModalGroup({ ...modalGroup, index: -1, input: '', loading: false });
      switch (index) {
        case 1:
          await refreshAllItems();
          break;
        case 3:
          await refreshOnChangeLucs();
          break;
        default:
          console.log('default');
      }
    } else if (profile === 'COBRANÇA SHOPPING') {
      setModalGroup({ ...modalGroup, index: -1, input: '', loading: false });
      switch (index) {
        case 2:
          await NoRefreshClientes();
          break;
        case 3:
          await NoRefreshLucs();
          break;
        case 5:
          await NoRefreshMarcas();
          break;
        default:
          console.log('default');
      }
    }
  };

  const disableShoppingButton = useMemo(() => {
    return profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING';
  }, [profile]);

  const handleSubmit = async () => {
    if (!isValidSubmit) {
      alert('Preencha todos os campos!');
      return;
    }
    setScreenLoading(true);
    const lucs = modalGroup.values[3].map((item: any) => item.label).join(',');
    let response = null;
    if (disableShoppingButton) {
      response = await api.get(
        `/filters/siscob-filters-information?shoppingIds=${modalGroup.values[0][0].value}&lucs=${lucs}`,
      );
    } else {
      response = await api.get(
        `/filters/siscob-filters-information?billingGroupIds=${modalGroup.values[1][0].value}&lucs=${lucs}`,
      );
    }
    const dataPost: any = [];
    response.data.forEach((item: any) => {
      dataPost.push({
        cpfCnpj: item.cnpjCpf,
        billingGroupId: disableShoppingButton ? null : modalGroup.values[1][0].value,
        shoppingId: item.shoppingId,
        luc: item.luc,
        brand: item.brand,
        active: 1,
        userIdentifier: userName,
        contactDate: converterData(modalGroup.values[4]),
        additionalInformation: modalGroup.values[7],
        negotiationReturnId: modalGroup.values[6][0].value,
      });
    });
    api
      .post(`/negotiation-history/negotiation-histories`, dataPost)
      .then(
        respnse => {
          alert('Registrado com sucesso!');
          setIsNegotiation(false);
        },
        error => {
          alert('Ocorreu um erro, tente novamente!');
        },
      )
      .finally(() => setScreenLoading(false));
  };

  const isValidSubmit = useMemo(() => {
    let valid = modalGroup.values.length >= 7;
    modalGroup.values.forEach((e, i) => {
      if ([4, 7].includes(i) || valid === false) {
      } else if (e === undefined || e.length === 0) {
        valid = false;
      }
    });
    return valid;
  }, [modalGroup]);

  const negotioationUseCase = useMemo(() => {
    if (profile === 'COBRANÇA CORPORATIVA') {
      return new CobrancaCorporativaUseCase();
    }
    const id = parseInt(shoppingProfileId ?? '0', 10);
    const name = shoppingProfileName ?? '';
    return new ShoppingUseCase(id, name);
  }, [profile]);

  const viewFilter = useCallback(() => {
    switch (profile) {
      case 'COBRANÇA CORPORATIVA':
        return (data: INegotiation) => data.billingGroupName && data;
      default:
        return () => true;
    }
  }, [profile]);

  const actionButtons = useCallback(
    (item: INegotiation) => {
      const buttons = (
        <ActionBox active={tableActionSelected === item.identifier}>
          <ActionEdit onClick={() => handleEditBillingGroup(item)}>Editar</ActionEdit>
          <ActionDelete onClick={() => handleClickOpenBg(item)}>Excluir</ActionDelete>
        </ActionBox>
      );
      switch (profile) {
        case 'RÉGUA DE COBRANÇA':
          return null;
        case 'COBRANÇA SHOPPING':
        case 'APROVADOR SHOPPING':
          return !item.billingGroupName ? buttons : null;
        default:
          return buttons;
      }
    },
    [profile, tableActionSelected],
  );

  return (
    <>
      <Container>
        <h2>Selecione os filtros desejados</h2>
        <FiltroWrapper>
          <TickSelect onClick={() => handleModalShopping()}>
            <TickTitle>
              {(shoppingProfileId && profile === 'COBRANÇA SHOPPING') || profile === 'APROVADOR SHOPPING' ? (
                <>{shoppingProfileName}</>
              ) : (
                <>Shopping</>
              )}
            </TickTitle>
            <TickSubtitle>
              {(selectedShoppings.length <= 0 && profile === 'COBRANÇA SHOPPING') ||
              profile === 'APROVADOR SHOPPING' ||
              selectedShoppings.length > 0 ? (
                selectedShoppings.map((item: any, index: number) => <>{index < 3 && `${item} | `}</>)
              ) : (
                <>TODOS</>
              )}
              {selectedShoppings.length >= 4 && <> +{selectedShoppings.length - 3}</>}
            </TickSubtitle>
          </TickSelect>
          <TickSelect onClick={() => handleModalBillingGroup()}>
            <TickTitle>Grupo de Cobrança</TickTitle>
            <TickSubtitle>
              {selectedBgroup.length === 0 ? (
                <>TODOS</>
              ) : (
                selectedBgroup.map((item: any, index: number) => <>{index < 3 && `${item} | `}</>)
              )}
              {selectedBgroup.length >= 4 && <> +{selectedBgroup.length - 3}</>}
            </TickSubtitle>
          </TickSelect>
          <TickSelect onClick={() => handleModalCustomer()}>
            <TickTitle>Clientes</TickTitle>
            <TickSubtitle>
              {selectedCustomer.length === 0 ? (
                <>TODOS</>
              ) : (
                selectedCustomer.map((item: any, index: number) => <>{index < 3 && `${item} | `}</>)
              )}
              {selectedCustomer.length >= 4 && <> +{selectedCustomer.length - 3}</>}
            </TickSubtitle>
          </TickSelect>
          <TickSelect onClick={() => handleModalBrand()}>
            <TickTitle>Marca</TickTitle>
            <TickSubtitle>
              {selectedBrand.length === 0 ? (
                <>TODOS</>
              ) : (
                selectedBrand.map((item: any, index: number) => <>{index < 3 && `${item} | `}</>)
              )}
              {selectedBrand.length >= 4 && <> +{selectedBrand.length - 3}</>}
            </TickSubtitle>
          </TickSelect>
          <TickSelect onClick={() => handleModalNegotiator()}>
            <TickTitle>Negociador</TickTitle>
            <TickSubtitle>
              {selectedNegotiator.length === 0 ? (
                <>TODOS</>
              ) : (
                selectedNegotiator.map((item: any, index: number) => <>{index < 3 && `${item} | `}</>)
              )}
              {selectedNegotiator.length >= 4 && <> +{selectedNegotiator.length - 3}</>}
            </TickSubtitle>
          </TickSelect>
          <ActionArea>
            <Button onClick={handleSearch} text="PESQUISAR" />
            <Button onClick={clearFilter} text="LIMPAR" />
          </ActionArea>
        </FiltroWrapper>
        <Section>
          <h2>
            Histórico de Negociações
            <Button onClick={() => setIsNegotiation(!isNegotiation)} text="Registrar Negociação" />
          </h2>

          {loaderTableActive ? (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          ) : (
            <>
              <ContentTable>
                <Table>
                  <Thead>
                    <Tr>
                      <Th onClick={() => ordernar('cpfCnpj')}>
                        Cliente{' '}
                        {campoOrdenacao2 === 'cpfCnpj' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </Th>
                      <Th onClick={() => ordernar('customerName')}>
                        Razão social{' '}
                        {campoOrdenacao2 === 'customerName' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}{' '}
                      </Th>
                      <Th onClick={() => ordernar('brand')}>
                        Nome Fantasia{' '}
                        {campoOrdenacao2 === 'brand' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </Th>
                      <Th onClick={() => ordernar('billingGroupName')} hidden={profile === 'COBRANÇA CORPORATIVA'}>
                        Grupo de cobrança{' '}
                        {campoOrdenacao2 === 'billingGroupName' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </Th>
                      <Th onClick={() => ordernar('luc')}>
                        LUC{' '}
                        {campoOrdenacao2 === 'luc' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </Th>
                      <Th onClick={() => ordernar('shoppingName')}>
                        Shopping{' '}
                        {campoOrdenacao2 === 'shoppingName' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </Th>
                      <Th onClick={() => ordernar('contactDate')}>
                        Data contato{' '}
                        {campoOrdenacao2 === 'contactDate' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </Th>
                      <Th onClick={() => ordernar('negotiationReturnName')}>
                        Retorno Negociação{' '}
                        {campoOrdenacao2 === 'negotiationReturnName' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </Th>
                      <Th onClick={() => ordernar('additionalInformation')}>
                        Descrição da negociação{' '}
                        {campoOrdenacao2 === 'additionalInformation' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </Th>
                      <Th onClick={() => ordernar('contactResponsible')}>
                        Responsável
                        <br />
                        Pelo contato{' '}
                        {campoOrdenacao2 === '-contactResponsible' ? (
                          <img alt="default-alt" src={iconArrow} />
                        ) : (
                          <img alt="default-alt" src={iconArrow} style={{ transform: 'rotate(180deg)' }} />
                        )}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {negotiationData.filter(viewFilter).map((item: INegotiation) => (
                      <Tr key={item.identifier}>
                        <Td>{item.cpfCnpj}</Td>
                        <Td style={{ whiteSpace: 'nowrap' }} data-tip data-for={item.identifier.toString()}>
                          {formatarRazaoSocial(item.customerName)}
                          <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id={item.identifier.toString()}>
                            <span>{item.customerName}</span>
                          </ReactTooltip>
                        </Td>
                        <Td>{item.brand}</Td>
                        <Td hidden={profile === 'COBRANÇA CORPORATIVA'}>{item.billingGroupName}</Td>
                        <Td>{item.luc}</Td>
                        <Td>{item.shoppingName}</Td>
                        <Td>{formatarData(item.contactDate)}</Td>
                        <Td>{item.negotiationReturnName}</Td>
                        <Td>{item.additionalInformation}</Td>
                        <Td>{item.contactResponsible}</Td>
                        <Td onClick={() => handleTableActions(item.identifier)}>
                          <button type="button">
                            <img src={more} alt="Edit" />
                          </button>
                        </Td>
                        <Td style={{ padding: '1px' }}>{actionButtons(item)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </ContentTable>
              <NovaNegociacao
                active={modalNewNegotiationActive}
                customer={{
                  cpfCnpj: negotiationSelected?.cpfCnpj,
                  query: { shoppingId: negotiationSelected?.shoppingId },
                }}
                closeModalFunction={(inseriu: any = false) => handleExitAddNewNegotiation(inseriu)}
                userName={userName}
                negotiation={negotiationSelected}
              />
              <NewNegotiationBillingGroup
                active={modalNewNegotiationBgActive}
                customer={{
                  cpfCnpj: negotiationSelected?.cpfCnpj,
                  query: { shoppingId: negotiationSelected?.shoppingId },
                }}
                closeModalFunction={(inseriu: any = false) => handleExitAddNewNegotiation(inseriu)}
                userName={userName}
                negotiation={negotiationSelected}
              />
            </>
          )}
          {showPagination && (
            <>
              <PaginationWrapper>
                <Pagination count={nbPage} page={page} onChange={handleChangePagination} />
              </PaginationWrapper>
              <ButtonTable onClick={exportData}>Exportar</ButtonTable>
            </>
          )}
        </Section>

        <Negotiation active={isNegotiation} negotiation={negotioationUseCase} />

        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <DialogContentText>Você tem certeza que deseja excluir essa negociação?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} text="Cancelar" />
            <Button onClick={() => handleDelete(itemDelete)} text="Excluir" />
          </DialogActions>
        </Dialog>
      </Container>

      <SelectModal active={isSelect} onClick={() => setIsSelect(!isSelect)} />
      <ModalContainer active={isSelect}>
        <CloseSelectModal onClick={() => setIsSelect(!isSelect)}>X</CloseSelectModal>
        <h3>Selecionar Shoppings</h3>
        <input
          disabled={loading}
          type="text"
          onChange={(e: any) => setInputShopping(e.target.value.toUpperCase())}
          value={inputShopping}
          placeholder="Digite o Shopping desejado"
        />
        <SelectedCard>
          <h5>Selecionados:</h5>
          {selectedShoppings.map((item: any, index: number) => (
            <span>
              {item}
              <button type="button" onClick={() => handleRemoveShopping(index)}>
                x
              </button>
            </span>
          ))}
        </SelectedCard>
        <ListCard>
          {loading ? (
            <div className="loaderUser">
              <Loader />
            </div>
          ) : (
            <ul>
              {shoppings.length > 0 &&
                shoppings
                  .filter((item: any) => item.label.match(inputShopping))
                  .map((itemFiltered: any) => (
                    <li onClick={() => handleSelectShoppings(itemFiltered.label, itemFiltered.value)}>
                      {itemFiltered.label}
                    </li>
                  ))}
            </ul>
          )}
        </ListCard>
      </ModalContainer>
      {/* //Grupo de cobrança */}
      <SelectModal active={isSelectBg} onClick={() => setIsSelectBg(!isSelectBg)} />
      <ModalContainer active={isSelectBg}>
        <CloseSelectModal onClick={() => setIsSelectBg(!isSelectBg)}>X</CloseSelectModal>
        <h3>Selecione o Grupo de Cobrança</h3>
        <input
          disabled={loading}
          type="text"
          onChange={(e: any) => setInputBgroup(e.target.value.toUpperCase())}
          value={inputBgroup}
          placeholder="Digite o Grupo de Cobrança"
        />
        <SelectedCard>
          <h5>Selecionados:</h5>
          {selectedBgroup.map((item: any, index: number) => (
            <span>
              {item}
              <button type="button" onClick={() => handleRemoveBgroup(index)}>
                x
              </button>
            </span>
          ))}
        </SelectedCard>
        <ListCard>
          {loading ? (
            <div className="loaderUser">
              <Loader />
            </div>
          ) : (
            <ul>
              {billingGroups.length > 0 &&
                billingGroups
                  .filter((item: any) => item.label.match(inputBgroup))
                  .map((itemFiltered: any) => (
                    <li onClick={() => handleSelectbgroup(itemFiltered.label, itemFiltered.value)}>
                      {itemFiltered.label}
                    </li>
                  ))}
            </ul>
          )}
        </ListCard>
      </ModalContainer>
      <SelectModal active={isSelectCustomer} onClick={() => setIsSelectCustomer(!isSelectCustomer)} />

      <ModalContainer active={isSelectCustomer}>
        <CloseSelectModal onClick={() => setIsSelectCustomer(!isSelectCustomer)}>X</CloseSelectModal>
        <h3>Selecione o Cliente</h3>
        <input
          disabled={loading}
          type="text"
          onChange={(e: any) => setInputCustomer(e.target.value.toUpperCase())}
          value={inputCustomer}
          placeholder="Digite o Cliente"
        />
        <SelectedCard>
          <h5>Selecionados:</h5>
          {selectedCustomer.map((item: any, index: number) => (
            <span>
              {item}
              <button type="button" onClick={() => handleRemoveCustomer(index)}>
                x
              </button>
            </span>
          ))}
        </SelectedCard>
        <ListCard>
          {loading ? (
            <div className="loaderUser">
              <Loader />
            </div>
          ) : (
            <ul>
              {customers.length > 0 &&
                inputCustomer.length > 1 &&
                customers
                  .filter((item: any) => item.label.match(inputCustomer))
                  .map((itemFiltered: any) => (
                    <li onClick={() => handleSelectCustomer(itemFiltered.label, itemFiltered.value)}>
                      {itemFiltered.label}
                    </li>
                  ))}
            </ul>
          )}
        </ListCard>
      </ModalContainer>

      <SelectModal active={isSelectBrand} onClick={() => setIsSelectBrand(!isSelectBrand)} />
      <ModalContainer active={isSelectBrand}>
        <CloseSelectModal onClick={() => setIsSelectBrand(!isSelectBrand)}>X</CloseSelectModal>
        <h3>Selecione o Nome Fantasia</h3>
        <input
          disabled={loading}
          type="text"
          onChange={(e: any) => setInputBrand(e.target.value.toUpperCase())}
          value={inputBrand}
          placeholder="Digite o Nome fantasia"
        />
        <SelectedCard>
          <h5>Selecionados:</h5>
          {selectedBrand.map((item: any, index: number) => (
            <span>
              {item}
              <button type="button" onClick={() => handleRemoveBrand(index)}>
                x
              </button>
            </span>
          ))}
        </SelectedCard>
        <ListCard>
          {loading ? (
            <div className="loaderUser">
              <Loader />
            </div>
          ) : (
            <ul>
              {brand.length > 0 &&
                inputBrand.length > 1 &&
                brand
                  .filter((item: any) => item.label.match(inputBrand))
                  .map((itemFiltered: any) => (
                    <li onClick={() => handleSelectBrand(itemFiltered.label, itemFiltered.value)}>
                      {itemFiltered.label}
                    </li>
                  ))}
            </ul>
          )}
        </ListCard>
      </ModalContainer>
      <SelectModal active={isSelectNegotiator} onClick={() => setIsSelectNegotiator(!isSelectNegotiator)} />
      <ModalContainer active={isSelectNegotiator}>
        <CloseSelectModal onClick={() => setIsSelectNegotiator(!isSelectNegotiator)}>X</CloseSelectModal>
        <h3>Selecionar Negociador</h3>
        <input
          disabled={loading}
          type="text"
          onChange={(e: any) => setInputNegotiator(e.target.value.toUpperCase())}
          value={inputNegotiator}
          placeholder="Digite o Negociador"
        />
        <SelectedCard>
          <h5>Selecionados:</h5>
          {selectedNegotiator.map((item: any, index: number) => (
            <span>
              {item}
              <button type="button" onClick={() => handleRemoveNegotiator(index)}>
                x
              </button>
            </span>
          ))}
        </SelectedCard>
        <ListCard>
          {loading ? (
            <div className="loaderUser">
              <Loader />
            </div>
          ) : (
            <ul>
              {negotiator.length > 0 &&
                negotiator
                  .filter((item: any) => item.label.toUpperCase().match(inputNegotiator))
                  .map((itemFiltered: any) => (
                    <li onClick={() => handleSelectNegotiator(itemFiltered.label, itemFiltered.value)}>
                      {itemFiltered.label}
                    </li>
                  ))}
            </ul>
          )}
        </ListCard>
      </ModalContainer>
    </>
  );
};
