import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import api from '../../../../_core/api';
import { ContractType } from './index';
import { failure, success } from '../../../../_core/services/toast';
import { Button, Form, Input } from '../../../../_core/_components';

type Props = {
  open: boolean;
  onClose: () => void;
  onFinishSave: () => void;
  selectedRegister?: ContractType | null;
};

const Modal: React.FC<Props> = ({ open, onClose, selectedRegister, onFinishSave }: Props) => {
  const [loading, setLoading] = useState(false);
  const [contract, setContract] = useState<string>('');

  useEffect(() => {
    if (selectedRegister) {
      setContract(selectedRegister.contract);
    } else {
      resetModalState();
    }
  }, [selectedRegister]);

  const resetModalState = () => {
    setContract('');
  };

  const save = async () => {
    if (contract) {
      setLoading(true);

      if (selectedRegister) {
        const payload = {
          id: selectedRegister.id,
          contract: contract.toUpperCase(),
          active: selectedRegister.active,
        };
        try {
          await api.put('/judgment/type-of-contract/upd', payload);
          success('Atualizado com sucesso.');
          setLoading(false);
          resetModalState();
          onFinishSave();
        } catch (e) {
          failure('Erro ao tentar atualizar registro.');
          setLoading(false);
        }
      } else {
        try {
          const payload = {
            contract: contract.toUpperCase(),
          };
          await api.post('/judgment/type-of-contract/add', payload);
          success('Criado com sucesso.');
          setLoading(false);
          resetModalState();
          onFinishSave();
        } catch (e) {
          failure('Erro ao tentar criar registro.');
          setLoading(false);
        }
      }
    } else {
      failure('O campo TIPO DE CONTRATO é obrigatório');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[<Input placeholder="Tipo de contrato" label="Tipo de contrato" state={[contract, setContract]} />]}
            submitButton={<Button onClick={save} text="Salvar" disabled={loading} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
