import styled from 'styled-components';

interface IContainer {
  active?: boolean;
}

export interface IButton {
  action?: any;
  text?: string;
  filled?: boolean;
}

export const Container = styled.div<IContainer>`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 999;
  display: ${props => (props.active ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background-color: #7e93953c;
`;

export const Box = styled.div`
  position: relative;
  padding: 20px;
  background-color: white;
  min-width: 50%;
  min-height: 40%;
  border-radius: 25px;
  box-shadow: 4px 10px 8px -2px #7e93953c;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: move;
`;

export const Content = styled.div``;

export const Footer = styled.div`
  margin: 35px 0 0;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button<IButton>`
  margin: 0 5px;
  background-color: ${props => (props.filled ? '#00959C' : 'white')};
  color: ${props => (props.filled ? 'white' : '#7e9395')};
  border: 2px solid ${props => (props.filled ? 'transparent' : '#7e9395')};
  padding: 5px 15px;
  border-radius: 50px;
  font-family: Open Sans;
  font-size: 12px;
  letter-spacing: 0.7px;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    background-color: #7e939522;
    color: #7e9395;
    border-color: transparent;
  }
`;
