import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import * as S from '../styles';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';
import api from '../../../../_core/api';
import { failure, handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import Table from '../../../../_core/_components/table';
import { Button, Input, Option, SingleSelect } from '../../../../_core/_components';

export type Rubrica = {
  id: number;
  rubric: string;
  active: boolean;
};

const AssociacaoTipoAcao: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [classificacao, setClassificacao] = useState<Option>();
  const [rubrica, setRubrica] = useState<Option>();
  const [tipoAcao, setTipoAcao] = useState<Option>();
  const [registerList, setRegisterList] = useState<any[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRegister, setSelectedRegister] = useState<any>(null);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleFinishedSaving = () => {
    setModalOpen(false);
    setSelectedRegister(null);
    getData();
  };

  const handleEditItem = () => {
    setModalOpen(true);
    setAnchorEl(null);
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList.filter(reg => reg.rubric.toUpperCase().includes(searchText.toUpperCase()));
      return filtered;
    }
    return registerList;
  }, [searchText, registerList]);

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        id: item.id,
        classificacao: <SingleSelect state={[classificacao, setClassificacao]} options={[]} />,
        rubrica: <SingleSelect state={[rubrica, setRubrica]} options={[]} />,
        acao: <SingleSelect state={[tipoAcao, setTipoAcao]} options={[]} />,
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.id.toString()}
            onClick={(e: any) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  return (
    <S.ContentContainer>
      <FullScreenLoading isEnabled={loading} />
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setModalOpen(true)} />
      </S.TopBar>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => console.log(true)}>Excluir</MenuItem>
      </Menu>
      {renderedData.length > 0 && (
        <Table
          columns={[
            { label: 'Classificação', key: 'classificacao' },
            { label: 'Tipo de rubrica', key: 'rubrica' },
            { label: 'Tipo ação', key: 'acao' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
        />
      )}
    </S.ContentContainer>
  );
};

export default AssociacaoTipoAcao;
