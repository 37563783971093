import React from 'react';
import { Container, IButton } from './styles';

interface IModal {
  name?: string;
  buttons?: IButton[];
  onContinue?: any;
  active?: boolean;
}

const Modal: React.FC<IModal> = (props: any) => {
  return <Container active={props.active}>{props.children}</Container>;
};

export default Modal;
