import { UnidadeEconomica } from './types';

export type EditUnidadeEconomicaProperty =
  | 'SET_UNIDADE_ECONOMICA'
  | 'UNSET_UNIDADE_ECONOMICA'
  | 'SET_SHOPPING'
  | 'SET_ORIGEM'
  | 'SET_EMPRESAS';

export const INITIAL_STATE = {
  id: 0,
  shoppingId: 0,
  nomeShopping: '',
  empresaId: 0,
  razaoSocialEmpresa: '',
  codigoUnidadeEconomica: '',
  origem: 'ORACLE',
  empresas: [],
};

export function reducer(
  state: UnidadeEconomica,
  { type, payload }: { type: EditUnidadeEconomicaProperty; payload?: any },
): any {
  switch (type) {
    case 'SET_UNIDADE_ECONOMICA':
      return {
        ...payload,
      };
    case 'UNSET_UNIDADE_ECONOMICA':
      return undefined;
    case 'SET_SHOPPING':
      return {
        ...state,
        shoppingId: payload.shoppingId,
        nomeShopping: payload.nomeShopping,
      };
    case 'SET_ORIGEM':
      return {
        ...state,
        origem: payload,
      };
    case 'SET_EMPRESAS':
      return {
        ...state,
        empresas: payload,
      };
    default:
      return state;
  }
}
