import React, { useEffect, useState, useContext, useMemo, useCallback, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { useHistory } from 'react-router-dom';
import FileSaver from 'file-saver';
import MaskedInput from 'react-maskedinput';
import { AuthContext } from '../../../_main/contexts/auth';
import more from '../../../assets/more.svg';
import bin from '../../../assets/bin.svg';
import api from '../../../_core/api';
import { IContactItem, IContactInfoDetails } from './types';
import { NovaNegociacao } from '../components/nova-negociacao';
import NewNegotiationBillingGroup from '../components/new-negotiation-billing-group';
import NewNegotiationBillingGroupEdit from '../components/new-negotiation-billing-group/edit';
import Checkbox from '../components/deprecated-checkbox';
import iconDetalhar from '../../../assets/ic_choices.svg';
import iconDividas from '../../../assets/ic_xls.svg';
import iconContact from '../../../assets/ic_edit.svg';
import iconSearch from '../../../assets/Icon_search.svg';
import iconArrow from '../../../assets/apple-keyboard-control.svg';
import {
  Container,
  FilterBar,
  Box,
  Item,
  ItemSelect,
  Resume,
  Tabela,
  HeadTable,
  Records,
  Tabs,
  TabItem,
  Content,
  FieldBox,
  FieldModal,
  Label,
  Field,
  ActionBox,
  ActionBoxContato,
  DeleteButton,
  EditButton,
  AddTab,
  TabMenuTable,
  TabMenuItem,
  ContentTable,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  PaginationWrapper,
  Pagination,
  RecordsBox,
  Div,
  DivVoltar,
  Search,
  ActionDelete,
  ActionEdit,
  MoreContacts,
  CloseModal,
  NegotiationArea,
  BoxNegotiation,
  ButtonNegotiation,
  SelectActive,
  Option,
  Dpicker,
  SelectModal,
  ModalContainer,
  CloseSelectModal,
  SelectedCard,
  ListCard,
  ActionArea,
  ClearButtonModal,
} from './styles';
import Modal from '../components/modal';
import Loader from '../../../assets/loader';
import NewContact from './new-contact';
import { EditNewContact } from './new-contact/edit-new-contact';
import { NewContactBillingGroup } from './new-contact-billing-group';
import { EditNewContactBillingGroup } from './new-contact-billing-group/edit-contact';
import { SelectArea } from '../priorizacao/styles';
import { Multiselect } from '../components/select';

interface checkBoxProps {
  active?: boolean;
  all?: boolean;
  onClick?: any;
}

interface IFilter {
  billingGroupIds: string[];
  shoppingIds: string[];
  cpfsCnpjs: string[];
  nomesFantasia: string[];
  lucs: string[];
  negotiator: string[];
}

interface INegotiation {
  identifier: number;
  cpfCnpj?: number;
  customerName?: string;
  brand?: string;
  billingGroupId?: number;
  billingGroupName?: string;
  luc?: string;
  shoppingId: number;
  shoppingName?: string;
  contactDate: string;
  negotiationReturnId?: number;
  negotiationReturnName?: string;
  contactResponsible?: string;
  active?: boolean;
  additionalInformation?: string;
}

interface IContactInfo {
  active: any;
  brand: string;
  contactDepartment: string;
  contactName: string;
  identifier: number;
}

interface OptionsItems {
  id: number;
  label: string;
}

interface ModelGroup {
  index: number;
  loading: boolean;
  onRemove: number;
  input: string;
  response: any[];
  values: any[];
}

const INITIAL_VALUES = {
  index: -1,
  loading: false,
  onRemove: -1,
  input: '',
  response: [],
  values: [undefined, undefined, undefined, undefined, new Date(), undefined, undefined, undefined],
};

export const Boletos: React.FC<checkBoxProps> = () => {
  const { userName, profile, shoppingProfileId } = useContext(AuthContext);
  const history = useHistory();
  const [checkList, setChecklist] = useState<number[]>([]);
  const [checkSlip] = useState<string[]>([]);
  const [tabActive, setTabActive] = useState<number | null>(null);
  const [query, setQuery] = useState({
    shopping: '',
    id: '',
    blockStart: '',
    blockEnd: '',
    shoppingId: '',
  });
  const initialFilter: IFilter = {
    billingGroupIds: [''],
    shoppingIds: profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING' ? [shoppingProfileId || ''] : [''],
    cpfsCnpjs: [''],
    nomesFantasia: [''],
    lucs: [''],
    negotiator: [''],
  };
  const [filter, setFilter] = useState<IFilter>(initialFilter as IFilter);
  const [, setFilterQuery] = useState<string | null>(null);
  const [contactInfo, setContactInfo] = useState<IContactInfo[]>([]);
  const [editContactActive, setEditContactActive] = useState<number>();
  const [contactInfoDetails, setContactInfoDetails] = useState<IContactInfoDetails[]>([]);
  const [data, setData] = useState<any>(null);
  const [filterData, setFilterData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [modalNewContactBillingGroupActive, setModalNewContactBillingGroupActive] = useState(false);
  const [modalNewContactActive, setModalNewContactActive] = useState(false);
  const [modalEditContactActive, setModalEditContactActive] = useState(false);
  const [modalEditContactBillingGroupActive, setModalEditContactBillingGroupActive] = useState(false);
  const [modalNewNegotiationActive, setModalNewNegotiationActive] = useState(false);
  const [modalNewNegotiationGroupActive, setModalNewNegotiationGroupActive] = useState(false);
  const [modalNewNegotiationEdit, setModalNewNegotiationEdit] = useState(false);
  const [modalNewNegotiationGroupEdit, setModalNewNegotiationGroupEdit] = useState(false);
  const [negotiationData, setNegotiationData] = useState([]);
  const [isTabActive, setIsTabActive] = useState(1);
  const [page, setPage] = useState(1);
  const [nbPage, setNbPage] = useState(1);
  const [pageN, setPageN] = useState('1');
  const [rowsPerPage] = useState(10);
  const [open, setOpen] = React.useState(false);
  const [itemDelete, setItemDelete] = React.useState(0);
  const [options, setOptions] = useState<OptionsItems[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionsItems[]>([]);
  const modalGroupRef = useRef<ModelGroup>(INITIAL_VALUES);
  const [modalGroup, setModalGroup] = useState<ModelGroup>(INITIAL_VALUES);
  const [screenLoading, setScreenLoading] = useState(false);
  const [onStart, setOnStart] = useState(true);
  const objetoOrdenacaoZerado = {
    brand: 0,
    contractType: 0,
    contractNumber: 0,
    contractStatus: 0,
    legalStatus: 0,
    dueBalanceInMonth: 0,
    totalDueBalance: 0,
    overdueBalanceInMonth: 0,
    totalOverdueBalance: 0,
  };
  const [ordenacao, setOrdenacao] = useState<any>(objetoOrdenacaoZerado);
  const [tableActionSelected, setTableActionSelected] = useState<number | null>(null);
  const [negotiationSelected, setNegotiationSelected] = useState<INegotiation | null>(null);
  const [contactToEdit, setContactToEdit] = useState({});
  const [inputFIlter, setInputFilter] = useState('');
  const [modalActive, setModalActive] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [maxDate] = useState(new Date());
  const [activeNegotiation, setActiveNegotiation] = useState(false);

  const getQueryStringParams = (queryString: string) => {
    if (!queryString) {
      queryString = window.location.search.substring(1);
    }

    const params: any = {};

    const queries = queryString.split('&');

    queries.forEach((indexQuery: string, index: number) => {
      const indexPair = indexQuery.split('=');

      const queryKey = index === 0 ? decodeURIComponent(indexPair[0]).substring(1) : decodeURIComponent(indexPair[0]);
      const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : '');
      params[queryKey] = queryValue;
    });
    return params;
  };

  useEffect(() => {
    // make parsed query
    const filterTemp = filter;

    if (filterTemp.billingGroupIds[0] === '' && filterTemp.billingGroupIds.length > 1) {
      filterTemp.billingGroupIds.shift();
    }
    if (filterTemp.shoppingIds[0] === '' && filterTemp.shoppingIds.length > 1) {
      filterTemp.shoppingIds.shift();
    }
    if (filterTemp.lucs[0] === '' && filterTemp.lucs.length > 1) {
      filterTemp.lucs.shift();
    }
    if (filterTemp.cpfsCnpjs[0] === '' && filterTemp.cpfsCnpjs.length > 1) {
      filterTemp.cpfsCnpjs.shift();
    }
    if (filterTemp.nomesFantasia[0] === '' && filterTemp.nomesFantasia.length > 1) {
      filterTemp.nomesFantasia.shift();
    }
    let parsed_query = JSON.stringify(filterTemp);

    parsed_query = parsed_query.replace('{', '');
    parsed_query = parsed_query.replace('}', '');
    parsed_query = parsed_query.replace(/],/g, ']&');
    parsed_query = parsed_query.replace(/[\[\]']+/g, '');
    parsed_query = parsed_query.replace(/"/g, '');
    parsed_query = parsed_query.replace(/:/g, '=');
    parsed_query = parsed_query.replace('cpfsCnpjs', 'cpfsCnpjs');
    parsed_query = parsed_query.replace('nomesFantasia', 'brands');

    if (filterTemp.billingGroupIds[0] === '' || !filterTemp.billingGroupIds.length) {
      parsed_query = parsed_query.replace('&billingGroupIds=', '');
      parsed_query = parsed_query.replace('billingGroupIds=&', '');
      parsed_query = parsed_query.replace('billingGroupIds=', '');
    }
    if (filterTemp.shoppingIds[0] === '') {
      parsed_query = parsed_query.replace('&shoppingIds=', '');
      parsed_query = parsed_query.replace('shoppingIds=&', '');
      parsed_query = parsed_query.replace('shoppingIds=', '');
    }
    if (filterTemp.nomesFantasia[0] === '') {
      parsed_query = parsed_query.replace('&brands=', '');
      parsed_query = parsed_query.replace('brands=&', '');
      parsed_query = parsed_query.replace('brands=', '');
    }
    if (filterTemp.cpfsCnpjs[0] === '') {
      parsed_query = parsed_query.replace('&cpfsCnpjs=', '');
      parsed_query = parsed_query.replace('cpfsCnpjs=&', '');
      parsed_query = parsed_query.replace('cpfsCnpjs=', '');
    }
    if (filterTemp.negotiator[0] === '') {
      parsed_query = parsed_query.replace('&negotiator=', '');
      parsed_query = parsed_query.replace('negotiator=&', '');
      parsed_query = parsed_query.replace('negotiator=', '');
    }
    if (filterTemp.lucs[0] === '') {
      parsed_query = parsed_query.replace('&lucs=', '');
      parsed_query = parsed_query.replace('lucs=&', '');
      parsed_query = parsed_query.replace('lucs=', '');
    }

    setFilterQuery(parsed_query);
  }, [filter]);

  // historico de neggociacao

  const getHistoryNegotiation = async (page: string) => {
    setNegotiationData([]);
    setLoadingPage(true);
    try {
      if (profile === 'COBRANÇA CORPORATIVA') {
        const response = await api.get(`/negotiation-history?billingGroupIds=${query?.id}&page=${page}&active=1`);
        setNbPage(Math.ceil(response.data.pageInfo.totalItems / response.data.pageInfo.pageSize));

        setNegotiationData(
          response.data.data
            .sort(function compare(a: any, b: any) {
              return new Date(a.contactDate).getTime() - new Date(b.contactDate).getTime();
            })
            .reverse(),
        );
        setLoadingPage(false);
      }
      if (profile === 'HOLDING' && !query.shopping) {
        const response = await api.get(`/negotiation-history?billingGroupIds=${query?.id}&page=${page}&active=1`);
        setNbPage(Math.ceil(response.data.pageInfo.totalItems / response.data.pageInfo.pageSize));
        setNegotiationData(
          response.data.data
            .sort(function compare(a: any, b: any) {
              return new Date(a.contactDate).getTime() - new Date(b.contactDate).getTime();
            })
            .reverse(),
        );
        setLoadingPage(false);
      }
      if (profile === 'APROVADOR HOLDING' && !query.shopping) {
        const response = await api.get(`/negotiation-history?billingGroupIds=${query?.id}&page=${page}&active=1`);
        setNbPage(Math.ceil(response.data.pageInfo.totalItems / response.data.pageInfo.pageSize));
        setNegotiationData(
          response.data.data
            .sort(function compare(a: any, b: any) {
              return new Date(a.contactDate).getTime() - new Date(b.contactDate).getTime();
            })
            .reverse(),
        );
        setLoadingPage(false);
      }
      if (profile === 'RÉGUA DE COBRANÇA' && !query.shopping) {
        const response = await api.get(`/negotiation-history?billingGroupIds=${query?.id}&page=${page}&active=1`);
        setNbPage(Math.ceil(response.data.pageInfo.totalItems / response.data.pageInfo.pageSize));
        setNegotiationData(
          response.data.data
            .sort(function compare(a: any, b: any) {
              return new Date(a.contactDate).getTime() - new Date(b.contactDate).getTime();
            })
            .reverse(),
        );
        setLoadingPage(false);
      }

      // SHOPPING
      if (profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING') {
        const response = await api.get(
          `/negotiation-history?cpfCnpj=${data.cpfCnpj}&shoppingId=${query?.shoppingId}&page=${page}&active=1`,
        );
        setNbPage(Math.ceil(response.data.pageInfo.totalItems / response.data.pageInfo.pageSize));
        setNegotiationData(
          response.data.data
            .sort(function compare(a: any, b: any) {
              return new Date(a.contactDate).getTime() - new Date(b.contactDate).getTime();
            })
            .reverse(),
        );
        setLoadingPage(false);
      }
      if (profile === 'HOLDING' && query.shopping) {
        const response = await api.get(
          `/negotiation-history?cpfCnpj=${data.cpfCnpj}&shoppingId=${query?.shoppingId}&page=${page}&active=1`,
        );
        setNbPage(Math.ceil(response.data.pageInfo.totalItems / response.data.pageInfo.pageSize));
        setNegotiationData(
          response.data.data
            .sort(function compare(a: any, b: any) {
              return new Date(a.contactDate).getTime() - new Date(b.contactDate).getTime();
            })
            .reverse(),
        );
        setLoadingPage(false);
      }
      if (profile === 'APROVADOR HOLDING' && query.shopping) {
        const response = await api.get(
          `/negotiation-history?cpfCnpj=${data.cpfCnpj}&shoppingId=${query?.shoppingId}&page=${page}&active=1`,
        );
        setNbPage(Math.ceil(response.data.pageInfo.totalItems / response.data.pageInfo.pageSize));
        setNegotiationData(
          response.data.data
            .sort(function compare(a: any, b: any) {
              return new Date(a.contactDate).getTime() - new Date(b.contactDate).getTime();
            })
            .reverse(),
        );
        setLoadingPage(false);
      }
      if (profile === 'RÉGUA DE COBRANÇA' && query.shopping) {
        const response = await api.get(
          `/negotiation-history?cpfCnpj=${data.cpfCnpj}&shoppingId=${query?.shoppingId}&page=${page}&active=1`,
        );
        setNbPage(response.data.pageInfo.pageSize / response.data.pageInfo.totalItems);
        setNegotiationData(
          response.data.data
            .sort(function compare(a: any, b: any) {
              return new Date(a.contactDate).getTime() - new Date(b.contactDate).getTime();
            })
            .reverse(),
        );
        setLoadingPage(false);
      }
    } catch (err) {
      setLoadingPage(false);
    }
  };

  // contatos

  const getContactInfo = async (contactIdentifier: number | undefined) => {
    api
      .get(`charge-legal-information/contacts/customer-contact-points?customerContactId=${contactIdentifier}`)
      .then(response => {
        setContactInfoDetails(response.data.data);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTab = (contactIdentifier: number | null | undefined) => {
    if (contactIdentifier != null) {
      getContactInfo(contactIdentifier);
      setTabActive(contactIdentifier);
    }
  };

  const handleTableActions = (identifier: number) => {
    if (tableActionSelected != identifier) {
      setTableActionSelected(identifier);
    } else {
      setTableActionSelected(null);
    }
  };

  const handleEdit = (item: INegotiation) => {
    setNegotiationSelected(item);
    setModalNewNegotiationActive(true);
    setTableActionSelected(null);
    setModalNewNegotiationEdit(true);
    window.scrollTo(0, 0);
  };

  const handleEditGroup = (item: INegotiation) => {
    setNegotiationSelected(item);
    setTableActionSelected(null);
    setModalNewNegotiationGroupEdit(true);
    window.scrollTo(0, 0);
  };

  const handleDelete = (identifier: number) => {
    deleteNegotiationtRequest(identifier);
    setTableActionSelected(null);
    handleClose();
  };

  const handleExitAddNewNegotiation = () => {
    setModalNewNegotiationActive(false);
    setModalNewNegotiationEdit(false);
    setModalNewNegotiationGroupActive(false);
    setModalNewNegotiationGroupEdit(false);
    getHistoryNegotiation(pageN);
    pegarCliente(1);
    setNegotiationSelected(null);
  };

  const getContacts = async (update = false) => {
    api.get(`charge-legal-information/contacts/customer-contacts?cpfCnpjs=${data?.cpfCnpj}`).then(
      response => {
        setContactInfo(response.data.data);
        if (response.data.data.length > 0) {
          if (response.data.data.length > 0) {
            if (update) {
              getContactInfo(editContactActive);
            } else {
              const id: number = response.data.data[0].identifier;
              getContactInfo(id);
            }
          }
        }

        if (response.data.data.length > 0) {
          if (update) {
            setTabActive(tabActive);
          } else {
            setTabActive(response.data.data[0].identifier);
          }
        }
      },
      error => {
        setContactInfo([]);
      },
    );
  };

  // BillingGroup
  const getContactsBillingGroup = async (update = false) => {
    api.get(`charge-legal-information/contacts/customer-contacts?billingGroupId=${query?.id}`).then(
      response => {
        setContactInfo(response.data.data);

        if (response.data.data.length > 0) {
          if (response.data.data.length > 0) {
            if (update) {
              getContactInfo(editContactActive);
            } else {
              const id: number = response.data.data[0].identifier;
              getContactInfo(id);
            }
          }
        }

        if (response.data.data.length > 0) {
          if (update) {
            setTabActive(tabActive);
          } else {
            setTabActive(response.data.data[0].identifier);
          }
        }
      },
      error => {
        setContactInfo([]);
      },
    );
  };

  const handleDeleteContact = (item: IContactItem, key: any) => {
    deleteContactRequest(item.identifier);
  };

  const deleteContactRequest = async (identifier: number) => {
    api
      .delete(`/charge-legal-information/contacts/customer-contacts/${identifier}?userIdentifier=${userName}`)
      .then(response => {
        if (query.shopping) {
          getContacts();
        } else {
          getContactsBillingGroup();
        }
      });
  };

  const deleteNegotiationtRequest = async (identifier: number) => {
    api.delete(`negotiation-history/${identifier}?userIdentifier=${userName}`).then(response => {
      getHistoryNegotiation(pageN);
    });
  };

  // end contatos

  const handleCheck = (contractNumber: string) => {
    const index = filterData.findIndex((item: any) => item.contractNumber == contractNumber);

    const itemOf = checkList.indexOf(index);
    const array = [...checkList];
    if (itemOf === -1) {
      array.push(index);
    } else {
      array.splice(itemOf, 1);
    }

    setChecklist(array);
  };

  const handleSelectAll = (e: any) => {
    const items: any = document.getElementsByName('inputCheckboxSlips');
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      element.checked = e.target.checked;
    }
    const allCheck: any = document.getElementsByName('allCheck');
    if (allCheck[0].checked == true) {
      const checks = [];
      for (let i = 0; i < data?.bankSlips.length; i++) {
        checks.push(i);
      }
      setChecklist(checks);
    } else {
      setChecklist([]);
    }
  };

  function getSlipIds() {
    const arrayString = [...checkSlip];

    data.bankSlips.map((item: any, index: number) => {
      for (let i = 0; i < checkList.length; i++) {
        if (index === checkList[i]) {
          const res = item.slipIds.join();
          arrayString.push(res);
        }
      }
    });

    let slipIds = '';
    if (arrayString.length > 0) {
      arrayString.map((i, index) => {
        if (index !== 0) {
          slipIds = `${slipIds},${i}`;
        } else {
          slipIds += i;
        }
      });
    }
    return slipIds;
  }

  const handleSlips = async () => {
    const slipIds = getSlipIds();
    if (slipIds != '') {
      if (query.shopping) {
        history.push(
          `/Detalhes-Boletos?slipids=${slipIds}&shopping=${query.shopping}&blockStartingDate=${
            query.blockStart
          }&blockEnd=${query.blockEnd}&cpf=${data?.cpfCnpj}&razaoSocial=${encodeURIComponent(
            data?.customerName,
          )}&classificacao=${montarClassificacao(data?.isActiveCustomer, data?.isCorporateCustomer)}`,
        );
      } else {
        history.push(
          `/Detalhes-Boletos?slipids=${slipIds}&groupId=${query.id}&blockStartingDate=${query.blockStart}&blockEnd=${query.blockEnd}&groupName=${data?.groupName}`,
        );
      }
    } else {
      alert('Selecione ao menos um detalhe');
    }
  };

  const customerDebtReport = async () => {
    const list = [...checkList];
    if (checkList.length == 0) {
      alert('Selecione uma cobrança');
    } else {
      const slipIds = getSlipIds()
        .split(',')
        .map(function (item) {
          return parseInt(item, 10);
        });

      if (slipIds != []) {
        const a: HTMLAnchorElement = document.createElement('A') as HTMLAnchorElement;

        const requestOptions = {
          slipIds,
        };

        if (query.shopping) {
          api
            .post(`/prioritization/shopping/customer-debt-report/${query.shopping}`, requestOptions, {
              responseType: 'arraybuffer',
            })
            .then(response => {
              const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              FileSaver.saveAs(blob, response.headers.filename);
            });
        } else {
          api
            .post(`/prioritization/billing-group/customer-debt-report`, requestOptions, { responseType: 'arraybuffer' })
            .then(response => {
              const blob = new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              FileSaver.saveAs(blob, response.headers.filename);
            });
        }
      } else {
        alert('Selecione ao menos um detalhe');
      }
    }
  };

  const getOptions = async () => {
    const { data } = await api.get('/filters/negotiations-return');
    const arrOptions: OptionsItems[] = [];
    data.map((item: any) => {
      arrOptions.push({
        id: item.returnId,
        label: item.returnName,
      });
    });
    arrOptions.sort((a, b) => {
      if (a.id > b.id) return 1;
      if (a.id < b.id) return -1;
      return 0;
    });
    arrOptions.unshift({ id: 0, label: 'Selecione' });
    setOptions(arrOptions);
  };

  const onSubmitSelect = async () => {
    const { id } = getQueryStringParams(history.location.search);
    const body = {
      brandStatus: selectedOption[0].id,
    };
    setLoading(true);
    api.put(`/prioritization/billing-group/${id}`, body).finally(() => setLoading(false));
  };

  const handleTabTable = (item: number) => {
    setIsTabActive(item);
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    const params = getQueryStringParams(history.location.search);

    setQuery(params);
  }, [history.location.search]);

  useEffect(() => {
    if (data) {
      getHistoryNegotiation(pageN);
      if (query.shopping) {
        getContacts();
      } else {
        getContactsBillingGroup();
      }
    }
  }, [data]);

  useEffect(() => {
    pegarCliente();
  }, [query]);

  function pegarCliente(reload = 0) {
    if (reload == 0) {
      setLoading(true);
    }
    if (query.id) {
      if (query.shopping) {
        const handleItem = async () => {
          const response = await api
            .get(
              `/prioritization/shopping/${query.shopping}/${query.id}?blockStartingDate=${query.blockStart}&blockEndingDate=${query.blockEnd}`,
            )
            .then(
              async response => {
                const resposta = response.data;
                resposta.bankSlips.sort((item1: any, item2: any) =>
                  ordemDecrescente(item1, item2, 'overdueBalanceInMonth'),
                );
                setOrdenacao({
                  ...objetoOrdenacaoZerado,
                  overdueBalanceInMonth: -1,
                });

                if (profile === 'COBRANÇA CORPORATIVA') {
                  const { groupName, query } = resposta;
                  setModalGroup({
                    ...modalGroup,
                    values: [undefined, [{ value: query?.id, label: groupName }], undefined, undefined, new Date()],
                  });
                  const newFilter = { ...filter };
                  newFilter.billingGroupIds.push(query?.id);
                  setFilter(newFilter);
                } else if (disableShoppingButton) {
                  const { cpfCnpj, customerName, bankSlips } = resposta;
                  const responseMarcas = await api.get(
                    `/filters/siscob-filters-information/marcas?shoppingIds=${query.shoppingId}&cpfsCnpjs=${cpfCnpj}`,
                  );
                  const marcas = responseMarcas.data.map((name: string, i: number) => ({
                    value: i.toString(),
                    label: name,
                  }));
                  console.log('ENTRA AQUI?', marcas);
                  const lucs = bankSlips.map((item: any, index: number) => ({
                    label: item.lucs.toUpperCase(),
                    value: index.toString(),
                  }));
                  setModalGroup({
                    ...modalGroup,
                    values: [
                      [{ value: query.shoppingId, label: query.shopping }],
                      undefined,
                      [{ value: cpfCnpj, label: customerName }],
                      lucs,
                      new Date(),
                      marcas,
                    ],
                  });
                  const newFilter = { ...filter };
                  newFilter.shoppingIds.push(query.shoppingId);
                  newFilter.cpfsCnpjs.push(cpfCnpj);
                  setFilter(newFilter);
                }
                setData(resposta);
                setFilterData(resposta.bankSlips);
                setLoading(false);
              },
              error => {
                alert('Cliente não encontrado');
                setLoading(false);
              },
            );
        };
        handleItem();
      } else {
        api
          .get(
            `/prioritization/billing-group/${query.id}?blockStartingDate=${query.blockStart}&blockEndingDate=${query.blockEnd}`,
          )
          .then(
            response => {
              const resposta = response.data;
              resposta.bankSlips.sort((item1: any, item2: any) =>
                ordemDecrescente(item1, item2, 'overdueBalanceInMonth'),
              );
              setOrdenacao({
                ...objetoOrdenacaoZerado,
                overdueBalanceInMonth: -1,
              });
              if (
                profile === 'COBRANÇA CORPORATIVA' ||
                (profile === 'APROVADOR HOLDING' && !query.shopping) ||
                (profile === 'HOLDING' && !query.shopping)
              ) {
                const { groupName } = resposta;
                setModalGroup({
                  ...modalGroup,
                  values: [undefined, [{ value: query.id, label: groupName }], undefined, undefined, new Date()],
                });
                const newFilter = { ...filter };
                newFilter.billingGroupIds.push(query.id);
                setFilter(newFilter);
              } else if (disableShoppingButton) {
                const { cpfCnpj, custumerName } = resposta;
                setModalGroup({
                  ...modalGroup,
                  values: [
                    [{ value: query.shoppingId, label: query.shopping }],
                    undefined,
                    [{ value: cpfCnpj, label: custumerName }],
                    undefined,
                    new Date(),
                  ],
                });
                const newFilter = { ...filter };
                newFilter.shoppingIds.push(query.shoppingId);
                newFilter.cpfsCnpjs.push(cpfCnpj);
                setFilter(newFilter);
              }
              setData(resposta);
              setFilterData(resposta.bankSlips);
              setLoading(false);
            },
            error => {
              alert('Cliente não encontrado');
              setLoading(false);
            },
          );
      }
      setOnStart(false);
    }
  }

  /** new methods */
  const handleRegisterNegotiation = () => {
    // setModalNewNegotiationActive(true);
    setActiveNegotiation(true);
    window.scrollTo(0, document.body.scrollHeight);
  };

  /** new methods */
  const handleRegisterNegotiationBillingGroup = () => {
    // setModalNewNegotiationGroupActive(true);
    setActiveNegotiation(true);
    window.scrollTo(0, document.body.scrollHeight);
  };

  function formatarDataMesAno(date: string) {
    const data = new Date(date);
    const month = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ][data.getMonth()];
    const year = data.getFullYear();

    return `${month}/${year}`.toUpperCase();
  }

  function formatarData(data: any) {
    // 2020-05-02 para 02-05-2020
    try {
      return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0, 4)}`;
    } catch (error) {
      return '';
    }
  }

  function montarClassificacao(isActiveCustomer: boolean, isCorporateCustomer: boolean) {
    let string = '';
    if (isActiveCustomer == null && isCorporateCustomer == null) {
      return '';
    }

    if (isCorporateCustomer) {
      string += 'CORPORATIVO';
    } else {
      string += 'SHOPPING';
    }

    return string;
  }

  const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const countPages = (total: number) => {
    const pages = total / rowsPerPage;
    return Math.ceil(pages);
  };

  function formatarVirgula(string: any) {
    try {
      return string.replaceAll(',', ', ');
    } catch (error) {
      return '';
    }
  }

  function pegarFiltro(value: any) {
    setInputFilter(value);
    if (isTabActive === 1) {
      const dataFiltred = data.bankSlips;
      dataFiltred.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'overdueBalanceInMonth'));
      setOrdenacao({ ...objetoOrdenacaoZerado, overdueBalanceInMonth: 1 });
      setFilterData(dataFiltred);
      setPage(1);
    } else {
      setPageN('1');
    }
  }

  function filtrar(bankSlip: any, value: any) {
    if (bankSlip.lucs?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 && bankSlip.lucs != null) {
      return true;
    }
    if (
      bankSlip.chargingType?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.chargingType != null
    ) {
      return true;
    }
    if (bankSlip.brand?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 && bankSlip.brand != null) {
      return true;
    }
    if (
      bankSlip.contractType?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.contractType != null
    ) {
      return true;
    }
    if (
      bankSlip.contractNumber?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.contractNumber != null
    ) {
      return true;
    }
    if (
      bankSlip.contractStatus?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.contractStatus != null
    ) {
      return true;
    }
    if (
      bankSlip.legalStatus?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.legalStatus != null
    ) {
      return true;
    }
    if (
      bankSlip.dueBalanceInMonth?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.dueBalanceInMonth != null
    ) {
      return true;
    }
    if (
      bankSlip.totalDueBalance?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.totalDueBalance != null
    ) {
      return true;
    }
    if (
      bankSlip.overdueBalanceInMonth?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.overdueBalanceInMonth != null
    ) {
      return true;
    }
    if (
      bankSlip.totalOverdueBalance?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.totalOverdueBalance != null
    ) {
      return true;
    }
    return false;
  }

  function filtrarNegociation(negociation: any, value: any) {
    if (
      negociation.cpfCnpj?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      negociation.cpfCnpj != null
    ) {
      return true;
    }
    if (
      negociation.customerName?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      negociation.customerName != null
    ) {
      return true;
    }
    if (negociation.brand?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 && negociation.brand != null) {
      return true;
    }
    if (
      negociation.billingGroupName?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      negociation.billingGroupName != null
    ) {
      return true;
    }
    if (negociation.luc?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 && negociation.luc != null) {
      return true;
    }
    if (
      negociation.shoppingName?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      negociation.shoppingName != null
    ) {
      return true;
    }
    if (
      negociation.contactDate?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      negociation.contactDate != null
    ) {
      return true;
    }
    if (
      negociation.negotiationReturnName?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      negociation.negotiationReturnName != null
    ) {
      return true;
    }
    if (
      negociation.additionalInformation?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      negociation.additionalInformation != null
    ) {
      return true;
    }
    if (
      negociation.contactResponsible?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      negociation.contactResponsible != null
    ) {
      return true;
    }
    return false;
  }

  function ordemCrescente(item1: any, item2: any, prop: string) {
    if (item1[prop] > item2[prop]) return 1;
    if (item1[prop] < item2[prop]) return -1;
    return 0;
  }

  function ordemDecrescente(item1: any, item2: any, prop: string) {
    if (item1[prop] < item2[prop]) return 1;
    if (item1[prop] > item2[prop]) return -1;
    return 0;
  }

  // ordem /--/ 1 = crescente /--/ 0= sem ordenação /--/ -1 = decrescente
  function ordernar(nomeCampo: string) {
    const valorCampo = ordenacao[nomeCampo];

    const listTemp = filterData;
    if (valorCampo == 0) {
      listTemp.sort((item1: any, item2: any) => ordemCrescente(item1, item2, nomeCampo));
      setFilterData(listTemp);
      setOrdenacao({ ...objetoOrdenacaoZerado, [nomeCampo]: 1 });
    } else if (valorCampo == 1) {
      listTemp.sort((item1: any, item2: any) => ordemDecrescente(item1, item2, nomeCampo));
      setFilterData(listTemp);
      setOrdenacao({ ...objetoOrdenacaoZerado, [nomeCampo]: -1 });
    } else if (nomeCampo == 'overdueBalanceInMonth' && valorCampo == -1) {
      listTemp.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'overdueBalanceInMonth'));
      setFilterData(listTemp);
      setOrdenacao({ ...objetoOrdenacaoZerado, overdueBalanceInMonth: 1 });
    } else {
      listTemp.sort((item1: any, item2: any) => ordemDecrescente(item1, item2, 'overdueBalanceInMonth'));
      setFilterData(listTemp);
      setOrdenacao({ ...objetoOrdenacaoZerado, overdueBalanceInMonth: -1 });
    }
  }

  function testarOrdenacao(nomeCampo: string): string {
    const valorCampo = ordenacao[nomeCampo];
    if (valorCampo == 0) {
      return 'decrescente';
    }
    if (valorCampo == 1) {
      return 'crescente';
    }

    return 'decrescente';
  }

  const handleClickOpen = (item: number) => {
    setOpen(true);
    setItemDelete(item);
  };

  function formatarRazaoSocial(nome: any) {
    try {
      if (nome.length <= 15) {
        return nome;
      }
      if (nome.charAt(14) == ' ' || nome.charAt(14) == ',') {
        return `${nome.substring(0, 14)}...`;
      }
      return `${nome.substring(0, 15)}...`;
    } catch {
      return '';
    }
  }

  function totalizador(atributo: string) {
    return filterData
      ?.reduce((acc: number, v: any) => acc + v[atributo], 0)
      .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
  }

  /** editar contato */

  const handleAddNewContact = () => {
    if (query.shopping) {
      setModalNewContactActive(true);
    } else {
      setModalNewContactBillingGroupActive(true);
    }
  };

  const handleExitAddNewContact = () => {
    setModalNewContactActive(false);
    if (query.shopping) {
      getContacts();
    } else {
      getContactsBillingGroup();
    }
  };

  const handleExitAddNewContactBillingGroup = () => {
    setModalNewContactBillingGroupActive(false);
    if (query.shopping) {
      getContacts();
    } else {
      getContactsBillingGroup();
    }
  };

  const handleEditContact = (item: IContactItem, tab: string) => {
    setEditContactActive(item.identifier);
    setContactToEdit(item);
    setModalEditContactActive(true);
  };
  const handleEditContactBillingGroup = (item: IContactItem, tab: string) => {
    setEditContactActive(item.identifier);
    setContactToEdit(item);
    setModalEditContactBillingGroupActive(true);
  };

  const handleExitEditNewContact = () => {
    setModalEditContactActive(false);
    setModalEditContactBillingGroupActive(false);
    if (query.shopping) {
      getContacts(true);
    } else {
      getContactsBillingGroup(true);
    }
  };
  const AlertErro = () => {
    alert('Usuário sem permissão para esta ação');
  };

  const handleChangePagination = (event: any, value: any) => {
    setPage(value);
    getHistoryNegotiation(value);
  };

  const handleExport = async () => {
    if (query.shopping) {
      api
        .get(
          `/prioritization/shopping/xls/${query.shopping}/${query.id}?blockStartingDate=${query.blockStart}&blockEndingDate=${query.blockEnd}`,
          { responseType: 'arraybuffer' },
        )
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(blob, response.headers.filename);
        });
    } else {
      api
        .get(
          `/prioritization/billing-group/xls/${query.id}?blockStartingDate=${query.blockStart}&blockEndingDate=${query.blockEnd}`,
          { responseType: 'arraybuffer' },
        )
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(blob, response.headers.filename);
        });
    }
  };

  function converterData(data: any): string {
    let dia = `${data.getDate()}`;
    let mes = `${data.getMonth() + 1}`;
    const ano = `${data.getFullYear()}`;

    const pad = '00';
    dia = pad.substring(0, pad.length - dia.length) + dia;
    mes = pad.substring(0, pad.length - mes.length) + mes;

    return `${ano}-${mes}-${dia}`;
  }

  const handleSelectItem = (label: string, value: string) => {
    const { index, values } = modalGroup;
    const newFilter = { ...filter };
    const arr = [...values];
    if (index === 1 && arr[1]?.length > 0) return;
    if (index === 6 && arr[6]?.length > 0) return;
    if (arr[index]?.find((item: any) => item.value === value)) return;
    if (arr[index]?.length > 0) {
      arr[index].push({ label, value });
    } else {
      arr[index] = [{ label, value }];
    }

    switch (index) {
      case 0:
        newFilter.shoppingIds.push(value);
        break;
      case 1:
        newFilter.billingGroupIds.push(value);
        break;
      case 2:
        newFilter.cpfsCnpjs.push(value);
        break;
      case 3:
        newFilter.lucs.push(label);
        break;
      case 5:
        newFilter.nomesFantasia.push(value);
        break;
    }

    setFilter(newFilter);

    setModalGroup({ ...modalGroup, values: arr });
  };

  const handleClickModal = (index: number) => {
    if ([0, 1, 2, 5].includes(index) && disableShoppingButton) return;
    if (index != 1 && disableButtonProfile) return;
    setModalGroup({ ...modalGroup, index, loading: true });
  };

  const getShoppingsModal = async () => {
    const list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/shoppings?${getDinamicFilter(0)}`);
    response.data.map((item: any) => {
      list.push({
        value: item.shoppingId.toString(),
        label: item.shopping,
      });
    });
    setModalGroup({ ...modalGroup, response: list, loading: false });
  };

  const getBillingGroupsModal = async () => {
    const list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/billing-groups?${getDinamicFilter(1)}`);
    response.data.map((item: any) => {
      list.push({
        value: item.federationGroupId.toString(),
        label: item.federationGroupName,
      });
    });
    setModalGroup({ ...modalGroup, response: list, loading: false });
  };

  const getCustomersModal = async () => {
    const customer_list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`);
    response.data.map((item: any) => {
      customer_list.push({
        value: item.cpfCnpj.toString(),
        label: item.customerName,
      });
    });
    setModalGroup({ ...modalGroup, response: customer_list, loading: false });
  };

  const getBrandsModal = async () => {
    const brand_list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`);
    response.data.map((item: any) => {
      brand_list.push({
        value: item.toString(),
        label: item,
      });
    });
    setModalGroup({ ...modalGroup, response: brand_list, loading: false });
  };

  const getNegotiationsReturnModal = async () => {
    const { data } = await api.get(`/filters/negotiations-return`);
    const negotiations: any[] = [];
    data.map((item: any) => {
      negotiations.push({
        value: item.returnId.toString(),
        label: item.returnName.toUpperCase(),
      });
    });
    negotiations.sort((item1, item2) => {
      if (item1.label > item2.label) return 1;
      if (item1.label < item2.label) return -1;
      return 0;
    });
    setModalGroup({ ...modalGroup, response: negotiations, loading: false });
  };

  const getLucModal = async () => {
    const lucs: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`);
    console.log('dinamic', getDinamicFilter(3));
    response.data.map((item: any, i: number) => {
      lucs.push({
        value: i.toString(),
        label: item.luc.toUpperCase(),
      });
    });
    setModalGroup({ ...modalGroup, response: lucs, loading: false });
  };

  useEffect(() => {
    console.log('1395');
    const { index, loading, onRemove } = modalGroup;
    if (loading) {
      console.log('1419');
      switch (index) {
        case 0:
          getShoppingsModal();
          break;
        case 1:
          getBillingGroupsModal();
          break;
        case 2:
          getCustomersModal();
          break;
        case 3:
          getLucModal();
          break;
        case 5:
          getBrandsModal();
          break;
        case 6:
          getNegotiationsReturnModal();
          break;
      }
    } else if (onRemove > -1 && !disableShoppingButton) {
      if (onRemove != 1) onRefreshByIndex();
      console.log('1419');
    } else if (onStart === false && !disableShoppingButton) {
      refreshAllItems();
      console.log('1424');
    } else if (onRemove > -1 && disableShoppingButton) {
      refreshOnChangeLucs();
    }
  }, [modalGroup]);

  useEffect(() => {
    modalGroupRef.current = modalGroup;
  }, [onStart]);

  const refreshAllItems = async () => {
    if (modalGroup.values[1]?.length > 0) {
      const { values } = modalGroup;
      const arr = [...values];
      setScreenLoading(true);
      const response = await Promise.all([
        api.get(`/filters/siscob-filters-information/shoppings?${getDinamicFilter(0)}`),
        api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
        api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`),
        api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
      ]);
      response.forEach((payload: any, i: number) => {
        switch (i) {
          case 0: // Shoppings
            arr[0] = payload.data.map((item: any) => ({
              value: item.shoppingId.toString(),
              label: item.shopping,
            }));
            break;
          case 1: // Clientes
            arr[2] = payload.data.map((item: any) => ({
              value: item.cpfCnpj.toString(),
              label: item.customerName,
            }));
            break;
          case 2: // Lucs
            arr[3] = payload.data.map((item: any, i: number) => ({
              value: i.toString(),
              label: item.luc.toUpperCase(),
            }));
            break;
          case 3: // Nome fantasias
            arr[5] = payload.data.map((item: any) => ({
              value: item.toString(),
              label: item,
            }));
            break;
        }
      });
      setModalGroup({ ...modalGroup, index: -1, input: '', values: arr });
      setScreenLoading(false);
      setOnStart(true);
    }
  };

  const onRefreshByIndex = async (i?: number) => {
    setScreenLoading(true);
    const { values, onRemove } = modalGroup;
    const index = i ?? onRemove;
    const arr = [...values];
    const allPromises = [
      api.get(`/filters/siscob-filters-information/shoppings?${getDinamicFilter(0)}`),
      undefined,
      api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
      api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`),
      undefined,
      api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
    ];
    const response = await Promise.all(allPromises);
    response.forEach((payload: any, i: number) => {
      if (i === index) return;
      switch (i) {
        case 0: // Shoppings
          arr[0] = payload.data.map((item: any) => ({
            value: item.shoppingId.toString(),
            label: item.shopping,
          }));
          break;
        case 2: // Clientes
          arr[2] = payload.data.map((item: any) => ({
            value: item.cpfCnpj.toString(),
            label: item.customerName,
          }));
          break;
        case 3: // Lucs
          arr[3] = payload.data.map((item: any, i: number) => ({
            value: i.toString(),
            label: item.luc.toUpperCase(),
          }));
          break;
        case 5: // Nome fantasias
          arr[5] = payload.data.map((item: any) => ({
            value: item.toString(),
            label: item,
          }));
          break;
      }
    });
    setModalGroup({ ...modalGroup, index: -1, input: '', values: arr, onRemove: -1 });
    setScreenLoading(false);
  };

  const refreshOnChangeLucs = async () => {
    const { values } = modalGroup;
    const arr = [...values];
    setScreenLoading(true);
    const response = await Promise.all([
      api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
      api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
    ]);
    response.forEach((payload: any, i: number) => {
      switch (i) {
        case 0: // Clientes
          arr[2] = payload.data.map((item: any) => ({
            value: item.cpfCnpj.toString(),
            label: item.customerName,
          }));
          break;
        case 1: // Nome fantasias
          arr[5] = payload.data.map((item: any) => ({
            value: item.toString(),
            label: item,
          }));
          break;
      }
    });
    setScreenLoading(false);
    setModalGroup({ ...modalGroup, index: -1, input: '', values: arr, onRemove: -1 });
  };

  const refreshOnChangeShoppings = async () => {
    const { values } = modalGroup;
    const arr = [...values];
    setScreenLoading(true);
    const response = await Promise.all([
      api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
      api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`),
      api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`),
    ]);
    response.forEach((payload: any, i: number) => {
      switch (i) {
        case 0: // Clientes
          arr[2] = payload.data.map((item: any) => ({
            value: item.cpfCnpj.toString(),
            label: item.customerName,
          }));
          break;
        case 1: // Lucs
          arr[3] = payload.data.map((item: any, i: number) => ({
            value: i.toString(),
            label: item.luc.toUpperCase(),
          }));
          break;
        case 2: // Nome fantasias
          arr[5] = payload.data.map((item: any) => ({
            value: item.toString(),
            label: item,
          }));
          break;
      }
    });
    setScreenLoading(false);
    setModalGroup({ ...modalGroup, index: -1, input: '', values: arr });
  };

  const modalListContent = useMemo(() => {
    const { loading, index, response, input } = modalGroup;
    if (index != -1 && !loading) {
      switch (index) {
        default:
          return (
            <ul>
              {response.length > 0 &&
                response
                  .filter((item: any) => item.label.includes(input.toUpperCase()))
                  .map((itemFiltered: any) => (
                    <li onClick={() => handleSelectItem(itemFiltered.label, itemFiltered.value)}>
                      {itemFiltered.label}
                    </li>
                  ))}
            </ul>
          );
      }
    } else {
      return (
        <div className="loaderUser">
          <Loader />
        </div>
      );
    }
  }, [modalGroup]);

  const handleRemoveSelectedModalItems = (i: number, modelGroupIndex?: number) => {
    const { index, values } = modalGroup;
    const currentIndex = modelGroupIndex ?? index;
    const arr = [...values];
    if (values[3].length < 2) {
      return;
    } else {
      arr[currentIndex] = values[currentIndex].filter((e: any, idx: number) => idx !== i);
      if (currentIndex === 1) {
        arr.forEach((item, index) => {
          if (index === 1) return;
          arr[index] = [];
        });
      }
      setModalGroup({ ...modalGroup, values: arr, onRemove: modelGroupIndex ?? -1 });
    }
  };

  const handleChangeValueModalGroup = (value: any, i: number) => {
    const { values } = modalGroup;
    const arr = [...values];
    arr[i] = value;
    setModalGroup({ ...modalGroup, values: arr });
  };

  const clearAll = useCallback(
    (index: number) => {
      const { values } = modalGroup;
      const arr = [...values];
      arr[index] = undefined;
      setModalGroup({ ...modalGroup, values: arr });
    },
    [modalGroup],
  );

  const selectedModalItems = useMemo(() => {
    const { loading, index, values } = modalGroup;
    const arr = [...values];
    if (index != -1 && !loading) {
      switch (index) {
        default:
          return (
            <>
              <SelectedCard>
                <h5>Selecionados:</h5>
                {arr[index]?.map((item: any, index: number) => (
                  <span>
                    {item.label}
                    <button onClick={() => handleRemoveSelectedModalItems(index)}>x</button>
                  </span>
                ))}
              </SelectedCard>
              <ClearButtonModal onClick={() => clearAll(index)}>limpar</ClearButtonModal>
            </>
          );
      }
    } else {
      return '';
    }
  }, [modalGroup]);

  const disableButtonProfile = useMemo(() => {
    switch (profile) {
      case 'COBRANÇA CORPORATIVA':
        return modalGroup.values[1] && modalGroup.values[1].length > 0 ? false : true;
    }
  }, [modalGroup]);

  const disableShoppingButton = useMemo(() => {
    return (
      profile === 'COBRANÇA SHOPPING' ||
      profile === 'APROVADOR SHOPPING' ||
      (profile === 'APROVADOR HOLDING' && query.shopping) ||
      (profile === 'HOLDING' && query.shopping)
    );
  }, [profile, query]);

  const handleSubmit = async () => {
    if (!isValidSubmit) {
      alert('Preencha todos os campos!');
      return;
    }
    setScreenLoading(true);
    const brands = modalGroup.values[5].map((item: any) => item.label).join(',');
    const lucs = modalGroup.values[3].map((item: any) => item.label).join(',');
    let response = null;
    if (disableShoppingButton) {
      response = await api.get(
        `/filters/siscob-filters-information?shoppingIds=${modalGroup.values[0][0].value}&brands=${brands}&lucs=${lucs}`,
      );
    } else {
      response = await api.get(
        `/filters/siscob-filters-information?billingGroupIds=${modalGroup.values[1][0].value}&brands=${brands}&lucs=${lucs}`,
      );
    }
    const shoppingIds = modalGroup.values[0].map((e: any) => parseInt(e.value));
    const dataPost: any = [];
    response.data.forEach((item: any) => {
      if (!shoppingIds.includes(item.shoppingId)) return;
      // validar luc repetida
      dataPost.push({
        cpfCnpj: item.cnpjCpf,
        billingGroupId: disableShoppingButton ? null : modalGroup.values[1][0].value,
        shoppingId: item.shoppingId,
        luc: item.luc,
        brand: item.brand,
        active: 1,
        userIdentifier: userName,
        contactDate: converterData(modalGroup.values[4]),
        additionalInformation: modalGroup.values[7],
        negotiationReturnId: modalGroup.values[6][0].value,
      });
    });
    api
      .post(`/negotiation-history/negotiation-histories`, dataPost)
      .then(
        response => {
          alert('Negociação feita com sucesso!');
          setActiveNegotiation(false);
          setModalGroup(modalGroupRef.current);
          getHistoryNegotiation(pageN);
        },
        error => {
          alert('Ocorreu um erro, tente novamente!');
        },
      )
      .finally(() => setScreenLoading(false));
  };

  const renderOptionModalGroup = (item: any, index: number, indexModalGroup: number) => {
    switch (index) {
      case 0:
      case 1:
      case 2:
        return (
          <Option>
            {item.label}
            {disableShoppingButton && indexModalGroup != 3 ? null : (
              <button onClick={() => handleRemoveSelectedModalItems(index, indexModalGroup)}>x</button>
            )}
          </Option>
        );
      case 3:
        return <Option>{`+${modalGroup.values[indexModalGroup].length - 3}`}</Option>;
      default:
        return null;
    }
  };

  const onCloseModal = async () => {
    const { index } = modalGroup;
    setModalGroup({ ...modalGroup, index: -1, input: '', loading: false });
    if (!disableShoppingButton) {
      await onRefreshByIndex(index);
    }
  };

  const getDinamicFilter = (index: number) => {
    const shoppings =
      modalGroup.values[0]?.length > 0 ? `shoppingIds=${modalGroup.values[0].map((item: any) => item.value)}` : '';
    const billingGroup = modalGroup.values[1]?.length > 0 ? `billingGroupIds=${modalGroup.values[1][0].value}` : '';
    const cpfsCnpjs =
      modalGroup.values[2]?.length > 0
        ? `cpfsCnpjs=${modalGroup.values[2].map((item: any) => item.value).join(',')}`
        : '';
    const lucs = modalGroup.values[3]?.length > 0 ? `lucs=${modalGroup.values[3].map((item: any) => item.label)}` : '';

    const brands =
      modalGroup.values[5]?.length > 0 ? `brands=${modalGroup.values[5].map((item: any) => item.label)}` : '';
    switch (index) {
      case 0:
        return [billingGroup, cpfsCnpjs, lucs, brands].filter(e => e).join('&');
      case 1:
        return [shoppings, cpfsCnpjs, lucs, brands].filter(e => e).join('&');
      case 2:
        return [shoppings, billingGroup, lucs, brands].filter(e => e).join('&');
      case 3:
        return [shoppings, billingGroup, cpfsCnpjs, brands].filter(e => e).join('&');
      case 5:
        return [shoppings, billingGroup, cpfsCnpjs, lucs].filter(e => e).join('&');
    }
  };

  const isValidSubmit = useMemo(() => {
    let valid = modalGroup.values.length >= 7;
    modalGroup.values.forEach((e, i) => {
      if ([1, 4, 7].includes(i) || valid === false) {
        return;
      } else if (e == undefined || e.length == 0) {
        valid = false;
      }
    });
    return valid;
  }, [modalGroup]);

  const bStatusMarca = useMemo(() => ['COBRANÇA CORPORATIVA', 'APROVADOR HOLDING', 'HOLDING'].includes(profile), [
    profile,
  ]);

  return (
    <>
      <DivVoltar onClick={history.goBack}>
        <p>&lt; Voltar para Priorização de cobrança</p>
      </DivVoltar>
      {loading ? (
        <div className="loaderUser">
          <Loader />
        </div>
      ) : (
        <>
          <Container>
            <Dialog open={open} onClose={handleClose}>
              <DialogContent>
                <DialogContentText>Você tem certeza que deseja excluir essa negociação?</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancelar
                </Button>
                <Button onClick={() => handleDelete(itemDelete)} color="secondary" autoFocus>
                  Excluir
                </Button>
              </DialogActions>
            </Dialog>
            <Resume>
              <h2>Dados do Cliente</h2>
              <FilterBar>
                <Box disabled={!query.shopping}>
                  <Item>
                    <span>Shopping</span>
                    <p>{query?.shopping}</p>
                  </Item>
                  <Item>
                    <span>CPF/CNPJ</span>
                    <p>{data?.cpfCnpj}</p>
                  </Item>
                  <Item>
                    <span>Razão Social</span>
                    <p>{data?.customerName}</p>
                  </Item>
                </Box>

                <Box disabled={query.shopping}>
                  <Item>
                    <span>Grupo de Cobrança</span>
                    <p>{data?.groupName}</p>
                  </Item>
                </Box>

                <Box>
                  <Item>
                    <span>Mês e ano de inadimplência</span>
                    <p>{formatarDataMesAno(query.blockEnd)}</p>
                  </Item>
                  <Item disabled={!query.shopping}>
                    <span>Classificação</span>
                    <p>{montarClassificacao(data?.isActiveCustomer, data?.isCorporateCustomer)}</p>
                  </Item>
                  {bStatusMarca && (
                    <Item>
                      <span>Status Marca</span>
                      <ItemSelect>
                        <SelectArea>
                          <Multiselect
                            selectedFromParent={[{ id: 0, label: 'Selecione' }]}
                            onPick={setSelectedOption}
                            search
                            options={options}
                            singleSelect
                          />
                        </SelectArea>
                        {selectedOption[0] && <Button onClick={onSubmitSelect}>ok</Button>}
                      </ItemSelect>
                    </Item>
                  )}
                </Box>
              </FilterBar>
            </Resume>
            <Records>
              <h2>Dados de contato</h2>
              <Tabs>
                {contactInfo.map((contactItem: IContactItem, key) => (
                  <TabItem
                    key={`${contactItem.identifier}-${key}`}
                    active={tabActive === contactItem.identifier}
                    onClick={() => handleTab(contactItem.identifier)}
                    disabled={contactItem.identifier == 0}
                  >
                    {contactItem.contactName}
                  </TabItem>
                ))}
                {(data?.isCorporateCustomer && profile === 'COBRANÇA SHOPPING') ||
                  (data?.isCorporateCustomer && profile === 'APROVADOR SHOPPING' && (
                    <AddTab onClick={() => AlertErro()} data-tip data-for="newContact">
                      +
                      <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="newContact" effect="solid">
                        <span>Adicionar novo contato</span>
                      </ReactTooltip>
                    </AddTab>
                  ))}
                {profile === 'APROVADOR HOLDING' && !data?.isCorporateCustomer && (
                  <AddTab
                    onClick={() => {
                      handleAddNewContact();
                    }}
                    data-tip
                    data-for="newContact"
                  >
                    +
                    <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="newContact" effect="solid">
                      <span>Adicionar novo contato</span>
                    </ReactTooltip>
                  </AddTab>
                )}
                {profile === 'HOLDING' && !data?.isCorporateCustomer && (
                  <AddTab
                    onClick={() => {
                      handleAddNewContact();
                    }}
                    data-tip
                    data-for="newContact"
                  >
                    +
                    <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="newContact" effect="solid">
                      <span>Adicionar novo contato</span>
                    </ReactTooltip>
                  </AddTab>
                )}

                {profile === 'COBRANÇA CORPORATIVA' && (
                  <AddTab
                    onClick={() => {
                      handleAddNewContact();
                    }}
                    data-tip
                    data-for="newContact"
                  >
                    +
                    <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="newContact" effect="solid">
                      <span>Adicionar novo contato</span>
                    </ReactTooltip>
                  </AddTab>
                )}

                {profile === 'APROVADOR SHOPPING' && !data?.isCorporateCustomer && (
                  <AddTab
                    onClick={() => {
                      handleAddNewContact();
                    }}
                    data-tip
                    data-for="newContact"
                  >
                    +
                    <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="newContact" effect="solid">
                      <span>Adicionar novo contato</span>
                    </ReactTooltip>
                  </AddTab>
                )}
                {profile === 'COBRANÇA SHOPPING' && !data?.isCorporateCustomer && (
                  <AddTab
                    onClick={() => {
                      handleAddNewContact();
                    }}
                    data-tip
                    data-for="newContact"
                  >
                    +
                    <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id="newContact" effect="solid">
                      <span>Adicionar novo contato</span>
                    </ReactTooltip>
                  </AddTab>
                )}
              </Tabs>

              {contactInfo.map((item: IContactItem, key: number) => (
                <Content key={`${item.identifier}-${key}`} active={tabActive === item.identifier}>
                  <Div>
                    <RecordsBox>
                      <FieldBox>
                        <Label>Nome do contato</Label>
                        <Field>{item.contactName}</Field>
                      </FieldBox>
                      <FieldBox>
                        <Label>E-mail</Label>
                        <Field>
                          {contactInfoDetails
                            .filter((item: any) => {
                              return item.contactPointType == 'E-Mail' && item;
                            })
                            .map((infoDetail: IContactInfoDetails, key: number) => (
                              <span key={key}>
                                {key === 0 && `${infoDetail.contactPoint}`}
                                {key === 0 && (
                                  <MoreContacts onClick={() => setModalActive(true)}> Ver Mais</MoreContacts>
                                )}
                              </span>
                            ))}
                        </Field>
                      </FieldBox>
                    </RecordsBox>
                    <RecordsBox>
                      <FieldBox>
                        <Label>Tipo do contato</Label>
                        <Field>{item.contactDepartment}</Field>
                      </FieldBox>
                      <FieldBox>
                        <Label>Telefones</Label>
                        <Field>
                          {contactInfoDetails
                            .filter((item: any) => item.contactPointType != 'E-Mail')
                            .map((infoDetail: IContactInfoDetails, key: number) => (
                              <span key={key}>
                                {key === 0 && `${infoDetail.contactPoint}`}
                                {key === 0 && (
                                  <MoreContacts onClick={() => setModalActive(true)}> Ver Mais</MoreContacts>
                                )}
                              </span>
                            ))}
                        </Field>
                      </FieldBox>
                    </RecordsBox>
                  </Div>
                  <ActionBoxContato>
                    {(profile === 'APROVADOR HOLDING' && !query.shopping) ||
                    profile === 'COBRANÇA CORPORATIVA' ||
                    (profile === 'HOLDING' && !query.shopping) ? (
                      <EditButton
                        onClick={() => {
                          handleEditContactBillingGroup(item, `${item.identifier}-${key}`);
                        }}
                      >
                        Editar Contato
                      </EditButton>
                    ) : (!data?.isCorporateCustomer && profile === 'COBRANÇA SHOPPING') ||
                      (!data?.isCorporateCustomer && profile === 'APROVADOR SHOPPING') ||
                      (profile === 'APROVADOR HOLDING' && !data?.isCorporateCustomer) ||
                      (profile === 'HOLDING' && !data?.isCorporateCustomer) ? (
                      <EditButton
                        onClick={() => {
                          handleEditContact(item, `${item.identifier}-${key}`);
                        }}
                      >
                        Editar Contato
                      </EditButton>
                    ) : (
                      <EditButton onClick={() => AlertErro()}>Editar Contato</EditButton>
                    )}

                    {(!data?.isCorporateCustomer && profile === 'COBRANÇA SHOPPING') ||
                    (!data?.isCorporateCustomer && profile === 'APROVADOR SHOPPING') ||
                    profile === 'COBRANÇA CORPORATIVA' ||
                    (profile === 'APROVADOR HOLDING' && !data?.isCorporateCustomer) ||
                    (profile === 'HOLDING' && !data?.isCorporateCustomer) ? (
                      <DeleteButton
                        onClick={() => {
                          handleDeleteContact(item, key);
                        }}
                      >
                        Excluir Contato
                      </DeleteButton>
                    ) : (
                      <DeleteButton onClick={() => AlertErro()}>Excluir Contato</DeleteButton>
                    )}
                  </ActionBoxContato>
                </Content>
              ))}
            </Records>
          </Container>
          <HeadTable>
            <Search>
              <img src={iconSearch} />
              <input onChange={e => pegarFiltro(e.target.value)} />
            </Search>
            <button
              type="button"
              onClick={() => {
                handleSlips();
              }}
            >
              DETALHAR BOLETOS
              <img src={iconDetalhar} alt="" />
            </button>
            <button
              type="button"
              onClick={() => {
                customerDebtReport();
              }}
            >
              GERAR RELATÓRIO DE DÍVIDAS PARA O CLIENTE
              <img src={iconDividas} alt="" />
            </button>

            {profile === 'APROVADOR HOLDING' && !query.shopping && (
              <button
                type="button"
                onClick={() => {
                  handleRegisterNegotiationBillingGroup();
                }}
              >
                Registrar Negociação
                <img src={iconContact} alt="" />
              </button>
            )}
            {profile === 'COBRANÇA CORPORATIVA' && (
              <button
                type="button"
                onClick={() => {
                  handleRegisterNegotiationBillingGroup();
                }}
              >
                Registrar Negociação
                <img src={iconContact} alt="" />
              </button>
            )}

            {profile === 'HOLDING' && !query.shopping && (
              <button
                type="button"
                onClick={() => {
                  handleRegisterNegotiationBillingGroup();
                }}
              >
                Registrar Negociação
                <img src={iconContact} alt="" />
              </button>
            )}

            {profile === 'COBRANÇA SHOPPING' && !data?.isCorporateCustomer && (
              <button
                type="button"
                onClick={() => {
                  handleRegisterNegotiation();
                }}
              >
                Registrar Negociação
                <img src={iconContact} alt="" />
              </button>
            )}
            {profile === 'APROVADOR SHOPPING' && !data?.isCorporateCustomer && (
              <button
                type="button"
                onClick={() => {
                  handleRegisterNegotiation();
                }}
              >
                Registrar Negociação
                <img src={iconContact} alt="" />
              </button>
            )}

            {profile === 'HOLDING' && !data?.groupName && !data?.isCorporateCustomer && (
              <button
                type="button"
                onClick={() => {
                  handleRegisterNegotiation();
                }}
              >
                Registrar Negociação
                <img src={iconContact} alt="" />
              </button>
            )}
            {profile === 'APROVADOR HOLDING' && !data?.groupName && !data?.isCorporateCustomer && (
              <button
                type="button"
                onClick={() => {
                  handleRegisterNegotiation();
                }}
              >
                Registrar Negociação
                <img src={iconContact} alt="" />
              </button>
            )}
          </HeadTable>
          <TabMenuTable>
            <TabMenuItem onClick={() => handleTabTable(1)} active={isTabActive === 1}>
              Detalhamento da cobrança
            </TabMenuItem>
            <TabMenuItem onClick={() => handleTabTable(2)} active={isTabActive === 2}>
              Histórico de negociações
            </TabMenuItem>
          </TabMenuTable>
          <ContentTable active={isTabActive === 1}>
            <Tabela>
              <>
                <table>
                  <thead>
                    <tr>
                      <th>
                        <Checkbox
                          name="allCheck"
                          onChange={(e: any) => {
                            handleSelectAll(e);
                          }}
                        />
                      </th>
                      <th>LUC</th>
                      <th>Tipo de cobrança</th>
                      <th onClick={() => ordernar('brand')}>
                        Marca
                        <img src={iconArrow} className={testarOrdenacao('brand')} />
                      </th>
                      <th onClick={() => ordernar('contractType')}>
                        Tipo do
                        <br />
                        Contrato
                        <img src={iconArrow} className={testarOrdenacao('contractType')} />
                      </th>
                      <th onClick={() => ordernar('contractNumber')}>
                        Número do
                        <br />
                        contrato
                        <img src={iconArrow} className={testarOrdenacao('contractNumber')} />
                      </th>
                      <th onClick={() => ordernar('contractStatus')}>
                        Status do
                        <br />
                        contrato
                        <img src={iconArrow} className={testarOrdenacao('contractStatus')} />
                      </th>
                      <th onClick={() => ordernar('legalStatus')}>
                        Status
                        <br />
                        Jurídico
                        <img src={iconArrow} className={testarOrdenacao('legalStatus')} />
                      </th>
                      <th onClick={() => ordernar('overdueBalanceInMonth')}>
                        Saldo vencido
                        <br />
                        no mês/ano
                        <img src={iconArrow} className={testarOrdenacao('overdueBalanceInMonth')} />
                      </th>
                      <th onClick={() => ordernar('totalOverdueBalance')}>
                        Saldo vencido
                        <br />
                        total
                        <img src={iconArrow} className={testarOrdenacao('totalOverdueBalance')} />
                      </th>
                      <th onClick={() => ordernar('dueBalanceInMonth')}>
                        Saldo a vencer
                        <br />
                        no mês/ano
                        <img src={iconArrow} className={testarOrdenacao('dueBalanceInMonth')} />
                      </th>
                      <th onClick={() => ordernar('totalDueBalance')}>
                        Saldo a vencer
                        <br />
                        total
                        <img src={iconArrow} className={testarOrdenacao('totalDueBalance')} />
                      </th>
                      <th>
                        Ultimo
                        <br />
                        contato
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterData
                      ?.filter((bankSlip: any) => isTabActive === 1 && filtrar(bankSlip, inputFIlter))
                      .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                      .map((item: any, index: number) => (
                        <tr key={index}>
                          <td>
                            <Checkbox name="inputCheckboxSlips" onChange={() => handleCheck(item.contractNumber)} />
                          </td>
                          <td>{item.lucs}</td>
                          <td>{item.chargingType}</td>
                          <td>{item.brand}</td>
                          <td>{formatarVirgula(item.contractType)}</td>
                          <td>{formatarVirgula(item.contractNumber)}</td>
                          <td>{formatarVirgula(item.contractStatus)}</td>
                          <td>{item.legalStatus}</td>
                          <td>
                            {item.overdueBalanceInMonth.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                          </td>
                          <td>
                            {item.totalOverdueBalance.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td>
                            {item.dueBalanceInMonth.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td>
                            {item.totalDueBalance.toLocaleString('pt-br', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </td>
                          <td>{item.lastContactStatus}</td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={3}>
                        <button onClick={() => handleExport()} type="button">
                          Exportar
                          <img src={bin} alt="" />
                        </button>
                      </td>
                      <td />
                      <td />
                      <td />
                      <td colSpan={2}>
                        <p>Valores totais:</p>
                      </td>
                      <td>{totalizador('overdueBalanceInMonth')}</td>
                      <td>{totalizador('totalOverdueBalance')}</td>
                      <td>{totalizador('dueBalanceInMonth')}</td>
                      <td>{totalizador('totalDueBalance')}</td>
                      <td>
                        <PaginationWrapper>
                          <Pagination
                            count={countPages(
                              filterData?.filter((bankSlip: any) => isTabActive === 1 && filtrar(bankSlip, inputFIlter))
                                .length,
                            )}
                            page={page}
                            onChange={(e, page) => handleChange(e, page)}
                          />
                        </PaginationWrapper>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </>
            </Tabela>
          </ContentTable>
          <ContentTable active={isTabActive === 2}>
            <Table>
              <Thead>
                <Tr>
                  <Th>Cliente</Th>
                  <Th>Razão social</Th>
                  <Th>Nome Fantasia</Th>
                  <Th>Grupo de cobrança</Th>
                  <Th>LUC</Th>
                  <Th>Shopping</Th>
                  <Th>Data contato</Th>
                  <Th>Retorno Negociação</Th>
                  <Th>Descrição da negociação</Th>
                  <Th>
                    Responsável
                    <br />
                    Pelo contato
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {loadingPage ? (
                  <div className="loaderUser">
                    <Loader />
                  </div>
                ) : (
                  negotiationData
                    .filter((negotiation: any) => isTabActive === 2 && filtrarNegociation(negotiation, inputFIlter))
                    .map((item: INegotiation) => (
                      <Tr key={item.identifier}>
                        <Td>{item.cpfCnpj}</Td>
                        <Td style={{ whiteSpace: 'nowrap' }} data-tip data-for={item.identifier.toString()}>
                          {formatarRazaoSocial(item.customerName)}
                          <ReactTooltip backgroundColor="#31C6D3" className="tooltip" id={item.identifier.toString()}>
                            <span>{item.customerName}</span>
                          </ReactTooltip>
                        </Td>
                        <Td>{item.brand}</Td>
                        <Td>{item.billingGroupName}</Td>
                        <Td>{item.luc}</Td>
                        <Td>{item.shoppingName}</Td>
                        <Td>{formatarData(item.contactDate)}</Td>
                        <Td>{item.negotiationReturnName}</Td>
                        <Td>{item.additionalInformation}</Td>
                        <Td>{item.contactResponsible}</Td>
                        {(data?.isCorporateCustomer && query.shopping) || profile === 'RÉGUA DE COBRANÇA' ? (
                          <Td />
                        ) : (
                          <Td onClick={() => handleTableActions(item.identifier)}>
                            <button type="button">
                              <img src={more} alt="Edit" />
                            </button>
                          </Td>
                        )}

                        <Td style={{ padding: '1px' }}>
                          <ActionBox active={tableActionSelected === item.identifier}>
                            {profile === 'COBRANÇA CORPORATIVA' && (
                              <ActionEdit onClick={() => handleEditGroup(item)}>Editar</ActionEdit>
                            )}
                            {profile === 'HOLDING' && !query.shopping && (
                              <ActionEdit onClick={() => handleEditGroup(item)}>Editar</ActionEdit>
                            )}
                            {profile === 'APROVADOR HOLDING' && !query.shopping && (
                              <ActionEdit onClick={() => handleEditGroup(item)}>Editar</ActionEdit>
                            )}
                            {profile === 'HOLDING' && query.shopping && (
                              <ActionEdit onClick={() => handleEdit(item)}>Editar</ActionEdit>
                            )}
                            {profile === 'APROVADOR HOLDING' && query.shopping && (
                              <ActionEdit onClick={() => handleEdit(item)}>Editar</ActionEdit>
                            )}
                            {profile === 'APROVADOR SHOPPING' && (
                              <ActionEdit onClick={() => handleEdit(item)}>Editar</ActionEdit>
                            )}
                            {profile === 'COBRANÇA SHOPPING' && (
                              <ActionEdit onClick={() => handleEdit(item)}>Editar</ActionEdit>
                            )}

                            <ActionDelete onClick={() => handleClickOpen(item.identifier)}>Excluir</ActionDelete>
                          </ActionBox>
                        </Td>
                      </Tr>
                    ))
                )}
              </Tbody>
              <tfoot>
                <tr>
                  <td colSpan={11}>
                    <PaginationWrapper>
                      <Pagination count={nbPage} page={page} onChange={handleChangePagination} />
                    </PaginationWrapper>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </ContentTable>
          <NegotiationArea active={activeNegotiation}>
            <h2>Registrar Negociação</h2>
            <BoxNegotiation>
              <ButtonNegotiation onClick={() => handleClickModal(0)}>Shopping</ButtonNegotiation>
              <SelectActive>
                {modalGroup.values[0]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 0))}
              </SelectActive>
            </BoxNegotiation>
            <BoxNegotiation>
              <ButtonNegotiation>Grupo de Cobrança</ButtonNegotiation>
              <SelectActive>
                {modalGroup.values[1]?.map((item: any, index: number) => (
                  <Option>{item.label}</Option>
                ))}
              </SelectActive>
            </BoxNegotiation>
            <BoxNegotiation>
              <ButtonNegotiation onClick={() => handleClickModal(2)}>Cliente</ButtonNegotiation>
              <SelectActive>
                {modalGroup.values[2]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 2))}
              </SelectActive>
            </BoxNegotiation>
            <BoxNegotiation>
              <ButtonNegotiation onClick={() => handleClickModal(3)}>LUC</ButtonNegotiation>
              <SelectActive>
                {modalGroup.values[3]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 3))}
              </SelectActive>
            </BoxNegotiation>
            <BoxNegotiation>
              <ButtonNegotiation>Data de contato</ButtonNegotiation>
              <Dpicker
                value={modalGroup.values[4].toLocaleString()}
                onChange={(date: any) => handleChangeValueModalGroup(date, 4)}
                dateFormat="dd/MM/yyyy"
                maxDate={maxDate}
                customInput={<MaskedInput mask="11/11/1111" placeholder="dd/MM/yyyy" />}
              />
            </BoxNegotiation>
            <BoxNegotiation>
              <ButtonNegotiation onClick={() => handleClickModal(5)}>Marca</ButtonNegotiation>
              <SelectActive>
                {modalGroup.values[5]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 5))}
              </SelectActive>
            </BoxNegotiation>
            <BoxNegotiation>
              <ButtonNegotiation onClick={() => handleClickModal(6)}>Retorno da negociação</ButtonNegotiation>
              <SelectActive>
                {modalGroup.values[6]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 6))}
              </SelectActive>
            </BoxNegotiation>
            <BoxNegotiation>
              <ButtonNegotiation>Descrição da Negociação</ButtonNegotiation>
              <textarea
                value={modalGroup.values[7] ?? ''}
                onChange={e => handleChangeValueModalGroup(e.target.value, 7)}
              />
            </BoxNegotiation>
            <ActionArea>
              <Button onClick={handleSubmit}>Fazer uma negociação</Button>
            </ActionArea>
          </NegotiationArea>

          <NewContact
            customer={{ ...data, query }}
            active={modalNewContactActive}
            closeModalFunction={handleExitAddNewContact}
            userName={userName}
          />
          <EditNewContact
            customer={{ ...data, query, contactToEdit, contactInfoDetails }}
            active={modalEditContactActive}
            closeModalFunction={handleExitEditNewContact}
            userName={userName}
          />
          <EditNewContactBillingGroup
            customer={{ ...data, query, contactToEdit, contactInfoDetails }}
            active={modalEditContactBillingGroupActive}
            closeModalFunction={handleExitEditNewContact}
            userName={userName}
          />
          <NovaNegociacao
            active={modalNewNegotiationActive}
            customer={{ ...data, query }}
            closeModalFunction={handleExitAddNewNegotiation}
            userName={userName}
            negotiation={negotiationSelected}
            edit={modalNewNegotiationEdit}
          />
          <NewNegotiationBillingGroup
            active={modalNewNegotiationGroupActive}
            customer={{ ...data, query }}
            closeModalFunction={handleExitAddNewNegotiation}
            userName={userName}
            negotiation={negotiationSelected}
          />
          <NewNegotiationBillingGroupEdit
            active={modalNewNegotiationGroupEdit}
            customer={{ ...data, query }}
            closeModalFunction={handleExitAddNewNegotiation}
            userName={userName}
            negotiation={negotiationSelected}
          />
          <NewContactBillingGroup
            customer={{ ...data, query }}
            active={modalNewContactBillingGroupActive}
            closeModalFunction={handleExitAddNewContactBillingGroup}
            userName={userName}
          />
          <Modal active={modalActive}>
            {contactInfo.map((item: IContactItem, key: number) => (
              <Content key={`${item.identifier}-${key}`} active={tabActive === item.identifier}>
                <Div>
                  <CloseModal onClick={() => setModalActive(false)}>Fechar</CloseModal>
                  <h2>Contatos do cliente</h2>
                  <RecordsBox>
                    <FieldModal>
                      <Label>Nome do contato</Label>
                      <Field>{item.contactName}</Field>
                      <Label>Tipo do contato</Label>
                      <Field>{item.contactDepartment}</Field>
                    </FieldModal>
                  </RecordsBox>
                  <RecordsBox>
                    <FieldModal>
                      <Label>E-mail</Label>
                      <Field>
                        {contactInfoDetails
                          .filter((item: any) => {
                            return item.contactPointType == 'E-Mail' && item;
                          })
                          .map((infoDetail: IContactInfoDetails, key: number) => (
                            <li key={key}>{`${infoDetail.contactPoint}`}</li>
                          ))}
                      </Field>
                    </FieldModal>
                  </RecordsBox>
                  <RecordsBox>
                    <FieldModal>
                      <Label>Telefones</Label>
                      <Field>
                        {contactInfoDetails
                          .filter((item: any) => item.contactPointType != 'E-Mail')
                          .map((infoDetail: IContactInfoDetails, key: number) => (
                            <li key={key}>{`${infoDetail.contactPoint}`}</li>
                          ))}
                      </Field>
                    </FieldModal>
                  </RecordsBox>
                </Div>
              </Content>
            ))}
          </Modal>
          <SelectModal active={screenLoading} />
          <SelectModal active={modalGroup.index != -1} onClick={onCloseModal} />
          <ModalContainer active={modalGroup.index != -1}>
            <CloseSelectModal onClick={onCloseModal}>X</CloseSelectModal>
            <h3>Selecione </h3>
            <input
              disabled={false}
              type="text"
              onChange={(e: any) => setModalGroup({ ...modalGroup, input: e.target.value })}
              value={modalGroup.input}
              placeholder="Digite"
            />
            {selectedModalItems}
            <ListCard>{modalListContent}</ListCard>
          </ModalContainer>
        </>
      )}
    </>
  );
};
