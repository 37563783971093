import React, { useState } from 'react';
import { Pagination } from '@material-ui/lab';
import {
  Container,
  CustomTable,
  HeaderItem,
  PaginationWrapper,
  Row,
  RowItem,
  Orderable,
  Footer,
  FooterRow,
  FooterItem,
  SubRowItem,
} from './styles';
import ArrowDown from '../../../assets/downArrow';
import { PaginatedTableProps, SortOptions, TableHeader } from './types';

export function PaginatedTable({
  columns,
  data,
  pagination,
  onRowClick,
  onChangePage,
}: PaginatedTableProps): JSX.Element {
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    key: '',
    type: undefined,
  });

  const onClickOrderable = (item: TableHeader) => {};

  const renderHeaderItem = (item: TableHeader) => {
    if (item.orderable) {
      const arrowDirection = item.key === sortOptions.key ? sortOptions.type : undefined;
      return (
        <Orderable onClick={() => onClickOrderable(item)} arrowDirection={arrowDirection}>
          {item.label}
          <ArrowDown click={null} color="white" />
        </Orderable>
      );
    }
    return item.label;
  };

  const renderRowItem = (item: any) => {
    return columns.map((header, i) => <RowItem key={i}>{item[header.key]}</RowItem>);
  };

  const renderSubRowItem = (subItems: any) => {
    return columns.map((header, i) => <SubRowItem key={i}>{subItems[i] ? subItems[i] : ''}</SubRowItem>);
  };

  return (
    <Container>
      <CustomTable>
        <thead>
          <tr>
            {columns.map((item, i) => (
              <HeaderItem key={i}>{renderHeaderItem(item)}</HeaderItem>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <React.Fragment key={i}>
              <Row
                style={{
                  backgroundColor: i % 2 === 0 ? '#ffffff' : '#e5e9ea',
                }}
                onClick={() => (onRowClick ? onRowClick(item) : null)}
              >
                {renderRowItem(item)}
              </Row>
              {item.subItems?.length > 0 && (
                <Row
                  style={{
                    backgroundColor: i % 2 === 0 ? '#ffffff' : '#e5e9ea',
                  }}
                  onClick={() => (onRowClick ? onRowClick(item) : null)}
                >
                  {renderSubRowItem(item.subItems)}
                </Row>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </CustomTable>
      <PaginationWrapper>
        <Pagination
          count={pagination!.totalPages}
          page={pagination!.currentPage}
          onChange={(e, i) => onChangePage!(i)}
        />
      </PaginationWrapper>
    </Container>
  );
}
