import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import FileSaver from 'file-saver';
import api from '../../../_core/api';
import { Container, FileDownload } from './styles';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import Table from '../../../_core/_components/table';
import { handleErrors } from '../../../_core/services/toast';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Button, Form, Input, Select, SingleSelect, Option, Checkbox } from '../../../_core/_components';
import { mascaraDDMMAAAA, mascaraMMAAAA } from '../../../_core/masks';

export const Exportacoes: React.FC = () => {
  const { response: responseFiltersShoppings, isLoading: isLoadingFiltersShoppings } = useCache({
    key: 'getFiltersShoppings',
    fn: requests.get('getFiltersShoppings'),
  });
  const { response: responseStatus, isLoading: isLoadingStatus } = useCache({
    key: 'getStatus',
    fn: requests.get('getStatus'),
  });
  const [dataRequest, setDataRequest] = useState<any[]>([]);
  const [shoppingsOptions, setShoppingsOptions] = useState<any[]>([]);
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [status, setStatus] = useState<Option>();
  const [shopping, setShopping] = useState<Option[]>([]);
  const [shoppings, setShoppings] = useState<string[]>([]);
  const [mountYear, setMountYear] = useState('');
  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (responseFiltersShoppings !== undefined) {
      const shoppingList: any[] = [];
      responseFiltersShoppings.data.forEach((item: any) => {
        shoppingList.push({
          value: item.shoppingId.toString(),
          label: item.shopping,
        });
      });
      setShoppingsOptions(shoppingList);
    }
  }, [responseFiltersShoppings]);

  useEffect(() => {
    if (responseStatus !== undefined) {
      const statusList: any[] = [
        {
          value: 0,
          label: 'Selecione',
        },
      ];
      responseStatus.data.forEach((item: any) => {
        statusList.push({
          value: item.id,
          label: item.status,
        });
      });
      setStatusOptions(statusList);
    }
  }, [responseStatus]);

  const data = useMemo(
    () =>
      dataRequest.map(e => ({
        id: e.id,
        nameShopping: e.nameShopping,
        dateMonthYear: moment(e.dateReference).format('MM/YYYY'),
        processingDate: moment(e.processingDate).format('DD/MM/YYYY'),
        ordinal: e.ordinal,
        dateCreated: moment(e.dateCreated).format('DD/MM/YYYY hh:mm:ss'),
        status: e.status.status,
        typeSpreadSheet: e.typeSpreadSheet.name,
      })),
    [dataRequest],
  );

  const reqSumary = async () => {
    setIsLoading(true);
    const [month, year] = mountYear.split('/');
    const queryDate = year ? `month=${month}&year=${year}` : '';
    const queryProcessDate = date ? `processingDate=${convertDate(date)}` : '';
    const queryShopping = shopping.length > 0 ? `shopping=${shopping.map(e => e.label).join(',')}` : '';
    const queryStatus = status?.value ? `status=${status!.value}` : '';
    const query = [queryDate, queryProcessDate, queryShopping, queryStatus].filter(e => e).join('&');
    const [responseLucLuc, responseQuality] = await Promise.all([
      api.get(`/spredsheet/shopping/LUCLUC?${query}`),
      api.get(`/spredsheet/shopping/QUALITY?${query}`),
    ]);
    setDataRequest([...responseLucLuc.data, ...responseQuality.data]);
    setIsLoading(false);
  };

  const convertDate = (value: string) => {
    const arr = value.split('/');
    return `${arr[2]}-${arr[1]}-${arr[0]}`;
  };

  const onClickCheckbox = (item: string) => {
    const arr = [...shoppings];
    if (arr.includes(item)) {
      setShoppings(arr.filter(e => e !== item));
    } else {
      arr.push(item);
      setShoppings(arr);
    }
  };

  const onClickDownload = async (item: any) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/spredsheet/download/${item.id}`, { responseType: 'arraybuffer' });
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, `IND_${item.typeSpreadSheet}_${item.dateCreated}`);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const bToggleAll = useMemo(() => {
    const allShoppingsNames = data.map(e => e.nameShopping);
    return allShoppingsNames.every(e => shoppings.includes(e));
  }, [shoppings, data]);

  const onToggleAll = () => {
    const allShoppingsNames = data.map(e => e.nameShopping);
    if (bToggleAll) {
      setShoppings([]);
    } else {
      setShoppings(allShoppingsNames);
    }
  };

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => ({
        ...item,
        toggle: (
          <Checkbox
            checked={shoppings.includes(item.nameShopping)}
            onClick={() => onClickCheckbox(item.nameShopping)}
          />
        ),
        download: (
          <>
            {item.status === 'PROCESSADO COM SUCESSO' && (
              <FileDownload onClick={() => onClickDownload(item)}>Download</FileDownload>
            )}
          </>
        ),
      }));
    }
    return [];
  }, [data, shoppings]);

  return (
    <Container>
      <FullScreenLoading isEnabled={isLoading || isLoadingFiltersShoppings || isLoadingStatus} />
      <h1>Exportações de inadimplência</h1>
      <Form
        items={[
          <Select placeholder="Shoppings" options={shoppingsOptions} state={[shopping, setShopping]} />,
          <Input
            placeholder="MM/AAAA"
            label="Mês e ano de processamento"
            state={[mountYear, setMountYear]}
            pattern={mascaraMMAAAA}
          />,
          <Input
            placeholder="DD/MM/AAAA"
            label="Data de processamento"
            state={[date, setDate]}
            pattern={mascaraDDMMAAAA}
          />,
          <SingleSelect placeholder="Status" options={statusOptions} state={[status, setStatus]} />,
        ]}
        submitButton={<Button text="Pesquisar" onClick={reqSumary} />}
      />
      <FullScreenLoading isEnabled={isLoading} />
      {renderedData.length > 0 && (
        <>
          <h1>Resultado</h1>
          <Table
            columns={[
              { label: <Checkbox checked={bToggleAll} onClick={onToggleAll} />, key: 'toggle' },
              { label: 'Shopping', key: 'nameShopping', orderable: true },
              { label: 'Mês/Ano', key: 'dateMonthYear', orderable: true },
              { label: 'Data de processamento', key: 'processingDate', orderable: true },
              { label: 'Ordinal', key: 'ordinal', orderable: true },
              { label: 'Data de carregamento', key: 'dateCreated' },
              { label: 'Status', key: 'status', orderable: true },
              { label: 'Tipo de arquivo', key: 'typeSpreadSheet', orderable: true },
              { label: 'Arquivo', key: 'download' },
            ]}
            data={renderedData}
          />
        </>
      )}
    </Container>
  );
};
