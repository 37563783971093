import _ from 'lodash';
import React, { useEffect, useState, useContext, useMemo } from 'react';
import api from '../../../_core/api';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { Container, TableContainer } from './styles';
import { AuthContext } from '../../../_main/contexts/auth';
import {
  DataSaleCluster,
  DataSaleClusterDetail,
  DataSummaryDetail,
  DataSummaryMaster,
  IDate,
  NonPaymentSummaryDTO,
} from './types';
import EvolucaoInadBruta from './evolucao-bruta';
import EvolucaoInadLiquida from './evolucao-liquida';
import ClusterVenda from './cluster-venda';
import { handleErrors, warning } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { useCache } from '../../../_core/cache';
import { Button, Checkbox, Form, Input, Option, SingleSelect } from '../../../_core/_components';
import { mascaraMMAAAA } from '../../../_core/masks';

export const ResumoInadimplencia: React.FC = () => {
  const { profile, shoppingProfileName } = useContext(AuthContext);
  const { response: responseShoppings, isLoading } = useCache({
    key: 'nonPaymentShoppings',
    fn: () => api.get('/non-payment-summary/shoppings'),
  });
  const [loading, setLoading] = useState(false);
  const [dataNonPaymentLiquid, setNonPaymentLiquid] = useState<NonPaymentSummaryDTO | null>(null);
  const [dataNonPaymentBrute, setNonPaymentBrute] = useState<NonPaymentSummaryDTO | null>(null);
  const [dataSummaryMaster, setDataSummaryMaster] = useState<DataSummaryMaster[]>([]);
  const [dataSummaryDetail, setDataSummaryDetail] = useState<DataSummaryDetail[]>([]);
  const [shoppingOptions, setShoppingOptions] = useState<Option[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [shoppingSelected, setShoppingSelected] = useState('');
  const [showCorporate, setShowCorporate] = useState(profile === 'COBRANÇA CORPORATIVA');
  const [dataSaleClusterDetails, setDataSaleClusterDetails] = useState<DataSaleClusterDetail[]>([]);

  const startDateTo: Date = useMemo(() => {
    if (startDate.length === 7) {
      const [month, year] = startDate.split('/');
      return new Date(+year, +month - 1);
    }
    return new Date();
  }, [startDate]);

  const reqSumaryMaster = async (shopping: string, showCorporateBool: boolean, date: IDate) => {
    const response = await api.get(
      `/non-payment-summary/master?month=${date.month}&year=${date.year}&showCorporate=${showCorporateBool}&shopping=${shopping}`,
    );
    if (response.data) {
      setDataSummaryMaster(response.data);
    } else {
      setDataSummaryMaster([]);
    }
  };

  const reqSumaryDetail = async (shopping: string, showCorporateBool: boolean, date: IDate) => {
    const response = await api.get(
      `/non-payment-summary/detail?month=${date.month}&year=${date.year}&showCorporate=${showCorporateBool}&shopping=${shopping}`,
    );
    if (response.data) {
      const arrOrdenador = ['EMPREENDEDOR', 'CONDOMINIO', 'FUNDO', 'TOTAL', 'CONFISSAO'];
      const sortedCollection = _.sortBy(response.data, (item: any) => arrOrdenador.indexOf(item.indicador));
      setDataSummaryDetail(sortedCollection);
    } else {
      setDataSummaryDetail([]);
      warning('Não há retorno nessa pesquisa');
    }
  };

  useEffect(() => {
    if (responseShoppings !== undefined) {
      const shoppingList: any[] = [];
      responseShoppings.data.forEach((item: string) => {
        shoppingList.push({
          value: item,
          label: item,
        });
      });
      shoppingList
        .sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        })
        .sort((a, b) => {
          if (a.label === 'BRMALLS') {
            return -1;
          }
          return 0;
        });
      setShoppingSelected(shoppingList[0].value);
      setShoppingOptions(shoppingList);
    }
  }, [responseShoppings]);

  const getDataNonPaymentLiquid = async (shopping: string, showCorporateBool: boolean, date: IDate) => {
    const response = await api.get(
      `/non-payment-summary/net?month=${date.month}&year=${date.year}&shopping=${shopping}&showCorporate=${showCorporateBool}`,
    );
    setNonPaymentLiquid(response.data);
  };

  const getDataNonPaymentBrute = async (shopping: string, showCorporateBool: boolean, date: IDate) => {
    const response = await api.get(
      `/non-payment-summary/raw?month=${date.month}&year=${date.year}&shopping=${shopping}&showCorporate=${showCorporateBool}`,
    );
    setNonPaymentBrute(response.data);
  };

  const getSaleCluster = async (date: IDate) => {
    const { data } = await api.get(
      `non-payment-summary/cluster?month=${date.month}&year=${date.year}&showCorporate=${showCorporate}`,
    );
    const arr: DataSaleClusterDetail[] = [];
    data.forEach((element: DataSaleCluster) => {
      element.dados.forEach(item =>
        arr.push({
          grupo: element.dados.length > 1 ? element.nome : undefined,
          ...item,
        }),
      );
    });
    setDataSaleClusterDetails(arr);
  };

  const getShoppingSelected = () => {
    if (['COBRANÇA SHOPPING', 'APROVADOR SHOPPING'].includes(profile)) {
      return shoppingProfileName || '';
    }
    return shoppingSelected;
  };

  const handleResumeGenerateButton = async () => {
    setLoading(true);
    const date: IDate = {
      day: startDateTo.getDate(),
      month: startDateTo.getMonth() + 1,
      year: startDateTo.getFullYear(),
    };
    try {
      await Promise.all([
        reqSumaryMaster(getShoppingSelected(), showCorporate, date),
        reqSumaryDetail(getShoppingSelected(), showCorporate, date),
        getDataNonPaymentLiquid(getShoppingSelected(), showCorporate, date),
        getDataNonPaymentBrute(getShoppingSelected(), showCorporate, date),
        getSaleCluster(date),
      ]);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const reqLucAtLuc = async () => {
    setLoading(true);
    try {
      const date: IDate = {
        day: startDateTo.getDate(),
        month: startDateTo.getMonth() + 1,
        year: startDateTo.getFullYear(),
      };
      const response = await api.get(
        `spredsheet/shopping/LUCLUC?month=${date.month}&year=${date.year}&shopping=${getShoppingSelected()}`,
      );
      const arr = response.data.sort((a: any, b: any) => Date.parse(b.processingDate) - Date.parse(a.processingDate));
      let id = null;
      if (arr[0] && arr[0].id) {
        id = arr[0].id;
      }

      const url = id ? `spredsheet/download/${id}` : `spredsheet/lucluc/download`;

      const responseSpreadsheet = await api.get(url, {
        responseType: 'arraybuffer',
        params: {
          ...(!id && { month: date.month }),
          ...(!id && { year: date.year }),
          ...(!id && { shopping: getShoppingSelected() }),
          // ...(id && { id }),
        },
      });
      downloadXls(responseSpreadsheet);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const handleAjuizamentoExport = async () => {
    setLoading(true);
    try {
      const date: IDate = {
        day: startDateTo.getDate(),
        month: startDateTo.getMonth() + 1,
        year: startDateTo.getFullYear(),
      };
      const response = await api.get(
        `spredsheet/shopping/QUALITY?month=${date.month}&year=${date.year}&shopping=${getShoppingSelected()}`,
      );
      const arr = response.data.sort((a: any, b: any) => Date.parse(b.processingDate) - Date.parse(a.processingDate));
      let id = null;
      if (arr[0] && arr[0].id) {
        id = arr[0].id;
      }

      const responseSpreadsheet = await api.get(`spredsheet/download${id ? `/${id}` : ''}`, {
        responseType: 'arraybuffer',
        params: {
          ...(!id && { month: date.month }),
          ...(!id && { year: date.year }),
          ...(!id && { shopping: getShoppingSelected() }),
          // ...(id && { id }),
        },
      });
      downloadXls(responseSpreadsheet);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const shoppingFormSelect = useMemo(() => {
    if (!['COBRANÇA SHOPPING', 'APROVADOR SHOPPING'].includes(profile)) {
      return (
        <SingleSelect
          placeholder="Shopping"
          options={shoppingOptions}
          state={[
            shoppingOptions.find(e => e.label === shoppingSelected),
            option => setShoppingSelected(option?.label as string),
          ]}
        />
      );
    }
    return <></>;
  }, [profile, shoppingOptions, shoppingSelected]);

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoading} />
      <Container>
        <Form
          items={[
            shoppingFormSelect,
            <Input placeholder="MM/YYYY" label="Data" state={[startDate, setStartDate]} pattern={mascaraMMAAAA} />,
            <Checkbox
              label="Visão corporativa"
              onClick={() => setShowCorporate(!showCorporate)}
              checked={showCorporate}
            />,
          ]}
          submitButton={<Button text="Gerar resumo" onClick={handleResumeGenerateButton} />}
        />

        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>
                  Abertura
                  <br />
                  Inadimplência
                </th>
                <th>
                  Recebido Até <br />o Vencimento Real
                </th>
                <th>
                  Recebimento até
                  <br />o Vencimento - Meta
                </th>
                <th>
                  Inadimplência
                  <br />
                  Bruta - Real
                </th>
                <th>
                  Inadimplência
                  <br />
                  Bruta - Tendência
                </th>
                <th>
                  Inadimplência
                  <br />
                  Bruta - Meta
                </th>
                <th>
                  Inadimplência
                  <br />
                  Líquida - Real
                </th>
                <th>
                  Inadimplência
                  <br />
                  Líquida - Tendência
                </th>
                <th>
                  Inadimplência
                  <br />
                  Líquida - Meta
                </th>
              </tr>
            </thead>
            <tbody>
              {dataSummaryMaster.map((item, index) => (
                <tr key={index}>
                  <td> {item.aberturaReal}%</td>
                  <td> {item.recebidoAteVencimentoReal}%</td>
                  <td> {item.realRecebimentoMeta}%</td>
                  <td> {item.inadBrutaReal}%</td>
                  <td> {item.inadBrutaTendencia}%</td>
                  <td> {item.inadBrutaMeta}%</td>
                  <td> {item.inadLiquidaReal}%</td>
                  <td> {item.inadLiquidaTendencia}%</td>
                  <td> {item.inadLiquidaMeta}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>
                  Indicador
                  <br />
                  Tipo Detalhe
                </th>
                <th>
                  Faturado
                  <br />
                  Mês
                </th>
                <th>
                  Inadimplência
                  <br />
                  Bruta - Real
                </th>
                <th>
                  Inadimplência
                  <br />
                  Bruta - Tendência
                </th>
                <th>
                  Inadimplência
                  <br />
                  Líquida - Real
                </th>
                <th>
                  Inadimplência
                  <br />
                  Líquida - Tendência
                </th>
                <th>
                  Inadimplência
                  <br />
                  Líquida - % Lojas Corporativas
                </th>
                <th>
                  Inadimplência
                  <br />
                  Líquida - % Lojas Jurídicas
                </th>
              </tr>
            </thead>
            <tbody>
              {dataSummaryDetail.map((item, index) => (
                <tr key={index}>
                  <td>{item.indicador}</td>
                  <td>
                    {item.faturadoMes &&
                      item.faturadoMes.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                  </td>
                  <td> {item.inadimplenciaBrutaReal}%</td>
                  <td> {item.inadimplenciaBrutaTendencia}%</td>
                  <td> {item.inadimplenciaLiquidaReal}%</td>
                  <td> {item.inadimplenciaLiquidaTendencia}%</td>
                  <td> {item.percentualInadimplenciaLiquidaLojasCorporativas}%</td>
                  <td> {item.percentualInadimplenciaLiquidaLojasJuridicas}%</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3}>
                  <div>
                    <Button onClick={reqLucAtLuc} text="Relatório Completo LUC a LUC" />
                  </div>
                </td>
                <td>
                  <div>
                    <Button onClick={handleAjuizamentoExport} text="Exportar" />
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </TableContainer>
        {/* <>
          {dataNonPaymentBrute && (
            <>
              <h1>Evolução Inadimplência Bruta</h1>
              <EvolucaoInadBruta date={startDateTo} values={dataNonPaymentBrute!} />
            </>
          )}
          {dataNonPaymentLiquid && (
            <>
              <h1>Evolução Inadimplência Líquida</h1>
              <EvolucaoInadLiquida date={startDateTo} values={dataNonPaymentLiquid!} />
            </>
          )}
          {dataSaleClusterDetails.length > 0 && (
            <>
              <h1>Cluster de venda</h1>
              <ClusterVenda date={startDateTo} values={dataSaleClusterDetails} />
            </>
          )}
        </> */}
      </Container>
    </>
  );
};
