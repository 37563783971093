import styled from 'styled-components/macro';

interface IListItem {
  active?: boolean;
  onClick?: any;
  hidden?: boolean;
}

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeadList = styled.ul`
  display: flex;
  justify-content: flex-start;
`;

export const HeadListItem = styled.li<IListItem>`
  display: ${({ hidden }) => (hidden ? 'none' : '')};
  position: relative;
  color: #00959c;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  cursor: pointer;
  margin: 0rem 1.5rem;

  a {
    color: #00959c;
    padding: 1rem 0;
    position: relative;
    text-decoration: none;
    display: inline-block;

    &:before,
    &:after {
      height: 0.1rem;
      position: absolute;
      content: '';
      -webkit-transition: all 0.35s ease;
      transition: all 0.35s ease;
      background-color: #00959c;
      width: ${({ active }) => (active ? '100%' : '0')};
    }

    &:before {
      top: 0;
      left: 0;
    }
    &:after {
      bottom: 0;
      right: 0;
    }
    &:hover,
    .current a {
      color: #00959c;
    }
    /* &:hover:before, */
    &:hover:after {
      width: 100%;
    }
  }
  /* &:after {
    display: ${({ active }) => (active ? 'block' : 'none')};
    content: '';
    width: 100%;
    height: 0.3rem;
    background-color: #00959c;
    position: absolute;
    bottom: -1.9rem;
    left: 0;
  } */
  &:last-child {
    margin-right: 0;
  }
  /* &:hover {
    color: #00959c;
    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 0.3rem;
      background-color: #00959c;
      position: absolute;
      bottom: -1.9rem;
      left: 0;
    }
  } */
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const AvatarTextInfoColumn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px 1rem;
  color: rgb(126, 147, 149);
  font-size: 0.8rem;
  p:first-child {
    font-weight: bold;
  }
`;

export const PerfilOpen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 2.5rem;
  font-size: 0.8rem;
  color: rgb(126, 147, 149);

  span {
    font-size: 0.8rem;
    font-weight: 600;
  }

  button {
    background: rgb(126, 147, 149) none no-repeat scroll 0% 0% padding-box;
    border-radius: 1.5rem;
    opacity: 1;
    border: 0rem none;
    color: rgb(255, 255, 255);
    padding: 0.5rem 1.5rem;
    margin-top: 1rem;
    width: 100%;
  }
`;
