import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import api from '../../../../_core/api';
import { Shopping } from '../../../../_main/contexts/inadimplencia';

import { InadDateRegister } from './index';
import { failure, handleErrors, success } from '../../../../_core/services/toast';
import { Button, Form, Input, Select, Option } from '../../../../_core/_components';

type Props = {
  open: boolean;
  onClose: () => void;
  shoppings: Shopping[];
  selectedRegister?: InadDateRegister;
};

const DataCorteModal: React.FC<Props> = ({ open, onClose, shoppings, selectedRegister }: Props) => {
  const [dataCorte, setDataCorte] = useState<string>('');
  const [dataPrevia, setDataPrevia] = useState<string>('');
  const [periodo, setPeriodo] = useState<string>('');
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedRegister) {
      setDataCorte(moment(selectedRegister.cuttingDate).format('DD/MM/YYYY'));
      setDataPrevia(moment(selectedRegister.previewDate).format('DD/MM/YYYY'));
      setPeriodo(selectedRegister.period.replace('-', '/'));
      const registerShoppings = shoppings.filter(s => selectedRegister!.shoppingName.includes(s.label));
      setSelectedShoppings(registerShoppings);
    } else {
      resetModalState();
    }
  }, [selectedRegister]);

  const resetModalState = () => {
    setDataCorte('');
    setDataPrevia('');
    setPeriodo('');
    setSelectedShoppings([]);
  };

  const save = async () => {
    setLoading(true);
    // Criação de registro
    if (!selectedRegister) {
      if (!dataCorte || !dataPrevia || !periodo || !selectedShoppings) {
        failure('Preencha os campos obrigatórios!');
        setLoading(false);
        return;
      }
      try {
        selectedShoppings.forEach(async shop => {
          await api.post('/data-inadimplencia', {
            cuttingDate: moment(dataCorte, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            previewDate: moment(dataPrevia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            period: periodo,
            shoppingId: shop.value,
            shoppingName: shop.label,
            active: true,
          });
        });
        success('O registro foi salvo com sucesso!');
        resetModalState();
        onClose();
        setLoading(false);
      } catch (e) {
        handleErrors(e);
        setLoading(false);
      }
    } else {
      // edição de registro
      try {
        await api.put(`/data-inadimplencia/${selectedRegister.id}`, {
          cuttingDate: moment(dataCorte, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          previewDate: moment(dataPrevia, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          period: periodo,
          shoppingId: selectedShoppings![0].value,
          shoppingName: selectedShoppings![0].label,
          active: true,
          id: selectedRegister.id,
        });
        success('O registro foi atualizado com sucesso!');
        resetModalState();
        onClose();
        setLoading(false);
      } catch (e) {
        handleErrors(e);
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar Data Corte e Prévia
        </span>
        <Grid>
          <Form
            items={[
              <Input label="Data Corte" placeholder="DD/MM/AAAA" state={[dataCorte, setDataCorte]} />,
              <Input label="Data Prévia" placeholder="DD/MM/AAAA" state={[dataPrevia, setDataPrevia]} />,
              <Input label="Período" placeholder="MM/AAAA" state={[periodo, setPeriodo]} />,
              <Select options={shoppings} state={[selectedShoppings, setSelectedShoppings]} placeholder="Shoppings" />,
            ]}
            submitButton={<Button onClick={save} text="Salvar" disabled={loading} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DataCorteModal;
