import React, { useCallback, useContext, useMemo } from 'react';
import { Route as ReactDOMRoute, RouteProps as ReactDOMRouteProps, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/auth';

interface RouteProps extends ReactDOMRouteProps {
  path: string;
  component: React.ComponentType;
}

export const AuthRoute: React.FC<RouteProps> = ({ component: Component, path }) => {
  const { routes } = useContext(AuthContext);

  const allowAccess = useMemo(() => {
    if (routes) {
      return routes.find(modulo => modulo.menus.find(menu => menu.url === path));
    }
    return undefined;
  }, [routes]);

  const render = useCallback(() => {
    if (allowAccess) {
      return <Component />;
    }

    return <Redirect to={{ pathname: '/' }} />;
  }, [allowAccess]);

  return <ReactDOMRoute exact path={path} render={render} />;
};
