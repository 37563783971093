import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { Container } from './styles';
import api from '../../../_core/api';
import { AuthContext } from '../../../_main/contexts/auth';
import { handleErrors, warning } from '../../../_core/services/toast';
import { STATUSES } from './constants';
import Table from '../../../_core/_components/table';
import { Select, Input, Button, Option, Form } from '../../../_core/_components';

export const AcompanhamentoWorkflow: React.FC = () => {
  const { id, shoppings: profileShoppings } = useContext(AuthContext);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<Option[]>(STATUSES);
  const [ciclo, setCiclo] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);

  const getInfo = async () => {
    setLoading(true);
    try {
      const responseInfo = await api.post('workflow/serasa/serasa-shopping/acompanhar-shopping', {
        shoppings: selectedShoppings.map(e => e.value),
        idCiclo: ciclo,
        codigoStatus: selectedStatuses.map(e => e.value),
        idUsuario: id,
      });
      if (_.isEmpty(responseInfo.data.content)) {
        warning('Não obteve resultados.');
      } else {
        setData(responseInfo.data.content);
      }
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
      setSelectedShoppings(listShopping);
    }
  }, [profileShoppings]);

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => ({
        nmShopping: item.shopping.nmShopping,
        quantidadeBoletos: item.quantidadeBoletos,
        quantidadeFiador: item.quantidadeFiador,
        quantidadeExcecao: item.quantidadeExcecao,
        nome: item.statusWorkflow.nome,
      }));
    }
    return [];
  }, [data]);

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Container>
        <Form
          items={[
            <Select state={[selectedShoppings, setSelectedShoppings]} placeholder="Shoppings" options={shoppings} />,
            <Select state={[selectedStatuses, setSelectedStatuses]} placeholder="Status" options={STATUSES} />,
            <Input label="Ciclo" state={[ciclo, setCiclo]} disabled />,
          ]}
          submitButton={<Button text="Pesquisar" onClick={getInfo} />}
        />
        {renderedData.length > 0 && (
          <Table
            columns={[
              { label: 'Shopping', key: 'nmShopping', orderable: true },
              { label: 'Boletos', key: 'quantidadeBoletos', orderable: true },
              { label: 'Fiadores', key: 'quantidadeFiador', orderable: true },
              { label: 'Justificativas', key: 'quantidadeExcecao', orderable: true },
              { label: 'Status', key: 'nome', orderable: true },
            ]}
            data={renderedData}
          />
        )}
      </Container>
    </>
  );
};
