import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@material-ui/core';

type Props = {
  handleCancel: () => void;
  handleOk: () => void;
  open: boolean;
  text: string;
};

const ConfirmationModal: React.FC<Props> = ({ handleCancel, handleOk, open, text }: Props) => {
  return (
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Confirmação
        </span>
        <DialogContentText>{text}</DialogContentText>
        <DialogActions>
          <Button onClick={handleCancel} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleOk} color="primary">
            Confirmar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
