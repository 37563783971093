import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Autenticacao } from '../../modules/autenticacao';
import AuthRoutes from './auth';
import { AuthContext } from '../contexts/auth';
import { Layout } from '../layout';

const Routes: React.FC = () => {
  const { userName } = useContext(AuthContext);

  return userName ? (
    <Layout>
      <AuthRoutes />
    </Layout>
  ) : (
    <Switch>
      <Route path="/" exact component={Autenticacao} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
