import React from 'react';

// import { Container } from './styles';

const SVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7.379"
    height="5.534"
    viewBox="0 0 7.379 5.534"
  >
    <path
      id="Caminho_7347"
      data-name="Caminho 7347"
      d="M8.767,13.362,6,10.6l.922-.922,1.845,1.845,3.689-3.689.922.922Z"
      transform="translate(-6 -7.828)"
      fill="#31c6d3"
    />
  </svg>
);

export default SVG;
