import React, { useMemo, useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { Intercompany } from './interfaces';
import { Button, Form, Input, SingleSelect } from '../../../../_core/_components';

type ModalProps = {
  onClose: () => void;
  onSubmit: (values: any) => void;
  values?: Intercompany;
};

const IS_INTERCOMPANY_OPTIONS = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
];

const Modal: React.FC<ModalProps> = ({ onClose, onSubmit, values }) => {
  const [id] = useState(values?.id ?? '');
  const [cnpj, setCnpj] = useState(values?.cnpj ?? '');
  const [brand, setBrand] = useState(values?.brand ?? '');
  const [luc, setLuc] = useState(values?.luc ?? '');
  const [isIntercompany, setisIntercompany] = useState(values?.isIntercompany ?? true);

  const handleSubmit = () => onSubmit({ id, cnpj, brand, luc, isIntercompany });

  const isValid = useMemo(() => cnpj && brand && luc, [cnpj, brand, luc]);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {values ? 'Editar' : 'Criar'} Intercompany
        </span>
        <Grid>
          <Form
            items={[
              <Input label="CNPJ" placeholder="CNPJ" state={[cnpj, setCnpj]} />,
              <Input label="Nome fantasia" placeholder="Nome fantasia" state={[brand, setBrand]} />,
              <Input label="LUC" placeholder="LUC" state={[luc, setLuc]} />,
              <SingleSelect
                options={IS_INTERCOMPANY_OPTIONS}
                state={[
                  IS_INTERCOMPANY_OPTIONS.find(e => e.value === isIntercompany),
                  option => setisIntercompany(option?.value as boolean),
                ]}
              />,
            ]}
            submitButton={<Button disabled={!isValid} text="Salvar" onClick={handleSubmit} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
