import React, { CSSProperties } from 'react';
import MaterialButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

interface Props {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  styles?: CSSProperties;
}

export function Button({ text, onClick, disabled = false, styles }: Props): JSX.Element {
  const CustomButton = withStyles(() => ({
    root: {
      color: '#fff',
      borderRadius: 30,
      ...styles,
    },
  }))(MaterialButton);

  return (
    <CustomButton variant="contained" color="primary" disableElevation onClick={onClick} disabled={disabled}>
      {text}
    </CustomButton>
  );
}
