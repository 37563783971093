import styled from 'styled-components/macro';

export const Container = styled.div`
  > h2 {
    color: #7e9395;

    margin-bottom: 1rem;
    font-weight: 600;
  }

  .approveNegative__multiselect {
    padding: 0.9rem 2.2rem;
    p {
    }
  }
`;
