import { Option } from '../../../../_core/_components';

// An enum with all the types of actions to use in our reducer
export type EditUserProperty =
  | 'SET_USER'
  | 'NAME'
  | 'USERNAME'
  | 'EMAIL'
  | 'LOTACAO'
  | 'PROFILE'
  | 'SHOPPINGS'
  | 'ACTIVE';

type EditUserState = {
  userId?: number;
  name: string;
  userName: string;
  email: string;
  lotacao: string;
  profile: Option;
  shoppings: Option[];
  ativo: boolean;
};

export const INITIAL_STATE = {
  userId: undefined,
  name: '',
  userName: '',
  email: '',
  lotacao: '',
  profile: undefined,
  shoppings: [],
  ativo: true,
};

export function reducer(state: EditUserState, { type, payload }: { type: EditUserProperty; payload: any }): any {
  switch (type) {
    case 'SET_USER':
      return {
        ...payload,
      };
    case 'NAME':
      return {
        ...state,
        name: payload,
      };
    case 'USERNAME':
      return {
        ...state,
        userName: payload,
      };
    case 'EMAIL':
      return {
        ...state,
        email: payload,
      };
    case 'LOTACAO':
      return {
        ...state,
        lotacao: payload,
      };
    case 'PROFILE':
      return {
        ...state,
        profile: payload,
      };
    case 'SHOPPINGS':
      return {
        ...state,
        shoppings: payload,
      };
    case 'ACTIVE':
      return {
        ...state,
        ativo: payload,
      };
    default:
      return state;
  }
}
