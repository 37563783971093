import React, { useEffect, useRef, useState } from 'react';
import ReactSelect, { CSSObjectWithLabel, SingleValue } from 'react-select';
import { InputLabel, FormControl, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Option } from './types';

interface Props {
  state: [Option | undefined, (option: Option | undefined) => void];
  placeholder?: string;
  options: Option[];
  disabled?: boolean;
  isClearable?: boolean;
  controlStyle?: CSSObjectWithLabel;
  containerStyles?: CSSObjectWithLabel;
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    position: 'relative',
  },
  label: {
    backgroundColor: 'white',
    padding: '0 4px',
    transform: 'translate(14px, -9px) scale(0.75)',
    transformOrigin: 'top left',
    zIndex: 1,
    position: 'absolute',
    transition: 'color 0.2s',
  },
}));

export function SingleSelect({
  state: [value, setValue],
  placeholder = 'Selecione',
  options,
  disabled = false,
  isClearable = true,
  controlStyle = {},
  containerStyles = {},
}: Props): JSX.Element {
  const classes = useStyles();
  const ref = useRef<any>(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (value === undefined && ref.current?.clearValue) {
      ref.current.clearValue();
    }
  }, [value]);

  const onChange = (option: SingleValue<Option>) => {
    setValue(option || undefined);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel shrink className={classes.label} style={{ color: isFocused ? '#31c6d3' : 'rgba(0, 0, 0, 0.54)' }}>
        {placeholder}
      </InputLabel>
      <ReactSelect
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        hideSelectedOptions={false}
        isMulti={false}
        isSearchable
        isClearable={isClearable}
        options={options}
        placeholder=""
        menuPortalTarget={document.body}
        styles={{
          container: base => ({
            ...base,
            ...containerStyles,
          }),
          control: (base, state) => ({
            ...base,
            height: 40,
            minHeight: 40,
            minWidth: '10rem',
            borderRadius: 30,
            boxShadow: state.isFocused ? '0 0 0 1px #31c6d3' : 'none',
            borderColor: state.isFocused ? '#31c6d3' : 'hsl(0, 0%, 80%)',
            '&:hover': {
              borderColor: state.isFocused ? '#31c6d3' : 'hsl(0, 0%, 80%)',
            },
            ...controlStyle,
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
          valueContainer: base => ({
            ...base,
            flexWrap: 'nowrap',
          }),
        }}
        noOptionsMessage={() => ''}
        isDisabled={disabled}
        ref={ref}
      />
      <FormHelperText />
    </FormControl>
  );
}
