import styled from 'styled-components';

import DatePicker from 'react-datepicker';

export const Container = styled.div`
  h1 {
    font-size: 20px;
    font-weight: bold;
    color: #7e9395;
    padding: 20px 0;
  }
  label {
    font-size: 14px;
    color: #7e9395;
    margin: 0 10px;
  }
  a {
    background: #00959c 0% 0% no-repeat padding-box;
    box-shadow: 3px 3px 6px #7e939526;
    border-radius: 50px;
    width: 287px;
    height: 39px;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    padding: 10px;
  }
`;

export const DateArea = styled.div``;

export const FilterBar = styled.div`
  background-color: #fff;
  padding: 22px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  select {
    width: 350px;
  }
`;

export const RadioArea = styled.div`
  display: flex;
  align-items: center;
  span {
    top: -6px;
    left: 13px;
  }
`;

export const Dpicker = styled(DatePicker)`
  border: 1px solid #7e93954d;
  border-radius: 20px;
  width: 129px;
  height: 39px;
  padding: 8px 10px;
  text-align: center;
  color: #7e9395;
`;

export const TableContainer = styled.div`
  margin: 65px 0;
  h1 {
    color: #7e9395;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0px;
    margin: 60px 0 30px 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    thead {
      tr {
        opacity: 1;
        cursor: pointer;
        white-space: nowrap;
        th {
          background-color: #f2f4f4;
          text-align: left;
          color: #7e9395;
          font-weight: 600;
          font-size: 12px;
          padding: 10px;
          img {
            width: 13px !important;
            height: 7px !important;
            margin: auto !important;
            margin-left: 4px !important;
          }
          .semOrdenacao {
            display: none;
          }
          .crescente {
            transform: rotate(-180deg);
          }
          .decrescente {
          }
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:nth-child(odd) {
          background: #ffffff 0% 0% no-repeat padding-box;
          opacity: 1;
        }
        &:nth-child(even) {
          background: #e5e9ea 0% 0% no-repeat padding-box;
          opacity: 1;
        }
        td {
          padding: 15px 10px;
          text-align: left;
          font: normal normal normal 12px/17px Open Sans;
          letter-spacing: 0px;
          color: #3f3f3f;
          opacity: 1;
        }
      }
    }
    tfoot {
      tr {
        td {
          text-align: left;
          font: normal normal 600 14px Open Sans;
          letter-spacing: 0.7px;
          color: #00959c;
          text-transform: uppercase;
          opacity: 1;
          white-space: nowrap;
          padding: 15px 8px;
          p {
            text-align: right;
            font: normal normal 600 14px Open Sans;
            letter-spacing: 0.7px;
            color: #7e9395;
            text-transform: uppercase;
            opacity: 1;
            white-space: nowrap;
          }

          div {
            margin-top: 2rem;
          }

          button {
            border-radius: 20px;
            border: 0;
            background-color: #fff;
            color: #00959c;
            font-weight: 600;
            padding: 8px 15px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 14px;
            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  button {
    border-radius: 20px;
    border: 0;
    background-color: #fff;
    color: #00959c;
    font-weight: 600;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    img {
      margin-left: 10px;
    }
  }
  span {
    color: #00959c;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;
