import { Contato, Endereco, Shopping } from './types';

export type EditShoppingProperty =
  | 'SET_SHOPPING'
  | 'UNSET_SHOPPING'
  | 'SET_NOME'
  | 'SET_ORIGEM'
  | 'SET_DATA_INICIO'
  | 'SET_DATA_FIM'
  | 'SET_CONTATOS'
  | 'SET_CEP'
  | 'SET_ENDERECO'
  | 'SET_CIDADE'
  | 'SET_UF'
  | 'SET_CONTATOS';

export const CONTATO: Contato = {
  tipo: 'EMAIL',
  contato: '',
};

export const ENDERECO: Endereco = {
  uf: '',
  cidade: '',
  cep: '',
  logradouro: '',
};

export const INITIAL_STATE = {
  nome: '',
  dataInicio: '',
  dataFim: '',
  origem: 'ORACLE',
  endereco: {
    logradouro: '',
    cep: '',
    cidade: '',
    uf: '',
  },
  contatos: [CONTATO],
};

export function reducer(state: Shopping, { type, payload }: { type: EditShoppingProperty; payload?: any }): any {
  switch (type) {
    case 'SET_SHOPPING':
      return {
        ...payload,
      };
    case 'UNSET_SHOPPING':
      return undefined;
    case 'SET_NOME':
      return {
        ...state,
        nome: payload,
      };
    case 'SET_ORIGEM':
      return {
        ...state,
        origem: payload,
      };
    case 'SET_DATA_INICIO':
      return {
        ...state,
        dataInicio: payload,
      };
    case 'SET_DATA_FIM':
      return {
        ...state,
        dataFim: payload,
      };
    case 'SET_CONTATOS':
      return {
        ...state,
        contatos: payload,
      };
    case 'SET_CEP':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          cep: payload,
        },
      };
    case 'SET_ENDERECO':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          logradouro: payload,
        },
      };
    case 'SET_CIDADE':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          cidade: payload,
        },
      };
    case 'SET_UF':
      return {
        ...state,
        endereco: {
          ...state.endereco,
          uf: payload,
        },
      };
    default:
      return state;
  }
}
