import React, { useMemo, useState } from 'react';
import { Button } from '@material-ui/core';
import { Form, Title, ModalDialog, ModalDialogContent } from './styles';
import { TipoUnidade } from '../interfaces';

type ModalProps = {
  options: string[];
  onClose: () => void;
  onSubmit: (values: any) => void;
  values?: TipoUnidade;
};

const Modal: React.FC<ModalProps> = ({ onClose, onSubmit, values, options }) => {
  const [id] = useState(values?.id ?? '');
  const [businessUnitType, setBusinessUnitType] = useState(values?.businessUnitType ?? '');
  const [transactionType, setTransactionType] = useState(values?.transactionType ?? '');
  const [revenueType, setRevenueType] = useState(values?.revenueType ?? '');
  const [debtBusinessUnitType, setDebtBusinessUnitType] = useState(values?.debtBusinessUnitType ?? '');

  const handleSubmit = () => onSubmit({ id, businessUnitType, transactionType, revenueType, debtBusinessUnitType });

  const isValid = useMemo(() => businessUnitType && transactionType && debtBusinessUnitType, [
    businessUnitType,
    transactionType,
    debtBusinessUnitType,
  ]);

  return (
    <ModalDialog open onClose={onClose} fullWidth maxWidth="md">
      <ModalDialogContent>
        <Title>{values ? 'Editar' : 'Criar'} Tipo de Unidade de Negócio Inadimplência</Title>
        <Form>
          <div>
            <label>Tipo unidade de Negócio</label>
            <input type="text" onChange={event => setBusinessUnitType(event.target.value)} value={businessUnitType} />
          </div>
          <div>
            <label>Tipo de Transação</label>
            <input type="text" onChange={event => setTransactionType(event.target.value)} value={transactionType} />
          </div>
          <div>
            <label>Tipo de receita</label>
            <input type="text" onChange={event => setRevenueType(event.target.value)} value={revenueType} />
          </div>
          <div>
            <label>Tipo de unidade de negócio de inadimplência</label>
            <input
              type="text"
              onChange={event => setDebtBusinessUnitType(event.target.value)}
              value={debtBusinessUnitType}
            />
          </div>
          <Button disabled={!isValid} color="primary" onClick={handleSubmit}>
            Salvar
          </Button>
        </Form>
      </ModalDialogContent>
    </ModalDialog>
  );
};

export default Modal;
