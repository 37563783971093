import React from 'react';
import LoaderIcon from '../../../assets/loader.jsx';
import { LoaderWrapper } from './styles';

export type Props = {
  isEnabled: boolean;
};

export const FullScreenLoading: React.FC<Props> = ({ isEnabled }) => {
  return isEnabled ? (
    <LoaderWrapper>
      <LoaderIcon />
    </LoaderWrapper>
  ) : null;
};
