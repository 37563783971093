import React, { useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { Button, Form, Input, SingleSelect, Option } from '../../../../_core/_components';

type Props = {
  onSave: (payload: any) => void;
  onClose: () => void;
  selectedRegister?: any;
  tipoDivida: Option[];
};

const Modal: React.FC<Props> = ({ onSave, onClose, selectedRegister, tipoDivida }: Props) => {
  const [conta, setConta] = useState(selectedRegister?.conta ?? '');
  const [idTipoDivida, setIdTipoDivida] = useState(selectedRegister?.idTipoDivida);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[
              <Input label="Conta" state={[conta, setConta]} />,
              <SingleSelect
                placeholder="Tipo dívida"
                state={[
                  tipoDivida.find(e => e.value === idTipoDivida),
                  option => setIdTipoDivida(option?.value as number),
                ]}
                options={tipoDivida}
              />,
            ]}
            submitButton={
              <Button
                onClick={() => onSave({ conta, idTipoDivida, ativo: true })}
                text="Salvar"
                disabled={!conta || !idTipoDivida}
              />
            }
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
