import React, { useState, useEffect, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import * as S from '../styles';
import api from '../../../../_core/api';
import { handleErrors } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Input, Option, PaginatedTable } from '../../../../_core/_components';
import Modal from './modal';

export type Rubrica = {
  id: number;
  rubric: string;
  active: boolean;
};

const ContaContabil: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [searchText, setSearchText] = useState('');
  const [selectedRegister, setSelectedRegister] = useState<any>(null);
  const [tipoDividaOptions, setTipoDividaOptions] = useState<Option[]>([]);

  useEffect(() => {
    getTipoDivida();
  }, []);

  useEffect(() => {
    getData();
  }, [page]);

  const getTipoDivida = async () => {
    const { data: dataTipoDivida } = await api.get('/parametros/ajuizamento/tipo-divida');
    setTipoDividaOptions(dataTipoDivida.data.map((e: any) => ({ value: e.id, label: e.descricao })));
  };

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/parametros/ajuizamento/conta-contabil?page=${page}`);
      setRegisterList(data.data);
      setPageInfo(data.pageInfo);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    if (searchText) {
      const filtered = registerList.filter(reg => reg.rubric.toUpperCase().includes(searchText.toUpperCase()));
      return filtered;
    }
    return registerList;
  }, [searchText, registerList]);

  const renderedData = useMemo(() => {
    if (filteredItems.length > 0) {
      return filteredItems.map(item => ({
        ...item,
        editar: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.id.toString()}
            onClick={() => setSelectedRegister(item)}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [filteredItems]);

  const onSave = async (payload: any) => {
    setLoading(true);
    try {
      if (selectedRegister.id) {
        await api.put(`/parametros/ajuizamento/conta-contabil/${selectedRegister.id}`, payload);
      } else {
        await api.post('/parametros/ajuizamento/conta-contabil', payload);
      }
      setSelectedRegister(null);
      const { data } = await api.get(`/parametros/ajuizamento/conta-contabil?page=${page}`);
      setRegisterList(data.data);
      setPageInfo(data.pageInfo);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <S.ContentContainer>
      <FullScreenLoading isEnabled={loading} />
      {selectedRegister && (
        <Modal
          onSave={onSave}
          onClose={() => setSelectedRegister(null)}
          tipoDivida={tipoDividaOptions}
          selectedRegister={selectedRegister}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField>
            <Input label="Buscar" state={[searchText, setSearchText]} />
          </S.SearchField>
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setSelectedRegister({})} />
      </S.TopBar>
      {renderedData.length > 0 && (
        <PaginatedTable
          columns={[
            { label: 'Conta contábil', key: 'conta' },
            { label: 'Tipo dívida', key: 'nometipoDivida' },
            { label: 'Editar', key: 'editar' },
          ]}
          data={renderedData}
          pagination={pageInfo}
          onChangePage={setPage}
        />
      )}
    </S.ContentContainer>
  );
};

export default ContaContabil;
