import { NegotiationUseCase } from '../interfaces';

export default class ShoppingUseCase implements NegotiationUseCase {
  readonly order: number[];
  private readonly shoppingName: string;
  private readonly shoppingId: number;

  constructor(shoppingId: number, shoppingName: string) {
    this.order = [5, 2, 3];
    this.shoppingId = shoppingId;
    this.shoppingName = shoppingName;
  }

  async fillFields(): Promise<Array<any>> {
    return new Promise(resolve =>
      resolve([
        [
          {
            value: this.shoppingId,
            label: this.shoppingName,
          },
        ],
        undefined,
        undefined,
        undefined,
        new Date(),
        undefined,
        undefined,
        '',
      ]),
    );
  }

  validateFields(values: any[], step: number): boolean[] {
    const validateArr: boolean[] = values.map(() => true);
    const required = this.order.every(e => values[e]?.length > 0);
    if (required) {
      const indexOf = this.order.indexOf(step);
      const slcArr = this.order.slice(indexOf);
      this.order.forEach(e => {
        validateArr[e] = slcArr.includes(e);
      });
    } else {
      let finished = false;
      this.order.forEach(e => {
        if (!finished && (values[e] === undefined || values[e]?.length === 0)) {
          validateArr[e] = true;
          finished = true;
        } else {
          validateArr[e] = false;
        }
      });
    }
    validateArr[0] = false;
    validateArr[1] = false;
    return validateArr;
  }

  isValidSubmit(values: any[]): boolean {
    let valid = values.length >= 6;
    values.forEach((e, i) => {
      if ([1, 4, 7].includes(i) || valid === false) {
        return;
      } else if (e == undefined || e.length == 0) {
        valid = false;
      }
    });
    return valid;
  }
}
