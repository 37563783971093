import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import MaskedInput from 'react-maskedinput';
import { AuthContext } from '../../../../_main/contexts/auth';
import api from '../../../../_core/api';
import Loader from '../../../../assets/loader';
import { NegotiationProps } from './interfaces';
import {
  ActionArea,
  NegotiationArea,
  BoxNegotiation,
  ButtonNegotiation,
  SelectActive,
  Dpicker,
  SelectModal,
  ModalContainer,
  ListCard,
  CloseSelectModal,
  SelectedCard,
  ClearButtonModal,
  Option,
} from './styles';
import { Button } from '../../../../_core/_components';

const INITIAL_VALUES = [undefined, undefined, undefined, undefined, new Date(), undefined, undefined, ''];

const Negotiation: React.FC<NegotiationProps> = ({ active, negotiation }) => {
  const { profile, userName } = useContext(AuthContext);
  const [values, setValues] = useState<any[]>(INITIAL_VALUES);
  const valuesRef = useRef<any[]>(INITIAL_VALUES);
  const [screenLoading, setScreenLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [index, setIndex] = useState(-1);
  const [input, setInput] = useState('');
  const [response, setResponse] = useState<any[]>([]);
  const [maxDate] = useState(new Date());
  const [step, setStep] = useState(negotiation.order[0]);
  const [onRemove, setOnRemove] = useState(-1);
  const [onSubmit, setOnSubmit] = useState(false);

  useEffect(() => {
    getFields();
  }, []);

  useEffect(() => {
    if (onRemove != -1 && negotiation.order.includes(onRemove)) {
      const remove = async () => {
        setScreenLoading(true);
        const arr = await refreshForward(onRemove);
        const nextIndex = negotiation.order.indexOf(onRemove);
        const nextStep = negotiation.order[nextIndex];
        setStep(nextStep);
        setOnRemove(-1);
        setValues(arr);
        setScreenLoading(false);
        valuesRef.current = arr;
      };
      remove();
    }
  }, [onRemove, values]);

  const shoppingUser = useMemo(() => profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING', [profile]);

  const getFields = async () => {
    setScreenLoading(true);
    const fields = await negotiation.fillFields();
    setValues(fields);
    valuesRef.current = fields;
    setStep(negotiation.order[0]);
    setScreenLoading(false);
  };

  const refreshForward = async (idx: number) => {
    const arr = [...values];
    const slcArr = negotiation.order;
    const arrRequests = [
      {
        request: api.get(`/filters/siscob-filters-information/shoppings?${getDinamicFilter(0)}`),
        index: 0,
      },
      {
        request: api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`),
        index: 2,
      },
      { request: api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`), index: 3 },
      { request: api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`), index: 5 },
    ];
    const response = await Promise.all(arrRequests.map(e => (slcArr.includes(e.index) ? e.request : null)));
    response.forEach((payload: any, i: number) => {
      if (idx === arrRequests[i].index) return;
      if (!slcArr.includes(arrRequests[i].index)) return;
      if (!payload) return;
      switch (i) {
        case 0: // Shoppings
          arr[0] = payload.data.map((item: any) => ({
            value: item.shoppingId.toString(),
            label: item.shopping,
          }));
          break;
        case 1: // Clientes
          arr[2] = payload.data.map((item: any) => ({
            value: item.cpfCnpj.toString(),
            label: item.customerName,
          }));
          break;
        case 2: // Lucs
          arr[3] = payload.data.map((item: any, i: number) => ({
            value: i.toString(),
            label: item.luc.toUpperCase(),
          }));
          break;
        case 3: // Nome fantasias
          arr[5] = payload.data.map((item: any) => ({
            value: item.toString(),
            label: item,
          }));
          break;
      }
    });
    return arr;
  };

  const getDinamicFilter = (index: number) => {
    const shoppings = values[0]?.length > 0 ? `shoppingIds=${values[0].map((item: any) => item.value)}` : '';
    const billingGroup = values[1]?.length > 0 ? `billingGroupIds=${values[1][0].value}` : '';
    const cpfsCnpjs = values[2]?.length > 0 ? `cpfsCnpjs=${values[2].map((item: any) => item.value).join(',')}` : '';
    const lucs = values[3]?.length > 0 ? `lucs=${values[3].map((item: any) => item.label)}` : '';
    const brands = values[5]?.length > 0 ? `brands=${values[5].map((item: any) => item.value)}` : '';
    switch (index) {
      case 0:
        return [billingGroup, cpfsCnpjs, lucs, brands].filter(e => e).join('&');
      case 1:
        return [shoppings, cpfsCnpjs, lucs, brands].filter(e => e).join('&');
      case 2:
        return [shoppings, billingGroup, lucs, brands].filter(e => e).join('&');
      case 3:
        return [shoppings, billingGroup, cpfsCnpjs, brands].filter(e => e).join('&');
      case 5:
        return [shoppings, billingGroup, cpfsCnpjs, lucs].filter(e => e).join('&');
    }
  };

  const getShoppingsModal = async () => {
    const list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/shoppings?${getDinamicFilter(0)}`);
    response.data.map((item: any) => {
      list.push({
        value: item.shoppingId.toString(),
        label: item.shopping,
      });
    });
    return list;
  };

  const getBillingGroupsModal = async () => {
    const list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/billing-groups?${getDinamicFilter(1)}`);
    response.data.map((item: any) => {
      list.push({
        value: item.federationGroupId.toString(),
        label: item.federationGroupName,
      });
    });
    return list;
  };

  const getCustomersModal = async () => {
    const list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/clientes?${getDinamicFilter(2)}`);
    response.data.map((item: any) => {
      list.push({
        value: item.cpfCnpj.toString(),
        label: item.customerName,
      });
    });
    return list;
  };

  const getLucModal = async () => {
    const list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information?${getDinamicFilter(3)}`);
    response.data.forEach((item: any, i: number) => {
      if (shoppingUser && item.businessGroupId) return;
      list.push({
        value: i.toString(),
        label: item.luc.toUpperCase(),
      });
    });
    return list;
  };

  const getBrandsModal = async () => {
    const list: any[] = [];
    const response = await api.get(`/filters/siscob-filters-information/marcas?${getDinamicFilter(5)}`);
    response.data.map((item: any) => {
      list.push({
        value: item.toString(),
        label: item,
      });
    });
    return list;
  };

  const getNegotiationsReturnModal = async () => {
    const { data } = await api.get(`/filters/negotiations-return`);
    const list: any[] = [];
    data.map((item: any) => {
      list.push({
        value: item.returnId.toString(),
        label: item.returnName.toUpperCase(),
      });
    });
    return list.sort((item1, item2) => {
      if (item1.label > item2.label) return 1;
      if (item1.label < item2.label) return -1;
      return 0;
    });
  };

  const handleClickModal = async (index: number) => {
    if (!validationArr[index]) return;
    setModalLoading(true);
    setIndex(index);
    let arrResponse = [...response];
    let res = null;
    switch (index) {
      case 0:
        res = await getShoppingsModal();
        break;
      case 1:
        res = await getBillingGroupsModal();
        break;
      case 2:
        res = await getCustomersModal();
        break;
      case 3:
        res = await getLucModal();
        break;
      case 5:
        res = await getBrandsModal();
        break;
      case 6:
        res = await getNegotiationsReturnModal();
        break;
    }
    arrResponse[index] = res;
    setResponse(arrResponse);
    setModalLoading(false);
  };

  const handleCloseModal = async () => {
    const idx = index;
    setIndex(-1);
    setInput('');
    setModalLoading(false);
    if (JSON.stringify(values[idx]) != JSON.stringify(valuesRef.current[idx]) && idx != 6) {
      const nextIndex = negotiation.order.indexOf(idx);
      const nextStep = negotiation.order[nextIndex];
      setStep(nextStep);
      console.log('step', nextStep);
      setScreenLoading(true);
      const arr = await refreshForward(idx);
      setValues(arr);
      setScreenLoading(false);
      valuesRef.current = arr;
    }
  };

  const renderOptionModalGroup = (item: any, index: number, indexModalGroup: number) => {
    switch (index) {
      case 0:
      case 1:
      case 2:
        return (
          <Option>
            {item.label}
            {!validationArr[indexModalGroup] ? null : (
              <button onClick={() => handleRemoveGroupItem(index, indexModalGroup)}>x</button>
            )}
          </Option>
        );
      case 3:
        return <Option>{`+${values[indexModalGroup].length - 3}`}</Option>;
      default:
        return null;
    }
  };

  const clearAll = useCallback(() => {
    const arr = [...values];
    arr[index] = undefined;
    setValues(arr);
  }, [values]);

  const handleSelectItem = useCallback(
    (label: string, value: string) => {
      const arr = [...values];
      if (index === 6 && arr[index]?.length > 0) return;
      if (arr[index]?.find((item: any) => item.value === value)) return;
      if (arr[index]?.length > 0) {
        arr[index].push({ label, value });
      } else {
        arr[index] = [{ label, value }];
      }
      setValues(arr);
    },
    [values, index],
  );

  const handleRemoveItem = useCallback(
    (i: number) => {
      const arr = [...values];
      arr[index] = values[index].filter((e: any, idx: number) => idx !== i);
      setValues(arr);
    },
    [values, index],
  );

  const handleRemoveGroupItem = useCallback(
    (index: number, groupIndex) => {
      const arr = [...values];
      arr[groupIndex] = values[groupIndex].filter((e: any, idx: number) => idx !== index);
      setValues(arr);
      setOnRemove(groupIndex);
    },
    [values],
  );

  const selectedModalItems = useMemo(() => {
    const arr = [...values];
    if (index != -1 && !modalLoading) {
      return (
        <>
          <SelectedCard>
            <h5>Selecionados:</h5>
            {arr[index]?.map((item: any, index: number) => (
              <span>
                {item.label}
                <button onClick={() => handleRemoveItem(index)}>x</button>
              </span>
            ))}
          </SelectedCard>
          <ClearButtonModal onClick={() => clearAll()}>limpar</ClearButtonModal>
        </>
      );
    }
    return null;
  }, [index, modalLoading, values, handleRemoveItem]);

  const modalListContent = useMemo(() => {
    if (modalLoading) {
      return (
        <div className="loaderUser">
          <Loader />
        </div>
      );
    } else {
      return (
        <ul>
          {response[index]?.length > 0 &&
            response[index]
              .filter((item: any) => item.label.includes(input.toUpperCase()))
              .map((itemFiltered: any) => (
                <li onClick={() => handleSelectItem(itemFiltered.label, itemFiltered.value)}>{itemFiltered.label}</li>
              ))}
        </ul>
      );
    }
  }, [modalLoading, handleSelectItem, input]);

  const validationArr = useMemo(() => negotiation.validateFields(values, step), [values, step]);

  const handleChangeValues = (value: any, i: number) => {
    const arr = [...values];
    arr[i] = value;
    setValues(arr);
  };

  function converterData(data: any): string {
    let dia = `${data.getDate()}`;
    let mes = `${data.getMonth() + 1}`;
    const ano = `${data.getFullYear()}`;

    const pad = '00';
    dia = pad.substring(0, pad.length - dia.length) + dia;
    mes = pad.substring(0, pad.length - mes.length) + mes;

    return `${ano}-${mes}-${dia}`;
  }

  const handleSubmit = async () => {
    if (!isValidSubmit) {
      alert('Preencha todos os campos!');
      return;
    }
    setOnSubmit(true);
    setScreenLoading(true);
    const lucs = values[3].map((item: any) => item.label).join(',');
    const cpfcnpjs = values[2].map((item: any) => item.value).join(',');

    let response = null;
    if (disableShoppingButton) {
      response = await api.get(
        `/filters/siscob-filters-information?shoppingIds=${values[0][0].value}&lucs=${lucs}&cpfscnpjs=${cpfcnpjs}`,
      );
    } else {
      response = await api.get(
        `/filters/siscob-filters-information?billingGroupIds=${values[1][0].value}&lucs=${lucs}`,
      );
    }
    const dataPost: any = [];
    response.data.forEach((item: any) => {
      const isCliente = values[2].find((e: any) => e.value == item.cnpjCpf);
      const isLuc = values[3].find((e: any) => e.label == item.luc);
      if (isCliente && isLuc) {
        dataPost.push({
          cpfCnpj: item.cnpjCpf,
          billingGroupId: disableShoppingButton ? null : values[1][0].value,
          shoppingId: item.shoppingId,
          luc: item.luc,
          brand: item.brand,
          active: 1,
          userIdentifier: userName,
          contactDate: converterData(values[4]),
          additionalInformation: values[7],
          negotiationReturnId: values[6][0].value,
        });
      }
    });
    api
      .post(`/negotiation-history/negotiation-histories`, dataPost)
      .then(
        response => {
          alert('Registrado com sucesso!');
          getFields();
        },
        error => {
          alert('Ocorreu um erro, tente novamente!');
        },
      )
      .finally(() => {
        setScreenLoading(false);
        setOnSubmit(false);
      });
  };

  const isValidSubmit = useMemo(() => {
    return negotiation.isValidSubmit(values);
  }, [values]);

  const disableShoppingButton = useMemo(() => {
    return profile === 'COBRANÇA SHOPPING' || profile === 'APROVADOR SHOPPING';
  }, [profile]);

  return (
    <>
      <NegotiationArea active={active}>
        <h2>Registrar Negociação</h2>
        <BoxNegotiation>
          <ButtonNegotiation isSelect={step === 0} active={validationArr[0]} onClick={() => handleClickModal(0)}>
            Shopping
          </ButtonNegotiation>
          <SelectActive>
            {values[0]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 0))}
          </SelectActive>
        </BoxNegotiation>
        <BoxNegotiation>
          <ButtonNegotiation isSelect={step === 1} active={validationArr[1]} onClick={() => handleClickModal(1)}>
            Grupo de Cobrança
          </ButtonNegotiation>
          <SelectActive>
            {values[1]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 1))}
          </SelectActive>
        </BoxNegotiation>
        <BoxNegotiation>
          <ButtonNegotiation isSelect={step === 5} active={validationArr[5]} onClick={() => handleClickModal(5)}>
            Marca
          </ButtonNegotiation>
          <SelectActive>
            {values[5]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 5))}
          </SelectActive>
        </BoxNegotiation>

        <BoxNegotiation>
          <ButtonNegotiation isSelect={step === 3} active={validationArr[3]} onClick={() => handleClickModal(3)}>
            LUC
          </ButtonNegotiation>
          <SelectActive>
            {values[3]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 3))}
          </SelectActive>
        </BoxNegotiation>
        <BoxNegotiation>
          <ButtonNegotiation isSelect={step === 4} active={true}>
            Data de contato
          </ButtonNegotiation>
          <Dpicker
            value={values[4].toLocaleString()}
            onChange={(date: any) => handleChangeValues(date, 4)}
            dateFormat="dd/MM/yyyy"
            maxDate={maxDate}
            customInput={<MaskedInput mask="11/11/1111" placeholder="dd/MM/yyyy" />}
          />
        </BoxNegotiation>
        <BoxNegotiation>
          <ButtonNegotiation isSelect={step === 2} active={validationArr[2]} onClick={() => handleClickModal(2)}>
            Cliente
          </ButtonNegotiation>
          <SelectActive>
            {values[2]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 2))}
          </SelectActive>
        </BoxNegotiation>
        <BoxNegotiation>
          <ButtonNegotiation isSelect={step === 6} active={validationArr[6]} onClick={() => handleClickModal(6)}>
            Retorno da negociação
          </ButtonNegotiation>
          <SelectActive>
            {values[6]?.map((item: any, index: number) => renderOptionModalGroup(item, index, 6))}
          </SelectActive>
        </BoxNegotiation>
        <BoxNegotiation>
          <ButtonNegotiation isSelect={step === 7} active={true}>
            Descrição da Negociação
          </ButtonNegotiation>
          <textarea value={values[7]} onChange={e => handleChangeValues(e.target.value, 7)} />
        </BoxNegotiation>
        <ActionArea>
          <Button text="Fazer uma negociação" onClick={handleSubmit} />
          <Button text="Limpar" onClick={getFields} />
        </ActionArea>
      </NegotiationArea>
      <>
        <SelectModal active={screenLoading} />
        <SelectModal active={index != -1} onClick={handleCloseModal} />
        <ModalContainer active={index != -1}>
          <CloseSelectModal onClick={handleCloseModal}>X</CloseSelectModal>
          <h3>Selecione </h3>
          <input
            disabled={false}
            type="text"
            onChange={(e: any) => setInput(e.target.value)}
            value={input}
            placeholder="Digite"
          />
          {selectedModalItems}
          <ListCard>{modalListContent}</ListCard>
        </ModalContainer>
      </>
    </>
  );
};

export default Negotiation;
