import React from 'react';
import ISelectProps from './Iselect';
import { Container, SelectArea, SelectInput } from './Select.styles';

export const Select: React.FC<ISelectProps> = ({
  options,
  label,
  helperClass,
  onChange,
  onClick,
  name,
  selectedValue,
  hidden,
  disabled = false,
}) => {
  return (
    <Container className={helperClass} hidden={hidden}>
      {label}
      <SelectArea>
        <SelectInput name={name} onChange={onChange} onClick={onClick} disabled={disabled}>
          {options.map(option => (
            <option key={`${name}-${option.value}`} value={option.value} selected={selectedValue == option.value}>
              {option.label}
            </option>
          ))}
        </SelectInput>
      </SelectArea>
    </Container>
  );
};
