import React, { useState } from 'react';
import { baseURL } from '../../../_core/api';
import LoadingIcon from '../../../assets/loader.jsx';
import icon from '../../../assets/cloud-computing.svg';
import { Container, Content, ImportArea, InputGroup, LoadingBox, Sucess, Error } from './styles';

interface IDatePeriod {
  month: number;
  year: number;
}

export const ImportacoesInadimplencia: React.FC = () => {
  const [uploadingXls, setUploadingXls] = useState<boolean>(false);
  const [uploadingZip, setUploadingZip] = useState<boolean>(false);
  const [sucessXls, setSucessXls] = useState<boolean>(false);
  const [sucessZip, setSucessZip] = useState<boolean>(false);
  const [errorXls, setErrorXls] = useState<string>('');
  const [errorZip, setErrorZip] = useState<string>('');
  const [dateUploadXls, setDateUploadXls] = useState<string>('');
  const [dateUploadZip, setDateUploadZip] = useState<IDatePeriod>();

  const handleChangeDate = (fieldName: string, dateInput: string) => {
    const year = parseInt(dateInput.split('-')[0], 10);
    const month = parseInt(dateInput.split('-')[1], 10);

    if (fieldName === 'inputDateXls') {
      setDateUploadXls(dateInput);
    } else {
      setDateUploadZip({ month, year });
    }
  };

  const handleSendButton = (postType: string) => {
    if (postType === 'inputDateXls') {
      setErrorXls('');
      setUploadingXls(true);
      sendFormXls();
    }
    if (postType === 'inputDateZip') {
      setErrorZip('');
      setUploadingZip(true);
      sendFormZip();
    }
  };

  const sendFormXls = async () => {
    const token = localStorage.getItem('@Siscob:token');
    const fileInput = document.getElementById('inputFileXls') as HTMLInputElement;

    if (fileInput.files![0] != null) {
      if (dateUploadXls != '') {
        const formdata = new FormData();
        formdata.append('file', fileInput.files![0]);
        formdata.append('date', dateUploadXls);

        const requestOptions: RequestInit = {
          method: 'POST',
          body: formdata,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(`${baseURL}/non-payment-summary/upload`, requestOptions).then(response => {
          if (response.ok) {
            response.text();
            setUploadingXls(false);
            setSucessXls(true);
          } else {
            setUploadingXls(false);
            setErrorXls('Insira um arquivo válido.');
          }
        });
      } else {
        setUploadingXls(false);
        setErrorXls('Insira uma data válida.');
      }
    } else {
      setUploadingXls(false);
      setErrorXls('Insira um arquivo válido.');
    }
  };

  const sendFormZip = async () => {
    const token = localStorage.getItem('@Siscob:token');
    const fileInput = document.getElementById('inputFileZip') as HTMLInputElement;

    if (fileInput.files![0] != null) {
      if (dateUploadZip != null) {
        const formdata = new FormData();
        formdata.append('file', fileInput.files![0]);
        formdata.append('month', `${dateUploadZip?.month}`);
        formdata.append('year', `${dateUploadZip?.year}`);

        const requestOptions: RequestInit = {
          method: 'POST',
          body: formdata,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(`${baseURL}/non-payment-file/upload`, requestOptions).then(response => {
          if (response.ok) {
            response.text();
            setUploadingZip(false);
            setSucessZip(true);
          } else {
            setUploadingZip(false);
            setSucessZip(false);
            setErrorZip('Insira um arquivo válido.');
          }
        });
      } else {
        setUploadingZip(false);
        setSucessZip(false);
        setErrorZip('Insira uma data válida.');
      }
    } else {
      setUploadingZip(false);
      setSucessZip(false);
      setErrorZip('Insira um arquivo válido.');
    }
  };

  return (
    <Container>
      <Content>
        <h1>Importar dados da planilha com a apuração final da inadimplência</h1>
        <ImportArea>
          <h2>Buscar arquivos</h2>
          <img src={icon} alt="" />
          <InputGroup>
            <input
              name="inputDateXls"
              type="date"
              required
              onChange={e => handleChangeDate(e.target.name, e.target.value)}
            />
            <input id="inputFileXls" type="file" />
          </InputGroup>
          <LoadingBox active={uploadingXls}>
            <LoadingIcon />
          </LoadingBox>
        </ImportArea>
        {errorXls && <Error>{errorXls}</Error>}
        {sucessXls && <Sucess>Importado com sucesso!</Sucess>}
        <button type="button" onClick={() => handleSendButton('inputDateXls')}>
          Importar
        </button>
      </Content>

      <Content>
        <h1>Importar dados da planilha ZIP</h1>
        <ImportArea>
          <h2>Buscar arquivos</h2>
          <img src={icon} alt="" />
          <InputGroup>
            <input
              name="inputDateZip"
              type="month"
              required
              onChange={e => handleChangeDate(e.target.name, e.target.value)}
            />
            <input id="inputFileZip" type="file" />
          </InputGroup>
          <LoadingBox active={uploadingZip}>
            <LoadingIcon />
          </LoadingBox>
        </ImportArea>
        {errorZip && <Error>{errorZip}</Error>}
        {sucessZip && <Sucess>Importado com sucesso!</Sucess>}
        <button type="button" onClick={() => handleSendButton('inputDateZip')}>
          Importar
        </button>
      </Content>
    </Container>
  );
};
