import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import MaskedInput from 'react-maskedinput';
import { Label, FormField, FlexForm, InputBox, InputElement, Dpicker, LucDpicker, TextArea } from './styles';
import Modal from '../modal';
import * as ModalModules from '../modal/styles';
import Select from '../deprecated-select';
import api from '../../../../_core/api';

interface INewNegotiation {
  active?: boolean | undefined;
  closeModalFunction?: any;
  customer?: any;
  userName?: any;
  negotiation?: INegotiation | null;
  edit?: boolean;
}

interface IContactFormFields {
  contactDate?: Date;
  additionalInformation?: string;
  cpfCnpj?: string;
  billingGroupId?: number;
  negotiationReturnId?: number;
  shopping?: string;
  luc?: string;
  brand?: string;
  userIdentifier?: string;
}

interface INegotiation {
  identifier?: number;
  cpfCnpj?: number;
  customerName?: string;
  brand?: string;
  billingGroupId?: number;
  billingGroupName?: string;
  luc?: string;
  shoppingId: number;
  shoppingName?: string;
  contactDate: string;
  negotiationReturnId?: number;
  negotiationReturnName?: string;
  contactResponsible?: string;
  active?: boolean;
  additionalInformation?: string;
}

interface OptionsItems {
  value: string;
  label: string;
}

const NewNegotiationBillingGroup: React.FC<INewNegotiation> = ({
  active,
  closeModalFunction,
  customer,
  userName,
  negotiation = null,
}) => {
  // Stats
  const [modalActive, setModalActive] = useState<boolean | undefined>(false);
  const [contactFormFields, setContactFormFields] = useState<IContactFormFields>({});
  const [startDate] = useState(new Date());
  const [negotiationsReturn, setNegotiationsReturn] = useState<OptionsItems[]>([]);

  function montarFormField(negotiation: INegotiation) {
    const date = new Date(negotiation.contactDate);
    date.setDate(date.getDate() + 1);

    const objetoFormField: IContactFormFields = {
      contactDate: date,
      additionalInformation: negotiation.additionalInformation,
      cpfCnpj: negotiation.cpfCnpj?.toString(),
      billingGroupId: negotiation.billingGroupId,
      negotiationReturnId: negotiation.negotiationReturnId,
      shopping: negotiation.shoppingId.toString(),
      luc: negotiation.luc,
      brand: negotiation.brand,
      userIdentifier: negotiation.contactResponsible,
    };
    setContactFormFields(objetoFormField);
  }

  const getNegotiationsReturn = async () => {
    const { data } = await api.get(`/filters/negotiations-return`);
    const negotiations: OptionsItems[] = negotiationsReturn;
    data.map((item: any) => {
      negotiations.push({
        value: item.returnId.toString(),
        label: item.returnName,
      });
    });
    negotiations.sort((item1, item2) => comparar(item1, item2));
    negotiations.unshift({ value: '0', label: 'Selecione' });
    setNegotiationsReturn(negotiations);
  };

  function comparar(item1: OptionsItems, item2: OptionsItems) {
    if (item1.label > item2.label) return 1;
    if (item1.label < item2.label) return -1;
    return 0;
  }

  const handleChangeField = (field: any, value: any) => {
    const formFields: IContactFormFields = contactFormFields;
    setContactFormFields({ ...formFields, [field]: value });
  };

  useEffect(() => {
    setModalActive(active);
    handleChangeField('contactDate', startDate);

    if (negotiation != null) {
      montarFormField(negotiation);
    }
  }, [active, customer]);

  useEffect(() => {
    getNegotiationsReturn();
  }, []);

  function converterData(data: any) {
    let dia = `${data.getDate()}`;
    let mes = `${data.getMonth() + 1}`;
    const ano = `${data.getFullYear()}`;

    const pad = '00';
    dia = pad.substring(0, pad.length - dia.length) + dia;
    mes = pad.substring(0, pad.length - mes.length) + mes;

    return `${ano}-${mes}-${dia}`;
  }

  const sendData = async () => {
    const dataPut = {
      contactDate: converterData(contactFormFields.contactDate),
      additionalInformation: contactFormFields.additionalInformation,
      cpfCnpj: negotiation?.cpfCnpj,
      billingGroupId: negotiation?.billingGroupId,
      negotiationReturnId: contactFormFields.negotiationReturnId,
      shoppingId: negotiation?.shoppingId,
      luc: negotiation?.luc,
      brand: negotiation?.brand,
      active: 1,
      userIdentifier: userName,
    };

    api.put(`/negotiation-history/${negotiation?.identifier}`, dataPut).then(response => {
      handleExitModal(true);
    });
  };

  const handleExitModal = (inseriu = false) => {
    closeModalFunction(inseriu);
    setModalActive(false);
    setContactFormFields({ additionalInformation: '' } as IContactFormFields);
  };

  return (
    <Modal active={modalActive}>
      <Draggable>
        <ModalModules.Box>
          <ModalModules.Content>
            <FlexForm>
              <FormField>
                <Label>Shoppings</Label>
                <InputElement name="shoppingName" defaultValue={negotiation?.shoppingName} disabled />
              </FormField>

              <FormField>
                <InputBox>
                  <Label>Grupo de Cobrança</Label>
                  <InputElement name="billingGroupId" defaultValue={negotiation?.billingGroupName} disabled />
                </InputBox>
              </FormField>
              <FormField>
                <Label>Cliente</Label>
                <InputElement name="brand" defaultValue={negotiation?.customerName} disabled />
              </FormField>
              <LucDpicker>
                <FormField>
                  <Label>LUC</Label>
                  <InputElement name="luc" defaultValue={negotiation?.luc} disabled />
                </FormField>
                <FormField>
                  <Label>Data de contato</Label>
                  <Dpicker
                    selected={contactFormFields.contactDate}
                    required
                    name="contactDate"
                    onChange={(date: any) => handleChangeField('contactDate', date)}
                    dateFormat="dd/MM/yyyy"
                    customInput={<MaskedInput mask="11/11/1111" placeholder="dd/MM/yyyy" />}
                  />
                </FormField>
              </LucDpicker>
              <FormField>
                <Label>Nome fantasia</Label>
                <InputElement name="cliente" defaultValue={negotiation?.brand} disabled />
              </FormField>
              <FormField>
                <Select
                  helperClass="select"
                  name="negotiationReturnId"
                  options={negotiationsReturn}
                  selectedValue={negotiation?.negotiationReturnId}
                  label="Retorno da negociação"
                  onChange={e => {
                    handleChangeField(e.target.name, e.target.value);
                  }}
                />
              </FormField>
              <FormField>
                <InputBox>
                  <Label>Descrição da Negociação</Label>
                  <TextArea
                    name="additionalInformation"
                    value={contactFormFields.additionalInformation}
                    onChange={e => {
                      handleChangeField(e.target.name, e.target.value);
                    }}
                  />
                </InputBox>
              </FormField>
            </FlexForm>
          </ModalModules.Content>
          <ModalModules.Footer>
            <ModalModules.Button type="button" onClick={() => handleExitModal()}>
              Fechar
            </ModalModules.Button>
            <ModalModules.Button filled type="button" onClick={() => sendData()}>
              Enviar
            </ModalModules.Button>
          </ModalModules.Footer>
        </ModalModules.Box>
      </Draggable>
    </Modal>
  );
};

export default NewNegotiationBillingGroup;
