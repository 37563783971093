import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../../_main/contexts/auth';

import * as S from './styles';

type CurrentRoute = {
  menuLabel: string;
  routeLabel: string;
};

const PageHeader = (): JSX.Element => {
  const { routes } = useContext(AuthContext);
  const location = useLocation();
  const currentPath = location.pathname;

  const routeLabels = (): CurrentRoute | undefined => {
    let currentRouteData;
    routes.forEach(r => {
      r.menus.forEach(m => {
        if (m.url === currentPath)
          currentRouteData = {
            menuLabel: m.label,
            routeLabel: r.nome,
          };
      });
    });

    return currentRouteData;
  };

  const showBreads = () => {
    if (routeLabels()?.routeLabel && routeLabels()?.menuLabel) {
      return `${routeLabels()?.routeLabel} / ${routeLabels()?.menuLabel}`;
    }
    return '';
  };

  return (
    <S.Wrapper>
      <S.Title>{routeLabels()?.menuLabel || 'Home'}</S.Title>
      <S.BreadCrumb>{showBreads()}</S.BreadCrumb>
    </S.Wrapper>
  );
};

export default PageHeader;
