import axios from 'axios';
import Config from '../config/index';

const baseURL = Config.BASE_PATH;

const api = axios.create({
  baseURL: Config.BASE_PATH,
});

const setInterceptor = (): void => {
  api.interceptors.response.use(
    res => res,
    error => {
      if (error.response.status === 401) {
        localStorage.removeItem('@Siscob:id');
        localStorage.removeItem('@Siscob:token');
        localStorage.removeItem('@Siscob:name');
        localStorage.removeItem('@Siscob:userName');
        localStorage.removeItem('@Siscob:email');
        localStorage.removeItem('@Siscob:profile');
        localStorage.removeItem('@Siscob:shoppingProfileName');
        localStorage.removeItem('@Siscob:shoppingProfileId');
        localStorage.removeItem('@Siscob:shoppings');
        localStorage.removeItem('@Siscob:routes');
        return window.location.reload();
      }

      return Promise.reject(error);
    },
  );
};

const removeInterceptor = (): void => {
  api.interceptors.response.use(
    res => res,
    error => Promise.reject(error),
  );
};

const setBearerToken = (token: string): void => {
  api.defaults.headers.common = { Authorization: `Bearer ${token}` };
};

export default api;
export { baseURL, setInterceptor, removeInterceptor, setBearerToken };
