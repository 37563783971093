import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div`
  display: flex;
  flex: 1;
  background: #f2f4f4;
  box-shadow: 0px 4px 10px rgba(196, 196, 196, 0.44);
  border-radius: 10px;
  padding: 20px;
`;

export const Expanded = styled.div`
  display: flex;
  flex: 7;
`;

export const Form = styled.form`
  flex-direction: column;
  display: flex;
  flex: 3;
  input {
    margin-top: 10px;
  }
  span {
    margin-top: 10px;
  }
  button {
    margin-top: 10px;
  }
`;

export const InputDate = styled(InputMask)`
  height: 40px;
  width: 100%;
  padding: 7px 15px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
`;

export const TextFieldTitle = styled.span`
  color: #7e9395;
  font-size: 16px;
`;

export const SizedBoxHeight = styled.div`
  min-height: 32px;
`;

export const TextField = styled.input`
  height: 40px;
  width: 100%;
  padding: 7px 15px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
`;

export const SubmitButton = styled.button`
  min-width: 138px;
  height: 40px;
  background: ${props => (props.disabled ? 'grey' : '#31c6d3')};
  border-radius: 25px;
  color: #fff !important;
  font-size: 16px;
  border: none;
`;
