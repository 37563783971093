import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div``;
export const FlexForm = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
`;
export const FormField = styled.div`
  padding: 10px;
  &:nth-child(odd) {
    width: 60%;
  }
  &:nth-child(even) {
    width: 40%;
  }
`;

/** map to isolated component */
export const Label = styled.label`
  box-sizing: border-box;
  display: block;
  color: #7e9395;
  margin-bottom: 10px;
  font-size: 16px;
  margin: 0;
`;
export const InputBox = styled.div``;
export const MoreButton = styled.button`
  border: 0;
  background-color: #00959c;
  color: #fff;
  padding: 8px 10px;
  border-radius: 32px;
  margin-left: 15px;
`;
export const InputElement = styled.input`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7e93954d;
  border-radius: 20px;
  opacity: 1;
  padding: 10px 15px;
  width: 100%;
`;
export const InputTel = styled(InputMask)`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #7e93954d;
  border-radius: 20px;
  opacity: 1;
  padding: 10px 15px;
  width: 100%;
`;
