import React, { useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import api from '../../../../_core/api';
import { MinimumRequirement } from './index';
import { handleErrors, success } from '../../../../_core/services/toast';
import { Button, Form, Input, Option, SingleSelect } from '../../../../_core/_components';
import { converterParaFloat, mascaraNumeracaoPtBr, mascaraNumeros } from '../../../../_core/masks';

type Props = {
  onClose: () => void;
  refresh: () => void;
  selectedRegister: MinimumRequirement | null;
  tipoDividaOptions: Option[];
  tipoAcaoOptions: Option[];
};

const Modal: React.FC<Props> = ({ onClose, refresh, selectedRegister, tipoDividaOptions, tipoAcaoOptions }: Props) => {
  const [loading, setLoading] = useState(false);
  const [divMinTotal, setDivMinTotal] = useState<string>(
    selectedRegister
      ? selectedRegister.divMinTotal.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          currency: 'BRL',
          style: 'currency',
        })
      : 'R$ 0,00',
  );
  const [divMinMes, setDivMinMes] = useState<string>(
    selectedRegister
      ? selectedRegister.divMinMes.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          currency: 'BRL',
          style: 'currency',
        })
      : 'R$ 0,00',
  );
  const [inicioAgingEmAnos, setInicioAgingEmAnos] = useState<string>(
    selectedRegister?.inicioAgingEmAnos.toString() ?? '',
  );
  const [fimAgingEmDias, setFimAgingEmDias] = useState<string>(selectedRegister?.fimAgingEmDias.toString() ?? '');
  const [tipoDivida, setTipoDivida] = useState<Option | undefined>(
    tipoDividaOptions.find(e => e.value === selectedRegister?.idTipoDivida) ?? undefined,
  );
  const [tipoAcao, setTipoAcao] = useState<Option | undefined>(
    tipoAcaoOptions.find(e => e.value === selectedRegister?.idTipoAcao) ?? undefined,
  );
  const [boletosAbertos, setBoletosAbertos] = useState<string>(selectedRegister?.boletosAbertos.toString() ?? '');

  const save = async () => {
    setLoading(true);
    try {
      const payload = {
        idTipoAcao: tipoAcao?.value,
        idTipoDivida: tipoDivida?.value,
        divMinTotal: converterParaFloat(divMinTotal),
        divMinMes: converterParaFloat(divMinMes),
        inicioAgingEmAnos,
        fimAgingEmDias,
        boletosAbertos,
      };
      if (selectedRegister) {
        await api.put(`/parametros/ajuizamento/requisitos-minimos/${selectedRegister.id}`, {
          ...selectedRegister,
          ...payload,
        });
      } else {
        await api.post('/parametros/ajuizamento/requisitos-minimos', {
          ...payload,
          // apenasContratosAtivos: true,
          // requerAcordoAutos: true,
          // deveIncluirBoletosAdm: true,
          ativo: true,
        });
      }
      success('Salvo com sucesso.');
      onClose();
      refresh();
      return;
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[
              <SingleSelect
                state={[tipoDivida, setTipoDivida]}
                placeholder="Tipo de dívida"
                options={tipoDividaOptions}
              />,
              <SingleSelect state={[tipoAcao, setTipoAcao]} placeholder="Tipo de Ação" options={tipoAcaoOptions} />,
              <Input
                pattern={mascaraNumeracaoPtBr}
                placeholder="Div. Min. Total"
                label="Div. Min. Total"
                state={[divMinTotal, setDivMinTotal]}
              />,
              <Input pattern={mascaraNumeracaoPtBr} label="Div. Min. Mês" state={[divMinMes, setDivMinMes]} />,
              <Input pattern={mascaraNumeros} label="Aging início" state={[inicioAgingEmAnos, setInicioAgingEmAnos]} />,
              <Input pattern={mascaraNumeros} label="Aging fim" state={[fimAgingEmDias, setFimAgingEmDias]} />,
              <Input pattern={mascaraNumeros} label="Boletos abertos" state={[boletosAbertos, setBoletosAbertos]} />,
            ]}
            submitButton={<Button onClick={save} text="Salvar" disabled={loading} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
