import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import api from '../../../../_core/api';
import * as S from '../styles';
import { Button, Input, PaginatedTable } from '../../../../_core/_components';
import { handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { CONTATO, INITIAL_STATE, reducer } from './reducer';
import Modal from './modal';

export const Empresa: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [nomEmpresa, setNomEmpresa] = useState('');
  const [empresas, setEmpresas] = useState<any[]>([]);
  const [form, dispatch] = useReducer(reducer, undefined);

  useEffect(() => {
    getEmpresas(1);
  }, []);

  const getEmpresas = async (page: number) => {
    setIsLoading(true);
    try {
      const queryNomeEmpresa = nomEmpresa.length > 0 ? `&razaoSocial=${nomEmpresa}` : '';
      const { data } = await api.get(`/empresas?page=${page}${queryNomeEmpresa}`);
      setEmpresas(data.data);
      setPageInfo(data.pageInfo);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const data = useMemo(() => {
    return empresas.map(e => ({
      ...e,
      editar: (
        <IconButton
          onClick={() =>
            dispatch({
              type: 'SET_EMPRESA',
              payload: {
                ...e,
                contatos: e.contatos?.length > 0 ? e.contatos : [CONTATO],
              },
            })
          }
        >
          <Edit />
        </IconButton>
      ),
    }));
  }, [empresas, nomEmpresa]);

  const onClickSave = async () => {
    try {
      setIsLoading(true);
      const payload = {
        ...form,
        idErp: parseInt(form.idErp, 10),
      };
      if (form.id) {
        await api.put(`/empresas/${form.id}`, payload);
      } else {
        await api.post(`/empresas`, payload);
      }
      dispatch({ type: 'UNSET_EMPRESA' });
      const { data } = await api.get(`/empresas?page=1`);
      setEmpresas(data.data);
      setPageInfo(data.pageInfo);
      success('Empresa salva com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const getCep = async (cep: string) => {
    let response = {
      city: '',
      state: '',
    };
    setIsLoading(true);
    try {
      const { data } = await api.get(`https://brasilapi.com.br/api/cep/v2/${cep}`);
      response = data;
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
    return response;
  };

  return (
    <>
      {form && (
        <Modal
          form={form}
          dispatch={dispatch}
          getCep={getCep}
          onClickSave={onClickSave}
          onClose={() => dispatch({ type: 'UNSET_EMPRESA' })}
        />
      )}
      <FullScreenLoading isEnabled={isLoading} />
      <S.Content>
        <S.Filter>
          <Input
            state={[nomEmpresa, setNomEmpresa]}
            label="Buscar pela razão social"
            style={{ width: '35%' }}
            onBlur={() => getEmpresas(1)}
          />
          <Button
            text="Novo"
            onClick={() => dispatch({ type: 'SET_EMPRESA', payload: INITIAL_STATE })}
            styles={{ width: 100 }}
          />
        </S.Filter>
        {empresas.length > 0 && (
          <PaginatedTable
            columns={[
              { label: 'Razão Social', key: 'razaoSocial' },
              { label: 'CNPJ', key: 'cnpj' },
              { label: 'ID ERP', key: 'idErp' },
              { label: 'Editar', key: 'editar' },
            ]}
            data={data}
            pagination={pageInfo}
            onChangePage={getEmpresas}
          />
        )}
      </S.Content>
    </>
  );
};
