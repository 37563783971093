import React, { useState, useContext } from 'react';
import { baseURL } from '../../../_core/api';
import LoadingIcon from '../../../assets/loader.jsx';
import icon from '../../../assets/cloud-computing.svg';

import { AuthContext } from '../../../_main/contexts/auth';

import { Container, Content, ImportArea, InputGroup, LoadingBox, Sucess, Error } from './styles';
import { Button } from '../../../_core/_components';

interface IDatePeriod {
  month: number;
  year: number;
}

export const ImportacaoAcordos: React.FC = () => {
  const { token } = useContext(AuthContext);
  const [uploadingXls, setUploadingXls] = useState<boolean>(false);
  const [uploadingZip, setUploadingZip] = useState<boolean>(false);
  const [sucessXls, setSucessXls] = useState<boolean>(false);
  const [sucessZip, setSucessZip] = useState<boolean>(false);
  const [errorXls, setErrorXls] = useState<string>('');
  const [errorZip, setErrorZip] = useState<string>('');
  const [dateUploadZip, setDateUploadZip] = useState<IDatePeriod>();

  const handleSendButton = (postType: string) => {
    if (postType === 'inputDateXls') {
      setErrorXls('');
      setUploadingXls(true);
      sendFormXls();
    }
    if (postType === 'inputDateZip') {
      setErrorZip('');
      setUploadingZip(true);
      sendFormZip();
    }
  };

  const sendFormXls = async () => {
    const fileInput = document.getElementById('inputFileXls') as HTMLInputElement;

    if (fileInput.files![0] != null) {
      const formdata = new FormData();
      formdata.append('file', fileInput.files![0]);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: formdata,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      fetch(`${baseURL}/judgment/upload-acordo-auto`, requestOptions).then(response => {
        if (response.ok) {
          setUploadingXls(false);
          setSucessXls(true);
        } else {
          setUploadingXls(false);
          setErrorXls('Insira um arquivo válido.');
        }
      });
    } else {
      setUploadingXls(false);
      setErrorXls('Insira um arquivo válido.');
    }
  };

  const sendFormZip = async () => {
    const fileInput = document.getElementById('inputFileZip') as HTMLInputElement;

    if (fileInput.files![0] != null) {
      if (dateUploadZip != null) {
        const formdata = new FormData();
        formdata.append('file', fileInput.files![0]);
        formdata.append('month', `${dateUploadZip?.month}`);
        formdata.append('year', `${dateUploadZip?.year}`);

        const requestOptions: RequestInit = {
          method: 'POST',
          body: formdata,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        fetch(`${baseURL}/judgment/upload-acordo-auto`, requestOptions).then(response => {
          if (response.ok) {
            setUploadingZip(false);
            setSucessZip(true);
          } else {
            setUploadingZip(false);
            setSucessZip(false);
            setErrorZip('Insira uma data válida ou um arquivo válido!');
          }
        });
      } else {
        setUploadingZip(false);
        setErrorZip('Insira uma data válida.');
      }
    } else {
      setUploadingZip(false);
      setErrorZip('Insira um arquivo válido.');
    }
  };

  return (
    <Container>
      <Content>
        <h1>Importar dados da planilha com acordos dos autos</h1>
        <ImportArea>
          <h2>Buscar arquivos</h2>
          <img src={icon} alt="" />
          <InputGroup>
            <input id="inputFileXls" type="file" />
          </InputGroup>
          <LoadingBox active={uploadingXls}>
            <LoadingIcon />
          </LoadingBox>
        </ImportArea>
        {errorXls && <Error>{errorXls}</Error>}
        {sucessXls && <Sucess>Importado com sucesso!</Sucess>}
        <Button text="Importar" onClick={() => handleSendButton('inputDateXls')} />
      </Content>
    </Container>
  );
};
