import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import MaskedInput from 'react-maskedinput';
import { toast } from 'react-toastify';
import { Label, FormField, FlexForm, InputBox, InputElement, Dpicker, LucDpicker, TextArea, Required } from './styles';
import Loader from '../../../../assets/loader';
import Modal from '../modal';
import * as ModalModules from '../modal/styles';
import Select from '../deprecated-select';
import api from '../../../../_core/api';
import { Multiselect, IOption } from '../select';

interface INewNegotiation {
  active?: boolean | undefined;
  closeModalFunction?: any;
  customer?: any;
  userName?: any;
  negotiation?: INegotiation | null;
  edit?: boolean;
}

interface IContactFormFields {
  contactDate?: Date;
  additionalInformation?: string;
  cpfCnpj?: string;
  billingGroupId?: number;
  negotiationReturnId?: number;
  shopping?: string;
  luc?: string;
  brand?: string;
  userIdentifier?: string;
}

interface INegotiation {
  identifier?: number;
  cpfCnpj?: number;
  customerName?: string;
  brand?: string;
  billingGroupId?: number;
  billingGroupName?: string;
  luc?: string;
  shoppingId: number;
  shoppingName?: string;
  contactDate: string;
  negotiationReturnId?: number;
  negotiationReturnName?: string;
  contactResponsible?: string;
  active?: boolean;
  additionalInformation?: string;
}

const NewNegotiationBillingGroup: React.FC<INewNegotiation> = ({
  active,
  closeModalFunction,
  customer,
  userName,
  negotiation = null,
}) => {
  /** States */
  const [modalActive, setModalActive] = useState<boolean | undefined>(false);
  const [startDate] = useState(new Date());
  const [contactFormFields, setContactFormFields] = useState<IContactFormFields>({});
  const [lucSelected, setSelectedLuc] = useState<IOption[]>([]);
  const [, setSelectedShopping] = useState<IOption[]>([]);
  const [, setSelectedBrand] = useState<IOption[]>([]);
  const [, setSelectedCustomer] = useState<IOption[]>([]);
  const [isLuc, setIsLuc] = useState<boolean>(false);
  const [isReturnNegotiation, setIsReturnNegotiation] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean | undefined>(false);
  const filterVazio: IFilter = {
    lucs: [],
    businessGroup: [{ value: '0', label: 'Sem Grupo de Cobrança' }],
    shoppings: [],
    brands: [],
    customerNames: [],
  };
  const [filterData, setFilterData] = useState<IFilter>(filterVazio);
  const [negotiationsReturn, setNegotiationsReturn] = useState<OptionsItems[]>([]);
  const [arrayData, setArrayData] = useState<any>([]);

  /** UseEffects */
  useEffect(() => {
    getNegotiationsReturn();
  }, []);

  useEffect(() => {
    setModalActive(active);
    handleChangeField('contactDate', startDate);
  }, [active]);

  function montarFormField(negotiation: INegotiation) {
    const date = new Date(negotiation.contactDate);
    date.setDate(date.getDate() + 1);

    const objetoFormField: IContactFormFields = {
      contactDate: date,
      additionalInformation: negotiation.additionalInformation,
      cpfCnpj: negotiation.cpfCnpj?.toString(),
      billingGroupId: negotiation.billingGroupId,
      negotiationReturnId: negotiation.negotiationReturnId,
      shopping: negotiation.shoppingId.toString(),
      luc: negotiation.luc,
      brand: negotiation.brand,
      userIdentifier: negotiation.contactResponsible,
    };
    setContactFormFields(objetoFormField);
  }

  useEffect(() => {
    if (customer.query.id) {
      getData({
        billingGroupId: customer.query.id,
      });
    }
    if (negotiation != null) {
      montarFormField(negotiation);
      pegarLucNegociacao(negotiation.luc);
    }
  }, [customer, negotiation]);

  /** Handle methods */
  const handleExitModal = (inseriu = false) => {
    closeModalFunction(inseriu);
    setModalActive(false);
    setContactFormFields({ additionalInformation: '' } as IContactFormFields);
    setSelectedLuc([]);
    setSelectedShopping([]);
    setFilterData(filterVazio);
  };

  const handleChangeField = (field: any, value: any) => {
    const formFields: IContactFormFields = contactFormFields;

    setContactFormFields({ ...formFields, [field]: value });
  };

  /** Interfaces */
  interface IItemFilter {
    brand: string;
    businessGroupId: number;
    businessGroupName: string;
    cnpjCpf: string;
    customerName: string;
    idLuc: number;
    luc: string;
    shoppingId: number;
    shoppingName: string;
  }

  interface OptionsItems {
    value: string;
    label: string;
  }

  interface IFilter {
    brand?: string;
    businessGroupId?: number;
    businessGroupName?: string;
    cnpjCpf?: string;
    customerName?: string;
    idLuc?: number;
    luc?: string;
    shoppingId?: number;
    shoppingName?: string;

    shoppings: IOption[];
    customerNames: IOption[];
    lucs: IOption[];
    brands: IOption[];
    businessGroup: OptionsItems[];
  }

  /** Requests methods */
  const getData = async ({ billingGroupId }: any) => {
    const response = await api.get(`/filters/siscob-filters-information?billingGroupIds=${billingGroupId}`);
    const { data } = response;

    /** lógica para ter múltiplos itens em LUCS */
    let filter: IFilter = filterData;
    data.map((item: IItemFilter, index: number) => {
      const { lucs, businessGroup, shoppings, brands, customerNames } = filter;

      if (lucs.filter(l => l.label == item.luc).length > 0) {
      } else {
        lucs.push({ id: index * 3, label: item.luc });
      }

      if (shoppings.filter(l => l.label == item.shoppingName).length > 0) {
      } else {
        shoppings.push({ id: item.shoppingId, label: item.shoppingName });
      }

      if (brands.filter(b => b.label === item.brand).length > 0) {
      } else {
        brands.push({ id: index, label: item.brand });
      }

      if (customerNames.filter(c => c.label === item.customerName).length > 0) {
      } else {
        customerNames.push({ id: Number(item.cnpjCpf), label: item.customerName });
      }

      if (item.businessGroupId != null) {
        const verifyItem = businessGroup.find(e => e.value === item.businessGroupId.toString());

        if (verifyItem == null) {
          businessGroup.push({
            value: item.businessGroupId.toString(),
            label: item.businessGroupName,
          });
        }
      }

      filter = { ...filter, lucs, businessGroup };
      filter.businessGroupId = data[0].businessGroupId;
      filter.businessGroupName = data[0].businessGroupName;
      filter.shoppingId = data[0].shoppingId;
      filter.shoppingName = data[0].shoppingName;
      filter.cnpjCpf = data[0].cnpjCpf;
      filter.customerName = data[0].customerName;
      filter.brand = data[0].brand;
      filter.idLuc = data[0].idLuc;
      filter.luc = data[0].luc;
    });

    setFilterData(filter);
    setSelectedLuc(filter.lucs);
  };

  function pegarLucNegociacao(lucNegotiation: any) {
    const lucSelected: IOption[] = [];
    filterData.lucs.map(luc => {
      if (luc.label == lucNegotiation) {
        lucSelected.push(luc);
      }
    });
    setSelectedLuc(lucSelected);
  }

  useEffect(() => {
    const labelLucs = lucSelected.map(luc => luc.label);

    const lucs2 = labelLucs.join(',');

    const getShopping = async () => {
      const response = await api.get(
        `/filters/siscob-filters-information?billingGroupIds=${customer.query.id}&lucs=${lucs2}`,
      );
      const { data } = response;
      const shoppingList: any = [];
      const dataPost: any = [];

      data.forEach((item: IItemFilter) => {
        dataPost.push({
          cpfCnpj: item.cnpjCpf,
          billingGroupId: customer.query.id,
          shoppingId: item.shoppingId,
          luc: item.luc,
          brand: item.brand,
          active: 1,
          userIdentifier: userName,
        });
        if (!shoppingList.find((element: any) => element.id === item.shoppingId)) {
          shoppingList.push({
            id: item.shoppingId,
            label: item.shoppingName,
          });
        }
      });
      setArrayData(dataPost);
      const newFilter = {
        ...filterData,
        shoppings: shoppingList,
      };
      setFilterData(newFilter);
    };

    if (customer.query.id && lucSelected) {
      getShopping();
    }
  }, [lucSelected]);

  const notify = (error: {} | null | undefined) =>
    toast.error(error, {
      position: 'bottom-right',
    });

  const getNegotiationsReturn = async () => {
    const { data } = await api.get(`/filters/negotiations-return`);
    const negotiations: OptionsItems[] = negotiationsReturn;
    data.map((item: any) => {
      negotiations.push({
        value: item.returnId.toString(),
        label: item.returnName,
      });
    });
    negotiations.sort((item1, item2) => comparar(item1, item2));
    negotiations.unshift({ value: '0', label: 'Selecione' });
    setNegotiationsReturn(negotiations);
  };

  function comparar(item1: OptionsItems, item2: OptionsItems) {
    if (item1.label > item2.label) return 1;
    if (item1.label < item2.label) return -1;
    return 0;
  }

  function converterData(data: any): string {
    let dia = `${data.getDate()}`;
    let mes = `${data.getMonth() + 1}`;
    const ano = `${data.getFullYear()}`;

    const pad = '00';
    dia = pad.substring(0, pad.length - dia.length) + dia;
    mes = pad.substring(0, pad.length - mes.length) + mes;

    return `${ano}-${mes}-${dia}`;
  }

  const sendData = async () => {
    setIsLuc(false);
    setIsReturnNegotiation(false);
    const dataPost: any = [];

    if (contactFormFields.negotiationReturnId === undefined && lucSelected.length <= 0) {
      notify('Selecione o Retorno da Negociação e uma LUC');
    } else {
      if (contactFormFields.negotiationReturnId !== undefined) {
        if (lucSelected.length > 0) {
          setLoading(true);
          arrayData.map((item: any) => {
            dataPost.push({
              ...item,
              contactDate: converterData(contactFormFields.contactDate),
              additionalInformation: contactFormFields.additionalInformation,
              negotiationReturnId: contactFormFields.negotiationReturnId,
            });
          });

          api.post(`/negotiation-history/negotiation-histories`, dataPost).then(
            response => {
              setLoading(false);
              alert('Negociação salva com sucesso!');
              handleExitModal(true);
            },
            error => {
              setLoading(false);
              alert('Ocorreu um erro, tente novamente!');
            },
          );
        } else {
          notify('Selecione uma LUC');
        }
      } else {
        notify('Selecione o Retorno da Negociação');
        //setIsReturnNegotiation(true)
      }
    }
  };

  return (
    <Modal active={modalActive}>
      <Draggable>
        {loading ? (
          <div className="loaderUser">
            <Loader />
          </div>
        ) : (
          <ModalModules.Box>
            <ModalModules.Content>
              <FlexForm>
                <FormField>
                  <Label>Shoppings</Label>
                  <Multiselect
                    active
                    selectedFromParent={[]}
                    className="shopping"
                    onPick={(opcoes: IOption[]) => setSelectedShopping(opcoes)}
                    options={filterData.shoppings}
                    todos
                    allSelectedPadrao
                  />
                </FormField>

                <FormField>
                  <InputBox>
                    <Label>Grupo de Cobrança</Label>
                    <InputElement
                      name="billingGroupId"
                      defaultValue={filterData.businessGroupName}
                      onChange={e => {
                        handleChangeField(e.target.name, e.target.value);
                      }}
                      disabled
                    />
                  </InputBox>
                </FormField>
                <FormField>
                  <Label>Cliente</Label>
                  {/* <InputElement
                  hidden
                  name="cnpjCpf"
                  defaultValue={filterData.cnpjCpf}
                  onChange={e => {
                    handleChangeField(e.target.name, e.target.value);
                  }}
                />

                <InputElement
                  name="customerName"
                  defaultValue={filterData.customerName}
                  onChange={e => {
                    handleChangeField(e.target.name, e.target.value);
                  }}
                  disabled
                /> */}
                  <Multiselect
                    active
                    selectedFromParent={[]}
                    className="shopping"
                    onPick={(opcoes: IOption[]) => setSelectedCustomer(opcoes)}
                    options={filterData.customerNames}
                    todos
                    allSelectedPadrao
                  />
                </FormField>
                <LucDpicker>
                  <FormField>
                    <Label>
                      LUC
                      {isLuc && <Required>Luc é Obrigatório</Required>}
                    </Label>
                    <Multiselect
                      selectedFromParent={[]}
                      className="luc"
                      onPick={(opcoes: IOption[]) => setSelectedLuc(opcoes)}
                      search
                      options={filterData.lucs}
                      todos
                      allSelectedPadrao
                    />
                  </FormField>
                  <FormField>
                    <Label>Data de contato</Label>
                    <Dpicker
                      selected={contactFormFields.contactDate}
                      required
                      name="contactDate"
                      onChange={(date: any) => handleChangeField('contactDate', date)}
                      dateFormat="dd/MM/yyyy"
                      customInput={<MaskedInput mask="11/11/1111" placeholder="dd/MM/yyyy" />}
                    />
                  </FormField>
                </LucDpicker>
                <FormField>
                  <Label>Nome fantasia</Label>
                  <Multiselect
                    active
                    selectedFromParent={[]}
                    className="shopping"
                    onPick={(opcoes: IOption[]) => setSelectedBrand(opcoes)}
                    options={filterData.brands}
                    todos
                    allSelectedPadrao
                  />
                </FormField>
                <FormField>
                  {isReturnNegotiation && <Required>Retorno de negociação é Obrigatório</Required>}
                  <Select
                    helperClass="select"
                    name="negotiationReturnId"
                    options={negotiationsReturn}
                    selectedValue={contactFormFields.negotiationReturnId}
                    label="Retorno da negociação"
                    onChange={e => {
                      handleChangeField(e.target.name, e.target.value);
                    }}
                  />
                </FormField>
                <FormField>
                  <InputBox>
                    <Label>Descrição da Negociação</Label>
                    <TextArea
                      name="additionalInformation"
                      value={contactFormFields.additionalInformation}
                      onChange={e => {
                        handleChangeField(e.target.name, e.target.value);
                      }}
                    />
                  </InputBox>
                </FormField>
              </FlexForm>
            </ModalModules.Content>
            <ModalModules.Footer>
              <ModalModules.Button type="button" onClick={() => handleExitModal()}>
                Fechar
              </ModalModules.Button>
              <ModalModules.Button filled type="button" onClick={() => sendData()}>
                Enviar
              </ModalModules.Button>
            </ModalModules.Footer>
          </ModalModules.Box>
        )}
      </Draggable>
    </Modal>
  );
};

export default NewNegotiationBillingGroup;
