import React from 'react';

const SVG = ({
  color = '#063793',
  style = {},
  click,
  auxClass = '',
}) => (
  <svg
    className={`close ${auxClass}`}
    onClick={click}
    style={style}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs />
    <path
      fill={color}
      className="a"
      d="M25,7.014,22.986,5,15,12.986,7.014,5,5,7.014,12.986,15,5,22.986,7.014,25,15,17.014,22.986,25,25,22.986,17.014,15Z"
      transform="translate(-5 -5)"
    />
  </svg>
);

export default SVG;
