import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { IconButton, Menu, MenuItem, Popover } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { DataContainer, Table, TopBar, ListActions, ListActionItem } from './styles';
import { FilterContainer, Pagination, PaginationWrapper } from '../styles';
import Modal from './modal';
import ConfirmationModal from '../../../../_core/_components/confirmation-modal';
import { useInadimplencia } from '../hooks/useInadimplencia';
import api from '../../../../_core/api';
import { PageInfo } from '../portfolio/interfaces';
import { failure, handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Option, SingleSelect } from '../../../../_core/_components';

export type InadDateRegister = {
  id: number;
  period: string;
  cuttingDate: string;
  previewDate: string;
  shoppingName: string[];
};

type InadDateGetResponse = {
  data: InadDateRegister[];
  pageInfo: PageInfo;
};

const DataCorte: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { shoppings, getOptions } = useInadimplencia();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [selectedRegister, setSelectedRegister] = useState<InadDateRegister>();
  const [registerList, setRegisters] = useState<InadDateRegister[]>();
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [page, setPage] = useState(1);
  const [periodFilter, setPeriodFilter] = useState<string | null>(null);
  const [selectedShoppings, setSelectedShoppings] = useState<Option>();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  useEffect(() => {
    getFields();
  }, [page, selectedShoppings]);

  const getFields = async () => {
    setLoading(true);
    try {
      await getOptions(['shoppings']);
      const { data } = await api.get<InadDateGetResponse>(`/data-inadimplencia`, {
        params: {
          page,
          period: periodFilter || null,
          shoppingId: selectedShoppings ? selectedShoppings.value : undefined,
          active: true,
        },
      });
      setRegisters(data.data);
      setPageInfo(data.pageInfo);
      setPage(data.pageInfo.currentPage);
      setLoading(false);
    } catch (e) {
      failure('Houve um erro ao tentar recuperar os registros');
      setLoading(false);
    }
  };

  const confirmDeleteRegister = async () => {
    try {
      await api.put(`/data-inadimplencia/${selectedRegister!.id}`, {
        ...selectedRegister,
        active: false,
      });
      success('O registro foi removido com sucesso!');
      getFields();
      setLoading(false);
      setConfirmationModalOpen(false);
    } catch (e) {
      handleErrors(e);
      setLoading(false);
      setConfirmationModalOpen(false);
    }
  };

  const handleNewItem = () => {
    setSelectedRegister(undefined);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedRegister(undefined);
    setModalOpen(false);
  };

  const handleClickItemAction = (e: React.MouseEvent<HTMLElement>, id: number) => {
    setAnchorEl(e.currentTarget);
    const register = registerList?.find(i => i.id === id);
    setSelectedRegister(register);
  };

  const onClickEdit = () => {
    setAnchorEl(null);
    setModalOpen(true);
  };

  return (
    <DataContainer>
      <FullScreenLoading isEnabled={loading} />
      <Modal
        selectedRegister={selectedRegister}
        shoppings={shoppings}
        open={isModalOpen}
        onClose={() => handleCloseModal()}
      />
      <ConfirmationModal
        open={confirmationModalOpen}
        text="Confirma exclusão do registro?"
        handleCancel={() => setConfirmationModalOpen(false)}
        handleOk={() => confirmDeleteRegister()}
      />
      <TopBar>
        <FilterContainer>
          <SingleSelect placeholder="Shopping" state={[selectedShoppings, setSelectedShoppings]} options={shoppings} />
        </FilterContainer>
        <Button text="Criar novo" onClick={() => handleNewItem()} />
      </TopBar>
      <Table>
        <tr>
          <th>Data Corte</th>
          <th>Data Prévia</th>
          <th>Período</th>
          <th>Shopping</th>
          <th> </th>
        </tr>
        {registerList?.map(d => {
          return (
            <tr key={d.id}>
              <td>{moment(d.cuttingDate).format('DD/MM/YYYY')}</td>
              <td>{moment(d.previewDate).format('DD/MM/YYYY')}</td>
              <td>{d.period.replace('-', '/')}</td>
              <td>{d.shoppingName}</td>
              <td>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  component="span"
                  id="1"
                  onClick={(e: React.MouseEvent<HTMLElement>) => handleClickItemAction(e, d.id)}
                >
                  <MoreVert />
                </IconButton>
              </td>
            </tr>
          );
        })}
      </Table>
      <PaginationWrapper>
        <Pagination count={pageInfo?.totalPages} page={page} onChange={(e, i) => setPage(i)} />
      </PaginationWrapper>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onClickEdit}>Editar</MenuItem>
        <MenuItem onClick={() => setConfirmationModalOpen(true)}>Excluir</MenuItem>
      </Menu>
    </DataContainer>
  );
};

export default DataCorte;
