import qs from 'querystring';
import api from '../../_core/api';
import { HttpError, failure } from '../../_core/services/toast';

type UserAccessParams = {
  idUsuario: number;
  token: string;
};

export async function authenticate(token: string): Promise<any> {
  const { data } = await api.post(
    '/authenticate',
    qs.stringify({
      token,
    }),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );
  return data;
}

export function handleAuthenticateError(error: unknown): void {
  if (error as HttpError) {
    const err = error as HttpError;
    const { status } = err.response;
    switch (status) {
      case 401:
        failure('Erro: 401 - Usuário e/ou senha inválido(s)');
        break;
      case 403:
        failure('Erro: 403 - Forbidden - (Entre em contato com o suporte');
        break;
      default:
        failure('Erro: 500 - Internal Server Error - (Entre em contato com o suporte');
        break;
    }
    return;
  }
  failure('Erro interno');
}

export async function getUserAccess({ idUsuario, token }: UserAccessParams): Promise<any> {
  const { data } = await api.post(
    '/perfilizacao/usuario/acessos/buscar',
    {
      idUsuario,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return data.content;
}
