export const STATUS_OPTIONS = [
  { value: 1, label: 'Não resolvido' },
  { value: 2, label: 'Em atendimento' },
  { value: 3, label: 'Cancelado' },
  { value: 4, label: 'Resolvido' },
];

export const GUIAS_PAGAMENTO = [
  { valorGuia: '' },
  { valorGuia: '' },
  { valorGuia: '' },
  { valorGuia: '' },
  { valorGuia: '' },
];

export const NUMERO_PO = [{ numero: '' }, { numero: '' }, { numero: '' }, { numero: '' }, { numero: '' }];

export const NUMERO_CHAMADO_PGTO = [
  { numeroChamado: '' },
  { numeroChamado: '' },
  { numeroChamado: '' },
  { numeroChamado: '' },
  { numeroChamado: '' },
];

export const COMPROVANTES_PAGAMENTOS = [
  { comprovantes: false },
  { comprovantes: false },
  { comprovantes: false },
  { comprovantes: false },
  { comprovantes: false },
];

export type FASE =
  | 'Documentação'
  | 'Guia de Pagamento'
  | 'PO'
  | 'Chamado para Pagamento'
  | 'Comprovantes de Pagamento'
  | 'Chamado para Ajuizamento'
  | 'Ação Ajuizada';

export const FASES_MAP = new Map<FASE, number>([
  ['Documentação', 1],
  ['Guia de Pagamento', 2],
  ['PO', 3],
  ['Chamado para Pagamento', 4],
  ['Comprovantes de Pagamento', 5],
  ['Chamado para Ajuizamento', 6],
  ['Ação Ajuizada', 7],
]);
