import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  Table,
  MenuTable,
  FilterContainer,
  SizedBoxHeight,
  PaginationWrapper,
  Pagination,
  TableActions,
} from '../styles';
import more from '../../../../assets/more.svg';
import api from '../../../../_core/api';
import { Intercompany as TIntercompany, PageInfo } from './interfaces';
import Modal from './modal';
import { handleErrors, success } from '../../../../_core/services/toast';
import { downloadXls } from '../../../../_core/services/download';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button } from '../../../../_core/_components';

const Intercompany: React.FC = () => {
  const [loadingExport, setLoadingExport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState<TIntercompany[]>([]);
  const [editValues, setEditValues] = useState<TIntercompany>();
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  useEffect(() => {
    const getFields = async () => {
      setLoading(true);
      await getIntercompanies();
      setLoading(false);
    };
    getFields();
  }, [page]);

  const getIntercompanies = async () => {
    try {
      const { data } = await api.get(`intercompanies?active=true&page=${page}`);
      setValues(data.data);
      setPageInfo(data.pageInfo);
    } catch (error) {
      handleErrors(error);
    }
  };

  const onCreate = async (form: TIntercompany) => {
    setOpen(false);
    setLoading(true);
    try {
      await api.post('intercompanies', form);
      await getIntercompanies();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onUpdate = async (form: TIntercompany) => {
    setEditValues(undefined);
    setLoading(true);
    try {
      await api.put(`intercompanies/${form.id}`, form);
      await getIntercompanies();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    setAnchorEl(null);
    try {
      await api.delete(`intercompanies/${anchorEl?.id}`);
      await getIntercompanies();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onClickEdit = () => {
    const obj = values.find(e => e.id === parseInt(anchorEl?.id, 10));
    setEditValues(obj);
    setAnchorEl(null);
  };

  const createModal = useMemo(() => {
    if (open) {
      return <Modal onClose={() => setOpen(false)} onSubmit={onCreate} />;
    }
    return null;
  }, [open]);

  const editModal = useMemo(() => {
    if (editValues) {
      return <Modal onClose={() => setEditValues(undefined)} onSubmit={onUpdate} values={editValues} />;
    }
    return null;
  }, [editValues]);

  const exportFile = async () => {
    setLoadingExport(true);
    try {
      const response = await api.get(`/intercompanies/download?active=true&page=${page}`, {
        responseType: 'arraybuffer',
      });
      success('Arquivo baixado! Confira no gerenciador de arquivos de seu navegador.');
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoadingExport(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Table>
        <MenuTable>
          <FilterContainer />
          <Button text="Criar novo" onClick={() => setOpen(true)} />
        </MenuTable>
        <table>
          <tr>
            <th>Shopping</th>
            <th>CNPJ</th>
            <th>Fantasia</th>
            <th>LUC</th>
            <th>Intercompany</th>
            <th> </th>
          </tr>
          {values.map((item, i) => (
            <tr key={i}>
              <td>{item.shopping}</td>
              <td>{item.cnpj}</td>
              <td>{item.brand}</td>
              <td>{item.luc}</td>
              <td>{item.isIntercompany ? 'Sim' : 'Não'}</td>
              <td>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  component="span"
                  id={item.id.toString()}
                  onClick={(e: any) => setAnchorEl(e.currentTarget)}
                >
                  <img src={more} alt="" />
                </IconButton>
              </td>
            </tr>
          ))}
        </table>
        <SizedBoxHeight />
        <TableActions>
          <Button disabled={loadingExport} text="exportar" onClick={exportFile} />
          <PaginationWrapper>
            <Pagination count={pageInfo?.totalPages} page={page} onChange={(e, i) => setPage(i)} />
          </PaginationWrapper>
        </TableActions>
      </Table>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onClickEdit}>Editar</MenuItem>
        <MenuItem onClick={onDelete}>Excluir</MenuItem>
      </Menu>
      {createModal}
      {editModal}
    </>
  );
};

export default Intercompany;
