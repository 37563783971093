import React, { useMemo, useState } from 'react';
import * as S from './styles';
import { Empresa } from './empresa';
import { Shopping } from './shopping';
import { UnidadeEconomica } from './unidade-economica';
import { Option } from '../../../_core/_components';

type Item = 'Empresa' | 'Shopping' | 'Empresa x Shopping';

const MENU_LIST: Item[] = ['Empresa', 'Shopping', 'Empresa x Shopping'];

export const ORIGENS: Option[] = [
  {
    value: 'SAP',
    label: 'SAP',
  },
  {
    value: 'ORACLE',
    label: 'ORACLE',
  },
];

export const CadastroEmpresas: React.FC = () => {
  const [menu, setMenu] = useState<Item>('Empresa');

  const menuItem = useMemo(() => {
    switch (menu) {
      case 'Empresa':
        return <Empresa />;
      case 'Shopping':
        return <Shopping />;
      default:
        return <UnidadeEconomica />;
    }
  }, [menu]);

  return (
    <S.Container>
      <S.SideMenu>
        {MENU_LIST.map((item: Item, i: number) => (
          <S.ItemMenu key={i} onClick={() => setMenu(item)} active={item === menu}>
            {item}
          </S.ItemMenu>
        ))}
      </S.SideMenu>
      {menuItem}
    </S.Container>
  );
};
