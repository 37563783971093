import React from 'react';
import { Drawer, Toolbar, makeStyles, createStyles, Divider } from '@material-ui/core';
import { DrawerContent } from './drawer-content';
import logoSiscob from '../../assets/logo_siscob.svg';

type Props = {
  open: boolean;
  onClose: Function;
};

const drawerWidth = 250;

const useStyles = makeStyles(() =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
  }),
);

export function PresentationDrawer({ open, onClose }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Drawer anchor="left" open={open} onClose={() => onClose()}>
      <div onMouseLeave={() => onClose()}>
        <Toolbar>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <img src={`${logoSiscob}`} alt="" />
          </div>
        </Toolbar>
        <Divider />
        <div className={classes.drawerContainer}>
          <DrawerContent />
        </div>
      </div>
    </Drawer>
  );
}
