/* eslint-disable default-case */
import React, { useMemo, useState } from 'react';
import * as S from './styles';
import { Usuarios } from './usuarios';
import { Perfil } from './perfil';

type Item = 'Usuários' | 'Perfil';

const MENU_LIST: Item[] = ['Usuários', 'Perfil'];

export const Perfilizacao: React.FC = () => {
  const [menu, setMenu] = useState<Item>('Usuários');

  const menuItem = useMemo(() => {
    switch (menu) {
      case 'Usuários':
        return <Usuarios />;
      case 'Perfil':
        return <Perfil />;
    }
  }, [menu]);

  return (
    <S.Container>
      <S.SideMenu>
        {MENU_LIST.map((item: Item, i: number) => (
          <S.ItemMenu key={i} onClick={() => setMenu(item)} active={item === menu}>
            {item}
          </S.ItemMenu>
        ))}
      </S.SideMenu>
      {menuItem}
    </S.Container>
  );
};
