import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { Label } from './styles';
import { maskCPFCNPJ } from '../../../_core/masks';

type ModalProps = {
  onClose: () => void;
  details: any;
};

const Modal: React.FC<ModalProps> = ({ onClose, details }) => {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Label>TIPO: {details.tipoPessoa}</Label>
        {details.nomeDevedor && <Label>NOME: {details.nomeDevedor}</Label>}
        {details.documentoDevedor && <Label>DOCUMENTO: {maskCPFCNPJ(details.documentoDevedor)}</Label>}
        {details.retornoSerasa.erros && (
          <Label>ERRO: {details.retornoSerasa.erros?.map((err: any) => err.description).join(' / ')}</Label>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
