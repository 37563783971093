import styled from 'styled-components';

export const Wrapper = styled.div`
  .loading {
    width: 150px;
    margin-top: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Container = styled.div`
  > h2 {
    color: #7e9395;

    margin-bottom: 1rem;
    font-weight: 600;
  }

  > p {
    color: #00959c;
  }
`;

export const FiltroWrapper = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 2rem 3rem;
`;

export const FieldArea = styled.div`
  display: flex;
`;

export const FieldGroup = styled.div`
  display: flex;
  margin: 1rem 0;
  align-items: flex-end;
`;

export const Label = styled.div`
  margin: auto;
  color: #7e9395;

  font-weight: 600;
  float: left;
  padding-right: 2rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ColumnReverse = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const Title = styled.div`
  color: #7e9395;

  font-weight: 600;
  margin-left: 1rem;
`;

export const Section = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const ActionArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  button {
    background-color: #31c6d3;
    color: #fff;
  }
`;

export const Actions = styled.div`
  display: flex;
  margin-top: 2rem;

  .right-aligned {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    button {
      margin: 0 1rem;
    }
  }
`;
