import React from 'react';

type Props = {
  color?: string;
};

const SVG: React.FC<Props> = ({ color = '#ffad36' }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24.596" height="24.596" viewBox="0 0 24.596 24.596">
    <defs />
    <path
      fill={color}
      className="a"
      d="M15.673,3.375a12.3,12.3,0,1,0,12.3,12.3A12.334,12.334,0,0,0,15.673,3.375Zm1.419,18.447H14.254V19.457h2.838Zm0-5.2H14.254V9.051h2.838Z"
      transform="translate(-3.375 -3.375)"
    />
  </svg>
);

export default SVG;
