import FileSaver from 'file-saver';

interface Response<T = any> {
  data: T;
  headers: any;
}

export const downloadXls = (response: Response): void => {
  const blob = new Blob([response.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  FileSaver.saveAs(blob, response.headers.filename);
};
