import styled from 'styled-components/macro';
import Pages from '@material-ui/lab/Pagination';

interface IMenuActive {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SideMenu = styled.div`
  width: 20%;
  padding: 0 1rem;
`;

export const ItemMenu = styled.div<IMenuActive>`
  background: ${({ active }) => (active ? '#7E9395' : '#ffffff')};
  border-radius: 1rem;
  color: ${({ active }) => (active ? '#ffffff' : '#7E9395')};
  font-weight: 600;

  padding: 0.8rem;
  margin-bottom: 0.8rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s ease;

  :hover {
    background: #7e939529;
    color: #798c91;
  }
`;

export const Table = styled.div`
  width: 80%;
  background: #f2f4f4;
  padding: 20px;
  table {
    border-collapse: collapse;
    width: 100%;
    tr {
      &:nth-child(odd) {
        background: #ffffff 0% 0% no-repeat padding-box;
        opacity: 1;
      }
      &:nth-child(even) {
        background: #e5e9ea 0% 0% no-repeat padding-box;
        opacity: 1;
      }
      &:first-child {
        background-color: #31c6d3;
        padding: 10px;
        border-radius: 5px 5px 0px 0px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        line-height: 30px;
      }
      td {
        padding: 15px 8px;
        text-align: left;
        color: #3f3f3f;
        font-size: 12px;
        text-transform: uppercase;
        text-align: left;
      }
      th {
        text-align: left;
        padding: 0 10px;
      }
    }
  }
`;

export const MenuTable = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const DropdownContent = styled.ul`
  display: none;
  position: absolute;
  background-color: #7e9395;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  li {
    float: none;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    font-size: 14px;
  }

  li:hover {
    background-color: #31c6d3;
  }

  li:hover ul {
    display: block;
  }
`;

export const Submenu = styled.ul`
  height: 0px;
  display: none;
  li {
    background-color: #7e9395;
    position: relative;
    display: block;
    top: -31px;
    left: 100%;
    width: 100%;
    white-space: nowrap;
    z-index: 1;
  }
`;

export const SizedBoxHeight = styled.div`
  min-height: 15px;
`;

export const Pagination = styled(Pages)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    flex-wrap: nowrap;
    padding-left: 20px;
  }
  li {
    width: 28px;
    height: 28px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;

    button {
      border-radius: 0 !important;
      color: #7e9395 !important;
      font-size: 9px !important;
      font-weight: 600 !important;
      span {
        border-radius: 0 !important;
      }
    }

    .MuiPaginationItem-page.Mui-selected {
      background-color: #00959c27;
    }

    .MuiPaginationItem-page:hover {
      background-color: #00959c27;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ListActions = styled.div``;

export const ListActionItem = styled.div`
  padding: 1.5rem 1.5rem;
  cursor: pointer;

  transition: all 0.5s ease;

  :hover {
    background-color: #7e9395;
    color: white;
  }
`;

export const TableActions = styled.div`
  display: flex;
  justify-content: space-between;
`;
