import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { Label } from './styles';

type ModalProps = {
  onClose: () => void;
  errors: String;
};

const Modal: React.FC<ModalProps> = ({ onClose, errors }) => {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Label>{errors}</Label>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
