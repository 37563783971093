import React, { useState } from 'react';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import api from '../../../../_core/api';
import { Action } from './index';
import { handleErrors, success } from '../../../../_core/services/toast';
import { Button, Form, Input } from '../../../../_core/_components';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedRegister?: Action | null;
};

const Modal: React.FC<Props> = ({ open, onClose, selectedRegister }: Props) => {
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<string>(selectedRegister?.nome ?? '');

  const save = async () => {
    setLoading(true);
    try {
      const payload = {
        id: selectedRegister?.id,
        nome: action,
        ativo: selectedRegister?.ativo ?? true,
      };
      if (selectedRegister?.id) {
        await api.put('/parametros/ajuizamento/tipo-acao', payload);
      } else {
        await api.post('/parametros/ajuizamento/tipo-acao', payload);
      }
      success('Salvo com sucesso.');
      onClose();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar
        </span>
        <Grid>
          <Form
            items={[<Input label="Tipo ação" state={[action, setAction]} />]}
            submitButton={<Button onClick={save} text="Salvar" disabled={loading || !action} />}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default Modal;
