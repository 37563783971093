import React, { useState, useEffect } from 'react';

import { Label, FormField, FlexForm, InputBox, InputElement, InputTel } from './styles';

import Modal from '../../components/modal';
import * as ModalModules from '../../components/modal/styles';
import api from '../../../../_core/api';

interface IEditContact {
  active?: boolean | undefined;
  closeModalFunction?: any;
  customer?: any;
  userName?: any;
  dataEdit?: object;
}

interface IContactFormFields {
  shoppingName?: string;
  cpfCnpj?: string;
  contactName?: string;
  contactDepartment?: string;
  brand?: string;
  active?: string;
  contactsPoints?: IContactsPoints[];
}

interface IContactsPoints {
  identifier: number;
  contactPoint: string;
  contactPointType: string;
}

export const EditNewContact: React.FC<IEditContact> = ({ active, closeModalFunction, customer, userName }) => {
  const [modalActive, setModalActive] = useState<boolean | undefined>(false);
  const [contactType] = useState<string>('E-mail');
  const [, setValueEmailTelefone] = useState<string>('');
  const [contactFormFields, setContactFormFields] = useState<IContactFormFields>({});
  const contactsPointsVazio = [
    {
      identifier: 0,
      contactPoint: '',
      contactPointType: 'E-mail',
    },
    {
      identifier: 0,
      contactPoint: '',
      contactPointType: 'Telefone',
    },
  ];
  const [contactsPoints, setContactsPoints] = useState<IContactsPoints[]>(contactsPointsVazio);

  function zerarTudo() {
    setValueEmailTelefone('');
    setContactsPoints(contactsPointsVazio);
    setContactFormFields({
      ...contactFormFields,
      contactName: '',
      contactDepartment: '',
      contactsPoints: contactsPointsVazio,
    });
  }

  /** Handle methods */
  const handleClose = () => {
    closeModalFunction();
    zerarTudo();
  };

  const handleSubmit = () => {
    sendData();
  };

  const handleChangeContactType = (identifier: number, field: string, value: string) => {
    const contactsPointsTemp = contactsPoints.map((c: IContactsPoints, index) => {
      if (c.contactPointType.concat(index.toString()) == field) {
        c.contactPoint = value;
      }
      return c;
    });

    setContactsPoints(contactsPointsTemp);
  };

  const handleChangeField = (field: string, value: any, formFields: IContactFormFields = contactFormFields) => {
    setContactFormFields({ ...formFields, [field]: value });
  };

  /** Requests methods */
  const sendData = async () => {
    const contactsPointsTemp = contactsPoints.filter((c: IContactsPoints) => c.contactPoint != '');

    const data = {
      cpfCnpj: contactFormFields.cpfCnpj,
      contactName: contactFormFields.contactName,
      contactDepartment: contactFormFields.contactDepartment,
      brand: customer?.customerName,
      active: '1',
      userIdentifier: userName,
      contactsPoints: contactsPointsTemp,
    };

    const contactIdentifier = customer?.contactToEdit?.identifier;

    /** contact */
    api
      .put(`/charge-legal-information/contacts/customer-contacts/${contactIdentifier}`, {
        userIdentifier: userName,
        cpfCnpj: data.cpfCnpj,
        contactName: data.contactName,
        contactDepartment: data.contactDepartment,
        brand: data.brand,
      })
      .then(response => {})
      .catch(error => {});

    /** contact points */
    data.contactsPoints.map(({ identifier, contactPoint, contactPointType }: any) => {
      if (identifier != 0) {
        api
          .put(`/charge-legal-information/contacts/customer-contact-points/${identifier}`, {
            contactIdentifier,
            contactPoint,
            contactPointType,
            userIdentifier: userName,
            active: 1,
          })
          .then(response => {})
          .catch(error => {});
      } else {
        api
          .post(`/charge-legal-information/contacts/customer-contact-points`, {
            contactIdentifier,
            contactPoint,
            contactPointType,
            userIdentifier: userName,
            active: 1,
          })
          .then(response => {})
          .catch(error => {});
      }
    });

    closeModalFunction();
  };

  /** UseEffects */
  useEffect(() => {
    setModalActive(active);
  }, []);

  useEffect(() => {
    setModalActive(active);
  }, [active]);

  useEffect(() => {
    if (active) {
      const tmpFormFields = { ...contactFormFields };
      tmpFormFields.cpfCnpj = customer?.cpfCnpj;
      tmpFormFields.contactName = customer?.contactToEdit?.contactName;
      tmpFormFields.contactDepartment = customer?.contactToEdit?.contactDepartment;
      tmpFormFields.contactsPoints = [];

      const tmpContactPoints = customer.contactInfoDetails;
      // customer.contactInfoDetails.map((item: any) => {
      //   const { identifier } = item;
      //   const field = item.contactPointType;
      //   const value = item.contactPoint;

      //   if (field === 'Telefone') {
      //     tmpContactPoints[1].identifier = identifier;
      //     tmpContactPoints[1].contactPoint = value;
      //   } else {
      //     tmpContactPoints[0].identifier = identifier;
      //     tmpContactPoints[0].contactPoint = value;
      //   }
      // });

      setContactsPoints([...tmpContactPoints]);

      setContactFormFields({ ...tmpFormFields });
    }
  }, [customer, active]);

  useEffect(() => {
    handleChangeField('contactPointType', contactType);
  }, [contactType]);

  useEffect(() => {}, [contactFormFields]);

  return (
    <Modal active={modalActive}>
      {/* <Draggable> */}
      <ModalModules.Box>
        <ModalModules.Content>
          <FlexForm>
            <FormField>
              <InputBox>
                <Label>Shopping</Label>
                <InputElement name="shopping" disabled defaultValue={customer?.query.shopping} />
              </InputBox>
            </FormField>
            <FormField>
              <Label>Nome do contato</Label>
              <InputElement
                name="contactName"
                onChange={e => {
                  handleChangeField(e.target.name, e.target.value);
                }}
                value={contactFormFields.contactName}
              />
            </FormField>

            <FormField>
              <Label>Cliente</Label>
              <InputElement name="brand" defaultValue={customer?.customerName} disabled />
            </FormField>

            <FormField>
              <Label>Tipo de Contatoo</Label>
              <InputElement
                name="contactDepartment"
                onChange={e => {
                  handleChangeField(e.target.name, e.target.value);
                }}
                value={contactFormFields.contactDepartment}
              />
            </FormField>

            {/* {contactsPoints.map((item: any) => (
              <FormField>
                <InputBox hidden={contactType == null}>
                  <input name="identifier" value={item.identifier} hidden />
                  <Label>{item.contactPointType}</Label>
                  <InputElement
                    defaultValue={item.contactPoint}
                    name={item.contactPointType}
                    onChange={e => {
                      handleChangeContactType(item.identifier, e.target.name, e.target.value);
                    }}
                  />
                </InputBox>
              </FormField>
            ))} */}

            {contactsPoints.map((item: any, index: any) => (
              <FormField>
                <InputBox>
                  <Label>{item.contactPointType}</Label>
                  {item.contactPointType === 'E-Mail' ? (
                    <InputElement
                      type="email"
                      value={item.contactPoint}
                      name={item.contactPointType + index}
                      onChange={e => {
                        handleChangeContactType(item.identifier, e.target.name, e.target.value);
                      }}
                    />
                  ) : (
                    <InputTel
                      mask="(99) 99999-9999"
                      value={item.contactPoint}
                      name={item.contactPointType + index}
                      onChange={e => {
                        handleChangeContactType(item.identifier, e.target.name, e.target.value);
                      }}
                    />
                  )}
                </InputBox>
              </FormField>
            ))}
          </FlexForm>
        </ModalModules.Content>
        <ModalModules.Footer>
          <ModalModules.Button type="button" onClick={() => handleClose()}>
            Fechar
          </ModalModules.Button>
          <ModalModules.Button filled type="button" onClick={() => handleSubmit()}>
            Enviar
          </ModalModules.Button>
        </ModalModules.Footer>
      </ModalModules.Box>
      {/* </Draggable> */}
    </Modal>
  );
};
