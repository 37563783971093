import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
  Table,
  MenuTable,
  FilterContainer,
  SizedBoxHeight,
  PaginationWrapper,
  Pagination,
  TableActions,
} from '../styles';
import api from '../../../../_core/api';
import { TipoReceitaEncargo as TipoReceitaEncargoType, PageInfo } from './interfaces';
import Modal from './modal';
import { useInadimplencia } from '../hooks/useInadimplencia';
import { handleErrors, success } from '../../../../_core/services/toast';
import { downloadXls } from '../../../../_core/services/download';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button } from '../../../../_core/_components';

const TipoReceitaEncargo: React.FC = () => {
  const { encargos, getOptions } = useInadimplencia();
  const [loadingExport, setLoadingExport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState<TipoReceitaEncargoType[]>([]);
  const [editValues, setEditValues] = useState<TipoReceitaEncargoType>();
  const [pageInfo, setPageInfo] = useState<PageInfo>();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);

  useEffect(() => {
    const getFields = async () => {
      setLoading(true);
      await getTipoReceitaEncargo();
      await getOptions(['encargos']);
      setLoading(false);
    };
    getFields();
  }, [page]);

  const getTipoReceitaEncargo = async () => {
    try {
      const { data } = await api.get(`charge-revenue-type?active=true&page=${page}`);
      setValues(data.data);
      setPageInfo(data.pageInfo);
    } catch (error) {
      handleErrors(error);
    }
  };

  const onCreate = async (form: TipoReceitaEncargoType) => {
    setOpen(false);
    setLoading(true);
    try {
      await api.post('charge-revenue-type', form);
      await getTipoReceitaEncargo();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onUpdate = async (form: TipoReceitaEncargoType) => {
    setEditValues(undefined);
    setLoading(true);
    try {
      await api.put(`charge-revenue-type/${form.id}`, form);
      await getTipoReceitaEncargo();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    setAnchorEl(null);
    try {
      await api.delete(`charge-revenue-type/${anchorEl?.id}`);
      await getTipoReceitaEncargo();
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  const onClickEdit = () => {
    const obj = values.find(e => e.id === parseInt(anchorEl?.id, 10));
    setEditValues(obj);
    setAnchorEl(null);
  };

  const options = useMemo(
    () =>
      encargos.map((item: any) => ({
        label: item.chargeType,
        value: item.chargeTypeId,
      })),
    [encargos],
  );

  const createModal = useMemo(() => {
    if (open) {
      return <Modal onClose={() => setOpen(false)} onSubmit={onCreate} options={options} />;
    }
    return null;
  }, [open, options]);

  const editModal = useMemo(() => {
    if (editValues) {
      return (
        <Modal onClose={() => setEditValues(undefined)} onSubmit={onUpdate} values={editValues} options={options} />
      );
    }
    return null;
  }, [editValues, options]);

  const exportFile = async () => {
    setLoadingExport(true);
    try {
      const response = await api.get(`/charge-revenue-type/download?active=true&page=${page}`, {
        responseType: 'arraybuffer',
      });
      downloadXls(response);
      success('Arquivo baixado! Confira no gerenciador de arquivos de seu navegador.');
    } catch (error) {
      handleErrors(error);
    }
    setLoadingExport(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <Table>
        <MenuTable>
          <FilterContainer />
          <Button text="Criar novo" onClick={() => setOpen(true)} />
        </MenuTable>
        <table>
          <tr>
            <th>Ajuste</th>
            <th>Encargo</th>
            <th />
          </tr>
          {values.map((item, i) => (
            <tr key={i}>
              <td>{item.revenueType}</td>
              <td>{item.chargeType}</td>
              <td>
                <IconButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  color="primary"
                  component="span"
                  id={item.id.toString()}
                  onClick={(e: any) => setAnchorEl(e.currentTarget)}
                >
                  <MoreVert />
                </IconButton>
              </td>
            </tr>
          ))}
        </table>
        <SizedBoxHeight />
        <TableActions>
          <Button disabled={loadingExport} text="exportar" onClick={exportFile} />
          <PaginationWrapper>
            <Pagination count={pageInfo?.totalPages} page={page} onChange={(e, i) => setPage(i)} />
          </PaginationWrapper>
        </TableActions>
      </Table>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={onClickEdit}>Editar</MenuItem>
        <MenuItem onClick={onDelete}>Excluir</MenuItem>
      </Menu>
      {createModal}
      {editModal}
    </>
  );
};

export default TipoReceitaEncargo;
