import styled, { css } from 'styled-components';

type ListProps = {
  search?: boolean;
  smallMinWidth?: boolean;
};

export const MultiselectSearch = styled.div`
  margin: 1.9rem 2.5rem;
  position: relative;

  svg {
    position: absolute;
    right: 0;
    width: 1.6rem !important;
    height: 1.6rem !important;
    top: 0.4rem;
    fill: #7e93954d;
  }

  input {
    border: none;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #e3e8eb;
    }
  }
`;

export const Label = styled.div`
  margin-right: 1rem;
  flex: 0 1;
`;

export const List = styled.ul<ListProps>`
  border-top-left-radius: ${props => (props.search ? '0px' : '20px')};
  border-top-right-radius: ${props => (props.search ? '0px' : '20px')};
  list-style: none;
  height: 25rem;
  overflow-y: auto;
  margin: 0;
  padding: 0;
`;

interface ItemProp {
  readonly isSelected: boolean;
}

export const ListItem = styled.li<ItemProp>`
  padding: 1.6rem 3rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #495e84;
  font-size: 12px;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #00959c;
      color: #fff;

      svg path {
        fill: white;
      }
    `}
`;

export const Content = styled.div`
  position: relative;
  flex: 1 1;
`;

interface BoxProps {
  readonly completed: boolean;
}

export const Box = styled.div<BoxProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 1rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  border-radius: 4rem;
  border: 1px solid #7e93954d;
  color: #7e9395;
  /* width: 30rem; */
  cursor: pointer;

  svg {
    width: 1.5rem !important;
    fill: #7e9395;
    margin-left: 1rem;
  }

  p {
    margin: 0 !important;

    color: #7e9395;
    white-space: nowrap;
  }

  ${({ completed }) =>
    completed &&
    css`
      color: #00959c;
    `}
`;

export const Options = styled.div`
  position: absolute;
  background-color: #fff;
  top: 6rem;
  width: 100%;
  min-width: 40rem;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  z-index: 10;
`;

export const Tick = styled.div`
  height: 2.1rem;
  width: 2.1rem;
  border: 1px solid #7e9395;
  border-radius: 2px;
  margin-left: 14%;

  &.selected {
    background-color: #31c6d3;
    border: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
`;

export const MultiselectWrapper = styled.div<ListProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* max-width: 43.5rem; */
  /* max-height: 4.7rem; */
  min-width: 30rem;

  ${({ smallMinWidth }) =>
    smallMinWidth &&
    css`
      min-width: 15rem;
    `}
`;

export const Title = styled.div`
  color: #495e84;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
`;

export const FinishButton = styled.button`
  background-color: #00959c;
  border: none;
  padding: 1rem 3.5rem;
  border-radius: 50px;
  color: #fff;
  // margin-left: 10%;

  &:focus {
    outline: none;
  }
`;

export const SearchInput = styled.input``;
