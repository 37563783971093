import React, { useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Grid, IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { Button, Form, Input, SingleSelect } from '../../../../_core/_components';
import { EditUnidadeEconomicaProperty } from './reducer';

type Props = {
  empresas: any[];
  form: any;
  dispatch: React.Dispatch<{
    type: EditUnidadeEconomicaProperty;
    payload: any;
  }>;
  onClickSave: () => void;
  onClickDelete: (index: number) => void;
  onClose: () => void;
};

const Modal: React.FC<Props> = ({ empresas, form, dispatch, onClickSave, onClickDelete, onClose }: Props) => {
  const setCodigoBU = (payload: string, index: number) => {
    const arr = [...form.empresas];
    arr[index].codigoUnidadeEconomica = payload;
    dispatch({ type: 'SET_EMPRESAS', payload: arr });
  };

  const setEmpresa = (payload: unknown, index: number) => {
    const arr = [...form.empresas];
    arr[index].empresaId = payload;
    dispatch({ type: 'SET_EMPRESAS', payload: arr });
  };

  const onClickAdd = () => {
    const arr = [...form.empresas];
    arr.push({
      codigoUnidadeEconomica: '',
      empresaId: 0,
    });
    dispatch({ type: 'SET_EMPRESAS', payload: arr });
  };

  const camposRelacoes = useMemo(() => {
    const empresasOptions = empresas.map(e => ({ label: e.razaoSocial, value: e.id }));
    const arr: any[] = [];
    form.empresas.forEach((item: any, i: number) => {
      const empresaState = empresasOptions.find(e => e.value === item.empresaId);
      arr.push(
        <SingleSelect
          state={[empresaState, option => setEmpresa(option?.value, i)]}
          options={empresasOptions}
          isClearable={false}
        />,
      );
      arr.push(
        <Input
          state={[
            item.codigoUnidadeEconomica,
            (value: React.SetStateAction<string>) => setCodigoBU(value.toString(), i),
          ]}
          label="Código da UE"
        />,
      );
      const addButton = (
        <IconButton onClick={onClickAdd}>
          <Add />
        </IconButton>
      );
      const removeButton = (
        <IconButton onClick={() => onClickDelete(i)}>
          <Delete />
        </IconButton>
      );
      if (form.empresas.length === i + 1) {
        arr.push(
          <>
            {addButton}
            {removeButton}
          </>,
        );
      } else {
        arr.push(removeButton);
      }
    });
    return arr;
  }, [form.empresas]);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar Shopping
        </span>
        <Grid>
          <Form items={camposRelacoes} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickSave} text="Salvar" />
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
