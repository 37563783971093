import styled from 'styled-components/macro';

export const DataContainer = styled.div`
  width: 80%;
  padding: 0 3rem;
`;

export const Table = styled.table`
  border-collapse: collapse;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 2rem;
  tr {
    &:nth-child(odd) {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &:nth-child(even) {
      background: #e5e9ea 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &:first-child {
      background-color: #31c6d3;
      padding: 1.6rem;
      border-radius: 0.8rem 0.8rem 0rem 0rem;
      color: #fff;

      font-weight: 600;
      line-height: 4.3rem;
    }
    td {
      padding: 2.5rem 1.2rem;
      text-align: left;
      color: #3f3f3f;

      text-transform: uppercase;
      text-align: left;
    }
    th {
      text-align: left;
      padding: 0 1.8rem;
    }
  }
`;

export const AddButton = styled.button`
  border: 0;
  background-color: transparent;
  color: #7e9395;

  line-height: 3.2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;

  :hover {
    background-color: white;
    color: #31c6d3;
    padding: 0 2rem;

    border-radius: 1.3rem;
  }

  img {
    margin-left: 1rem;
    width: 3rem;
  }
`;

export const TopBar = styled.div`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50rem;
`;

export const ListActions = styled.div``;

export const ListActionItem = styled.div`
  padding: 1.5rem 1.5rem;
  cursor: pointer;

  transition: all 0.5s ease;

  :hover {
    background-color: #7e9395;
    color: white;
  }
`;

export const SearchBar = styled.div`
  display: flex;
`;
export const SearchField = styled.div`
  margin-right: 5rem;
  position: relative;
  svg {
    fill: #208cb9;
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }
`;
