import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { AuthContext } from '../../../_main/contexts/auth';
import { Container, Section } from './styles';
import api from '../../../_core/api';
import ConfirmationModal from '../../../_core/_components/confirmation-modal';
import Table from '../../../_core/_components/table';
import { handleErrors, success, warning } from '../../../_core/services/toast';
import { downloadXls } from '../../../_core/services/download';
import { Button, Checkbox, Form, Input, Option, Select } from '../../../_core/_components';
import { mascaraNumerosVirgula } from '../../../_core/masks';

export const NegativacaoManual: React.FC = () => {
  const { id, profile, shoppings: profileShoppings } = useContext(AuthContext);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [shoppingList, setShoppingList] = useState<Option[]>([]);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [numeroBoleto, setNumeroBoleto] = useState<string>('');
  const [luc, setLuc] = useState<string>('');
  const [nomeFantasia, setNomeFantasia] = useState<string>('');
  const [customerInSerasa, setCustomerInSerasa] = useState(false);
  const [customerInOutSerasa, setCustomerInOutSerasa] = useState(false);
  const [customerNeverSerasa, setCustomerNeverSerasa] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppingList(listShopping);
      setSelectedShoppings(listShopping);
    }
  }, [profileShoppings]);

  const getFilters = async () => {
    try {
      setLoading(true);
      const response = await api.post('/workflow/serasa/serasa-manual/buscar/boletos-shopping', {
        idShopping: selectedShoppings.map(item => item.value),
        idUsuario: id,
        clientesNegativado: customerInSerasa,
        clientesForamNegativado: customerInOutSerasa,
        clientesNuncaNegativado: customerNeverSerasa,
        numBoletos: numeroBoleto.length > 0 ? numeroBoleto.split(',') : undefined,
        marca: nomeFantasia.length > 0 ? nomeFantasia : undefined,
        luc: luc.length > 0 ? luc : undefined,
        tipo: 'INCLUSAO_MANUAL',
      });
      if (_.isEmpty(response.data.content)) {
        warning('Não obteve resultados.');
        return;
      }
      setData(response.data.content);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const submitNegativacaoManual = async () => {
    setLoading(true);
    try {
      const boletos = data
        .filter(item => selectedItems.includes(item.idBoleto))
        .map(item => ({
          idBoleto: item.idBoleto,
          loja: {
            idSerasaLoja: item.serasaLoja.idSerasaLoja,
            negativar: true,
          },
          fiadores: item.fiadores.map((e: any) => ({
            idSerasaBoletoFiador: e.idSerasaBoletoFiador,
            negativar: true,
          })),
        }));
      await api.post('/workflow/serasa/serasa-manual/negativar-manual', {
        idUsuario: id,
        boletos,
      });
      success('Negativações submetidas com sucesso');
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
    setConfirmOpen(false);
  };

  const onClickCheckbox = (idBoleto: number) => {
    const arr = [...selectedItems];
    if (arr.includes(idBoleto)) {
      setSelectedItems(arr.filter(e => e !== idBoleto));
    } else {
      arr.push(idBoleto);
      setSelectedItems(arr);
    }
  };

  const bToggleAll = useMemo(() => {
    const allBoletosIds = data.map(e => e.idBoleto);
    const selectedIds = selectedItems.map(e => e);
    return allBoletosIds.every(e => selectedIds.includes(e));
  }, [selectedItems, data]);

  const onToggleAll = () => {
    if (bToggleAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(data.map(e => e.idBoleto));
    }
  };

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      const fiadoresOptions: any[][] = [];
      data.forEach((item: any) => {
        fiadoresOptions.push(
          item.fiadores.map((fiador: any) => ({
            value: fiador.cpfcnpjFiador,
            label: fiador.nomeFiador,
          })),
        );
      });
      return data.map((item, i) => ({
        toggle: (
          <Checkbox onClick={() => onClickCheckbox(item.idBoleto)} checked={selectedItems.includes(item.idBoleto)} />
        ),
        idBoleto: item.idBoleto,
        nmShopping: item.nmShopping,
        nomFantasia: item.nomFantasia,
        luc: item.luc,
        datVencimento: item.datVencimento,
        numBoleto: item.numBoleto,
        valTotalVencido: item.valTotalVencido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        fiadores: <Select state={[fiadoresOptions[i], () => null]} options={fiadoresOptions[i]} />,
      }));
    }
    return [];
  }, [data, selectedItems]);

  const download = async () => {
    setLoading(true);
    try {
      const response = await api.post(
        `/workflow/serasa/serasa-manual/download/boletos-shopping`,
        {
          idShopping: selectedShoppings.map(item => item.value),
          idUsuario: id,
          clientesNegativado: customerInSerasa,
          clientesForamNegativado: customerInOutSerasa,
          clientesNuncaNegativado: customerNeverSerasa,
          numBoletos: numeroBoleto.length > 0 ? numeroBoleto.split(',') : undefined,
          marca: nomeFantasia.length > 0 ? nomeFantasia : undefined,
          luc: luc.length > 0 ? luc : undefined,
          tipo: 'INCLUSAO_MANUAL',
        },
        {
          responseType: 'arraybuffer',
        },
      );
      downloadXls(response);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  };

  return (
    <>
      <FullScreenLoading isEnabled={loading} />

      <Container>
        <Form
          items={[
            <Select
              state={[selectedShoppings, setSelectedShoppings]}
              options={shoppingList}
              disabled={profile.includes('SHOPPING')}
            />,
            <Input label="Nome fantasia" state={[nomeFantasia, setNomeFantasia]} />,
            <Input label="LUC" state={[luc, setLuc]} />,
            <Input label="Número do boleto" state={[numeroBoleto, setNumeroBoleto]} pattern={mascaraNumerosVirgula} />,
            null,
            null,
            <Checkbox
              checked={customerInSerasa}
              onClick={() => setCustomerInSerasa(!customerInSerasa)}
              label="Clientes que já estão no Serasa atualmente"
            />,
            <Checkbox
              checked={customerInOutSerasa}
              onClick={() => setCustomerInOutSerasa(!customerInOutSerasa)}
              label="Clientes que já estiveram no Serasa anteriormente"
            />,
            <Checkbox
              checked={customerNeverSerasa}
              onClick={() => setCustomerNeverSerasa(!customerNeverSerasa)}
              label="Clientes que nunca estiveram no Serasa"
            />,
          ]}
          submitButton={<Button onClick={getFilters} text="Pesquisar" />}
        />

        {renderedData.length > 0 && (
          <>
            <Table
              columns={[
                { label: <Checkbox type="secondary" checked={bToggleAll} onClick={onToggleAll} />, key: 'toggle' },
                { label: 'Nome do Shopping', key: 'nmShopping', orderable: true },
                { label: 'Nome fantasia', key: 'nomFantasia', orderable: true },
                { label: 'LUC', key: 'luc', orderable: true },
                { label: 'Vencimento do boleto', key: 'datVencimento', orderable: true },
                { label: 'Número do boleto', key: 'numBoleto', orderable: true },
                { label: 'Valor do Boleto', key: 'valTotalVencido', orderable: true },
                { label: 'Fiadores', key: 'fiadores' },
              ]}
              data={renderedData}
            />
            <Section>
              <Button
                onClick={() => (selectedItems.length === 0 ? null : setConfirmOpen(true))}
                text="NEGATIVAR LOJISTA"
              />
              <Button text="EXPORTAR" onClick={download} />
            </Section>
          </>
        )}
        <ConfirmationModal
          handleCancel={() => setConfirmOpen(false)}
          handleOk={submitNegativacaoManual}
          open={confirmOpen}
          text="Por favor, confirmar que deseja enviar para a negativação junto ao Serasa os boletos selecionados em tela"
        />
      </Container>
    </>
  );
};
