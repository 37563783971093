import styled from 'styled-components';

export const DialogContent = styled.div`
  input {
    background-color: #fff;
  }
`;

export const Header = styled.div`
  padding: 1.9rem 2.66rem;
  background-color: #00959c;
  position: relative;

  > svg {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: 1rem;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  color: #fff;
  font-weight: 600;
  font-size: 1.2rem;
`;

export const MainContent = styled.div`
  /* display: flex; */
  padding: 3rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'principal fases '
    'principal fases'
    'saldo fases'
    'actions actions';
  grid-gap: 2.12rem;
`;

export const Section = styled.div`
  border: 1px solid #00959c;
  position: relative;
  border-radius: 0.4rem;
  height: 100%;
  padding: 1.8rem 2rem;
  background-color: #fbfbfb;
`;
export const SectionTitle = styled.h3`
  background-color: #00959c;
  font-size: 1rem;
  color: #fff;
  position: absolute;
  padding: 0.27rem 1.2rem;
  top: -1rem;
  left: 1rem;
  border-radius: 0.4rem;
`;

export const PrincipalSection = styled.div`
  grid-area: principal;
`;
export const FasesSection = styled.div`
  grid-area: fases;
`;
export const SaldoSection = styled.div`
  grid-area: saldo;
`;

export const ActionsSection = styled.div`
  grid-area: actions;
`;

export const ActionsLabel = styled.div`
  color: #91939a;
  font-size: 0.95rem;
  margin-bottom: 0.5rem;
`;
export const ActionsButtons = styled.div`
  display: flex;
  gap: 1rem;

  > button {
    background-color: #00959c;
    border-radius: 0.5rem;
  }
`;

export const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 0;
`;

export const FieldFlex = styled.div`
  flex: 0 0 33%;
  padding: 0 0.5rem;
`;

export const SaldoValue = styled.div`
  font-size: 1.23rem;
  color: #00959c;
`;

export const FieldSaldo = styled.div`
  flex: 1;
  margin: 0 1rem;
`;

export const RazaoField = styled.div`
  flex: 1 0 100%;
  padding: 0 0.5rem;
`;

export const FieldValue = styled.div`
  font-size: 1rem;
  color: #313131;
`;

export const FieldLabel = styled.div`
  color: #91939a;
  font-size: 0.9rem;
`;

export const Fases = styled.div`
  padding: 2rem;
  flex: 50%;
`;

export const DetalhamentoFases = styled.div``;
export const FasesTitle = styled.div`
  color: #00959c;
  font-weight: 700;

  text-align: center;

  svg {
    margin-left: 2rem;
    cursor: pointer;
  }
`;
export const FasesItems = styled.div``;

export const FasesItem = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0.5rem 0;

  svg {
    width: 1rem;
    height: 1rem;
    border: 1px solid #00959c;
    border-radius: 0.2rem;
    padding: 0.2rem;
  }
`;

export const LoadingArea = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 9999;
`;

export const BottomContent = styled.div`
  display: flex;
`;

export const CheckIcon = styled.img`
  /* display: none; */
  width: 1rem;
  position: absolute;
  top: 3.8rem;
  right: 4rem;
`;

export const FaseStatusSection = styled.div`
  margin: 1rem 0;
  border-top: 1px solid #d1dbdc;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FasesStatus = styled.div``;
export const DetalhamentoAction = styled.div``;
export const DetalhamentoButton = styled.button`
  background-color: unset;
  border: 1px solid #00959c;
  color: #00959c;
  padding: 0.5rem 0.7rem;
  cursor: pointer;
`;

export const FaseBlank = styled.span`
  width: 1rem;
  height: 1rem;
  border-radius: 0.2rem;
  border: 1px solid #d7d7d7;
`;

export const FaseDados = styled.div``;

export const FaseName = styled.div`
  font-size: 1rem;
  color: #313131;
`;

export const FaseData = styled.div`
  font-size: 0.9rem;
  color: #91939a;
`;

export const SaveIcon = styled.img`
  width: 1rem;
  position: absolute;
  top: 3.7rem;
  right: 3.7rem;
  cursor: pointer;
`;

export const Toggle = styled.div`
  .rotating {
    position: absolute;
    right: 4rem;
    top: 2.8rem;
    width: 2rem;
  }
  /* > input:focus + img {
    display: block;
  } */
`;
