import { Option } from '../../../../_core/_components';

// An enum with all the types of actions to use in our reducer
export type EditProfileProperty = 'SET_PERFIL' | 'PERFIL' | 'COD_PERFIL' | 'PERMISSAO' | 'ACTIVE';

type EditProfileState = {
  codPerfil: string;
  flagAtivo: boolean;
  idPerfil?: number;
  nome: string;
  permissoes: Option[];
  todasPermissoes: Option[];
};

export const INITIAL_STATE = {
  codPerfil: '',
  flagAtivo: true,
  idPerfil: undefined,
  nome: '',
  permissoes: [],
  todasPermissoes: [],
};

export function reducer(state: EditProfileState, { type, payload }: { type: EditProfileProperty; payload: any }): any {
  switch (type) {
    case 'SET_PERFIL':
      return {
        ...payload,
      };
    case 'PERFIL':
      return {
        ...state,
        nome: payload,
      };
    case 'COD_PERFIL':
      return {
        ...state,
        codPerfil: payload,
      };
    case 'PERMISSAO':
      return {
        ...state,
        permissoes: payload,
      };
    case 'ACTIVE':
      return {
        ...state,
        flagAtivo: payload,
      };
    default:
      return state;
  }
}
