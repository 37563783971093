export function mascaraDDMMAAAA(data: string): string {
  let sanitizedValue = data.replace(/\D/g, ''); // Remove caracteres não numéricos
  if (sanitizedValue.length > 8) {
    sanitizedValue = sanitizedValue.substr(0, 8); // Limita para 8 caracteres
  }
  return sanitizedValue.replace(/(\d{2})(\d{2})(\d{4})/, '$1/$2/$3');
}

export function mascaraMMAAAA(data: string): string {
  let sanitizedValue = data.replace(/\D/g, ''); // Remove caracteres não numéricos
  if (sanitizedValue.length > 6) {
    sanitizedValue = sanitizedValue.substr(0, 6); // Limita para 6 caracteres
  }
  return sanitizedValue.replace(/(\d{2})(\d{4})/, '$1/$2');
}

export function mascaraNumeros(value: string): string {
  if (value === undefined || value === null) return value;
  return value.toString().replace(/[^\d]+/g, '');
}

export function mascaraNumerosVirgula(value: string): string {
  if (value === undefined || value === null) return value;

  // Remove caracteres que não são números ou vírgulas
  const cleanedValue = value.toString().replace(/[^\d,]+/g, '');

  return cleanedValue;
}

export function mascaraNumeracaoPtBr(value: string): string {
  /// Remove todos os caracteres que não são dígitos
  const digitsOnly = value.replace(/\D/g, '');

  // Converte a string de dígitos para um número
  const number = parseFloat(digitsOnly);

  // Verifica se o número é válido
  if (Number.isNaN(number)) {
    // Caso não seja um número válido, retorna o valor original
    return value;
  }

  // Divide o número por 100 para ajustar a escala
  const adjustedNumber = number / 100;

  // Formata o número como dinheiro
  const formattedNumber = adjustedNumber.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return formattedNumber;
}

export function converterParaFloat(valorFormatado: string): number {
  // Remove todos os caracteres que não são dígitos nem o separador decimal
  const cleanedValue = valorFormatado.replace(/[^\d,.-]/g, '');

  // Substitui a vírgula por ponto para obter o formato numérico correto
  const formattedValue = cleanedValue.split('.').join('').replace(',', '.');

  // Converte o valor para float
  const floatValue = parseFloat(formattedValue);

  return floatValue;
}

export const maskCPF = (value: string) => {
  return value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

export const maskCNPJ = (value: string) => {
  return value
    .replace(/\D/g, '')
    .slice(0, 14)
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    .replace(/\.(\d{3})(\d)/, '.$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2');
};

export const maskCPFCNPJ = (value: string) => {
  if (value.replace(/[^\d]/g, '').length > 11) {
    return maskCNPJ(value);
  }
  return maskCPF(value);
};
