import React, { useState, useEffect, useMemo, useCallback } from 'react';
import moment from 'moment';
import { Container, MainRow, SearchColumn, SizedBoxHeight, FileDownload } from './styles';
import api from '../../../_core/api';
import Modal from './modal';
import { handleErrors, success } from '../../../_core/services/toast';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import { downloadXls } from '../../../_core/services/download';
import { Table } from '../../../_core/_components/table';
import { useCache } from '../../../_core/cache';
import { requests } from '../../../requests';
import { Button, Checkbox, Form, Input, Select, SingleSelect, Option } from '../../../_core/_components';
import { mascaraDDMMAAAA, mascaraMMAAAA } from '../../../_core/masks';

export const StatusDados: React.FC = () => {
  const { response: responseFiltersShoppings, isLoading: isLoadingFiltersShoppings } = useCache({
    key: 'getFiltersShoppings',
    fn: requests.get('getFiltersShoppings'),
  });
  const { response: responseStatus, isLoading: isLoadingStatus } = useCache({
    key: 'getStatus',
    fn: requests.get('getStatus'),
  });
  const [shoppingsOptions, setShoppingsOptions] = useState<Option[]>([]);
  const [statusOptions, setStatusOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [shopping, setShopping] = useState<Option[]>([]);
  const [date, setDate] = useState('');
  const [processDate, setProcessDate] = useState('');
  const [status, setStatus] = useState<Option>();
  const [result, setResult] = useState<any[]>([]);
  const [shoppings, setShoppings] = useState<string[]>([]);

  useEffect(() => {
    if (responseFiltersShoppings !== undefined) {
      const shoppingList: any[] = [];
      responseFiltersShoppings.data.forEach((item: any) => {
        shoppingList.push({
          value: item.shoppingId.toString(),
          label: item.shopping,
        });
      });
      setShoppingsOptions(shoppingList);
    }
  }, [responseFiltersShoppings]);

  useEffect(() => {
    if (responseStatus !== undefined) {
      const statusList: any[] = [
        {
          value: 0,
          label: 'Selecione',
        },
      ];
      responseStatus.data.forEach((item: any) => {
        statusList.push({
          value: item.id,
          label: item.status,
        });
      });
      setStatusOptions(statusList);
    }
  }, [responseStatus]);

  const data = useMemo(
    () =>
      result.map(e => ({
        id: e.id,
        nameShopping: e.nameShopping,
        processingDate: moment(e.processingDate).format('DD/MM/YYYY'),
        dateCreated: moment(e.dateCreated).format('DD/MM/YYYY hh:mm:ss'),
        status: e.status.status,
      })),
    [result],
  );

  const onClickSearch = useCallback(async () => {
    setLoading(true);
    try {
      const [month, year] = date.split('/');
      const queryDate = year ? `month=${month}&year=${year}` : '';
      const queryShopping = shopping.length > 0 ? `shopping=${shopping.map(e => e.label).join(',')}` : '';
      const queryProcessDate = processDate ? `processingDate=${convertDate(processDate)}` : '';
      const queryStatus = status?.value ? `status=${status!.value}` : '';
      const query = [queryDate, queryShopping, queryProcessDate, queryStatus].filter(e => e).join('&');
      const response = await api.get(`/spredsheet/shopping/AUXILIARY?${query}`);
      setResult(response.data);
    } catch (error) {
      handleErrors(error);
    }
    setLoading(false);
  }, [shopping, date, processDate, status]);

  const onSubmitCalcInadimp = useCallback(
    async (values: any) => {
      setLoading(true);
      setOpen(false);
      try {
        await api.post(`/calculo-inadimplencia`, { ...values, shoppings });
        success('Processo de Cálculo Submetido');
      } catch (error) {
        handleErrors(error);
      }
      setLoading(false);
    },
    [shoppings],
  );

  const onClickDownload = useCallback(
    async (item: any) => {
      const response = await api.get(`/spredsheet/download/${item.id}`, { responseType: 'arraybuffer' });
      downloadXls(response);
    },
    [result],
  );

  const convertDate = (value: string) => {
    const arr = value.split('/');
    return `${arr[2]}-${arr[1]}-${arr[0]}`;
  };

  const onClickCheckbox = (item: any) => {
    const arr = [...shoppings];
    if (arr.includes(item.nameShopping)) {
      setShoppings(arr.filter(e => e !== item.nameShopping));
    } else {
      arr.push(item.nameShopping);
      setShoppings(arr);
    }
  };

  const bToggleAll = useMemo(() => {
    const allShoppingsNames = data.map(e => e.nameShopping);
    return allShoppingsNames.every(e => shoppings.includes(e));
  }, [shoppings, data]);

  const onToggleAll = () => {
    const allShoppingsNames = data.map(e => e.nameShopping);
    if (bToggleAll) {
      setShoppings([]);
    } else {
      setShoppings(allShoppingsNames);
    }
  };

  const renderedData = useMemo(() => {
    if (data.length > 0) {
      return data.map(item => ({
        toggle: <Checkbox checked={shoppings.includes(item.nameShopping)} onClick={() => onClickCheckbox(item)} />,
        nameShopping: item.nameShopping,
        processingDate: item.processingDate,
        dateCreated: item.dateCreated,
        status: item.status,
        download: <FileDownload onClick={() => onClickDownload(item)}>Download</FileDownload>,
      }));
    }
    return [];
  }, [data, shoppings]);

  return (
    <>
      <FullScreenLoading isEnabled={loading || isLoadingFiltersShoppings || isLoadingStatus} />
      <Container>
        <MainRow>
          <SearchColumn>
            <h1>Pesquisa</h1>
            <Form
              items={[
                <Select placeholder="Shoppings" options={shoppingsOptions} state={[shopping, setShopping]} />,
                <Input
                  placeholder="MM/AAAA"
                  label="Mês e ano de processamento"
                  state={[date, setDate]}
                  pattern={mascaraMMAAAA}
                />,
                <Input
                  placeholder="DD/MM/AAAA"
                  label="Data de processamento"
                  state={[processDate, setProcessDate]}
                  pattern={mascaraDDMMAAAA}
                />,
                <SingleSelect placeholder="Status" options={statusOptions} state={[status, setStatus]} />,
              ]}
              submitButton={<Button text="Pesquisar" onClick={onClickSearch} />}
            />
          </SearchColumn>
        </MainRow>
        {renderedData.length > 0 && (
          <>
            <h1>Resultado</h1>
            <Table
              columns={[
                { label: <Checkbox checked={bToggleAll} onClick={onToggleAll} type="secondary" />, key: 'toggle' },
                { label: 'Shopping', key: 'nameShopping', orderable: true },
                { label: 'Data de processamento', key: 'processingDate', orderable: true },
                { label: 'Data de carregamento', key: 'dateCreated', orderable: true },
                { label: 'Status', key: 'status', orderable: true },
                { label: 'Arquivo', key: 'download' },
              ]}
              data={renderedData}
            />
            <SizedBoxHeight />
            <div>
              <Button onClick={() => setOpen(true)} text="CALCULAR INADIMPLÊNCIA" />
            </div>
          </>
        )}
      </Container>
      {open && <Modal onClose={() => setOpen(false)} onSubmit={onSubmitCalcInadimp} />}
    </>
  );
};
