import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  padding: 2rem;
  background-color: #fff;
  border-radius: 15px;
  position: relative;
  left: 1rem;
  top: -2rem;
  width: 75vw;
  > h2 {
    color: #7e9395;

    margin-bottom: 1rem;
    font-weight: 600;
  }
`;

export const ContentWrapper = styled.div`
  background-color: #fff;
  padding: 1rem 0;
  margin: 0 1rem;
  border-radius: 1.1rem;
`;

export const ViewChanger = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 2rem;
`;

export const ActionsRow = styled.div`
  margin: 1rem;
  display: flex;
  justify-content: space-between;
`;

export const TableWrapper = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  overflow-y: hidden;
`;

export const TextInput = styled.input`
  background-color: #fff;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 32px;
  border: 1px solid #7e93954d;
  width: 100%;

  ::placeholder {
    color: #7e9395;
  }

  :disabled {
    background: #ebebeb;
    cursor: not-allowed;
  }
`;

export const FieldColumn = styled.div`
  flex: 25%;
  position: relative;

  & > button {
    bottom: 0;
    position: absolute;
  }
`;

export const FiltroWrapper = styled.div`
  /* max-width: 130rem; */
  background-color: #fff;
  border-radius: 10px;
  min-height: 30rem;
  padding: 2rem 3rem;
  display: flex;
`;

export const CheckArea = styled.div`
  border-bottom: 1px solid #7e9395;
  padding-bottom: 20px;
`;
export const FieldArea = styled.div`
  margin: 2rem 0;
  padding: 0 2rem;
  position: relative;

  & > button {
    margin-bottom: 2rem;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    display: none !important;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield !important;
  }
`;

export const Label = styled.div`
  color: #7e9395;

  font-weight: 600;
`;

export const LineTotalizador = styled.div`
  background-color: #00959c;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;

  padding: 1rem 0;
  color: #fff;
`;

export const Bottom = styled.div`
  display: flex;
  margin-top: 3rem;
`;

export const ImportarActions = styled.div`
  flex: 33%;
`;

export const AcoesPorFase = styled.div`
  background-color: #fff;
  padding: 1.5rem 2.3rem;
  border-radius: 10px;
  margin: 1rem 1rem;

  > h2 {
    color: #00959c;
    font-size: 1.03rem;
    font-weight: normal;
  }
`;

export const Acoes = styled.div`
  display: flex;
  margin-top: 1.61rem;
`;

export const Acao = styled.div`
  flex: 1;
  border-right: 1px solid #ececec;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 0.5rem;
`;

export const AcoesRow = styled.div`
  display: grid;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

export const getGridIconStyle = (currentView: 'table' | 'card') => {
  const style = {
    color: '#bababa',
  };
  if (currentView === 'card') {
    style.color = '#00959c';
  }

  return style;
};

export const getTableIconStyle = (currentView: 'table' | 'card') => {
  const style = {
    color: '#bababa',
  };
  if (currentView === 'table') {
    style.color = '#00959c';
  }

  return style;
};

export const CardWrapper = styled.div`
  display: flex;
  margin: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  background-color: #fbfbfb;
  border-radius: 8px;
  padding: 1.4rem 1.9rem;
  width: 19.15rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const CardLabel = styled.div`
  color: #91939a;
  font-size: 0.75rem;
`;

type CardInfoProps = {
  bold?: boolean;
  tipo?: string;
};

export const CardInfo = styled.div<CardInfoProps>`
  color: #313131;
  font-size: 0.8125rem;
  border-radius: 50rem;
  padding: 0.3rem 0;

  ${({ bold, tipo }) => css`
    font-weight: ${bold ? 'bold' : 'normal'};

    ${tipo === 'LOJA' &&
    css`
      background-color: #79e391;
      color: #217d00;
    `}

    ${tipo === 'MIDIA' &&
    css`
      background-color: #b875c2;
      color: #4a0078;
    `}
    ${tipo === 'MALL' &&
    css`
      background-color: #f8e791;
      color: #4e6200;
    `}

    ${tipo === 'CONFISSAO' &&
    css`
      background-color: #ffe4c4;
      color: #e87e00;
    `}

     ${tipo === 'CDU' &&
    css`
      background-color: #9e9cea;
      color: #2320a8;
    `}


    ${tipo &&
    css`
      text-align: center;
    `}
  `}
`;
export const CardSeparator = styled.div`
  border-bottom: 1px solid #f5f5f5;
`;

export const CardLine = styled.div`
  display: flex;
  padding: 0.75rem 0;
`;
export const CardCol = styled.div`
  flex: 1;
  padding: 0 0.5rem;
`;
export const CardValueInfo = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: #c27272;
`;

export const FaseIcon = styled.img`
  width: 1.5rem;
`;
export const AcaoText = styled.div`
  font-size: 0.7rem;
  color: #91939a;
  text-align: center;
`;
export const AcaoPercent = styled.div`
  font-size: 1.09rem;
  font-weight: bold;
  color: #5d5c66;
`;
