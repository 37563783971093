import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import * as S from './styles';
import { AjuizamentoDetalhes, TipoCancelamento } from '../types';
import TickIcon from '../../../../assets/tick.jsx';
import CloseIcon from '../../../../assets/close.jsx';
import { toReal } from '../../../../_core/services/formaters';
import { Button, Input } from '../../../../_core/_components';
import { mascaraNumeracaoPtBr, converterParaFloat } from '../../../../_core/masks';

type Props = {
  onCloseDetalhamento: () => void;
  ajuizamento: AjuizamentoDetalhes;
  onClickUpdateFase: () => void;
  onSave: (payload: any) => Promise<void>;
  onCancelamento: (tipoCancelamento: TipoCancelamento) => void;
};

const Detalhamento: React.FC<Props> = ({
  onCloseDetalhamento,
  ajuizamento,
  onClickUpdateFase,
  onSave,
  onCancelamento,
}) => {
  const [saldoDevedorAtualizado, setSaldoDevedorAtualizado] = useState(
    ajuizamento.saldoDevedorAtualizado
      ? mascaraNumeracaoPtBr(ajuizamento.saldoDevedorAtualizado.toString())
      : 'R$ 0,00',
  );

  const setSavePayload = () => {
    const payload = {
      documentacaoRecebida: ajuizamento.documentacaoRecebida,
      numeroChamado: ajuizamento.numeroChamado,
      saldoDevedorAtualizado: converterParaFloat(saldoDevedorAtualizado),
      guias: ajuizamento.guias,
      idStatusAcao: ajuizamento.idStatusAcao,
      idEspecieCredito: ajuizamento.idEspecieCredito,
      idFase: ajuizamento.idFaseAtual,
    };
    onSave(payload);
  };

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={onCloseDetalhamento} aria-labelledby="max-width-dialog-title">
      <S.DialogContent>
        <S.Header>
          <S.Title>Controle de Ações Ajuizadas - Por Contrato</S.Title>
          <CloseIcon click={onCloseDetalhamento} color="#fff" />
        </S.Header>
        <S.MainContent>
          <S.PrincipalSection>
            <S.Section>
              <S.SectionTitle>Principal</S.SectionTitle>
              <S.Fields>
                <S.RazaoField>
                  <S.FieldLabel>Razão Social</S.FieldLabel>
                  <S.FieldValue>{ajuizamento.razaoSocial}</S.FieldValue>
                </S.RazaoField>
                <S.FieldFlex>
                  <S.FieldLabel>Origem</S.FieldLabel>
                  <S.FieldValue>{ajuizamento.origem}</S.FieldValue>
                </S.FieldFlex>
                <S.FieldFlex>
                  <S.FieldLabel>Shopping</S.FieldLabel>
                  <S.FieldValue>{ajuizamento.nomeShopping}</S.FieldValue>
                </S.FieldFlex>

                <S.FieldFlex>
                  <S.FieldLabel>Nome Fantasia</S.FieldLabel>
                  <S.FieldValue>{ajuizamento.nomeFantasia}</S.FieldValue>
                </S.FieldFlex>
                <S.FieldFlex>
                  <S.FieldLabel>LUC</S.FieldLabel>
                  <S.FieldValue>{ajuizamento.luc}</S.FieldValue>
                </S.FieldFlex>
                <S.FieldFlex>
                  <S.FieldLabel>Número Contato</S.FieldLabel>
                  <S.FieldValue>{ajuizamento.numeroContrato}</S.FieldValue>
                </S.FieldFlex>
                <S.FieldFlex>
                  <S.FieldLabel>CPF/CNPJ</S.FieldLabel>
                  <S.FieldValue>{ajuizamento.cpfCnpj}</S.FieldValue>
                </S.FieldFlex>
              </S.Fields>
            </S.Section>
          </S.PrincipalSection>
          <S.FasesSection>
            <S.Section>
              <S.SectionTitle>Fases</S.SectionTitle>
              <S.DetalhamentoFases>
                <S.FasesItems>
                  {ajuizamento.historicoFases.map((fase, i) => {
                    return (
                      <S.FasesItem key={i}>
                        {fase.conclusao ? <TickIcon /> : <S.FaseBlank />}
                        <S.FaseDados>
                          <S.FaseName>{fase.fase.nome}</S.FaseName>
                          <S.FaseData>
                            <Tooltip title="Data de criação">
                              <span>{fase.conclusao ? moment(fase.conclusao).format('DD/MM/YYYY') : '-'}</span>
                            </Tooltip>
                          </S.FaseData>
                        </S.FaseDados>
                      </S.FasesItem>
                    );
                  })}
                </S.FasesItems>
                <S.FaseStatusSection>
                  <S.FasesStatus>
                    <S.FaseData>Status do processo</S.FaseData>
                    <S.FaseName>{ajuizamento.faseAtual}</S.FaseName>
                  </S.FasesStatus>
                  <S.DetalhamentoAction>
                    <S.DetalhamentoButton onClick={onClickUpdateFase}>DETALHAR</S.DetalhamentoButton>
                  </S.DetalhamentoAction>
                </S.FaseStatusSection>
              </S.DetalhamentoFases>
            </S.Section>
          </S.FasesSection>
          <S.SaldoSection>
            <S.Section>
              <S.SectionTitle>Saldo</S.SectionTitle>
              <S.Fields>
                <S.FieldSaldo>
                  <S.FieldLabel>Saldo Devedor Corrigido</S.FieldLabel>
                  <S.Toggle>
                    <Input state={[saldoDevedorAtualizado, setSaldoDevedorAtualizado]} pattern={mascaraNumeracaoPtBr} />
                  </S.Toggle>
                </S.FieldSaldo>
              </S.Fields>
            </S.Section>
          </S.SaldoSection>

          <S.ActionsSection>
            <S.ActionsLabel>
              {ajuizamento.idStatus === 11 ? 'Ação já cancelada' : 'Informar Cancelamento de Ação'}
            </S.ActionsLabel>
            {ajuizamento.idStatus !== 11 && (
              <S.ActionsButtons>
                <Button onClick={() => onCancelamento('DESISTENCIA')} text="Desistência da Ação" />
                <Button onClick={() => onCancelamento('EXCEÇÃO')} text="Inclusão como Exceção" />
              </S.ActionsButtons>
            )}
            <S.ActionsButtons>
              <Button onClick={setSavePayload} text="Atualizar saldo devedor" />
            </S.ActionsButtons>
          </S.ActionsSection>
        </S.MainContent>
      </S.DialogContent>
    </Dialog>
  );
};

export default Detalhamento;
