import styled from 'styled-components';

import DatePicker from 'react-datepicker';

import Pages from '@material-ui/lab/Pagination';

interface Itab {
  active?: boolean;
  onClick?: any;
  noActive?: boolean;
}

export const FilterBar = styled.div`
  width: 100%;
`;

export const TabMenu = styled.div``;

export const TabItem = styled.button<Itab>`
  background-color: ${({ active }) => (active ? '#ffffff' : '#e5e9ea')};
  display: ${({ noActive }) => (noActive ? 'none' : '')};
  border: 0;
  min-width: 125px;
  border-radius: 10px 10px 0 0;
  padding: 10px;
  margin-right: 2px;
  color: #7e9395;
`;

export const TabContent = styled.div<Itab>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  background-color: #ffffff;
  // max-height: 270px;
  height: 100%;
  padding: 35px;
  max-width: 1100px;

  .noMarginBottom {
    align-items: flex-start;
    margin-bottom: 0px;
  }
`;

export const Search = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 35px;
  align-items: center;

  #consultar {
    margin-top: 57px;
    margin-right: 5px;
  }
`;

export const Dpicker = styled(DatePicker)`
  border: 1px solid #7e93954d;
  border-radius: 20px;
  width: 129px;
  height: 39px;
  padding: 8px 10px;
  text-align: center;
  color: #7e9395;
`;

export const SelectArea = styled.div<Itab>`
  display: ${({ noActive }) => (noActive ? 'none' : '')};
  .tipoContrato {
    min-width: 150px;
  }
  .tipoShopping {
    min-width: 285px;
    height: 39px;
  }
  .tipoPortifolio {
    min-width: 260px;
    height: 39px;
  }
  div {
    min-height: 39px;
  }
`;
export const SelectAreaTemp = styled.div`
  width: 30rem;
  select {
    width: 90%;
    background-color: #ffffff;
    border: 1px solid #7e93954d;
    padding: 1rem;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    color: #7e9395;
  }
`;

export const InputArea = styled.div`
  margin: 0 40px;
  &:first-child {
    margin: 0;
  }
  input {
    background: #ffffff 0% 0% no-repeat padding-box;
    font: normal normal normal 14px Open Sans;
    border: 1px solid #7e93954d;
    border-radius: 20px;
    opacity: 1;
    padding: 10px;
    padding-left: 15px;
    width: 250px;
    height: 39px;
  }
  #luc {
    width: 195px;
  }
`;

export const RadioArea = styled.div`
  max-width: 170px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 40px;
  height: 71px;
  label {
    text-align: left;
  }
  input {
  }
  div {
    display: flex;
    flex-wrap: nowrap;
    min-width: 170px;
    align-items: baseline;
    font: normal normal normal 14px Open Sans;
    label {
      margin: 0;
      margin-right: 15px;
    }
  }
  span {
    top: -12px !important;
    left: -8px !important;
  }
`;

export const DateArea = styled.div<Itab>`
  display: block;
  box-sizing: border-box;
  // margin: 0 40px;
  margin: ${({ noActive }) => (noActive ? '0px 40px 0px 0px' : '0 40px;')};
  span {
    color: #7e9395;
    font-size: 16px;
  }
  .react-datepicker__day,
  .react-datepicker__day-name {
    width: 3rem;
  }
  .react-datepicker__current-month {
  }
`;

export const Label = styled.label`
  text-align: left;
  box-sizing: border-box;
  display: block;
  color: #7e9395;
  margin-bottom: 10px;
  font: normal normal normal 16px Open Sans;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    margin-right: 10px;
  }

  .div1 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .div2 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const TableContainer = styled.div`
  margin: 65px 0;
  h1 {
    color: #7e9395;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0px;
    margin: 60px 0 30px 0;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    thead {
      tr {
        opacity: 1;
        cursor: pointer;
        white-space: nowrap;
        th {
          background-color: #f2f4f4;
          text-align: left;
          color: #7e9395;
          font-weight: 600;
          font-size: 12px;
          padding: 10px;
          img {
            width: 13px !important;
            height: 7px !important;
            margin: auto !important;
            margin-left: 4px !important;
          }
          .semOrdenacao {
            display: none;
          }
          .crescente {
            transform: rotate(-180deg);
          }
          .decrescente {
          }
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        &:nth-child(odd) {
          background: #ffffff 0% 0% no-repeat padding-box;
          opacity: 1;
        }
        &:nth-child(even) {
          background: #e5e9ea 0% 0% no-repeat padding-box;
          opacity: 1;
        }
        td {
          padding: 15px 10px;
          text-align: left;
          font: normal normal normal 12px/17px Open Sans;
          letter-spacing: 0px;
          color: #3f3f3f;
          opacity: 1;
        }
      }
    }
    tfoot {
      tr {
        td {
          text-align: left;
          font: normal normal 600 14px Open Sans;
          letter-spacing: 0.7px;
          color: #00959c;
          text-transform: uppercase;
          opacity: 1;
          white-space: nowrap;
          padding: 15px 8px;
          p {
            text-align: right;
            font: normal normal 600 14px Open Sans;
            letter-spacing: 0.7px;
            color: #7e9395;
            text-transform: uppercase;
            opacity: 1;
            white-space: nowrap;
          }

          button {
            border-radius: 20px;
            border: 0;
            background-color: #fff;
            color: #00959c;
            font-weight: 600;
            padding: 8px 15px;
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 14px;
            img {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
`;

export const TableFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  button {
    border-radius: 20px;
    border: 0;
    background-color: #fff;
    color: #00959c;
    font-weight: 600;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    img {
      margin-left: 10px;
    }
  }
  span {
    color: #00959c;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const TH = styled.th`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin-bottom: 4px;
    width: 100%;
    text-align: center;
  }
`;

export const Tick = styled.div`
  margin: auto;
  height: 12px;
  width: 12px;
  border: 1px solid #7e9395;
  border-radius: 2px;

  &.selected {
    background-color: #31c6d3;
    border: none;
  }
`;

export const Pagination = styled(Pages)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    flex-wrap: nowrap;
    padding-left: 20px;
  }
  li {
    width: 28px;
    height: 28px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;

    button {
      border-radius: 0 !important;
      color: #7e9395 !important;
      font-size: 9px !important;
      font-weight: 600 !important;
      span {
        border-radius: 0 !important;
      }
    }

    .MuiPaginationItem-page.Mui-selected {
      background-color: #00959c27;
    }

    .MuiPaginationItem-page:hover {
      background-color: #00959c27;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SearchPriorization = styled.div`
  min-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 6px #7e939526;
  border-radius: 50px;
  opacity: 1;
  padding: 0 15px;
  margin-right: 30px;
  width: 50%;

  input {
    width: 85%;
    background: transparent;
    border: 0;
    padding: 8px 10px;
    margin-left: 10px;
  }
  img {
    width: 18px !important;
    margin-top: 0 !important;
  }
`;
