import styled from 'styled-components';

export const DialogContent = styled.div`
  padding: 5rem 3rem;
`;
export const Title = styled.div`
  color: #7e9395;
  font-weight: 600;
  text-align: center;
`;

export const FasesItems = styled.div`
  margin-top: 2rem;
  color: #7e9395;
`;
export const LoadingArea = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 9999;
`;

export const MainContent = styled.div`
  padding: 3rem 13rem;
`;

export const TextBox = styled.textarea`
  background-color: #fff;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 32px;
  border: 1px solid #7e93954d;
  width: 100%;
  height: 20rem;

  ::placeholder {
    color: #7e9395;
  }
`;

export const Label = styled.div`
  color: #7e9395;

  font-weight: 600;
  margin-bottom: 1rem;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: center;
`;

export const FieldArea = styled.div`
  margin: 2rem 0;
  padding: 0 2rem;
`;

export const TextInput = styled.input`
  background-color: #fff;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 32px;
  border: 1px solid #7e93954d;
  width: 100%;

  ::placeholder {
    color: #7e9395;
  }
`;

export const TopContent = styled.div`
  display: flex;
  padding: 0 13rem;
`;

export const DateText = styled.p`
  margin-bottom: 6rem;
`;
