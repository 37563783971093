import React, { useMemo, useState, useEffect, useReducer } from 'react';
import { Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import api from '../../../../_core/api';
import * as S from '../styles';
import { Input, PaginatedTable } from '../../../../_core/_components';
import { handleErrors, success } from '../../../../_core/services/toast';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { reducer } from './reducer';
import Modal from './modal';
import { useCache } from '../../../../_core/cache';
import { requests } from '../../../../requests';

export const UnidadeEconomica: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [nomShopping, setNomShopping] = useState('');
  const [unidadesEconomicas, setUnidadesEconomicas] = useState<any[]>([]);
  const [form, dispatch] = useReducer(reducer, undefined);

  const { response: responseEmpresas, isLoading: isLoadingEmpresas } = useCache({
    key: 'getEmpresas',
    fn: requests.get('getEmpresas'),
  });

  useEffect(() => {
    getUnidadesEconomicas();
  }, [pageInfo.currentPage]);

  const getUnidadesEconomicas = async () => {
    setIsLoading(true);
    try {
      const { currentPage } = pageInfo;
      const { data } = await api.get(`/empresas/unidades-economicas?page=${currentPage}`);
      setUnidadesEconomicas(data.data);
      setPageInfo(data.pageInfo);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const data = useMemo(() => {
    return unidadesEconomicas
      .filter(e => e.nome.includes(nomShopping))
      .map(e => ({
        ...e,
        editar: (
          <IconButton
            onClick={() =>
              dispatch({
                type: 'SET_UNIDADE_ECONOMICA',
                payload: {
                  ...e,
                  empresas: e.empresas?.length > 0 ? e.empresas : [{ codigoUnidadeEconomica: '', empresaId: 0 }],
                },
              })
            }
          >
            <Edit />
          </IconButton>
        ),
      }));
  }, [unidadesEconomicas, nomShopping]);

  const onClickDelete = async (index: number) => {
    setIsLoading(true);
    try {
      const arr = [...form.empresas];
      const empresa = arr[index];
      if (empresa.id) {
        await api.delete(`/empresas/unidades-economicas/empresa/${empresa.id}`);
      }
      arr.splice(index, 1);
      dispatch({ type: 'SET_EMPRESAS', payload: arr });
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const onClickSave = async () => {
    try {
      setIsLoading(true);
      const payload = form.empresas.map((e: any) => ({
        id: e.id,
        empresaId: e.empresaId,
        codigoUnidadeEconomica: e.codigoUnidadeEconomica,
        shoppingId: form.id,
        origem: form.origem,
      }));
      await api.put(`/empresas/unidades-economicas/empresas`, payload);
      dispatch({ type: 'UNSET_UNIDADE_ECONOMICA' });
      const { data } = await api.get(`/empresas/unidades-economicas?page=1`);
      setUnidadesEconomicas(data.data);
      setPageInfo(data.pageInfo);
      success('Unidade econômica salva com sucesso.');
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      {form && (
        <Modal
          form={form}
          dispatch={dispatch}
          onClickSave={onClickSave}
          onClickDelete={onClickDelete}
          onClose={() => dispatch({ type: 'UNSET_UNIDADE_ECONOMICA' })}
          empresas={responseEmpresas?.data}
        />
      )}
      <FullScreenLoading isEnabled={isLoading || isLoadingEmpresas} />
      <S.Content>
        <S.Filter>
          <Input state={[nomShopping, setNomShopping]} label="Buscar pelo shopping" style={{ width: '35%' }} />
        </S.Filter>
        {unidadesEconomicas.length > 0 && (
          <PaginatedTable
            columns={[
              { label: 'Nome shopping', key: 'nome' },
              { label: 'Origem', key: 'origem' },
              { label: 'Editar', key: 'editar' },
            ]}
            data={data}
            pagination={pageInfo}
            onChangePage={currentPage => setPageInfo({ ...pageInfo, currentPage })}
          />
        )}
      </S.Content>
    </>
  );
};
