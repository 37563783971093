import React from 'react';
import { Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { Button, Checkbox, Form, Input, Select } from '../../../../_core/_components';
import { EditProfileProperty, INITIAL_STATE } from './reducer';

type Props = {
  open: boolean;
  form: any;
  dispatch: React.Dispatch<{
    type: EditProfileProperty;
    payload: any;
  }>;
  onEditPermissions: (payload: any) => void;
  onClickSave: () => void;
  onClose: () => void;
};

const Modal: React.FC<Props> = ({ open, form, dispatch, onEditPermissions, onClickSave, onClose }: Props) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar Perfil
        </span>
        <Grid>
          <Form
            items={[
              <Input
                state={[
                  form.nome ?? '',
                  (value: React.SetStateAction<string>) => dispatch({ type: 'PERFIL', payload: value }),
                ]}
                pattern={value => value.toUpperCase()}
                label="Perfil"
              />,
              <Input
                state={[
                  form.codPerfil ?? '',
                  (value: React.SetStateAction<string>) => dispatch({ type: 'COD_PERFIL', payload: value }),
                ]}
                pattern={value =>
                  value
                    .replace(/[^\w_]/g, '')
                    .toUpperCase()
                    .trim()
                }
                label="Código do perfil"
              />,
              <Select
                options={form.todasPermissoes}
                state={[form.permissoes, onEditPermissions]}
                placeholder="Permissão"
                disabled={!form?.idPerfil}
              />,
              <Checkbox
                checked={form.flagAtivo ?? false}
                onClick={() => dispatch({ type: 'ACTIVE', payload: !form.flagAtivo })}
                label="Ativo"
              />,
            ]}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dispatch({ type: 'SET_PERFIL', payload: INITIAL_STATE })} text="Limpar" />
        <Button onClick={onClickSave} text="Salvar" />
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
