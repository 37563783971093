import styled from 'styled-components';

export const Label = styled.label`
	display: block;
	position: relative;
	padding-left: 16px;
	margin-bottom: 13px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
  		opacity: 0;
  		cursor: pointer;
  		height: 0;
  		width: 0;
		&:checked {
			& ~ span {
				background: #31C6D3 0% 0% no-repeat padding-box;
				border: 1px solid #7E93954D;
				opacity: 1;
				&:after {
					display: block;
				}
			}
		}
	}
	span {
		position: absolute;
  		top: 0;
  		left: 0;
		height: 14px;
		width: 14px;
  		border: 1px solid #3F3F3F;
  		border-radius: 2px;
  		opacity: 1;
		&:after {
			content: "";
  			position: absolute;
			display: none;
			margin-top: 1px;
    		margin-left: 4px;
    		width: 2px;
    		height: 6px;
  			border: solid white;
  			border-width: 0 2px 2px 0;
  			-webkit-transform: rotate(40deg);
  			-ms-transform: rotate(40deg);
  			transform: rotate(40deg);
		}
		&:checked {
			background: #31C6D3 0% 0% no-repeat padding-box;
			border: 1px solid #7E93954D;
			opacity: 1;
			&:after {
				display: block;
			}
		}
	}
`;
