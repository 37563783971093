import React, { useCallback, useContext, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useMsal } from '@azure/msal-react';
import bgLogin from '../../assets/bg_login.png';
import { AuthContext } from '../../_main/contexts/auth';
import { authenticate, getUserAccess } from './request';
import { homeRoute } from './home-route';
import { loginRequest } from '../../_core/config/authConfig';
import { handleErrors } from '../../_core/services/toast';
import { Input } from '../../_core/_components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${bgLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: 'white',
    borderRadius: 30,
  },
}));

const LOCALHOST = window.location.href.includes('http://localhost');

export function Autenticacao(): JSX.Element {
  const classes = useStyles();

  const { signIn } = useContext(AuthContext);
  const { instance } = useMsal();

  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState('');

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
      try {
        setLoading(true);
        let responseToken = '';
        e.preventDefault();
        if (LOCALHOST) {
          responseToken = token;
        } else {
          const response = await instance.loginPopup(loginRequest);
          responseToken = response.accessToken;
        }
        const data = await authenticate(responseToken);
        const routes = await getUserAccess({ idUsuario: data.id, token: data.token });
        routes.unshift(homeRoute);
        signIn({
          id: data.id,
          token: data.token,
          name: data.name,
          userName: data.username,
          email: data.email,
          profile: data.profile.name,
          shoppingProfileName: data.shoppings[0]?.name,
          shoppingProfileId: data.shoppings[0]?.id.toString(),
          shoppings: data.shoppings ?? null,
          routes,
        });
      } catch (error) {
        handleErrors(error);
        setLoading(false);
      }
    },
    [signIn, token],
  );

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Faça login na Azure para acessar o sistema
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            {LOCALHOST && <Input state={[token, setToken]} label="AD Token" />}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disableElevation
              className={classes.submit}
              disabled={loading}
            >
              Entrar
            </Button>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
