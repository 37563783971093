import React, { useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import api from '../../../_core/api';
import LoadingIcon from '../../../assets/loader.jsx';
import icon from '../../../assets/cloud-computing.svg';
import { AuthContext } from '../../../_main/contexts/auth';
import { Container, Content, ImportArea, InputGroup, LoadingBox, Sucess, Error } from './styles';
import { Button } from '../../../_core/_components';

interface IErrorXls {
  description?: string;
  message?: string;
}

export const ImportacaoAjuizamentoManual: React.FC = () => {
  const { userName } = useContext(AuthContext);
  const [uploadingXls, setUploadingXls] = useState<boolean>(false);
  const [sucessXls, setSucessXls] = useState<boolean>(false);
  const erroVazio = {
    description: '',
    message: '',
  };
  const [errorXls, setErrorXls] = useState<IErrorXls>(erroVazio);

  const handleSendButton = (postType: string) => {
    if (postType === 'inputDateXls') {
      setErrorXls(erroVazio);
      setUploadingXls(true);
      sendFormXls();
    }
  };

  const sendFormXls = async () => {
    const fileInput = document.getElementById('inputFileXls') as HTMLInputElement;

    if (fileInput.files![0] != null) {
      const formdata = new FormData();
      formdata.append('file', fileInput.files![0]);

      api
        .post(`/judgment/ajuizamento-manual/upload`, formdata, {
          headers: {
            'BRM-User-Identifier': userName,
          },
        })
        .then(
          response => {
            setUploadingXls(false);
            setSucessXls(true);
          },
          error => {
            setUploadingXls(false);
            setErrorXls({ description: error.response.data.description, message: error.response.data.message });
          },
        );
    } else {
      setUploadingXls(false);
      setErrorXls({ description: 'Insira um arquivo válido.' });
    }
  };

  return (
    <Container>
      <Content>
        <ImportArea>
          <h2>Buscar arquivos</h2>
          <img src={icon} alt="" />
          <InputGroup>
            <input id="inputFileXls" type="file" />
          </InputGroup>
          <LoadingBox active={uploadingXls}>
            <LoadingIcon />
          </LoadingBox>
        </ImportArea>
        {errorXls.description ? (
          <>
            <Error data-tip data-for="error">
              {errorXls.description}
            </Error>
            {errorXls.message ? (
              <ReactTooltip backgroundColor="#ff3333" effect="solid" className="tooltip" id="error">
                <span>{errorXls?.message}</span>
              </ReactTooltip>
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        {sucessXls && <Sucess>Importado com sucesso!</Sucess>}
        <Button text="Importar" onClick={() => handleSendButton('inputDateXls')} />
      </Content>
    </Container>
  );
};
