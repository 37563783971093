import React, { useState, useEffect, useMemo } from 'react';
import { IconButton } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import * as S from '../styles';
import Modal from './modal';
import api from '../../../../_core/api';
import { toReal } from '../../../../_core/services/formaters';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, PaginatedTable } from '../../../../_core/_components';
import { handleErrors } from '../../../../_core/services/toast';

export type MinimumRequirement = {
  id: number;
  idTipoAcao: number;
  tipoAcao: string;
  idTipoDivida: number;
  tipoDivida: string;
  divMinTotal: number;
  divMinMes: number;
  boletosAbertos: number;
  ativo: boolean;
  inicioAgingEmAnos: number;
  fimAgingEmDias: number;
  codigoTipoObjeto: any;
  apenasContratosAtivos: any;
  requerAcordoAutos: any;
  deveIncluirBoletosAdm: any;
};

const RequisitosMinimos: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [registerList, setRegisterList] = useState<MinimumRequirement[]>([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [tipoDivida, setTipoDivida] = useState<any[]>([]);
  const [tipoAcao, setTipoAcao] = useState<any[]>([]);
  const [itemInAction, setItemInAction] = useState<MinimumRequirement | null>();

  useEffect(() => {
    getData();
  }, [page]);

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/parametros/ajuizamento/requisitos-minimos?page=${page}`);
      if ([tipoDivida, tipoAcao].every(e => e.length === 0)) {
        const { data: dataTipoDivida } = await api.get('/parametros/ajuizamento/tipo-divida');
        const { data: dataTipoAcao } = await api.get('/parametros/ajuizamento/tipo-acao');
        setTipoDivida(dataTipoDivida.data);
        setTipoAcao(dataTipoAcao.data);
      }
      setRegisterList(data.data);
      setPageInfo(data.pageInfo);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const renderSubItems = (item: MinimumRequirement) => {
    return [
      <b>Contratos inativos: </b>,
      item.apenasContratosAtivos ? 'Sim' : 'Não',
      <b>Desconsiderar se houver acordo nos autos: </b>,
      item.requerAcordoAutos ? 'Sim' : 'Não',
      <b>Contratos admin: </b>,
      item.deveIncluirBoletosAdm ? 'Sim' : 'Não',
    ];
  };

  const renderedData = useMemo(() => {
    if (registerList.length > 0) {
      return registerList.map(item => ({
        id: item.id,
        tipoDivida: item.tipoDivida,
        tipoAcao: item.tipoAcao,
        agingInicio: item.inicioAgingEmAnos,
        agingFim: item.fimAgingEmDias,
        minTotal: toReal(item.divMinTotal),
        minMes: toReal(item.divMinMes),
        boletosAbertos: item.boletosAbertos,
        subItems: renderSubItems(item),
        ativo: item.ativo,
        actions: (
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            component="span"
            id={item.id.toString()}
            onClick={() => setItemInAction(item)}
          >
            <MoreVert />
          </IconButton>
        ),
      }));
    }
    return [];
  }, [registerList, tipoDivida, tipoAcao]);

  return (
    <S.ContentContainer>
      <FullScreenLoading isEnabled={loading} />
      {itemInAction !== undefined && (
        <Modal
          tipoAcaoOptions={tipoAcao.map(e => ({
            value: e.id,
            label: e.nome,
          }))}
          tipoDividaOptions={tipoDivida.map(e => ({
            value: e.id,
            label: e.descricao,
          }))}
          selectedRegister={itemInAction}
          onClose={() => setItemInAction(undefined)}
          refresh={getData}
        />
      )}
      <S.TopBar>
        <S.SearchBar>
          <S.SearchField />
        </S.SearchBar>
        <Button text="Criar novo" onClick={() => setItemInAction(null)} />
      </S.TopBar>
      {renderedData.length > 0 && (
        <PaginatedTable
          columns={[
            { label: 'Tipo Dívida', key: 'tipoDivida' },
            { label: 'Tipo de Ação', key: 'tipoAcao' },
            { label: 'Aging início', key: 'agingInicio' },
            { label: 'Aging fim', key: 'agingFim' },
            { label: 'Div Min Total', key: 'minTotal' },
            { label: 'Div Min Mês', key: 'minMes' },
            { label: 'Boletos Abertos', key: 'boletosAbertos' },
            { label: '', key: 'actions' },
          ]}
          data={renderedData}
          pagination={pageInfo}
          onChangePage={setPage}
        />
      )}
    </S.ContentContainer>
  );
};

export default RequisitosMinimos;
