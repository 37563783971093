import React, { useMemo } from 'react';
import { Dialog, DialogActions, DialogContent, Grid, IconButton } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import { Button, Form, Input, Option, SingleSelect } from '../../../../_core/_components';
import { EditEmpresaProperty, CONTATO } from './reducer';
import { mascaraNumeros, maskCNPJ } from '../../../../_core/masks';
import { Contato, Empresa, TipoContato } from './types';
import { estados } from '../estados-cidades.json';

const TIPOS_CONTATO: Option[] = [
  {
    value: 'EMAIL',
    label: 'EMAIL',
  },
  {
    value: 'TELEFONE',
    label: 'TELEFONE',
  },
];

type Props = {
  form: Empresa;
  dispatch: React.Dispatch<{
    type: EditEmpresaProperty;
    payload: any;
  }>;
  getCep: (cep: string) => Promise<any>;
  onClickSave: () => void;
  onClose: () => void;
};

const Modal: React.FC<Props> = ({ form, dispatch, getCep, onClickSave, onClose }: Props) => {
  const onClickDelete = (index: number) => {
    const arr = [...form.contatos];
    if (arr.length > 1) {
      arr.splice(index, 1);
      dispatch({ type: 'SET_CONTATOS', payload: arr });
    }
  };

  const camposContatos = useMemo(() => {
    const setTipoContato = (payload: Contato, index: number) => {
      const arr = [...form.contatos];
      arr[index] = payload;
      return arr;
    };
    const arr: any[] = [];
    form.contatos.forEach((item: Contato, i: number) => {
      arr.push(
        <SingleSelect
          state={[
            { label: item.tipo, value: item.tipo },
            option =>
              dispatch({
                type: 'SET_CONTATOS',
                payload: setTipoContato({ tipo: option!.value as TipoContato, contato: item.contato }, i),
              }),
          ]}
          options={TIPOS_CONTATO}
          isClearable={false}
        />,
      );
      arr.push(
        <Input
          state={[
            item.contato,
            (value: React.SetStateAction<string>) =>
              dispatch({
                type: 'SET_CONTATOS',
                payload: setTipoContato({ tipo: item.tipo, contato: value as string }, i),
              }),
          ]}
          label="Contato"
        />,
      );
      const removeButton = (
        <IconButton onClick={() => onClickDelete(i)}>
          <Delete />
        </IconButton>
      );
      const addButton = (
        <IconButton onClick={() => dispatch({ type: 'SET_CONTATOS', payload: [...form.contatos, CONTATO] })}>
          <Add />
        </IconButton>
      );
      if (form.contatos.length === i + 1) {
        arr.push(
          <>
            {addButton}
            {removeButton}
          </>,
        );
      } else {
        arr.push(removeButton);
      }
    });
    return arr;
  }, [form.contatos]);
  console.log(form);
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <span
          style={{
            color: '#00959c',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          Adicionar / Alterar Empresa
        </span>
        <Grid>
          <Form
            items={[
              <Input
                state={[
                  form.razaoSocial,
                  (value: React.SetStateAction<string>) => dispatch({ type: 'SET_RAZAO_SOCIAL', payload: value }),
                ]}
                label="Razão social"
              />,
              <Input
                state={[
                  form.nomeFantasia,
                  (value: React.SetStateAction<string>) => dispatch({ type: 'SET_NOME_FANTASIA', payload: value }),
                ]}
                label="Nome fantasia"
              />,
              <Input
                pattern={maskCNPJ}
                state={[
                  form.cnpj,
                  (value: React.SetStateAction<string>) => dispatch({ type: 'SET_CNPJ', payload: value }),
                ]}
                label="CNPJ"
              />,
              <Input
                pattern={mascaraNumeros}
                state={[
                  form.idErp?.toString() ?? '',
                  (value: React.SetStateAction<string>) => dispatch({ type: 'SET_ID_ERP', payload: value }),
                ]}
                label="ID Erp"
              />,
              <Input
                pattern={mascaraNumeros}
                state={[
                  form.endereco?.cep ?? '',
                  (value: React.SetStateAction<string>) => dispatch({ type: 'SET_CEP', payload: value }),
                ]}
                label="CEP"
              />,
              <Input
                state={[
                  form.endereco?.logradouro ?? '',
                  (value: React.SetStateAction<string>) => dispatch({ type: 'SET_ENDERECO', payload: value }),
                ]}
                label="Logradouro"
              />,
              <SingleSelect
                state={[
                  form.endereco?.uf ? { label: form.endereco.uf, value: form.endereco.uf } : undefined,
                  option =>
                    dispatch({
                      type: 'SET_UF',
                      payload: option?.value,
                    }),
                ]}
                options={estados.map(e => ({ label: e.sigla, value: e.sigla }))}
                placeholder="UF"
              />,
              <Input
                state={[
                  form.endereco?.cidade ?? '',
                  (value: React.SetStateAction<string>) => dispatch({ type: 'SET_CIDADE', payload: value }),
                ]}
                label="Cidade"
              />,
              null,
              ...camposContatos,
            ]}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickSave} text="Salvar" />
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
