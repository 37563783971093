import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import * as S from './styles';
import api from '../../../_core/api';
import { Boleto, SerasaBoletoResponse } from './serasaTypes';
import { failure, handleErrors, warning } from '../../../_core/services/toast';
import { FullScreenLoading } from '../../../_core/_components/fullscreen-loading';
import Table from '../../../_core/_components/table';
import { Select, Option, Input, Button, Form } from '../../../_core/_components';
import { AuthContext } from '../../../_main/contexts/auth';

type Props = {};

export const AcompanharBoletos: React.FC<Props> = () => {
  const { shoppings: profileShoppings } = useContext(AuthContext);
  const [selectedShoppings, setSelectedShoppings] = useState<Option[]>([]);
  const [shoppings, setShoppings] = useState<Option[]>([]);
  const [boletos, setBoletos] = useState<Boleto[]>([]);
  const [loading, setLoading] = useState(false);
  const [nmFantasia, setNmFantasia] = useState('');
  const [luc, setLuc] = useState('');
  const [numBoleto, setNumBoleto] = useState('');

  useEffect(() => {
    if (profileShoppings) {
      const listShopping: Option[] = profileShoppings.map(e => ({
        value: e.id,
        label: e.name,
      }));
      listShopping.sort((item1: Option, item2: Option) => {
        if (item1.label > item2.label) return 1;
        if (item1.label < item2.label) return -1;
        return 0;
      });
      setShoppings(listShopping);
      setSelectedShoppings(listShopping);
    }
  }, [profileShoppings]);

  const isValid = () => {
    if (numBoleto) return true;
    if (!selectedShoppings || selectedShoppings?.length === 0) {
      failure('Shopping obrigatório!');
      return false;
    }
    return true;
  };

  const searchBoletos = async () => {
    if (!isValid()) return;
    setLoading(true);
    const payload = {
      idShopping: selectedShoppings?.map(s => s.value),
      marca: nmFantasia,
      idUsuario: 60,
      ...(luc && { luc }),
      ...(numBoleto && { numBoleto }),
      clientesNegativado: false,
      clientesForamNegativado: false,
      clientesNuncaNegativado: false,
    };
    try {
      const { data } = await api.post<SerasaBoletoResponse>('/workflow/serasa/serasa-workflow/buscar/boletos', payload);
      if (data.content.length === 0) {
        warning('Pesquisa executada com sucesso, porém nenhum dado foi encontrado com os parâmetros especificados!');
      }
      setBoletos(data.content);
    } catch (e) {
      handleErrors(e);
    }
    setLoading(false);
  };

  const renderedData = useMemo(() => {
    if (boletos.length > 0) {
      return boletos.map(boleto => ({
        nmShopping: boleto.nmShopping,
        nomFantasia: boleto.nomFantasia,
        numCpfcnpjLoja: boleto.serasaLoja.numCpfcnpjLoja,
        luc: boleto.serasaLoja.luc,
        numBoleto: boleto.numBoleto,
        datVencimento: moment(boleto.datVencimento, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        statusSerasa: boleto.statusWorkflowSerasa.nome,
      }));
    }
    return [];
  }, [boletos]);

  return (
    <>
      <FullScreenLoading isEnabled={loading} />
      <S.Wrapper>
        <Form
          items={[
            <Select placeholder="Shoppings" state={[selectedShoppings, setSelectedShoppings]} options={shoppings} />,
            <Input label="Nome Fantasia" state={[nmFantasia, setNmFantasia]} />,
            <Input label="Nr. Boleto" state={[numBoleto, setNumBoleto]} />,
            <Input label="LUC" state={[luc, setLuc]} />,
          ]}
          submitButton={<Button onClick={searchBoletos} text="Pesquisar" />}
        />
        {renderedData.length > 0 && (
          <Table
            columns={[
              { key: 'nmShopping', orderable: true, label: 'Shopping' },
              { key: 'nomFantasia', orderable: true, label: 'Nome Fantasia' },
              { key: 'numCpfcnpjLoja', orderable: true, label: 'CPF/CNPJ' },
              { key: 'luc', orderable: true, label: 'LUC' },
              { key: 'numBoleto', orderable: true, label: 'Nº Boleto' },
              { key: 'datVencimento', orderable: true, label: 'Vencimento' },
              { key: 'statusSerasa', orderable: true, label: 'Status Workflow' },
            ]}
            data={renderedData}
          />
        )}
      </S.Wrapper>
    </>
  );
};
