import React from 'react';
import ICheckboxWIthCheckedProps from './ICheckboxWIthChecked';

import { Label } from './CheckboxWIthChecked.styles';

export const CheckboxWIthChecked: React.FC<ICheckboxWIthCheckedProps> = ({ name, onChange, checked = false }) => {
  return (
    <Label>
      <input type="checkbox" name={name} onChange={onChange} checked={checked} />
      <span></span>
    </Label>
  );
};
