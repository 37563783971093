import { useContext } from 'react';
import InadimplenciaContext from '../../../../_main/contexts/inadimplencia';
import api from '../../../../_core/api';

type Item = 'portfolioShoppings' | 'shoppings' | 'brands' | 'clientes' | 'lucs' | 'encargos';
type Request = {
  request: string;
  item: Item;
};

const REQUESTS: Request[] = [
  {
    request: `/portfolio/available-shoppings`,
    item: 'portfolioShoppings',
  },
  {
    request: `/filters/shoppings`,
    item: 'shoppings',
  },
  {
    request: `/filters/siscob-filters-information/marcas`,
    item: 'brands',
  },
  {
    request: `/filters/siscob-filters-information/clientes`,
    item: 'clientes',
  },
  {
    request: `/filters/siscob-filters-information`,
    item: 'lucs',
  },
  {
    request: `/charge-type`,
    item: 'encargos',
  },
];

export const useInadimplencia = () => {
  const {
    portfolioShoppings,
    setPortfolioShoppings,
    shoppings,
    setShoppings,
    brands,
    setBrands,
    clientes,
    setClientes,
    lucs,
    setLucs,
    encargos,
    setEncargos,
  } = useContext(InadimplenciaContext);

  const getLength = (item: Item) => {
    switch (item) {
      case 'portfolioShoppings':
        return portfolioShoppings.length;
      case 'shoppings':
        return shoppings.length;
      case 'brands':
        return brands.length;
      case 'clientes':
        return clientes.length;
      case 'lucs':
        return lucs.length;
      case 'encargos':
        return encargos.length;
      default:
        return 0;
    }
  };

  const getOptions = async (items: Item[]): Promise<void> => {
    const arrItems = items.filter(item => getLength(item) === 0);
    const arrRequests = REQUESTS.map(e => (arrItems.includes(e.item) ? api.get(e.request) : null));
    const response = await Promise.all(arrRequests);
    response.forEach((payload: any, i: number) => {
      if (!payload) return;
      switch (i) {
        case 0:
          setPortfolioShoppings(
            payload.data.map((item: any) => ({
              value: item.id,
              label: item.name,
            })),
          );
          break;
        case 1:
          setShoppings(
            payload.data.map((item: any) => ({
              value: item.shoppingID,
              label: item.shoppingName,
            })),
          );
          break;
        case 2:
          setBrands(payload.data.map((item: any) => item));
          break;
        case 3:
          setClientes(payload.data.map((item: any) => item.customerName));
          break;
        case 4:
          setLucs(payload.data.map((item: any) => item.luc.toUpperCase()));
          break;
        case 5:
          setEncargos(payload.data.map((item: any) => item));
          break;
        default:
          break;
      }
    });
  };

  return {
    portfolioShoppings,
    shoppings,
    brands,
    clientes,
    lucs,
    encargos,
    getOptions,
  };
};
