import React, { createContext, useCallback, useState, useContext } from 'react';
import { AuthContext } from './auth';

interface PriorizacaoData {
  listShopping: any[];
  listPortifolio: any[];
  listTendencia: any[];
  selectedShopping: any[];
  selectedShoppingTendencia: any[];
  portifolioSelected: any[];
  tendenciasAlteradas: any[];
  isTabActive: number;
  setListShopping: any;
  setListPortifolio: any;
  setListTendencia: any;
  setSelectedShopping: any;
  setSelectedShoppingTendencia: any;
  setPortifolioSelected: any;
  setTendenciasAlteradas: any;
  setIsTabActive: any;
  clear: any;
}

const PriorizacaoContext = createContext<PriorizacaoData>({} as PriorizacaoData);

const PriorizacaoProvider: React.FC = ({ children }) => {
  const { profile } = useContext(AuthContext);
  const [listShopping, setListShopping] = useState<any[]>([]);
  const [listPortifolio, setListPortifolio] = useState<any[]>([]);
  const [listTendencia, setListTendencia] = useState<any[]>([]);
  const [selectedShopping, setSelectedShopping] = useState<any[]>([]);
  const [selectedShoppingTendencia, setSelectedShoppingTendencia] = useState<any[]>([]);
  const [portifolioSelected, setPortifolioSelected] = useState<any[]>([]);
  const [tendenciasAlteradas, setTendenciasAlteradas] = useState<any[]>([]);
  const [isTabActive, setIsTabActive] = useState(profile === 'COBRANÇA CORPORATIVA' ? 2 : 1);

  const clear = useCallback(() => {
    setListShopping([]);
    setListPortifolio([]);
    setListTendencia([]);
    setSelectedShopping([]);
    setSelectedShoppingTendencia([]);
    setPortifolioSelected([]);
    setTendenciasAlteradas([]);
  }, []);

  return (
    <PriorizacaoContext.Provider
      value={{
        listShopping: listShopping,
        tendenciasAlteradas: tendenciasAlteradas,
        listPortifolio: listPortifolio,
        listTendencia: listTendencia,
        selectedShopping: selectedShopping,
        selectedShoppingTendencia: selectedShoppingTendencia,
        portifolioSelected: portifolioSelected,
        isTabActive: isTabActive,
        setListTendencia: (tendencias: any) => setListTendencia(tendencias),
        setListShopping: (shoppings: any) => setListShopping(shoppings),
        setTendenciasAlteradas: (tendenciasAlteradas: any) => setTendenciasAlteradas(tendenciasAlteradas),
        setListPortifolio: (portifolios: any) => setListPortifolio(portifolios),
        setSelectedShopping: (shopping: any) => setSelectedShopping(shopping),
        setSelectedShoppingTendencia: (tendencia: any) => setSelectedShoppingTendencia(tendencia),
        setPortifolioSelected: (portifolio: any) => setPortifolioSelected(portifolio),
        setIsTabActive: (tabActive: number) => setIsTabActive(tabActive),
        clear,
      }}
    >
      {children}
    </PriorizacaoContext.Provider>
  );
};

export { PriorizacaoContext, PriorizacaoProvider };
