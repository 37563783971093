import React, { useState, useEffect } from 'react';
import Draggable from 'react-draggable';
import { Label, FormField, FlexForm, InputBox, InputElement, InputTel, MoreButton } from './styles';
import Modal from '../../components/modal';
import * as ModalModules from '../../components/modal/styles';
import api from '../../../../_core/api';
import Loader from '../../../../assets/loader';
import { failure } from '../../../../_core/services/toast';

interface INewContact {
  active?: boolean | undefined;
  closeModalFunction?: any;
  customer?: any;
  userName?: any;
}

interface IContactFormFields {
  groupName?: string;
  contactName?: string;
  contactDepartment?: string;
  brand?: string;
  active?: string;
  contactsPoints: IContactsPoints[];
}

interface IContactsPoints {
  contactPoint: string;
  contactPointType: string;
}

export const NewContactBillingGroup: React.FC<INewContact> = ({ active, closeModalFunction, customer, userName }) => {
  const [modalActive, setModalActive] = useState<boolean | undefined>(false);
  const [contactFormFields, setContactFormFields] = useState<IContactFormFields>({} as IContactFormFields);
  const [loading, setLoading] = useState(Boolean);
  const contactsPointsVazio = [
    {
      contactPoint: '',
      contactPointType: 'E-mail',
    },
    {
      contactPoint: '',
      contactPointType: 'Telefone',
    },
  ];
  const [quantyInput, setQuantyInput] = useState(1);
  const [contactsPoints, setContactsPoints] = useState<IContactsPoints[]>(contactsPointsVazio);

  function zerarTudo() {
    setContactsPoints(contactsPointsVazio);
    setContactFormFields({
      ...contactFormFields,
      contactName: '',
      contactDepartment: '',
      contactsPoints: contactsPointsVazio,
    });
  }

  /** Handle methods */
  const handleClose = () => {
    closeModalFunction();
    zerarTudo();
  };

  const handleSubmit = () => {
    sendData();
  };

  const handleMoreInput = () => {
    setQuantyInput(quantyInput + 1);
    const contactsForms = [...contactsPoints];
    contactsForms.push(
      {
        contactPoint: '',
        contactPointType: 'E-mail',
      },
      {
        contactPoint: '',
        contactPointType: 'Telefone',
      },
    );

    setContactsPoints(contactsForms);
    console.log(contactsForms);
  };

  const handleChangeField = (field: string, value: any) => {
    const formFields: IContactFormFields = contactFormFields;

    setContactFormFields({ ...formFields, [field]: value });
  };

  const handleChangeFieldEmailTelefone = (field: string, value: any) => {
    const formFields: IContactFormFields = contactFormFields;

    const contactsPointsTemp = contactsPoints.map((c: IContactsPoints, index) => {
      if (c.contactPointType.concat(index.toString()) == field) {
        c.contactPoint = value;
      }
      return c;
    });

    setContactsPoints(contactsPointsTemp);
    setContactFormFields({ ...formFields, contactsPoints: contactsPointsTemp });
  };

  /** Requests methods */
  const sendData = async () => {
    const contactsPointsTemp = contactsPoints.filter((c: IContactsPoints) => c.contactPoint != '');

    const data = {
      billingGroupId: customer?.query.id,
      contactName: contactFormFields.contactName,
      contactDepartment: contactFormFields.contactDepartment,
      brand: customer?.customerName,
      active: '1',
      userIdentifier: userName,
      contactsPoints: contactsPointsTemp,
    };

    // if (contactsPointsTemp.length > 0) {
    //   api.post(`charge-legal-information/contacts/customer-contacts-bff`, data).then(
    //     response => {
    //       zerarTudo();
    //       closeModalFunction();
    //     },
    //     error => {
    //     },
    //   );
    // }

    if (contactsPointsTemp.length > 0) {
      if (contactFormFields.contactName !== undefined && contactFormFields.contactDepartment) {
        setLoading(true);
        api.post(`charge-legal-information/contacts/customer-contacts-bff`, data).then(
          response => {
            zerarTudo();
            closeModalFunction();
            setLoading(false);
          },
          error => {
            failure(error.message.toString());
            setLoading(false);
          },
        );
      } else {
        if (contactFormFields.contactName === undefined) failure('Preencha o campo Nome do Contato!');
        else if (contactFormFields.contactDepartment === undefined) failure('Preencha o campo Tipo de Contato!');
      }
    } else {
      failure('Preencha Email e/ou Telefone!');
    }
  };

  /** UseEffects */
  useEffect(() => {
    setModalActive(active);
    setContactFormFields({ ...contactFormFields, contactsPoints: contactsPointsVazio });
  }, []);

  useEffect(() => {
    setModalActive(active);
  }, [active]);

  useEffect(() => {
    handleChangeField('groupName', customer?.groupName);
  }, [customer]);

  return (
    <Modal active={modalActive}>
      <Draggable>
        {loading ? (
          <div className="loaderUser">
            <Loader />
          </div>
        ) : (
          <ModalModules.Box>
            <ModalModules.Content>
              <FlexForm>
                <FormField>
                  <InputBox>
                    <Label>Grupo de cobrança</Label>
                    <InputElement name="groupName" disabled defaultValue={customer?.groupName} />
                  </InputBox>
                </FormField>
                <FormField>
                  <Label>Nome do contato</Label>
                  <InputElement
                    name="contactName"
                    onChange={e => {
                      handleChangeField(e.target.name, e.target.value);
                    }}
                    value={contactFormFields.contactName}
                  />
                </FormField>

                <FormField>
                  <Label>Tipo de Contato</Label>
                  <InputElement
                    name="contactDepartment"
                    onChange={e => {
                      handleChangeField(e.target.name, e.target.value);
                    }}
                    value={contactFormFields.contactDepartment}
                  />
                </FormField>
                <FormField></FormField>
                {contactsPoints.map((item: any, index: any) => (
                  <FormField>
                    <InputBox>
                      <Label>{item.contactPointType}</Label>
                      {item.contactPointType === 'E-mail' ? (
                        <InputElement
                          type="email"
                          value={item.contactPoint}
                          name={item.contactPointType + index}
                          onChange={e => {
                            handleChangeFieldEmailTelefone(e.target.name, e.target.value);
                          }}
                        />
                      ) : (
                        <InputTel
                          mask="(99) 99999-9999"
                          value={item.contactPoint}
                          name={item.contactPointType + index}
                          onChange={e => {
                            handleChangeFieldEmailTelefone(e.target.name, e.target.value);
                          }}
                        />
                      )}
                    </InputBox>
                  </FormField>
                ))}
                <MoreButton onClick={handleMoreInput}>+</MoreButton>
              </FlexForm>
            </ModalModules.Content>
            <ModalModules.Footer>
              <ModalModules.Button type="button" onClick={() => handleClose()}>
                Fechar
              </ModalModules.Button>
              <ModalModules.Button filled type="button" onClick={() => handleSubmit()}>
                Enviar
              </ModalModules.Button>
            </ModalModules.Footer>
          </ModalModules.Box>
        )}
      </Draggable>
    </Modal>
  );
};
