import React, { useEffect, useState, useContext } from 'react';
import FileSaver from 'file-saver';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import bin from '../../../assets/bin.svg';
import api from '../../../_core/api';
import pdfFile from '../../../assets/pdf-file.svg';
import iconArrow from '../../../assets/apple-keyboard-control.svg';
import { AuthContext } from '../../../_main/contexts/auth';
import {
  Container,
  FilterBar,
  Box,
  Item,
  FilterCheck,
  Resume,
  Check,
  Checks,
  Tabela,
  DivVoltar,
  Search,
  FilterSearch,
  PaginationWrapper,
  Pagination,
} from './styles';
import Loader from '../../../assets/loader';
import iconSearch from '../../../assets/Icon_search.svg';

export const DetalhesBoletos: React.FC = () => {
  const history = useHistory();
  const { token } = useContext(AuthContext);
  const [query, setQuery] = useState({
    slipids: '',
    shopping: '',
    groupId: '',
    groupName: '',
    blockEnd: '',
    blockStartingDate: '',
    cpf: '',
    razaoSocial: '',
    classificacao: '',
  });
  const [data, setData] = useState<any>(null);
  const [filterData, setFilterData] = useState<any>(null);
  const [loading, setLoading] = useState(Boolean);
  const [slipDue, setSlipDue] = useState(true);
  const [slipFutureDue, setSlipFutureDue] = useState(true);
  const [slipNegociated, setSlipNegociated] = useState(true);
  const [slipOriginals, setSlipOriginals] = useState(true);
  const [slipBank, setSlipBank] = useState(true);
  const [slipAdministration, setSlipAdministration] = useState(true);
  const objetoOrdenacaoZerado = {
    luc: 0,
    branc: 0,
    contractTypeNumber: 0,
    slipNumber: 0,
    slipType: 0,
    slipAmount: 0,
    slipDueDate: 0,
  };
  const [ordenacao, setOrdenacao] = useState<any>(objetoOrdenacaoZerado);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);

  /** new methods */
  const handleGeneratePdfBtn = (item: any) => {
    getSlipEncoded(item.slipNumber);
  };

  const getSlipEncoded = (slipNumber: number) => {
    api
      .get('/charge-legal-information/slip-print', {
        params: { slipNumberFrom: slipNumber, slipNumberTo: slipNumber },
      })
      .then(response => {
        convertAndGetPdfFile(response.data.content, slipNumber.toString());
      });
  };

  const convertAndGetPdfFile = (content: string, filename: string) => {
    const base64str = content;

    // decode base64 string, remove space for IE compatibility
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const a: HTMLAnchorElement = document.createElement('A') as HTMLAnchorElement;
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  /** old methods */
  const getQueryStringParams = (queryString: string) => {
    if (!queryString) {
      queryString = window.location.search.substring(1);
    }

    const params: any = {};

    const queries = queryString.split('&');

    queries.forEach((indexQuery: string, index: number) => {
      const indexPair = indexQuery.split('=');

      const queryKey = index === 0 ? decodeURIComponent(indexPair[0]).substring(1) : decodeURIComponent(indexPair[0]);
      const queryValue = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : '');
      params[queryKey] = queryValue;
    });
    return params;
  };

  useEffect(() => {
    const params = getQueryStringParams(history.location.search);
    console.log(params);
    setQuery(params);
  }, [history.location.search]);

  useEffect(() => {
    const slipIds = query.slipids.split(',').map(function (item) {
      return parseInt(item, 10);
    });
    const requestOptions = {
      slipIds,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'arraybuffer',
    };
    setLoading(true);
    if (query.slipids) {
      if (query.shopping) {
        const handleItem = async () => {
          const response = await api
            .post(
              `/prioritization/shopping/slips/${query.shopping}?slipDue=${slipDue}&slipFutureDue=${slipFutureDue}&slipNegociated=${slipNegociated}&slipOriginals=${slipOriginals}&slipBank=${slipBank}&slipAdministration=${slipAdministration}`,
              requestOptions,
            )
            .then(
              response => {
                const resposta = response.data;
                console.log(resposta);
                resposta.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'slipDueDate'));
                // setOrdenacao({ ...objetoOrdenacaoZerado, slipDueDate: 1 });
                setData(resposta);
                setFilterData(resposta);
                setLoading(false);
              },
              error => {
                alert('Boletos não encontrados');
                console.log(error);
                setLoading(false);
              },
            );
        };
        handleItem();
      } else {
        api
          .get(
            `/prioritization/billing-group/slips/${query.groupId}?slipIds=${query.slipids}&slipDue=${slipDue}&slipFutureDue=${slipFutureDue}&slipNegociated=${slipNegociated}&slipOriginals=${slipOriginals}&slipBank=${slipBank}&slipAdministration=${slipAdministration}`,
          )
          .then(
            response => {
              const resposta = response.data;
              resposta.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'slipDueDate'));
              setOrdenacao({ ...objetoOrdenacaoZerado, slipDueDate: 1 });
              setData(resposta);
              setFilterData(resposta);
              setLoading(false);
            },
            error => {
              alert('Boletos não encontrados');
              console.log(error);
              setLoading(false);
            },
          );
      }
    }
  }, [query, slipDue, slipFutureDue, slipNegociated, slipOriginals, slipBank, slipAdministration]);

  function clickCheck(campo: string) {
    switch (campo) {
      case 'slipDue':
        setSlipDue(!slipDue);
        break;
      case 'slipFutureDue':
        setSlipFutureDue(!slipFutureDue);
        break;
      case 'slipNegociated':
        setSlipNegociated(!slipNegociated);
        break;
      case 'slipOriginals':
        setSlipOriginals(!slipOriginals);
        break;
      case 'slipBank':
        setSlipBank(!slipBank);
        break;
      case 'slipAdministration':
        setSlipAdministration(!slipAdministration);
        break;
    }
  }

  function voltar() {
    history.goBack();
  }

  function formatarVirgula(string: any) {
    try {
      return string.replaceAll(',', ', ');
    } catch (error) {
      return '';
    }
  }

  function formatarData(date: string) {
    const data = moment(date, 'YYYY-M-D').add(0, 'months');
    const month = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ][data.month()];
    const year = data.year();

    return `${month}/${year}`.toUpperCase();
  }

  function formatarParaBrasileiro(data: string) {
    // 2020-05-02
    try {
      return `${data.substring(8, 10)}/${data.substring(5, 7)}/${data.substring(0, 4)}`;
    } catch (error) {
      return '';
    }
  }

  function pegarFiltro(value: any) {
    console.log(value);
    const dataFiltred = data.filter((bankSlip: any) => filtrar(bankSlip, value));
    dataFiltred.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'slipDueDate'));
    setOrdenacao({ ...objetoOrdenacaoZerado, slipDueDate: 1 });
    setFilterData(dataFiltred);
  }

  function filtrar(bankSlip: any, value: any) {
    if (bankSlip.luc?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 && bankSlip.luc != null) {
      return true;
    }
    if (bankSlip.branc?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 && bankSlip.branc != null) {
      return true;
    }
    if (
      bankSlip.contractTypeNumber?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.contractTypeNumber != null
    ) {
      return true;
    }
    if (
      bankSlip.slipNumber?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.slipNumber != null
    ) {
      return true;
    }
    if (bankSlip.slipType?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 && bankSlip.slipType != null) {
      return true;
    }
    if (
      bankSlip.slipAmount?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.slipAmount != null
    ) {
      return true;
    }
    if (
      formatarParaBrasileiro(bankSlip.slipDueDate?.toString().toUpperCase()).indexOf(value.toUpperCase()) != -1 &&
      bankSlip.slipDueDate != null
    ) {
      return true;
    }
    if (
      (bankSlip.tendency ? 'Sim' : 'Não')?.toString().toUpperCase().indexOf(value.toUpperCase()) != -1 &&
      bankSlip.slipDueDate != null
    ) {
      return true;
    }
    return false;
  }

  function ordemCrescente(item1: any, item2: any, prop: string) {
    if (item1[prop] > item2[prop]) return 1;
    if (item1[prop] < item2[prop]) return -1;
    return 0;
  }

  function ordemDecrescente(item1: any, item2: any, prop: string) {
    if (item1[prop] < item2[prop]) return 1;
    if (item1[prop] > item2[prop]) return -1;
    return 0;
  }

  // ordem /--/ 1 = crescente /--/ 0= sem ordenação /--/ -1 = decrescente
  function ordernar(nomeCampo: string) {
    const valorCampo = ordenacao[nomeCampo];

    const listTemp = filterData;
    if (valorCampo == 0) {
      listTemp.sort((item1: any, item2: any) => ordemCrescente(item1, item2, nomeCampo));
      setFilterData(listTemp);
      setOrdenacao({ ...objetoOrdenacaoZerado, [nomeCampo]: 1 });
    } else if (valorCampo == 1) {
      listTemp.sort((item1: any, item2: any) => ordemDecrescente(item1, item2, nomeCampo));
      setFilterData(listTemp);
      setOrdenacao({ ...objetoOrdenacaoZerado, [nomeCampo]: -1 });
    } else if (nomeCampo == 'slipDueDate' && valorCampo == 1) {
      listTemp.sort((item1: any, item2: any) => ordemDecrescente(item1, item2, 'slipDueDate'));
      setFilterData(listTemp);
      setOrdenacao({ ...objetoOrdenacaoZerado, slipDueDate: -1 });
    } else {
      listTemp.sort((item1: any, item2: any) => ordemCrescente(item1, item2, 'slipDueDate'));
      setFilterData(listTemp);
      setOrdenacao({ ...objetoOrdenacaoZerado, slipDueDate: 1 });
    }
  }

  function testarOrdenacao(nomeCampo: string): string {
    const valorCampo = ordenacao[nomeCampo];
    if (valorCampo == 0) {
      return 'decrescente';
    }
    if (valorCampo == 1) {
      return 'crescente';
    }

    return 'decrescente';
  }

  const countPages = (total: number) => {
    const pages = total / rowsPerPage;
    return Math.ceil(pages);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage);
  };

  const handleExport = async () => {
    const slipIds = query.slipids.split(',').map(function (item) {
      return parseInt(item, 10);
    });

    const requestOptions = {
      slipIds,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'arraybuffer',
    };

    if (query.shopping) {
      api
        .post(
          `/prioritization/shopping/slips/xls/${query.shopping}?slipDue=${slipDue}&slipFutureDue=${slipFutureDue}&slipNegociated=${slipNegociated}&slipOriginals=${slipOriginals}&slipBank=${slipBank}&slipAdministration=${slipAdministration}`,
          requestOptions,
        )
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(blob, response.headers.filename);
        });
    } else {
      api
        .post(
          `/prioritization/billing-group/xls/slips/${query.groupId}?slipIds=${query.slipids}&slipDue=${slipDue}&slipFutureDue=${slipFutureDue}&slipNegociated=${slipNegociated}&slipOriginals=${slipOriginals}&slipBank=${slipBank}&slipAdministration=${slipAdministration}`,
          requestOptions,
        )
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(blob, response.headers.filename);
        });
    }
  };

  return (
    <>
      <DivVoltar onClick={() => voltar()}>
        <p>&lt; Voltar para Detalhamento da cobrança</p>
      </DivVoltar>
      {loading ? (
        <div className="loaderUser">
          <Loader />
        </div>
      ) : (
        <>
          <Container>
            <Resume>
              <h2>Dados do Cliente</h2>
              <FilterBar>
                <Box disabled={!query.shopping}>
                  <Item>
                    <span>Shopping</span>
                    <p>{query?.shopping}</p>
                  </Item>
                  <Item>
                    <span>CPF/CNPJ</span>
                    <p>{query.cpf}</p>
                  </Item>
                  <Item>
                    <span>Razão Social</span>
                    <p>{query?.razaoSocial}</p>
                  </Item>
                </Box>

                <Box disabled={query.shopping}>
                  <Item>
                    <span>Grupo de Cobrança</span>
                    <p>{query?.groupName}</p>
                  </Item>
                </Box>

                <Box>
                  <Item>
                    <span>Mês e ano de inadimplência</span>
                    <p>{formatarData(query.blockEnd)}</p>
                  </Item>
                  <Item disabled={!query.shopping}>
                    <span>Classificação</span>
                    <p>{query.classificacao}</p>
                  </Item>
                </Box>
              </FilterBar>
            </Resume>
            <Check>
              <h2>Filtros</h2>
              <FilterCheck>
                <Checks>
                  <label>
                    <input type="checkbox" name="" checked={slipDue} onClick={() => clickCheck('slipDue')} />
                    <span>BOLETOS VENCIDOS</span>
                  </label>
                  <label>
                    <input type="checkbox" checked={slipFutureDue} onClick={() => clickCheck('slipFutureDue')} />
                    <span>BOLETOS A VENCER</span>
                  </label>
                </Checks>
                <Checks>
                  <label>
                    <input
                      type="checkbox"
                      checked={slipAdministration}
                      onClick={() => clickCheck('slipAdministration')}
                    />
                    <span>BOLETOS ADMINISTRATIVOS</span>
                  </label>
                  <label>
                    <input type="checkbox" checked={slipBank} onClick={() => clickCheck('slipBank')} />
                    <span>BOLETOS BANCÁRIOS</span>
                  </label>
                </Checks>
                <Checks>
                  <label>
                    <input type="checkbox" checked={slipNegociated} onClick={() => clickCheck('slipNegociated')} />
                    <span>BOLETOS NEGOCIADOS</span>
                  </label>
                  <label>
                    <input type="checkbox" checked={slipOriginals} onClick={() => clickCheck('slipOriginals')} />
                    <span>BOLETOS ORIGINAIS</span>
                  </label>
                </Checks>
              </FilterCheck>
              <FilterSearch>
                <Search>
                  <img src={iconSearch} />
                  <input
                    onChange={e => {
                      pegarFiltro(e.target.value);
                    }}
                  />
                </Search>
              </FilterSearch>
            </Check>
          </Container>
          <Tabela>
            <>
              <table>
                <thead>
                  <tr>
                    <th onClick={() => ordernar('luc')}>
                      LUC
                      <img src={iconArrow} className={testarOrdenacao('luc')} />
                    </th>
                    <th onClick={() => ordernar('branc')}>
                      Marca
                      <img src={iconArrow} className={testarOrdenacao('branc')} />
                    </th>
                    <th onClick={() => ordernar('contractTypeNumber')}>
                      Contrato
                      <img src={iconArrow} className={testarOrdenacao('contractTypeNumber')} />
                    </th>
                    <th onClick={() => ordernar('slipNumber')}>
                      Número do boleto
                      <img src={iconArrow} className={testarOrdenacao('slipNumber')} />
                    </th>
                    <th onClick={() => ordernar('slipType')}>
                      Original /
                      <br />
                      Negociado
                      <img src={iconArrow} className={testarOrdenacao('slipType')} />
                    </th>
                    <th onClick={() => ordernar('slipType')}>Tipo do boleto</th>
                    <th onClick={() => ordernar('slipAmount')}>
                      Valor
                      <img src={iconArrow} className={testarOrdenacao('slipAmount')} />
                    </th>
                    <th onClick={() => ordernar('slipDueDate')}>
                      Vencimento
                      <img src={iconArrow} className={testarOrdenacao('slipDueDate')} />
                    </th>
                    <th>Tendência</th>
                  </tr>
                </thead>
                <tbody>
                  {filterData
                    ?.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                    .map((item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.luc}</td>
                        <td>{item.branc}</td>
                        <td>{formatarVirgula(item.contractTypeNumber)}</td>
                        <td>
                          <button
                            type="button"
                            onClick={() => {
                              handleGeneratePdfBtn(item);
                            }}
                          >
                            {item.slipNumber}
                            <img src={pdfFile} alt="pdfFile" />
                          </button>
                        </td>
                        <td>{item.slipType}</td>
                        {item.isAdministrative ? <td>Administrativo</td> : <td>COBRANÇA</td>}
                        <td>
                          {item.slipAmount.toLocaleString('pt-br', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        </td>
                        <td>{formatarParaBrasileiro(item.slipDueDate)}</td>
                        <td>{item.tendency ? 'Sim' : 'Não'}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      <button onClick={() => handleExport()} type="button">
                        Exportar
                        <img src={bin} alt="" />
                      </button>
                    </td>
                    <td colSpan={2}>
                      <p style={{ textAlign: 'center' }}>
                        Total de Boletos:
                        {filterData?.length}
                      </p>
                    </td>
                    <td>
                      <p>Valor total:</p>
                    </td>
                    <td>
                      {filterData
                        ?.reduce((acc: any, v: any) => acc + v.slipAmount, 0)
                        .toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                    </td>
                    <td />
                    <td>
                      <PaginationWrapper>
                        <Pagination
                          count={countPages(filterData?.length)}
                          page={page}
                          onChange={(e, page) => handleChange(e, page)}
                        />
                      </PaginationWrapper>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </>
          </Tabela>
        </>
      )}
    </>
  );
};
