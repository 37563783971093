import React, { useReducer, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { Edit } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import api from '../../../../_core/api';
import * as S from '../styles';
import { FullScreenLoading } from '../../../../_core/_components/fullscreen-loading';
import { Button, Input, Table } from '../../../../_core/_components';
import { INITIAL_STATE, reducer } from './reducer';
import { handleErrors, success } from '../../../../_core/services/toast';
import { Profile } from './types';
import Modal from './modal';

export function Perfil(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [nomPerfil, setNomPerfil] = useState('');
  const [profilesRequest, setProfilesRequest] = useState<Profile[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [form, dispatch] = useReducer(reducer, INITIAL_STATE);

  useEffect(() => {
    getProfiles();
  }, []);

  const getProfiles = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/perfilizacao/pefil/buscar', {});
      setProfilesRequest(data.content);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const data = useMemo(() => {
    if (profilesRequest) {
      return profilesRequest
        .filter(e => e.nome.includes(nomPerfil))
        .map(e => ({
          ...e,
          ativo: e.flagAtivo ? 'Sim' : 'Não',
          editar: (
            <IconButton onClick={() => onSetProfile(e)}>
              <Edit />
            </IconButton>
          ),
        }));
    }
    return [];
  }, [profilesRequest, nomPerfil]);

  const onSetProfile = async (profile: Profile) => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/perfilizacao/pefil/permissoes/buscar', { idPerfil: profile.idPerfil! });
      const todasPermissoes = data.content.map((e: any) => ({
        ...e,
        value: e.idRegra,
        label: `${e.sistema.nome} - ${e.menu.label} - ${e.regra.descricao}`,
      }));
      const selectedProfile = {
        ...profile,
        todasPermissoes,
        permissoes: todasPermissoes.filter((e: any) => e.flagPermissao),
      };
      dispatch({ type: 'SET_PERFIL', payload: selectedProfile });
      setOpenModal(true);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const onAddPermission = async (idRegra: number) => {
    await api.post('/perfilizacao/pefil/permissao/salvar', {
      idPerfil: form.idPerfil,
      idRegra,
    });
  };

  const onRemovePermission = async (idRegra: number) => {
    await api.post('/perfilizacao/pefil/permissao/remover', {
      idPerfil: form.idPerfil,
      idRegra,
    });
  };

  const onEditPermissions = async (payload: any) => {
    setIsLoading(true);
    try {
      const addedItem: any[] = _.difference(payload, form.permissoes);
      const removedItem: any[] = _.difference(form.permissoes, payload);
      if (addedItem.length > 0) {
        await onAddPermission(addedItem[0].idRegra);
      } else {
        await onRemovePermission(removedItem[0].idRegra);
      }
      dispatch({ type: 'PERMISSAO', payload });
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  const onClickSave = async () => {
    setIsLoading(true);
    try {
      await api.post('/perfilizacao/pefil/salvar', {
        idPerfil: form?.idPerfil,
        nome: form.nome,
        codigoPerfil: form.codPerfil,
        flagAtivo: form.flagAtivo,
      });
      dispatch({ type: 'SET_PERFIL', payload: INITIAL_STATE });
      setOpenModal(false);
      success('Perfil salvo com sucesso.');
      const response = await api.post('/perfilizacao/pefil/buscar', {});
      setProfilesRequest(response.data.content);
    } catch (error) {
      handleErrors(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        form={form}
        dispatch={dispatch}
        onEditPermissions={onEditPermissions}
        onClickSave={onClickSave}
      />
      <FullScreenLoading isEnabled={isLoading} />
      <S.Content>
        <S.Filter>
          <Input state={[nomPerfil, setNomPerfil]} label="Buscar por nome do perfil" style={{ width: '35%' }} />
          <Button
            text="Novo"
            onClick={() => {
              dispatch({ type: 'SET_PERFIL', payload: INITIAL_STATE });
              setOpenModal(true);
            }}
            styles={{ width: 100 }}
          />
        </S.Filter>
        {profilesRequest && (
          <Table
            columns={[
              { label: 'Perfil', key: 'nome' },
              { label: 'Ativo', key: 'ativo' },
              { label: 'Editar', key: 'editar' },
            ]}
            data={data}
          />
        )}
      </S.Content>
    </>
  );
}
