import { mascaraNumeracaoPtBr } from '../../../../_core/masks';
import { Guia } from '../types';

export type GuiaPagamentoMask = {
  id: number;
  comprovantePagamento: boolean;
  valor: string;
  numeroPO: string;
  numeroChamadoPagamento: string;
  numeroChamadoAjuizamento: string;
  statusChamadoAjuizamento: string;
  numeroProtocolo: string;
  dataAjuizamento: string;
};

const convertFromDatabaseDate = (date: string): string => {
  const arr = date.split('-');
  return `${arr[2]}/${arr[1]}/${arr[0]}`;
};

export const convertToDatabaseDate = (date: string): string => {
  const arr = date.split('/');
  return `${arr[2]}-${arr[1]}-${arr[0]}`;
};

export const convertGuiasPagamentoMask = (guias: Guia[]): GuiaPagamentoMask[] => {
  return guias.map(e => ({
    id: e.id,
    comprovantePagamento: e.comprovantePagamento,
    numeroPO: e.numeroPO ?? '',
    numeroChamadoPagamento: e.numeroChamadoPagamento ?? '',
    numeroChamadoAjuizamento: e.numeroChamadoAjuizamento ?? '',
    statusChamadoAjuizamento: e.statusChamadoAjuizamento ?? '',
    numeroProtocolo: e.numeroProtocolo ?? '',
    valor: e.valor ? mascaraNumeracaoPtBr(e.valor.toString()) : '',
    dataAjuizamento: e.dataAjuizamento ? convertFromDatabaseDate(e.dataAjuizamento) : '',
  }));
};
