import React, { useMemo, useState } from 'react';
import { Container, SideMenu, ItemMenu } from './styles';
import RequisitosMinimos from './requisitos-minimos';
import TiposDeContrato from './tipos-contrato';
import TiposDeAcao from './tipos-acao';
import ExcecaoCPF from './excecao-cpf';
import AssociacaoTipoAcao from './associacao-tipo-acao';
import ContaContabil from './conta-contabil';

const MENU_LIST = [
  'Requisitos Mínimos',
  'Associação para Tipo Ação',
  'Conta Contábil',
  'Exceção CPF/CNPJ',
  'Tipo(s) de Contrato',
  'Tipo(s) de Ação',
];

export const CadastroAjuizamento: React.FC = () => {
  const [menu, setMenu] = useState(0);

  const menuItem = useMemo(() => {
    switch (menu) {
      case 0:
        return <RequisitosMinimos />;
      case 1:
        return <AssociacaoTipoAcao />;
      case 2:
        return <ContaContabil />;
      case 3:
        return <ExcecaoCPF />;
      case 4:
        return <TiposDeContrato />;
      case 5:
        return <TiposDeAcao />;
      default:
        return null;
    }
  }, [menu]);

  return (
    <Container>
      <SideMenu>
        {MENU_LIST.map((item: any, i: number) => (
          <ItemMenu key={MENU_LIST[i]} onClick={() => setMenu(i)} active={i === menu}>
            {item}
          </ItemMenu>
        ))}
      </SideMenu>

      {menuItem}
    </Container>
  );
};
