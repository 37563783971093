import React from 'react';
import ICheckboxProps from './ICheckbox';

import { Label } from './Checkbox.styles';

export const Checkbox: React.FC<ICheckboxProps> = ({ name, onChange }) => {
  return (
    <Label>
      <input type="checkbox" name={name} onChange={onChange} />
      <span></span>
    </Label>
  );
};
