import styled from 'styled-components';
import Pages from '@material-ui/lab/Pagination';

interface IMenuActive {
  active?: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SideMenu = styled.div`
  width: 28%;
  background-color: #fff;
  margin: 0 1rem;
  border-radius: 17px;
  position: relative;
  top: -2.6rem;
  padding: 1rem 1.5rem;
`;

export const ItemMenu = styled.div<IMenuActive>`
  border-radius: 1rem;
  color: ${({ active }) => (active ? '#00959C' : '#8890B5')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};

  padding: 1rem 0;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  width: 72%;
  padding: 1rem 3rem;
  background-color: #fff;
  margin: 0 1rem;
  border-radius: 17px;
  position: relative;
  top: -2.6rem;
`;

export const TopBar = styled.div`
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
`;

export const SearchBar = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SearchField = styled.div`
  margin-right: 5rem;
  position: relative;
  svg {
    fill: #208cb9;
    position: absolute;
    right: 2rem;
    top: 1.4rem;
    width: 2.2rem !important;
    height: 2.2rem !important;
  }
`;

export const SimpleSelect = styled.select`
  border: none;
  background-color: white;

  padding: 1rem 5rem;
  border-radius: 4rem;
  color: #7e9395;
`;
export const SimpleSelectOption = styled.option``;

export const SearchInput = styled.input`
  border: none;

  padding: 1rem 6rem 1rem 3rem;
  border-radius: 4rem;
  color: #7e9395;
`;

export const AddButton = styled.button`
  border: 0;
  background-color: transparent;
  color: #7e9395;

  line-height: 3.2rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;

  :hover {
    background-color: white;
    color: #31c6d3;
    padding: 0 2rem;

    border-radius: 1.3rem;
  }

  img {
    margin-left: 1rem;
    width: 3rem;
  }
`;

export const DataContainer = styled.div`
  width: 80%;
  padding: 0 3rem;
`;

export const Table = styled.table`
  border-collapse: collapse;
  white-space: nowrap;
  width: 100%;
  margin-bottom: 2rem;
  tr {
    &:nth-child(odd) {
      background: #ffffff 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &:nth-child(even) {
      background: #e5e9ea 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    &:first-child {
      background-color: #31c6d3;
      padding: 1.6rem;
      border-radius: 0.8rem 0.8rem 0rem 0rem;
      color: #fff;

      font-weight: 600;
      line-height: 4.3rem;
    }
    td {
      padding: 2.5rem 1.2rem;
      text-align: left;
      color: #3f3f3f;

      text-transform: uppercase;
      text-align: left;
    }
    th {
      text-align: left;
      padding: 0 1.8rem;
    }
  }
`;

export const ListActions = styled.div``;

export const ListActionItem = styled.div`
  padding: 1.5rem 1.5rem;
  cursor: pointer;

  transition: all 0.5s ease;

  :hover {
    background-color: #7e9395;
    color: white;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 50rem;
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
`;

export const Pagination = styled(Pages)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    flex-wrap: nowrap;
    padding-left: 4rem;
  }
  li {
    width: 4rem;
    height: 4rem;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1px;

    button {
      border-radius: 0 !important;
      color: #7e9395 !important;
      font-weight: 600 !important;
      span {
        border-radius: 0 !important;
      }
    }

    .MuiPaginationItem-page.Mui-selected {
      background-color: #00959c27;
    }

    .MuiPaginationItem-page:hover {
      background-color: #00959c27;
    }
  }
`;
