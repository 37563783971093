import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Button, SecondaryButton, SubmitContainer, Title } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export function Confirmation({ open, onClose, onConfirm }: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={onClose} aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <Title>Deseja enviar os boletos apresentados em tela para a próxima etapa de aprovação do workflow?</Title>
      </DialogContent>
      <SubmitContainer>
        <SecondaryButton onClick={onClose}>Cancelar</SecondaryButton>
        <Button onClick={onConfirm}>OK</Button>
      </SubmitContainer>
    </Dialog>
  );
}
