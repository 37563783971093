import React, { useMemo, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import _ from 'lodash';
import {
  Container,
  CustomTable,
  HeaderItem,
  PaginationWrapper,
  Row,
  RowItem,
  Orderable,
  Footer,
  FooterRow,
  FooterItem,
  SubRowItem,
} from './styles';
import ArrowDown from '../../../assets/downArrow';
import { Props, SortOptions, TableHeader } from './types';

const ROWS_PER_PAGE = 20;

export const Table: React.FC<Props> = ({ columns, data, footer, onRowClick }) => {
  const [page, setPage] = useState(1);
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    key: '',
    type: undefined,
  });

  const dataPaginated = useMemo(() => {
    const orderableArr = sortOptions.type ? _.orderBy(data, sortOptions.key, sortOptions.type) : data;
    let arr = [];
    arr = orderableArr.slice((page - 1) * ROWS_PER_PAGE, (page - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE);
    return arr;
  }, [data, page, sortOptions]);

  const onClickOrderable = (item: TableHeader) => {
    // Já clickou antes
    if (item.key === sortOptions.key) {
      switch (sortOptions.type) {
        case 'asc':
          setSortOptions({
            key: item.key,
            type: 'desc',
          });
          break;
        case 'desc':
          setSortOptions({
            key: '',
            type: undefined,
          });
          break;
        default:
          setSortOptions({
            key: item.key,
            type: 'asc',
          });
          break;
      }
      return;
    }
    // Primeiro Click
    setSortOptions({
      key: item.key,
      type: 'asc',
    });
  };

  const renderHeaderItem = (item: TableHeader) => {
    if (item.orderable) {
      const arrowDirection = item.key === sortOptions.key ? sortOptions.type : undefined;
      return (
        <Orderable onClick={() => onClickOrderable(item)} arrowDirection={arrowDirection}>
          {item.label}
          <ArrowDown click={null} color="white" />
        </Orderable>
      );
    }
    return item.label;
  };

  const renderRowItem = (item: any) => {
    return columns.map((header, i) => <RowItem key={i}>{item[header.key]}</RowItem>);
  };

  const renderSubRowItem = (subItems: any) => {
    return columns.map((header, i) => <SubRowItem key={i}>{subItems[i] ? subItems[i] : ''}</SubRowItem>);
  };

  return (
    <Container>
      <CustomTable>
        <thead>
          <tr>
            {columns.map((item, i) => (
              <HeaderItem key={i}>{renderHeaderItem(item)}</HeaderItem>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataPaginated.map((item, i) => (
            <React.Fragment key={i}>
              <Row
                style={{
                  backgroundColor: i % 2 === 0 ? '#ffffff' : '#e5e9ea',
                }}
                onClick={() => (onRowClick ? onRowClick(item) : null)}
              >
                {renderRowItem(item)}
              </Row>
              {item.subItems?.length > 0 && (
                <Row
                  style={{
                    backgroundColor: i % 2 === 0 ? '#ffffff' : '#e5e9ea',
                  }}
                  onClick={() => (onRowClick ? onRowClick(item) : null)}
                >
                  {renderSubRowItem(item.subItems)}
                </Row>
              )}
            </React.Fragment>
          ))}
        </tbody>
        {footer && (
          <Footer>
            <FooterRow>
              {footer.map((item, i) => (
                <FooterItem key={i}>{item}</FooterItem>
              ))}
            </FooterRow>
          </Footer>
        )}
      </CustomTable>
      <PaginationWrapper>
        <Pagination count={Math.ceil(data.length / 20)} page={page} onChange={(e, i) => setPage(i)} />
      </PaginationWrapper>
    </Container>
  );
};

export default Table;
