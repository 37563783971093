import React, { useContext, useState } from 'react';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { Collapse } from '@material-ui/core';
import List from '@material-ui/core/List';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AvTimerOutlinedIcon from '@material-ui/icons/AvTimerOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import { AuthContext } from '../contexts/auth';
import { MenuItem, IconStyle, ArrowStyle, MenuText, SubMenuItem, SubMenuText } from './styles';
import { Route } from '../routes/_route';

export function DrawerContent(): JSX.Element {
  const { routes } = useContext(AuthContext);
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();
  const [openSubMenu, setOpenSubMenu] = useState<number[]>([]);

  const onClickMenuItem = (index: number) => {
    const arr = [...openSubMenu];
    if (arr.includes(index)) {
      const i = arr.indexOf(index);
      arr.splice(i, 1);
    } else {
      arr.push(index);
    }

    setOpenSubMenu(arr);
  };

  const onClickRoute = (url: string) => {
    setOpenSubMenu([]);
    history.push(url);
  };

  const sortedRoutes = _.sortBy(routes, e => e.nome)
    .filter(r => r.nome)
    .filter(item => item.nome !== 'Boletos');
  const indexConf = sortedRoutes.findIndex(s => s.nome === 'Configurações');
  sortedRoutes.push(sortedRoutes.splice(indexConf, 1)[0]);

  const findOpenRouteIndex = () => {
    return sortedRoutes.findIndex(r => {
      return r.menus.find(m => {
        return m.url === currentPath;
      });
    });
  };

  const getIcon = (item: Route) => {
    if (item.nome === 'Cobranca') {
      return <AccountBalanceWalletOutlinedIcon style={IconStyle} />;
    }

    if (item.nome === 'Boletos') {
      return <PaymentOutlinedIcon style={IconStyle} />;
    }

    if (item.nome === 'Inadimplência') {
      return <FileCopyOutlinedIcon style={IconStyle} />;
    }

    if (item.nome === 'Serasa') {
      return <AvTimerOutlinedIcon style={IconStyle} />;
    }

    if (item.nome === 'Configurações') {
      return <SettingsOutlinedIcon style={IconStyle} />;
    }

    return <AccountBalanceIcon style={IconStyle} />;
  };
  return (
    <List>
      {sortedRoutes.map((item, index) => (
        <div key={index}>
          <MenuItem key={index} onClick={() => onClickMenuItem(index)}>
            {getIcon(item)}
            <MenuText>{item.nome}</MenuText>
            <KeyboardArrowRightIcon style={ArrowStyle} />
          </MenuItem>
          <Collapse in={openSubMenu.includes(index) || index === findOpenRouteIndex()}>
            <List>
              {item.menus.map((route, i) => (
                <SubMenuItem key={i} onClick={() => onClickRoute(route.url)}>
                  <SubMenuText active={route.url === currentPath}>{route.label}</SubMenuText>
                </SubMenuItem>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </List>
  );
}
