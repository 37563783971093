import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #00959c;
  padding: 2.8rem 2rem;
  margin-bottom: 1rem;
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 1.8rem;
`;

export const BreadCrumb = styled.span`
  color: #fff;
`;
