import React, { useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import moment from 'moment';
import {
  DialogContent,
  DateText,
  Title,
  MainContent,
  TextBox,
  Label,
  ModalActions,
  FieldArea,
  TextInput,
  TopContent,
} from './styles';
import { Button, SingleSelect, Option } from '../../../../_core/_components';
import { TipoCancelamento } from '../types';

export type OnSaveParams = {
  descricao: string;
  numeroChamado?: string;
  motivo?: Option;
};

type Props = {
  onCloseDesistencia: Function;
  tipoCancelamento: TipoCancelamento;
  onSave: (params: OnSaveParams) => void;
};

const MOTIVOS = [
  { value: 1, label: 'Erro de Faturamento' },
  { value: 2, label: 'Perdão de Dívida' },
  { value: 3, label: 'Negociação Comercial' },
  { value: 4, label: 'Confissão de Dívida' },
  { value: 5, label: 'Depósito Judicial' },
  { value: 6, label: 'Saldo Insuficiente' },
  { value: 7, label: 'Distrato Cadastrado' },
];

const Cancelamento: React.FC<Props> = ({ onCloseDesistencia, tipoCancelamento, onSave }) => {
  const [descricao, setDescricao] = useState('');
  const [numeroChamado, setNumeroChamado] = useState('');
  const [motivo, setMotivo] = useState<Option>();

  const disabled = useMemo(() => {
    if (descricao === '') return true;
    if (tipoCancelamento === 'DESISTENCIA') {
      return numeroChamado === '' || motivo === undefined;
    }
    return false;
  }, [descricao, numeroChamado, motivo]);

  const onClickSave = () => {
    if (tipoCancelamento === 'DESISTENCIA') {
      return onSave({
        descricao,
      });
    }
    return onSave({
      descricao,
      numeroChamado,
      motivo,
    });
  };

  return (
    <Dialog fullWidth maxWidth="md" open onClose={() => onCloseDesistencia()} aria-labelledby="max-width-dialog-title">
      <DialogContent>
        <Title>{tipoCancelamento === 'DESISTENCIA' ? 'Desistência da Ação' : 'Registrar Exceção'}</Title>

        {tipoCancelamento !== 'DESISTENCIA' && (
          <TopContent>
            <FieldArea>
              <Label>Número do Chamado</Label>
              <TextInput value={numeroChamado} onChange={e => setNumeroChamado(e.target.value)} />
            </FieldArea>

            <FieldArea>
              <Label>Motivo</Label>
              <SingleSelect state={[motivo, setMotivo]} options={MOTIVOS} />
            </FieldArea>
          </TopContent>
        )}

        <MainContent>
          {tipoCancelamento === 'DESISTENCIA' && (
            <>
              <Label>Data da Desistência</Label>
              <DateText>{moment().format('DD/MM/YYYY')}</DateText>
            </>
          )}

          <Label>Descrição do motivo</Label>
          <TextBox value={descricao} onChange={e => setDescricao(e.target.value)} />
        </MainContent>

        <ModalActions>
          <Button disabled={disabled} onClick={onClickSave} text="Salvar" />
        </ModalActions>
      </DialogContent>
    </Dialog>
  );
};

export default Cancelamento;
